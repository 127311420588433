import { FieldFormV2Module } from "@ucl/library";
import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../../../../common/Components/Error/ErrorStore";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { useNavigate } from "react-router";
import { useHomeFieldSchemaFactory } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { fortifiedHomeNCIUARoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { HomeNCIUAEvaluationFormProps } from "./HomeNCIUAEvaluationForm";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../../../evaluation/types/HomeNCIUAEvaluationFormModel";
import { NCIUAEvaluationForm_FormParts } from "../../../../evaluation/types/HomeNCIUAEvaluation_EvaluationFormModel";
import { homeNCIUAEvaluationAPIClient } from "../../../../evaluation/lib/apiClients/homeNCIUAEvaluationAPIClient";
import { homeNCIUAEvaluationFormAPIClient } from "../../../../evaluation/lib/apiClients/homeNCIUAEvaluationFormAPIClient";

function useHomeNCIUAEvaluationForm(props: HomeNCIUAEvaluationFormProps) {
  const { builders, wieBuilders } = useHomeFieldSchemaFactory();
  const navigate = useNavigate();

  const formRef =
    useRef<FieldFormV2Module<Evaluation_HomeNCIUAEvaluationFormModel>>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formModel, setFormModel] =
    useState<Evaluation_HomeNCIUAEvaluationFormModel>();

  const [isInitialized, setIsInitialized] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
      props.onInitialized?.();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isInitialized) {
      props.onInitialized?.();
    }
  }, [isInitialized]);

  useEffect(() => {
    //Used to React to External Changes Pushed on the Form (Iteration Engine)
    if (formModel) {
      setFormModel({ ...formModel });
    }
  }, [wieBuilders]);

  useEffect(() => {
    fetchForm();
  }, []);

  useEffect(() => {
    if (!!formModel) {
      setFormModel(formModel);

      if (props.setFormModel) {
        props.setFormModel(formModel);
      }
    }
  }, [formModel]);

  // Differentiates between save and submit
  let shouldSubmit = false;
  const setShouldSubmit = (value: boolean) => {
    shouldSubmit = value;
  };

  const isFirstPartOfForm =
    props.formPart === Object.values(NCIUAEvaluationForm_FormParts)[0];

  const isLastPartOfForm =
    props.formPart === Object.values(NCIUAEvaluationForm_FormParts).pop();

  const noBuilders = !builders && !wieBuilders;

  const fetchForm = async () => {
    setIsLoading(true);
    await homeNCIUAEvaluationAPIClient
      .getHomeNCIUAEvaluationFormModel(props.evaluationId)
      .then((response) => {
        setFormModel((prevModel) => ({
          ...prevModel,
          ...response,
        }));
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Debounce setup
  const handleFormSubmitDebounced = useCallback(
    debounce(
      async (
        form: Evaluation_HomeNCIUAEvaluationFormModel,
        _value: any,
        fieldProps: BaseFieldProps<any>
      ) => {
        if (fieldProps.fieldKey) {
          await handleFormSubmit(form, fieldProps.fieldKey).then(async () => {
            await props.onFormSave?.(fieldProps.fieldKey || "", _value);
          });
        }
      },
      2000
    ),
    []
  );

  useEffect(() => {
    // Cleanup the debounce function on component unmount
    return () => handleFormSubmitDebounced.cancel();
  }, [handleFormSubmitDebounced]);

  const onFormFieldChange = async (
    form: Evaluation_HomeNCIUAEvaluationFormModel,
    _value: any,
    fieldProps: BaseFieldProps<any>
  ) => {
    // Call the debounced submit function
    if (!!_value) {
      handleFormSubmitDebounced(form, _value, fieldProps);
      props.setHasUnsavedChanges(true);
    }
  };

  const getApplicationFormPartBuilder = () => {
    if (props.isIterationEnginePage) {
      return wieBuilders?.HomeNCIUAIterationEngineEvaluationForm;
    }
    switch (props.formPart) {
      case NCIUAEvaluationForm_FormParts.Information:
        return builders?.HomeNCIUAEvaluationInformation;
      default:
        return builders?.HomeNCIUAEvaluationForm;
    }
  };

  const handleFormSubmit = async (
    form: Evaluation_HomeNCIUAEvaluationFormModel,
    fieldKey?: string
  ) => {
    setFormModel(form);
    await (shouldSubmit
      ? homeNCIUAEvaluationFormAPIClient.submitHomeNCIUAEvaluationForm(
          form.id,
          form.homeNCIUAEvaluation.homeNCIUAEvaluationForm
        )
      : homeNCIUAEvaluationFormAPIClient.updateHomeNCIUAEvaluationForm(
          form.id,
          form.homeNCIUAEvaluation.homeNCIUAEvaluationForm,
          fieldKey
        )
    )
      .then(async (response) => {
        handleFormResponse(response);
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while saving the form",
            intent: Intent.DANGER,
          });
        }
        throw error;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleFormResponse = async (
    response: Evaluation_HomeNCIUAEvaluationFormModel
  ) => {
    props.setHasUnsavedChanges(false);

    if (shouldSubmit) {
      setFormModel(response);

      if (isLastPartOfForm) {
        navigate(
          `${fortifiedHomeNCIUARoutePrefix}/iteration-engine/${props.evaluationId}`
        );
      } else {
        // Navigate to next part of form
        const nextFormPart = Object.values(NCIUAEvaluationForm_FormParts)[
          Object.values(NCIUAEvaluationForm_FormParts).indexOf(
            props.formPart!
          ) + 1
        ].valueOf();

        navigate(
          `${fortifiedHomeNCIUARoutePrefix}/${props.evaluationId}/evaluation-form/${nextFormPart}`
        );
      }

      // }
    } else {
      handlePartialResponseFormUpdate(response);
    }
  };

  const handlePartialResponseFormUpdate = (
    response: Evaluation_HomeNCIUAEvaluationFormModel
  ) => {
    //This is used to combat save on blur issues. This only updates fields that are essetnial to the form.
    //These are set in the API response. Field Counts and sub section completion status
    setFormModel((prevState) => {
      if (!prevState) return prevState;

      const {
        informationRequiredFieldCount,
        informationLastSubmittedAt,
        informationHasBeenSubmitted,
        isInformationComplete,
        areAllRequiredFieldsComplete,
      } = response.homeNCIUAEvaluation.homeNCIUAEvaluationForm;

      return {
        ...prevState,
        homeNCIUAEvaluation: {
          ...prevState.homeNCIUAEvaluation,
          homeNCIUAEvaluationForm: {
            ...prevState.homeNCIUAEvaluation.homeNCIUAEvaluationForm,
            informationRequiredFieldCount,
            informationLastSubmittedAt,
            informationHasBeenSubmitted,
            isInformationComplete,
            areAllRequiredFieldsComplete,
          },
        },
      };
    });
  };

  return {
    builders,
    wieBuilders,
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    formModel,
    isFirstPartOfForm,
    isLastPartOfForm,
    getApplicationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    noBuilders,
    containerRef,
    setShouldSubmit,
  };
}

export default useHomeNCIUAEvaluationForm;
