import {
  useEvaluationChangeRequest,
  UseEvaluationChangeRequestProps,
} from "../../../../../common/customHooks/IterationEngine/ChangeRequests/useEvaluationChangeRequest";
import {
  UseEvaluationCommentsProps,
  useEvaluationIterationEngineComments,
} from "../../../../../common/customHooks/IterationEngine/Comments/useEvaluationIterationEngineComments";
import { useContext, useEffect, useRef, useState } from "react";
import { ChangeRequestV2 } from "../../../../../wildfire/components/IterationEngine/types/ChangeRequestV2";
import { fortifiedUserStore } from "../../../../../foritfied/stores/FortifiedUserStore";
import { fortifiedEvaluationCommentAPIClient } from "../../../../Common/lib/apiClients/fortifiedCommentAPIClient";
import { fortifiedChangeRequestApiClient } from "../../../../Common/lib/apiClients/fortifiedChangeRequestAPIClient";
import { HomeSchema } from "../../../customHooks/useHomeFieldSchemaFactory";
import { getThirdLevelNesting } from "../../../../Common/customHooks/useFortifiedChangeRequestNavigator";
import { useHomeStandardEvaluation } from "../../customHooks/useHomeStandardEvaluation";
import { AppHeaderContext } from "../../../../../wildfire/components/Provider/AppHeaderContext";

export const useHomeStandardIterationEngine = (
  evaluationId: string,
  commercialSchema: HomeSchema
) => {
  const {
    homeStandardEvaluation,
    setHomeStandardEvaluation,
    refreshHomeStandardEvaluation,
    isApplicationLoading,
  } = useHomeStandardEvaluation(evaluationId);

  const useEvaluationIterationEngineCommentsProps: UseEvaluationCommentsProps =
    {
      getEvaluationComments:
        fortifiedEvaluationCommentAPIClient.getEvaluationComments,
      createEvaluationComments:
        fortifiedEvaluationCommentAPIClient.createEvaluationComments,
      createEvaluationComment:
        fortifiedEvaluationCommentAPIClient.createEvaluationComment,
      deleteEvaluationComment:
        fortifiedEvaluationCommentAPIClient.deleteEvaluationComment,
    };

  const {
    comments,
    setComments,
    createComment,
    deleteComment,
    selectedKey,
    selectedField,
    setSelectedFieldAndGetComments,
    setSelectedKeyAndGetComments,
  } = useEvaluationIterationEngineComments(
    useEvaluationIterationEngineCommentsProps
  );

  const useEvaluationChangeRequestProps: UseEvaluationChangeRequestProps = {
    evaluationId: evaluationId,
    schema: commercialSchema,
    getChangeRequest: fortifiedChangeRequestApiClient.getChangeRequest,
    getChangeRequestsByEvaluation:
      fortifiedChangeRequestApiClient.getChangeRequestsByEvaluation,
    resolveChangeRequest: fortifiedChangeRequestApiClient.resolveChangeRequest,
    unresolveChangeRequest:
      fortifiedChangeRequestApiClient.unresolveChangeRequest,
  };

  const {
    allChangeRequests,
    setAllChangeRequests,
    updateChangeRequest,
    resolveChangeRequest,
    unresolveChangeRequest,
    unresolvedChangeRequests,
    hasNoUnaddressedChangeRequests,
    addressedAtLeastOneChangeRequest,
    resolvedAllChangeRequests,
    getChangeRequests,
    changeRequestCount,
    resolvedChangeRequestCount,
    getEvaluationFieldKeysForAllUnresolvedChangeRequests,
    resolvedChangeRequestsPercentage,
    hasOneNewChangeRequest,
    longestOutstandingChangeRequest,
  } = useEvaluationChangeRequest(useEvaluationChangeRequestProps);

  const defaultTabId = "BasicInformationForm";

  const [
    selectedIterationEngineFormTabId,
    setSelectedIterationEngineFormTabId,
  ] = useState<string>(defaultTabId);
  const hasSetInitialTab = useRef(false);

  useEffect(() => {
    if (unresolvedChangeRequests.length === 0 || hasSetInitialTab.current) {
      return;
    }

    const initialTab = getInitialTabFromChangeRequests(
      unresolvedChangeRequests
    );

    setSelectedIterationEngineFormTabId(initialTab);
    hasSetInitialTab.current = true;
  }, [allChangeRequests]);

  const getInitialTabFromChangeRequests = (
    unresolvedChangeRequests: ChangeRequestV2[]
  ): string => {
    return unresolvedChangeRequests.length > 0
      ? getThirdLevelNesting(unresolvedChangeRequests[0].evaluationFieldKey) ||
          defaultTabId
      : defaultTabId;
  };

  useEffect(() => {
    return () => {
      setDefaultHeader();
    };
  }, []);

  const { setFotifiedHomeEvaluationHeader, setDefaultHeader } =
    useContext(AppHeaderContext);

  useEffect(() => {
    if (homeStandardEvaluation) {
      setFotifiedHomeEvaluationHeader(homeStandardEvaluation);
    }
  }, [homeStandardEvaluation]);

  const canEditForm = fortifiedUserStore.IsFCFMFAdmin;

  const baseTabs = [
    {
      id: "BasicInformationForm",
      label: "Basic Information Form",
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation?.basicInformationForm,
    },
    {
      id: "EligibilityForm",
      label: "Eligibility Form",
      hidden: !homeStandardEvaluation?.homeStandardEvaluation?.eligibilityForm,
    },
    {
      id: "RooferConfirmationForm",
      label: "Roofer Confirmation Form",
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation?.rooferConfirmationForm,
    },
    {
      id: "RoofingComplianceGeneralInformationForm",
      label: "Roofing Compliance General Information Form",
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.roofingComplianceGeneralInformationForm,
    },
    {
      id: "RoofingComplianceRoofCoverForm",
      label: "Roofing Compliance Roof Cover Form",
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.roofingComplianceRoofCoverForm,
    },
    {
      id: "EngineerConfirmationForm",
      label: "Engineer Confirmation Form",
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.engineerConfirmationForm,
    },
    {
      id: "SilverGoldComplianceGeneralInformationForm",
      label: "Silver/Gold Compliance General Information Form",
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.silverGoldComplianceGeneralInformationForm,
    },
    {
      id: "InstallingContractorConfirmationForm",
      label: "Installing Contractor Confirmation Form",
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.installingContractorConfirmationForm,
    },
    {
      id: "InstallerComplianceGablesForm",
      label: "Installer Compliance Gables Form",
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.installerComplianceGablesForm,
    },
    {
      id: "InstallerComplianceContinuousLoadPathForm",
      label: "Installer Compliance Continuous Load Path Form",
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.installerComplianceContinuousLoadPathForm,
    },
    {
      id: "InstallerComplianceChimneyForm",
      label: "Installer Compliance Chimney Form",
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.installerComplianceChimneyForm,
    },
    {
      id: "InstallerComplianceAttachedStructureForm",
      label: "Installer Compliance Attached Structure Form",
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.installerComplianceAttachedStructureForm,
    },
    {
      id: "EngineeringComplianceGeneralInformationForm",
      label: "Engineering Compliance General Information Form",
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceGeneralInformationForm,
    },
    {
      id: "EngineeringComplianceGablesForm",
      label: "Engineering Compliance Gables Form",
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceGablesForm,
    },
    {
      id: "EngineeringComplianceContinuousLoadPathForm",
      label: "Engineering Compliance Continuous Load Path Form",
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceContinuousLoadPathForm,
    },
    {
      id: "EngineeringComplianceChimneyForm",
      label: "Engineering Compliance Chimney Form",
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceChimneyForm,
    },
    {
      id: "EngineeringComplianceAttachedStructureForm",
      label: "Engineering Compliance Attached Structure Form",
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceAttachedStructureForm,
    },
  ];
  //const additionalTabs = [{ id: PlusTabId, label: "Plus" }];
  const formTabs = [...baseTabs.filter((tab) => !tab.hidden)];

  return {
    //Evaluation
    homeStandardEvaluation,
    setHomeStandardEvaluation,
    refreshHomeStandardEvaluation,
    isApplicationLoading,
    //Comments
    comments,
    setComments,
    createComment,
    deleteComment,
    selectedKey,
    selectedField,
    setSelectedFieldAndGetComments,
    setSelectedKeyAndGetComments,
    //ChangeRequests
    allChangeRequests,
    setAllChangeRequests,
    updateChangeRequest,
    resolveChangeRequest,
    unresolveChangeRequest,
    unresolvedChangeRequests,
    hasNoUnaddressedChangeRequests,
    addressedAtLeastOneChangeRequest,
    resolvedAllChangeRequests,
    getChangeRequests,
    changeRequestCount,
    resolvedChangeRequestCount,
    getEvaluationFieldKeysForAllUnresolvedChangeRequests,
    resolvedChangeRequestsPercentage,
    hasOneNewChangeRequest,
    longestOutstandingChangeRequest,
    //Iteration Engine
    setSelectedIterationEngineFormTabId,
    selectedIterationEngineFormTabId,
    canEditForm,
    formTabs,
  };
};
