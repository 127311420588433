import React, { useContext, useRef } from "react";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../../../AppInsights";
import {
  PermissionsContext,
  PermissionsContextProps,
  PrimaryButton,
  PrimaryGrid,
  PrimaryGridModule,
} from "@ucl/library";
import variables from "../../../../common/config/variables";
import { wildfireUserStore } from "../../../stores/WildfireUserStore";
import wildfireBaseAPIClient, {
  getAuthRequestHeader,
} from "../../../lib/wildfireBaseApiClient";
import { CommentTemplateModelGridReadDTO } from "./dtos/CommentTemplateModelGridReadDTO";
import { getWildfireCommentTemplateGridColumnsConfigurationByRole } from "./configurations/WildfireCommentTemplateGridColumnsConfiguration";
import { openCommentTemplateDialog } from "../../../../fortifiedV2/Common/components/Dialogs/CommentTemplate/CommentTemplateDialog";
import "../styles.scss";

export interface WildfireCommentTemplateGridProps {}

const WildfireCommentTemplateGridComponent: React.FC<
  WildfireCommentTemplateGridProps
> = () => {
  const commentTemplateGridRef = useRef<PrimaryGridModule>(null);

  const { hasPermission } = useContext(
    PermissionsContext
  ) as PermissionsContextProps<PermissionsKey>;

  return (
    <div className="wildfire-board">
      <PrimaryButton
        content="Create New Comment Template"
        className="wildfire-board__add-comment-template-button"
        iconCss="e-icons e-plus"
        onClick={() => {
          openCommentTemplateDialog({
            isWildfireSystem: true,
            refreshGrid: () =>
              commentTemplateGridRef.current
                ?.getGridModule()
                .getGridRef()
                ?.refresh(),
          });
        }}
      ></PrimaryButton>
      <PrimaryGrid<CommentTemplateModelGridReadDTO, {}>
        gridKey="wildfire-comment-template-grid"
        ref={commentTemplateGridRef}
        showGridFilters
        apiClient={wildfireBaseAPIClient}
        odataUrl={`${variables.apiBaseUrl}odata/commenttemplateswildfireodata`}
        getAuthRequestHeader={getAuthRequestHeader}
        columnsConfiguration={getWildfireCommentTemplateGridColumnsConfigurationByRole(
          wildfireUserStore.user?.role
        )}
        viewsSettings={{
          gridTitle: "Comment Templates Dashboard",
          showGridViewPicker: hasPermission(
            "CanViewConfigureUsersGridColumnsButton"
          ),
          showUserDefaultView: true,
        }}
        gridConfiguration={{
          allowExcelExport: hasPermission(
            "CanViewExportToExcelUsersGridButton"
          ),
          defaultSort: {
            field: "createdAt_AsDateOnly",
            direction: "Descending",
          },
          onRecordClick: ({ rowData }) => {
            hasPermission("CanEditUsers") &&
              openCommentTemplateDialog({
                refreshGrid: () =>
                  commentTemplateGridRef.current
                    ?.getGridModule()
                    .getGridRef()
                    ?.refresh(),
                commentTemplateId: rowData.id,
                isWildfireSystem: true,
              });
          },
        }}
      />
    </div>
  );
};

export const WildfireCommentTemplateGrid = withAITracking(
  reactPlugin,
  WildfireCommentTemplateGridComponent,
  "WildfireCommentTemplateGrid",
  "tracking-container"
);
