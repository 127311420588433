import {
  EnumRadioFieldValue,
  EnumSingleSelectFieldValue,
  NumericFieldValue,
  TextFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorerValue } from "../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

export enum BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts {
  EngineeringComplianceGeneralInformation = "engineering-compliance-general-information",
}

export interface BaseHomeEvaluationEngineeringComplianceGeneralInformationFormModel
  extends IEngineeringComplianceGeneralInformation {}

interface IEngineeringComplianceGeneralInformation {
  buildingCodeAndEdition?: TextFieldValue;
  asce7Edition?: EnumSingleSelectFieldValue;
  designWindSpeed?: NumericFieldValue;
  exposureCategory?: EnumSingleSelectFieldValue;
  zone1CAndCRoofPressuresPositive?: NumericFieldValue;
  zone1CAndCRoofPressuresNegative?: NumericFieldValue;
  zone2CAndCRoofPressuresPositive?: NumericFieldValue;
  zone2CAndCRoofPressuresNegative?: NumericFieldValue;
  zone3CAndCRoofPressuresPositive?: NumericFieldValue;
  zone3CAndCRoofPressuresNegative?: NumericFieldValue;
  CAndCRoofDesignUpliftPressuresDocumentation?: WildfireBoxContentExplorerValue;
  didTheEngineerSpecifyTheRequiredWallWindPressuresForZone4And5?: EnumRadioFieldValue;
  zone4CAndCWallWindPressuresPositive?: NumericFieldValue;
  zone4CAndCWallWindPressuresNegative?: NumericFieldValue;
  zone5CAndCWallWindPressuresPositive?: NumericFieldValue;
  zone5CAndCWallWindPressuresNegative?: NumericFieldValue;
  CAndCWallDesignWindPressuresDocumentation?: WildfireBoxContentExplorerValue;
}
