import {
  AddressFieldValue,
  EnumRadioFieldValue,
  TextFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorerValue } from "../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

export enum BaseHomeEvaluationInstallingContractorConfirmationFormParts {
  InstallingContractorConfirmation = "installing-contractor-confirmation",
}

export interface BaseHomeEvaluationInstallingContractorConfirmationFormModel
  extends IInstallingContractorConfirmation {}

export interface IInstallingContractorConfirmation {
  CertifiedLicensedBuildingContractorClaim?: EnumRadioFieldValue;
  UnderstandFalseInformationSubjectToCriminalPenaltiesClaim?: EnumRadioFieldValue;
  PrintedName?: TextFieldValue;
  Company?: TextFieldValue;
  PhoneNumber?: TextFieldValue;
  Address?: AddressFieldValue;
  LicenseNumber?: TextFieldValue;
  SignedInstallerComplianceForms?: WildfireBoxContentExplorerValue;
}
