import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts } from "../../common/types/BaseHomeEvaluationRoofingComplianceGeneralInformationFormModel";
import HomeNCIUARoofingComplianceGeneralInformationFormContainer from "../components/Forms/RoofingComplianceGeneralInformationForm/Container/HomeNCIUARoofingComplianceGeneralFormContainer";

export interface HomeNCIUARoofingComplianceGeneralInformationPageProps {
  evaluationId?: string;
  formParts?: BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts;
}

const HomeNCIUARoofingComplianceGeneralInformationPageComponent: FC<
  HomeNCIUARoofingComplianceGeneralInformationPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeNCIUARoofingComplianceGeneralInformationFormContainer
            evaluationId={props.evaluationId}
            formParts={props.formParts}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeNCIUARoofingComplianceGeneralInformationPage = withAITracking(
  reactPlugin,
  HomeNCIUARoofingComplianceGeneralInformationPageComponent,
  "HomeNCIUARoofingComplianceGeneralInformationPage",
  "tracking-container"
);
