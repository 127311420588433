import { useContext, useEffect, useState } from "react";
import { EvaluationStep } from "../../../../../../foritfied/types/evaluation/Evaluation";
import { UnsavedChangesBlockerContext } from "../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import HomeSCSHEvaluationBasicInformationForm from "../BasicInformationForm/Form/HomeSCSHEvaluationBasicInformationForm";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { PrimaryCard } from "@ucl/library";
import classNames from "classnames";
import HomeSCSHEvaluationForm from "../SCSHEvaluationForm/Form/HomeSCSHEvaluationForm";

interface HomeSCSHIterationEngineFormsProps {
  selectedTabId: string;
  evaluationId: string;
  evaluationStep: EvaluationStep;
  onFormSave: (fieldKey: string) => Promise<void>;
  onAllFormsInitialized: () => void;
  fieldsContainerClassName: string;
}

const HomeSCSHIterationEngineForms: React.FC<
  HomeSCSHIterationEngineFormsProps
> = (props) => {
  const [evaluationFormInitiaized, setEvaluationFormInitiaized] =
    useState<boolean>();

  const [allFormsInitialized, setAllFormsInitialized] = useState(false);

  const [basicInformationFormInitiaized, setBasicInformationFormInitiaized] =
    useState<boolean>();

  useEffect(() => {
    const formsInitialized =
      evaluationFormInitiaized || basicInformationFormInitiaized;

    if (formsInitialized) {
      setTimeout(() => {
        setAllFormsInitialized(true);
        props.onAllFormsInitialized();
      }, 600);
    } else {
      setAllFormsInitialized(false);
    }
  }, [evaluationFormInitiaized, basicInformationFormInitiaized]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const getFormAndHeaderContent = () => {
    switch (props.selectedTabId) {
      case "HomeSCSHEvaluationForm":
        return {
          headerContent: "Home SCSH Evaluation Form",
          form: (
            <HomeSCSHEvaluationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setEvaluationFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      default:
        return {
          headerContent: "Home SCSH Basic Information Form",
          form: (
            <HomeSCSHEvaluationBasicInformationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setBasicInformationFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
    }
  };

  const { headerContent, form } = getFormAndHeaderContent();

  return (
    <>
      {!allFormsInitialized && <Loading />}
      <div
        className={classNames(props.fieldsContainerClassName, {
          "evaluation-forms-container-initialized": allFormsInitialized,
        })}
      >
        <PrimaryCard headerContent={headerContent}>{form}</PrimaryCard>
      </div>
    </>
  );
};

export default HomeSCSHIterationEngineForms;
