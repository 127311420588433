import baseApiClient from "../../../../../../foritfied/lib/apiClients/baseApiClient";
import { BaseHomeRoofingComplianceRoofCoverFormParts } from "../../../../common/types/BaseHomeEvaluationRoofingComplianceRoofCoverFormModel";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../types/HomeNCIUAEvaluationFormModel";
import { HomeNCIUAEvaluationRoofingComplianceRoofCoverFormModel } from "../../types/HomeNCIUAEvaluationRoofingComplianceRoofCoverFormModel";

class HomeNCIUAEvaluationRoofingComplianceRoofCoverAPIClient {
  updateHomeNCIUARoofingComplianceRoofCoverForm = async (
    evaluationId: string,
    roofingComplianceRoofCoverForm: HomeNCIUAEvaluationRoofingComplianceRoofCoverFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeNCIUAEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeNCIUAEvaluationFormModel>(
      `/home-nciua/roofing-compliance-roof-cover-form/${evaluationId}`,
      {
        roofingComplianceRoofCoverForm,
        fieldKey,
      }
    );
  };

  submitHomeNCIUARoofingComplianceRoofCoverForm = async (
    evaluationId: string,
    form: HomeNCIUAEvaluationRoofingComplianceRoofCoverFormModel,
    formPart?: BaseHomeRoofingComplianceRoofCoverFormParts
  ): Promise<Evaluation_HomeNCIUAEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeRoofingComplianceRoofCoverFormParts
      ).pop() as BaseHomeRoofingComplianceRoofCoverFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeNCIUAEvaluationFormModel>(
      `/home-nciua/roofing-compliance-roof-cover-form/${evaluationId}/${formPartValue}`,
      form
    );
  };
}

export const homeNCIUAEvaluationRoofingComplianceRoofCoverAPIClient =
  new HomeNCIUAEvaluationRoofingComplianceRoofCoverAPIClient();
