import { FormTypes } from "../../../../common/types/FormTypes";
import baseApiClient from "../baseApiClient";
import {
  CommentTemplateCreateDTO,
  CommentTemplateReadDTO,
  CommentTemplateUpdateDTO,
} from "./types";

class CommentTemplateApiClient {
  getCommentTemplateById = async (id: string) => {
    return baseApiClient.get<CommentTemplateReadDTO>(`comment-template/${id}`);
  };

  getCommentTemplatesByFormType = async (formType: FormTypes) => {
    return baseApiClient.get<CommentTemplateReadDTO[]>(
      `comment-template/by-form-type/${formType}`
    );
  };

  updateCommentTemplateById = async (
    id: string,
    data: CommentTemplateUpdateDTO
  ) => {
    return baseApiClient.put<CommentTemplateUpdateDTO>(
      `comment-template/${id}`,
      data
    );
  };

  createCommentTemplate = async (data: CommentTemplateCreateDTO) => {
    return await baseApiClient.post<{
      commentTemplate: CommentTemplateCreateDTO;
    }>(`comment-template`, data);
  };

  deleteCommentTemplateById = async (id: string) => {
    return baseApiClient.delete<CommentTemplateReadDTO>(
      `comment-template/${id}`
    );
  };
}

export const commentTemplateApiClient = new CommentTemplateApiClient();
