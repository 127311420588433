export enum SpecialProjectTypes {
  NCIUA_NCJUA_SYR = 1, // nciua_ncjua
  NCIUA_NCJUA_SYCR = 2, // nciua_ncjua_sycr
  NCIUA_NCJUA_Claim = 3, // nciua_ncjua_claim
  StrengthenAlabamaHomes = 4, // sah
  NA = 5, // not_applicable
  SCSH = 6, // scsh
  LDIFORTIFIEDRoofGrant = 7, // ldi_fortified_roof_grant
  FannieMae = 8, // fannie_mae
}

export enum SpecialProjectTypes_AsString {
  NCIUA_NCJUA_SYR = "NCIUA_NCJUA_SYR",
  NCIUA_NCJUA_SYCR = "NCIUA_NCJUA_SYCR",
  NCIUA_NCJUA_Claim = "NCIUA_NCJUA_Claim",
  StrengthenAlabamaHomes = "StrengthenAlabamaHomes",
  NA = "NA",
  SCSH = "SCSH",
  LDIFORTIFIEDRoofGrant = "LDIFORTIFIEDRoofGrant",
  FannieMae = "FannieMae",
}

export const NCIUAProjectTypes_AsString = [
  SpecialProjectTypes_AsString.NCIUA_NCJUA_SYR,
  SpecialProjectTypes_AsString.NCIUA_NCJUA_SYCR,
  SpecialProjectTypes_AsString.NCIUA_NCJUA_Claim,
];

export const NCIUAProjectTypes = [
  SpecialProjectTypes.NCIUA_NCJUA_SYR,
  SpecialProjectTypes.NCIUA_NCJUA_SYCR,
  SpecialProjectTypes.NCIUA_NCJUA_Claim,
];
