import { useEffect, useState } from "react";
import "./styles.scss";
import { closeCommentTemplateDialog } from "./CommentTemplateDialog";
import { ToolbarSettingsModel } from "@syncfusion/ej2-dropdowns";
import {
  Button,
  FormGroup,
  InputGroup,
  Intent,
  Radio,
  RadioGroup,
} from "@blueprintjs/core";
import { MinimalSelectableLabel } from "../../../../../foritfied/components/Fields/SelectableLabel/MinimalSelectableLabel";
import {
  EnumSingleSelectFieldV2,
  Footer,
  RichTextEditorField,
  RichTextEditorValueType,
} from "@ucl/library";
import { commentTemplateApiClient } from "../../../../../foritfied/lib/apiClients/commentTemplate/commentTemplateApiClient";
import { ProductTypeOptions } from "../../../../../common/types/ProductTypes";
import { FormTypeOptions } from "../../../../../common/types/FormTypes";
import {
  CommentTemplateDialogFormModel,
  CommentTemplateFormProps,
} from "./types";
import {
  CommentTemplateCreateDTO,
  CommentTemplateUpdateDTO,
} from "../../../../../foritfied/lib/apiClients/commentTemplate/types";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";

interface CommentTemplateCommonFormProps extends CommentTemplateFormProps {
  defaultFormValue: CommentTemplateDialogFormModel;
  formId: string;
  showProductType?: boolean;
  refreshGrid: () => void;
}

export const CommentTemplateCommonForm: React.FC<
  CommentTemplateCommonFormProps
> = ({ id, defaultFormValue, formId, showProductType = true, refreshGrid }) => {
  const [formModel, setFormModel] =
    useState<CommentTemplateDialogFormModel>(defaultFormValue);
  const [isLoading, setIsLoading] = useState(false);

  const createCommentTemplate = async (
    form: CommentTemplateDialogFormModel
  ) => {
    setIsLoading(true);

    const createDTO: CommentTemplateCreateDTO = {
      productType: Number(form.productType),
      formType: Number(form.formType),
      templateName: form.templateName,
      templateContent: form.templateContent,
    };

    await commentTemplateApiClient
      .createCommentTemplate(createDTO)
      .then(() => {
        refreshGrid();
        closeCommentTemplateDialog();
        AppToaster.show({
          message: (
            <div>
              <h3>Success</h3>Comment Template created.
            </div>
          ),
          intent: Intent.SUCCESS,
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateCommentTemplate = async (
    id: string,
    form: CommentTemplateDialogFormModel
  ) => {
    setIsLoading(true);

    const updateDTO: CommentTemplateUpdateDTO = {
      productType: Number(form.productType),
      formType: Number(form.formType),
      templateName: form.templateName,
      templateContent: form.templateContent,
    };

    await commentTemplateApiClient
      .updateCommentTemplateById(id, updateDTO)
      .then(() => {
        refreshGrid();
        closeCommentTemplateDialog();
        AppToaster.show({
          message: (
            <div>
              <h3>Success</h3>Comment Template saved.
            </div>
          ),
          intent: Intent.SUCCESS,
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteCommentTemplate = async (id: string) => {
    setIsLoading(true);
    await commentTemplateApiClient
      .deleteCommentTemplateById(id)
      .then(() => {
        refreshGrid();
        closeCommentTemplateDialog();
        AppToaster.show({
          message: (
            <div>
              <h3>Success</h3>Comment Template deleted.
            </div>
          ),
          intent: Intent.SUCCESS,
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      commentTemplateApiClient
        .getCommentTemplateById(id)
        .then((commentTemplate) => {
          if (!!commentTemplate) {
            const { formType, productType, templateContent, templateName } =
              commentTemplate;

            setFormModel({
              formType: String(formType),
              productType: String(productType),
              templateContent,
              templateName,
            });
          }
        });
    }
  }, [id]);

  return (
    <>
      <div>
        <form
          id={formId}
          onSubmit={async (e) => {
            e.preventDefault();

            if (id) {
              updateCommentTemplate(id, formModel);
            } else {
              createCommentTemplate(formModel);
            }

            setIsLoading(true);
          }}
        >
          {showProductType && (
            <FormGroup className="base-field">
              <MinimalSelectableLabel label={"Product Type"} />
              <RadioGroup
                onChange={(item) => {
                  setFormModel((prev) => ({
                    ...prev,
                    productType: item.currentTarget.value,
                  }));
                }}
                selectedValue={formModel.productType.toString()}
              >
                {/* Ignore the last option because its wildfire. */}
                {ProductTypeOptions.slice(0, 3).map((option) => (
                  <Radio
                    key={option.label}
                    label={option.label}
                    value={option.value.toString()}
                  />
                ))}
              </RadioGroup>
            </FormGroup>
          )}
          {formModel.productType && (
            <FormGroup className="base-field">
              <EnumSingleSelectFieldV2
                type="EnumSingleSelect"
                label="Form"
                value={Number(formModel.formType)}
                optionValues={FormTypeOptions[Number(formModel.productType)]}
                onSubmit={(value) => {
                  setFormModel((prev) => ({
                    ...prev,
                    formType: value?.toString() ?? "",
                  }));
                }}
              />
            </FormGroup>
          )}
          <FormGroup className="base-field">
            <MinimalSelectableLabel label={"Template Name"} />
            <div className="template-content-field">
              <div>
                <InputGroup
                  className="comment-textarea"
                  value={formModel.templateName}
                  onChange={(e) =>
                    setFormModel((prev) => ({
                      ...prev,
                      templateName: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </FormGroup>
          <FormGroup className="base-field">
            <MinimalSelectableLabel label={"Template"} />
            <div className="template-content-field">
              <div>
                <RichTextEditorField
                  className="comment-template-rte"
                  valueType={RichTextEditorValueType.html}
                  value={formModel.templateContent}
                  onSubmit={(value) => {
                    setFormModel((prev) => ({
                      ...prev,
                      templateContent: value ?? "",
                    }));
                  }}
                  richTextEditorSettings={{
                    height: "200px",
                    enableHtmlSanitizer: true,
                    autoSaveOnIdle: true,
                    saveInterval: 50,
                    toolbarSettings: {
                      items: [
                        "Formats",
                        "Bold",
                        "Italic",
                        "Underline",
                        "StrikeThrough",
                        "|",
                        "ClearFormat",
                      ],
                    } as ToolbarSettingsModel,
                    pasteCleanupSettings: {
                      prompt: false,
                      plainText: false,
                      keepFormat: true,
                      allowedStyleProps: [""],
                      deniedAttrs: ["class", "id", "style"],
                    },
                  }}
                />
              </div>
            </div>
          </FormGroup>
        </form>
      </div>
      <Footer>
        {id && (
          <Button
            minimal
            intent={Intent.DANGER}
            onClick={() => deleteCommentTemplate(id)}
            icon={"trash"}
            large
          />
        )}
        <Button
          minimal
          intent={Intent.NONE}
          text={"Cancel"}
          onClick={closeCommentTemplateDialog}
        />
        <Button
          intent={Intent.SUCCESS}
          text={"Save"}
          type="submit"
          form={formId}
          loading={isLoading}
        />
      </Footer>
    </>
  );
};
