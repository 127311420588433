import { Button, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useState } from "react";
import { browserDownloadFileResponse } from "../../../../../../common/utils/download";
import { homeStandardEvaluationAPIClient } from "../../../../standard/lib/apiClients/homeStandardEvaluationAPIClient";
import { InformationalBanner } from "../../../../../../wildfire/components/Forms/FormSections/InformationalBanner/InformationalBanner";

interface DownloadEngineeringConfirmationFormContainerProps {
  evaluationId: string;
  fortifiedId: string;
  asBannerLink?: boolean;
}

const DownloadEngineeringConfirmationFormContainer: React.FC<
  DownloadEngineeringConfirmationFormContainerProps
> = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const fileName = `EngineeringConfirmationForm-${props.fortifiedId}.pdf`;

  const download = async () => {
    setIsLoading(true);
    await homeStandardEvaluationAPIClient
      .getEngineeringConfirmationForm(props.evaluationId)
      .then((response) => {
        browserDownloadFileResponse(response.data, fileName);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {!props.asBannerLink && (
        <Button
          minimal
          intent={Intent.NONE}
          rightIcon={<Icon icon={IconNames.IMPORT} color={"#4d85eb"} />}
          loading={isLoading}
          onClick={async () => {
            await download();
          }}
        >
          Download ECF
        </Button>
      )}
      {props.asBannerLink && (
        <InformationalBanner
          title={"Download Engineering Confirmation Form"}
          content={
            <div>
              <p>
                Please <a onClick={async () => await download()}>click here</a>{" "}
                to download a PDF which needs to be signed and uploaded below
              </p>
            </div>
          }
        />
      )}
    </>
  );
};

export default DownloadEngineeringConfirmationFormContainer;
