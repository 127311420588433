import {
  PermissionsContext,
  PermissionsContextProps,
  PrimaryCard,
  PrimaryCardHeader,
  RelativityTooltip,
} from "@ucl/library";
import { WildfireEvaluationOverview } from "../../../types/WildfireApplication/WildfireApplication";
import { IconNames } from "@blueprintjs/icons";
import { Icon } from "@blueprintjs/core";
import useWildfireApplicationOverviewCard from "./useWildfireApplicationOverviewCard";
import "./styles.scss";
import { ApplicationCommentsButton } from "../../Button/ApplicationCommentsButton.tsx/ApplicationCommentsButton";
import WildfireCloseEvaluationButton from "../../Button/WildfireCloseEvaluationButton/WildfireCloseEvaluationButton";
import { useContext } from "react";
import { ProgressBarComponent } from "@syncfusion/ej2-react-progressbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { wildfireApplicationApiClient } from "../../../lib/apiClients/evaluation/wildfireApplicationApiClient";

export interface WildfireApplicationOverviewCardProps {
  wildfireEvaluation: WildfireEvaluationOverview;
  fetchWildfireApplications: () => Promise<void>;
  index: number;
}

export enum ApplicantApplicationStatuses {
  "Application" = 1,
  "Eligibility Review" = 2,
  "Field Evaluations" = 3,
  "IBHS Review" = 4,
  "Designation" = 5,
}

export enum ApplicantLandscapeReviewStatuses {
  "Application" = 1,
  "IBHS Review" = 2,
  "Renewal" = 3,
}

export const WildfireApplicationOverviewCard: React.FC<
  WildfireApplicationOverviewCardProps
> = ({ wildfireEvaluation, fetchWildfireApplications, index }) => {
  const {
    primaryHeaderInfo,
    secondaryHeaderInfo,

    originApplicationStatus,
    originApplicationStatusBadgeClass,
    originApplicationStatusSummary,
    originApplicationNextStatusSummary,
    originApplicationActionText,
    originApplicationCurrentApplicantStep,
    originApplicationApplicantProgressValue,
    originApplicationHasFinalDetermination,
    designationStatusCallout,

    firstLandscapeReviewStatus,
    firstLandscapeReviewStatusBadgeClass,
    firstLandscapeReviewStatusSummary,
    firstLandscapeReviewNextStatusSummary,
    firstLandscapeReviewActionText,
    firstLandscapeReviewCurrentApplicantStep,
    firstLandscapeReviewApplicantProgressValue,

    secondLandscapeReviewStatus,
    secondLandscapeReviewStatusBadgeClass,
    secondLandscapeReviewStatusSummary,
    secondLandscapeReviewNextStatusSummary,
    secondLandscapeReviewActionText,
    secondLandscapeReviewCurrentApplicantStep,
    secondLandscapeReviewApplicantProgressValue,

    applicationDisplayStatuses,
    landscapeReviewDisplayStatuses,
    getStatusClass,
    getStatusIcon,

    actionButtons,
  } = useWildfireApplicationOverviewCard({
    wildfireEvaluation,
    fetchWildfireApplications,
    index,
  });

  const displayOriginApplicationStatusToUse =
    originApplicationStatus === "Evaluator Assignment"
      ? "Field Evaluations"
      : originApplicationStatus;

  const { hasPermission } = useContext(
    PermissionsContext
  ) as PermissionsContextProps<PermissionsKey>;

  return (
    <div className="wildfire-application-overview-card">
      <PrimaryCard>
        <PrimaryCardHeader label="">
          <span className="wildfire-application-overview-card-header">
            <div className="wildfire-application-overview-card-header-text">
              <div className="wildfire-application-overview-card-header-text-primary-info">
                <Icon icon={IconNames.MAP_MARKER} color="#0E1624" />
                <div>{primaryHeaderInfo}</div>
              </div>
              <div className="wildfire-application-overview-card-header-text-secondary-info">
                {secondaryHeaderInfo}
              </div>
            </div>
            <div className="wildfire-application-overview-card-header__buttons">
              <ApplicationCommentsButton
                evaluationId={wildfireEvaluation.id}
                wildfirePreparedId={
                  wildfireEvaluation.wildfirePreparedApplicationId
                }
                unreadCommentsCount={wildfireEvaluation.unreadCommentsCount}
                fetchWildfireApplications={fetchWildfireApplications}
              />
              {!originApplicationHasFinalDetermination && (
                <WildfireCloseEvaluationButton
                  evaluationId={wildfireEvaluation.id}
                  isClosed={wildfireEvaluation.isWithdrawn}
                  close={async () =>
                    await wildfireApplicationApiClient
                      .closeEvaluation(wildfireEvaluation.id)
                      .then()
                  }
                  cancel={async () =>
                    await wildfireApplicationApiClient
                      .cancelEvaluation(wildfireEvaluation.id)
                      .then()
                  }
                  reopen={async () =>
                    await wildfireApplicationApiClient
                      .reopenEvaluation(wildfireEvaluation.id)
                      .then()
                  }
                  refreshWildfireEvaluation={fetchWildfireApplications}
                  hasWithdrawPermission={hasPermission("CanViewWithdrawButton")}
                  auditorNotes={wildfireEvaluation.auditorNotes}
                />
              )}
            </div>
          </span>
          <RelativityTooltip
            className="wildfire-application-overview-card-progress-container"
            shouldShowTooltip={true}
            content={
              <div>
                {applicationDisplayStatuses.map((status, idx) => (
                  <div key={status}>
                    <span key={status}>{`${idx + 1}. ${
                      ApplicantApplicationStatuses[status]
                    }`}</span>
                    <br />
                  </div>
                ))}
              </div>
            }
          >
            <span>
              <div className="wildfire-application-overview-status-bar">
                {applicationDisplayStatuses.map((status, idx) => (
                  <div
                    id={`${ApplicantApplicationStatuses[status]}`}
                    className={`wildfire-application-overview-status-bar__item application-status-bar-item ${getStatusClass(
                      status,
                      originApplicationCurrentApplicantStep,
                      originApplicationStatus
                    )}`}
                    key={status}
                  >
                    <FontAwesomeIcon
                      className="wildfire-application-overview-status-bar__item__icon"
                      icon={getStatusIcon(
                        status,
                        originApplicationCurrentApplicantStep,
                        originApplicationStatus
                      )}
                    />
                    <span>{`${idx + 1}. ${
                      ApplicantApplicationStatuses[status]
                    }`}</span>
                  </div>
                ))}
              </div>
              <ProgressBarComponent
                id={`${index}-origin`}
                type="Linear"
                secondaryProgress={originApplicationCurrentApplicantStep}
                value={originApplicationApplicantProgressValue}
                progressColor="#26cf8b"
                animation={{ enable: true, duration: 2000, delay: 0 }}
                progressThickness={8}
                trackThickness={8}
                secondaryProgressColor="#ffbd63"
                minimum={0}
                maximum={applicationDisplayStatuses.length}
                gapWidth={8}
                segmentCount={applicationDisplayStatuses.length}
                cornerRadius={"Round"}
              />
            </span>
          </RelativityTooltip>
        </PrimaryCardHeader>
        <div className="wildfire-application-overview-card-body">
          <div className="wildfire-application-overview-card-body-column">
            <h2>
              Application Status:{" "}
              <span
                className={`wildfire-application-status-badge wildfire-application-status-badge__${originApplicationStatusBadgeClass}`}
              >
                {displayOriginApplicationStatusToUse}
              </span>
            </h2>
            <div className="wildfire-application-overview-card-body-list">
              {originApplicationStatusSummary.map((summary_line, index) => (
                <div
                  key={`${wildfireEvaluation.id}_current_summary_item_${index}`}
                >
                  {summary_line}
                </div>
              ))}
            </div>
            {designationStatusCallout}
          </div>
          <div className="wildfire-application-overview-card-body-column">
            <h2>{originApplicationActionText}</h2>
            <div className="wildfire-application-overview-card-body-list">
              {originApplicationNextStatusSummary.map((summary_line, index) => (
                <div
                  key={`${wildfireEvaluation.id}_next_summary_item_${index}`}
                >
                  {summary_line}
                </div>
              ))}
            </div>
          </div>
        </div>
        {firstLandscapeReviewStatus &&
          firstLandscapeReviewCurrentApplicantStep && (
            <div className="wildfire-application-overview-card-body">
              <RelativityTooltip
                className="wildfire-application-overview-card-progress-container"
                shouldShowTooltip={true}
                content={
                  <div>
                    {landscapeReviewDisplayStatuses.map((status, idx) => (
                      <div key={status}>
                        <span key={status}>{`${idx + 1}. ${
                          ApplicantLandscapeReviewStatuses[status]
                        }`}</span>
                        <br />
                      </div>
                    ))}
                  </div>
                }
              >
                <span>
                  <div className="wildfire-application-overview-status-bar">
                    {landscapeReviewDisplayStatuses.map((status, idx) => (
                      <div
                        id={`${ApplicantLandscapeReviewStatuses[status]}`}
                        className={`wildfire-application-overview-status-bar__item landscape-review-status-bar-item ${getStatusClass(
                          status,
                          firstLandscapeReviewCurrentApplicantStep,
                          firstLandscapeReviewStatus
                        )}`}
                        key={status}
                      >
                        <FontAwesomeIcon
                          className="wildfire-application-overview-status-bar__item__icon"
                          icon={getStatusIcon(
                            status,
                            firstLandscapeReviewCurrentApplicantStep,
                            firstLandscapeReviewStatus
                          )}
                        />
                        <span>{`${idx + 1}. ${
                          ApplicantLandscapeReviewStatuses[status]
                        }`}</span>
                      </div>
                    ))}
                  </div>
                  <ProgressBarComponent
                    id={`${index}-first-review`}
                    type="Linear"
                    secondaryProgress={firstLandscapeReviewCurrentApplicantStep}
                    value={firstLandscapeReviewApplicantProgressValue}
                    progressColor="#26cf8b"
                    animation={{ enable: true, duration: 2000, delay: 0 }}
                    progressThickness={8}
                    trackThickness={8}
                    secondaryProgressColor="#ffbd63"
                    minimum={0}
                    maximum={landscapeReviewDisplayStatuses.length}
                    gapWidth={8}
                    segmentCount={landscapeReviewDisplayStatuses.length}
                    cornerRadius={"Round"}
                  />
                </span>
              </RelativityTooltip>
              <div className="wildfire-application-overview-card-body-column">
                <h2>
                  First Annual Review Status:{" "}
                  <span
                    className={`wildfire-application-status-badge wildfire-application-status-badge__${firstLandscapeReviewStatusBadgeClass}`}
                  >
                    {firstLandscapeReviewStatus}
                  </span>
                </h2>
                <div className="wildfire-application-overview-card-body-list">
                  {firstLandscapeReviewStatusSummary.map(
                    (summary_line, index) => (
                      <div
                        key={`${wildfireEvaluation.id}_current_summary_item_${index}`}
                      >
                        {summary_line}
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="wildfire-application-overview-card-body-column">
                <h2>{firstLandscapeReviewActionText}</h2>
                <div className="wildfire-application-overview-card-body-list">
                  {firstLandscapeReviewNextStatusSummary.map(
                    (summary_line, index) => (
                      <div
                        key={`${wildfireEvaluation.id}_next_summary_item_${index}`}
                      >
                        {summary_line}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
        {secondLandscapeReviewStatus &&
          secondLandscapeReviewCurrentApplicantStep && (
            <div className="wildfire-application-overview-card-body">
              <RelativityTooltip
                className="wildfire-application-overview-card-progress-container"
                shouldShowTooltip={true}
                content={
                  <div>
                    {landscapeReviewDisplayStatuses.map((status, idx) => (
                      <div key={status}>
                        <span key={status}>{`${idx + 1}. ${
                          ApplicantLandscapeReviewStatuses[status]
                        }`}</span>
                        <br />
                      </div>
                    ))}
                  </div>
                }
              >
                <span>
                  <div className="wildfire-application-overview-status-bar">
                    {landscapeReviewDisplayStatuses.map((status, idx) => (
                      <div
                        id={`${ApplicantLandscapeReviewStatuses[status]}`}
                        className={`wildfire-application-overview-status-bar__item landscape-review-status-bar-item ${getStatusClass(
                          status,
                          secondLandscapeReviewCurrentApplicantStep,
                          secondLandscapeReviewStatus
                        )}`}
                        key={status}
                      >
                        <FontAwesomeIcon
                          className="wildfire-application-overview-status-bar__item__icon"
                          icon={getStatusIcon(
                            status,
                            secondLandscapeReviewCurrentApplicantStep,
                            secondLandscapeReviewStatus
                          )}
                        />
                        <span>{`${idx + 1}. ${
                          ApplicantLandscapeReviewStatuses[status]
                        }`}</span>
                      </div>
                    ))}
                  </div>
                  <ProgressBarComponent
                    id={`${index}-first-review`}
                    type="Linear"
                    secondaryProgress={
                      secondLandscapeReviewCurrentApplicantStep
                    }
                    value={secondLandscapeReviewApplicantProgressValue}
                    progressColor="#26cf8b"
                    animation={{ enable: true, duration: 2000, delay: 0 }}
                    progressThickness={8}
                    trackThickness={8}
                    secondaryProgressColor="#ffbd63"
                    minimum={0}
                    maximum={landscapeReviewDisplayStatuses.length}
                    gapWidth={8}
                    segmentCount={landscapeReviewDisplayStatuses.length}
                    cornerRadius={"Round"}
                  />
                </span>
              </RelativityTooltip>
              <div className="wildfire-application-overview-card-body-column">
                <h2>
                  Second Annual Review Status:{" "}
                  <span
                    className={`wildfire-application-status-badge wildfire-application-status-badge__${secondLandscapeReviewStatusBadgeClass}`}
                  >
                    {secondLandscapeReviewStatus}
                  </span>
                </h2>
                <div className="wildfire-application-overview-card-body-list">
                  {secondLandscapeReviewStatusSummary.map(
                    (summary_line, index) => (
                      <div
                        key={`${wildfireEvaluation.id}_current_summary_item_${index}`}
                      >
                        {summary_line}
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="wildfire-application-overview-card-body-column">
                <h2>{secondLandscapeReviewActionText}</h2>
                <div className="wildfire-application-overview-card-body-list">
                  {secondLandscapeReviewNextStatusSummary.map(
                    (summary_line, index) => (
                      <div
                        key={`${wildfireEvaluation.id}_next_summary_item_${index}`}
                      >
                        {summary_line}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
        <div className="wildfire-application-overview-card-footer">
          {actionButtons}
        </div>
      </PrimaryCard>
    </div>
  );
};
