import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts } from "../../common/types/BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormModel";
import HomeStandardEngineeringComplianceContinuousLoadPathFormContainer from "../components/Forms/EngineeringComplianceContinuousLoadPathForm/Container/HomeStandardEngineeringComplianceContinuousLoadPathFormContainer";

export interface HomeStandardEngineeringComplianceContinuousLoadPathPageProps {
  evaluationId?: string;
  engineeringComplianceContinuousLoadPathFormPart?: BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts;
}

const HomeStandardEngineeringComplianceContinuousLoadPathPageComponent: FC<
  HomeStandardEngineeringComplianceContinuousLoadPathPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeStandardEngineeringComplianceContinuousLoadPathFormContainer
            evaluationId={props.evaluationId}
            formParts={props.engineeringComplianceContinuousLoadPathFormPart}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeStandardEngineeringComplianceContinuousLoadPathPage =
  withAITracking(
    reactPlugin,
    HomeStandardEngineeringComplianceContinuousLoadPathPageComponent,
    "HomeStandardEngineeringComplianceContinuousLoadPathPage",
    "tracking-container"
  );
