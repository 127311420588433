import { useNavigate } from "react-router";
import { FieldFormV2Module } from "@ucl/library";
import { ApplicationFormParts } from "../../../forms/ApplicationForm/ApplicationFormModel";
import { useCallback, useEffect, useRef, useState } from "react";
import { useFieldSchemaFactory } from "../../../customHooks/useFieldSchemaFactory";
import { ApplicationFormProps } from "./ApplicationForm";
import { wildfireEvaluationApiClient } from "../../../lib/apiClients/evaluation/wildfireEvaluationApiClient";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../common/Components/Error/ErrorStore";
import { Intent } from "@blueprintjs/core";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { debounce } from "lodash";
import { EvaluationFormModel } from "../../../forms/EvaluationFormModel";
import { wildfireRoutePrefix } from "../../../pages/index/wildfireRouteConfig";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { getScrollContainer } from "../../../utils/getScrollContainer";
import { WildfireEvaluationSteps } from "../../../../foritfied/types/evaluation/Evaluation";
import { getWildfireApplicationFeePaymentPageRoute } from "../../../../common/utils/paymentRouteUtils";

export function useApplicationForm(props: ApplicationFormProps) {
  const navigate = useNavigate();
  const { builders, wieBuilders } = useFieldSchemaFactory();
  const formRef = useRef<FieldFormV2Module<EvaluationFormModel>>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [applicationFormModel, setApplicationFormModel] =
    useState<EvaluationFormModel>();

  const [isInitialized, setIsInitialized] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!props.isWiePage && !!containerRef.current) {
      const scrollableParent = getScrollContainer(containerRef.current);

      scrollableParent?.scrollTo(0, 0);
    }
  }, [props.formPart]);

  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
      props.onInitialized?.();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isInitialized) {
      props.onInitialized?.();
    }
  }, [isInitialized]);

  // Differentiates between save and submit
  let shouldSubmit = false;
  const setShouldSubmit = (value: boolean) => {
    shouldSubmit = value;
  };

  useEffect(() => {
    //Used to React to External Changes Pushed on the Form (Iteration Engine)
    if (applicationFormModel) {
      setApplicationFormModel({ ...applicationFormModel });
    }
  }, [wieBuilders]);

  useEffect(() => {
    fetchApplicationForm();
  }, []);

  useEffect(() => {
    if (!!applicationFormModel) {
      setApplicationFormModel(applicationFormModel);

      if (props.setFormModel) {
        props.setFormModel(applicationFormModel);
      }
    }
  }, [applicationFormModel]);

  const isFirstPartOfForm =
    props.formPart === Object.values(ApplicationFormParts)[0];

  const isLastPartOfForm =
    props.formPart === Object.values(ApplicationFormParts).pop();

  const shouldShowPaymentButton =
    props.formPart === ApplicationFormParts.Eligibility &&
    (applicationFormModel?.wildfireApplication.step ===
      WildfireEvaluationSteps.WildfireApplicationPayment ||
      applicationFormModel?.wildfireApplication.step ===
        WildfireEvaluationSteps.WildfireApplication);

  const noBuilders = !builders && !wieBuilders;

  const hasLastPartOfFormDisableLogic =
    isLastPartOfForm &&
    !applicationFormModel?.wildfireApplication.applicationForm
      .allRequiredFieldsCompleted;

  const isSubmitDisabled =
    !!props.hasDuplicateAddress ||
    isSubmitting ||
    hasLastPartOfFormDisableLogic;

  const toolTipText = !!props.hasDuplicateAddress
    ? "There is already a Wildfire Prepared Home application for this address. This Application cannot be submitted"
    : hasLastPartOfFormDisableLogic
    ? "Please complete all required fields before submitting Payment."
    : "Submitting";

  const buttonText = shouldShowPaymentButton
    ? "Proceed to Payment"
    : isLastPartOfForm
    ? "Submit for Eligibility Review"
    : "Continue";

  const fetchApplicationForm = async () => {
    setIsLoading(true);
    await wildfireEvaluationApiClient
      .getWildfireEvaluationApplicationForm(props.wildfireEvaluationId)
      .then((response) => {
        setApplicationFormModel((prevModel) => ({
          ...prevModel,
          ...response,
        }));
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Debounce setup
  const handleFormSubmitDebounced = useCallback(
    debounce(
      async (
        form: EvaluationFormModel,
        _value: any,
        fieldProps: BaseFieldProps<any>
      ) => {
        if (fieldProps.fieldKey) {
          await handleFormSubmit(form, fieldProps.fieldKey).then(async () => {
            await props.onFormSave?.(fieldProps.fieldKey || "", _value);
          });
        }
      },
      2000
    ),
    []
  );

  useEffect(() => {
    // Cleanup the debounce function on component unmount
    return () => handleFormSubmitDebounced.cancel();
  }, [handleFormSubmitDebounced]);

  const onFormFieldChange = async (
    form: EvaluationFormModel,
    _value: any,
    fieldProps: BaseFieldProps<any>
  ) => {
    // Call the debounced submit function
    if (!!_value) {
      handleFormSubmitDebounced(form, _value, fieldProps);
      props.setHasUnsavedChanges(true);
    }
  };

  const getApplicationFormPartBuilder = () => {
    if (props.isWiePage) {
      return wieBuilders?.ApplicationForm;
    }
    switch (props.formPart) {
      case ApplicationFormParts.PropertyInformation:
        return builders?.ApplicationFormPropertyInformationPart;
      case ApplicationFormParts.ServiceOptions:
        return builders?.ApplicationFormServiceOptionsPart;
      case ApplicationFormParts.Eligibility:
        return builders?.ApplicationFormEligibilityPart;
      case ApplicationFormParts.PhotoRequirements:
        return builders?.ApplicationFormPhotoRequirementsPart;
      default:
        return builders?.ApplicationForm;
    }
  };

  const handleFormSubmit = async (
    form: EvaluationFormModel,
    fieldKey?: string
  ) => {
    setApplicationFormModel(form);

    await (shouldSubmit
      ? wildfireEvaluationApiClient.submitApplicationForm(
          form.wildfireApplication.id,
          form.wildfireApplication.applicationForm,
          props.formPart
        )
      : wildfireEvaluationApiClient.updateApplicationForm(
          form.wildfireApplication.id,
          form.wildfireApplication.applicationForm,
          fieldKey
        )
    )
      .then(async (response) => {
        handleFormResponse(response);
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while saving the form",
            intent: Intent.DANGER,
          });
        }
        throw error;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleFormResponse = (response: EvaluationFormModel) => {
    props.setHasUnsavedChanges(false);

    if (shouldSubmit) {
      setApplicationFormModel(response);
      if (response.wildfireApplication.applicationForm.madronusRedirectUrl) {
        window.location.href =
          response.wildfireApplication.applicationForm.madronusRedirectUrl;
        return;
      }

      if (shouldShowPaymentButton) {
        navigate(
          getWildfireApplicationFeePaymentPageRoute(props.wildfireEvaluationId)
        );
      } else if (isLastPartOfForm || !props.formPart) {
        navigate(`${wildfireRoutePrefix}/board`);
      } else {
        // Navigate to next part of form
        const nextFormPart =
          props.formPart === ApplicationFormParts.Eligibility
            ? ApplicationFormParts.PhotoRequirements
            : Object.values(ApplicationFormParts)[
                Object.values(ApplicationFormParts).indexOf(props.formPart) + 1
              ].valueOf();

        navigate(
          `${wildfireRoutePrefix}/${props.wildfireEvaluationId}/application-form/${nextFormPart}`
        );
      }
    } else {
      handlePartialResponseFormUpdate(response);
    }
  };

  const handlePartialResponseFormUpdate = (response: EvaluationFormModel) => {
    //This is used to combat save on blur issues. This only updates fields that are essetnial to the form.
    //These are set in the API response. Field Counts and sub section completion status
    setApplicationFormModel((prevState) => {
      if (!prevState) return prevState;

      const {
        allRequiredFieldsCompleted,
        propertyInformationRequiredFieldCount,
        serviceOptionsRequiredFieldCount,
        eligibilityRequiredFieldCount,
        photoRequirementsRequiredFieldCount,
        propertyInformationSubmitted,
        serviceOptionsSubmitted,
        eligibilitySubmitted,
        photoRequirementsSubmitted,
        submitted,
        backPhotoFolder: backPhotoFolderId,
        frontPhotoFolder: frontPhotoFolderId,
        rightSidePhotoFolder: rightSidePhotoFolderId,
        leftSidePhotoFolder: leftSidePhotoFolderId,
      } = response.wildfireApplication.applicationForm;

      return {
        ...prevState,
        wildfireApplication: {
          ...prevState.wildfireApplication,
          applicationForm: {
            ...prevState.wildfireApplication.applicationForm,
            allRequiredFieldsCompleted,
            propertyInformationRequiredFieldCount,
            serviceOptionsRequiredFieldCount,
            eligibilityRequiredFieldCount,
            photoRequirementsRequiredFieldCount,
            propertyInformationSubmitted,
            serviceOptionsSubmitted,
            eligibilitySubmitted,
            photoRequirementsSubmitted,
            submitted,
            backPhotoFolder: backPhotoFolderId,
            frontPhotoFolder: frontPhotoFolderId,
            rightSidePhotoFolder: rightSidePhotoFolderId,
            leftSidePhotoFolder: leftSidePhotoFolderId,
          },
        },
      };
    });
  };

  return {
    builders,
    wieBuilders,
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    applicationFormModel,
    setShouldSubmit,
    isFirstPartOfForm,
    isLastPartOfForm,
    getApplicationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    noBuilders,
    isSubmitDisabled,
    toolTipText,
    containerRef,
    buttonText,
  };
}

export default useApplicationForm;
