import "./applicant-styles.scss";
import {
  FieldSchemaFactoryProvider,
  WildfireSchema,
  WildfireSchemaFactoryV2,
} from "../../customHooks/useFieldSchemaFactory";
import WildfireApplicationApplicantIterationEngineChangeRequests from "./Forms/WildfireApplicationApplicantIterationEngineChangeRequests";
import IterationEngineHeader from "./Header/IterationEngineHeader";
import useWildfireIterationEngine, {
  PlusTabId,
} from "./useWildfireIterationEngine";
import { TabComponent } from "@syncfusion/ej2-react-navigations";
import { Tag } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ChangeRequestStatus } from "../../../foritfied/types/evaluation/ChangeRequest";
import { MessageComponent } from "@syncfusion/ej2-react-notifications";
import { WildfireFieldEvaluationCompliance } from "../../types/WildfireFieldComplianceDTO";
import { ComplianceTypes } from "../../types/ComplianceTypes";
import { get } from "lodash";
import { WildfireEvaluationSteps } from "../../../foritfied/types/evaluation/Evaluation";
import { wildfireApplicationApiClient } from "../../lib/apiClients/evaluation/wildfireApplicationApiClient";

export interface WildfireApplicationApplicantIterationEngineProps {
  evaluationId: string;
  wildfireSchemaFactory: WildfireSchemaFactoryV2;
}
export const WildfireApplicationApplicantIterationEngine: React.FC<
  WildfireApplicationApplicantIterationEngineProps
> = (props) => {
  const {
    selectedIterationEngineFormTabId,
    formFieldEvaluationDecisioning,
    formTabs,
    wildfireEvaluation,
    refreshWildfireEvaluation,
    sendForward,
    isSendForwardLoading,
    approve,
    isApproveLoading,
    reject,
    isRejectLoading,
    close,
    cancel,
    reopen,
    requestChanges,
    regenerateCertificate,
    downloadCertificate,
    isRequestChangesLoading,
    allChangeRequests,
    resolvedAllChangeRequests,
    getEvaluationFieldKeysForAllUnresolvedChangeRequests,
    hasNoUnaddressedChangeRequests,
    hasOneNewChangeRequest,
    canEditForm,
    getChangeRequestsForTab,
    getChangeRequests,
    longestOutstandingChangeRequest,
  } = useWildfireIterationEngine(props.evaluationId);

  const getFormFieldEvaluationDecisioningForTab = (
    schema: WildfireSchema,
    fieldEvaluationCompliance: WildfireFieldEvaluationCompliance,
    isPlus?: boolean
  ) => {
    const resolved = Object.keys(fieldEvaluationCompliance)
      .map((key) => {
        const compliance = fieldEvaluationCompliance[key];
        const complianceFieldSchema = get(schema.schema.Evaluation, key);
        if (complianceFieldSchema) {
          compliance.complianceDetails =
            complianceFieldSchema.label || compliance.complianceDetails;
        }
        return compliance;
      })
      .filter(
        (compliance) =>
          compliance.isPlusCompliant == isPlus &&
          compliance.complianceType === ComplianceTypes.Compliant &&
          compliance.complianceDetails
      );

    return resolved || [];
  };

  return (
    <section className="wildfire-applicant-iteration-engine">
      {wildfireEvaluation && formFieldEvaluationDecisioning && (
        <FieldSchemaFactoryProvider
          schemaFactory={props.wildfireSchemaFactory}
          formOverrides={{
            fieldDecisions: formFieldEvaluationDecisioning,
            isPlus: selectedIterationEngineFormTabId == PlusTabId,
            iterationEngineProps: {
              fieldKeysWithChangeRequests:
                getEvaluationFieldKeysForAllUnresolvedChangeRequests,
              shouldEnableForm: canEditForm,
              changeRequests: allChangeRequests,
            },
          }}
        >
          <div className="wildfire-iteration-engine">
            <div className="wildfire-iteration-engine__header">
              <IterationEngineHeader
                evaluationId={wildfireEvaluation.id}
                auditorId={wildfireEvaluation.auditorId}
                wildfireApplicationId={
                  wildfireEvaluation.wildfireApplication
                    .wildfirePreparedApplicationId
                }
                unreadCommentsCount={wildfireEvaluation.unreadCommentsCount}
                wildfireApplicationOwnerId={
                  wildfireEvaluation?.wildfireApplication.ownerId
                }
                step={wildfireEvaluation.wildfireApplication.step}
                status={wildfireEvaluation.status_AsString}
                resolvedAllChangeRequest={resolvedAllChangeRequests}
                hasOneNewChangeRequest={hasOneNewChangeRequest}
                hasNoUnaddressedChangeRequests={hasNoUnaddressedChangeRequests}
                submitAdminUpdate={(wildfireEvaluationId, form) =>
                  wildfireApplicationApiClient
                    .submitApplicationAdminUpdate(wildfireEvaluationId, form)
                    .then()
                }
                refreshWildfireApplication={refreshWildfireEvaluation}
                sendForward={sendForward}
                isSendForwardLoading={isSendForwardLoading}
                approve={approve}
                isApproveLoading={isApproveLoading}
                reject={reject}
                isRejectLoading={isRejectLoading}
                close={close}
                cancel={cancel}
                reopen={reopen}
                requestChanges={requestChanges}
                isRequestChangesLoading={isRequestChangesLoading}
                downloadCertificate={downloadCertificate}
                regenerateCertificate={regenerateCertificate}
                externalFolderId={wildfireEvaluation.externalFolderId}
                legacyEvaluationSource={
                  wildfireEvaluation.legacyEvaluationSource
                }
                auditorNotes={wildfireEvaluation.auditorNotes}
                isWithdrawn={wildfireEvaluation.isWithdrawn}
                shouldShowAutoRejectBanner={
                  wildfireEvaluation.wildfireApplication
                    .shouldShowAutoRejectBanner
                }
                longestOutstandingChangeRequest={
                  longestOutstandingChangeRequest
                }
                homeOwnerId={wildfireEvaluation.wildfireApplication.ownerId}
                moveableStepOptionsByAdmin={
                  wildfireEvaluation.wildfireApplication
                    .moveableStepOptionsByAdmin
                }
              />
            </div>
            <div className="wildfire-iteration-engine__content">
              <TabComponent
                headerPlacement="Top"
                overflowMode="MultiRow"
                animation={{
                  previous: { effect: "None" },
                  next: { effect: "None" },
                }}
              >
                <div className="e-tab-header wildfire-iteration-engine__top-panel">
                  {formTabs.map((tab) => {
                    const unresolvedChangeRequestsByTab =
                      getChangeRequestsForTab(
                        props.wildfireSchemaFactory.schema.wildfireFieldSchema,
                        tab.id === PlusTabId
                      ).filter((cr) => cr.status === ChangeRequestStatus.New);

                    let tabLabel = tab.label;
                    let tabTitle = "Wildfire Prepared Home";

                    if (
                      tabLabel === "Base" &&
                      wildfireEvaluation.step ===
                        WildfireEvaluationSteps.WildfireApplicationIBHSEligibilityReview
                    ) {
                      tabLabel = "Eligibility Review";
                      tabTitle = "";
                    }

                    return (
                      <div
                        key={tab.id}
                        className="wildfire-iteration-engine__tab"
                      >
                        <span className="wildfire-iteration-engine__tab-title">
                          <span className="wildfire-iteration-engine__tab-title-prefix">
                            {tabTitle}{" "}
                          </span>
                          <span className="wildfire-iteration-engine__tab-title-header">
                            {tabLabel}
                          </span>
                        </span>
                        {formFieldEvaluationDecisioning &&
                          unresolvedChangeRequestsByTab?.length > 0 && (
                            <span className="wildfire-iteration-engine__tab-change-requests-count">
                              <div className="change-requests-count">
                                <Tag
                                  icon={IconNames.WarningSign}
                                  large={true}
                                  round={true}
                                >
                                  <span className="change-requests-count-counter">
                                    {unresolvedChangeRequestsByTab.length}
                                  </span>
                                  <span className="change-requests-count-suffix">
                                    {` Change${
                                      unresolvedChangeRequestsByTab.length > 1
                                        ? "s"
                                        : ""
                                    } Required`}
                                  </span>
                                </Tag>
                              </div>
                            </span>
                          )}
                      </div>
                    );
                  })}
                </div>
                <div className="e-content wildfire-iteration-engine__bottom-panel">
                  {formTabs.map((tab) => {
                    const unresolvedChangeRequestsByTab =
                      getChangeRequestsForTab(
                        props.wildfireSchemaFactory.schema.wildfireFieldSchema,
                        tab.id === PlusTabId
                      ).filter((cr) => cr.status === ChangeRequestStatus.New);

                    return (
                      <div key={tab.id}>
                        <div
                          key={tab.id}
                          className="control_wrapper"
                          id="control_wrapper"
                        >
                          {tab.id === PlusTabId && (
                            <MessageComponent
                              className="wildfire-iteration-engine__plus-info-message"
                              severity="Info"
                              showIcon={true}
                              content="These changes only apply to the Wildfire Prepared Home Plus designation level. If you are not pursuing Plus, fulfilling these requirements is optional. Please specify this in your comment when addressing the change."
                              variant="Filled"
                            />
                          )}
                          <WildfireApplicationApplicantIterationEngineChangeRequests
                            key={unresolvedChangeRequestsByTab.length}
                            evaluationId={wildfireEvaluation.id}
                            changeRequests={getChangeRequestsForTab(
                              props.wildfireSchemaFactory.schema
                                .wildfireFieldSchema,
                              tab.id === PlusTabId
                            )}
                            fieldEvaluationCompliants={getFormFieldEvaluationDecisioningForTab(
                              props.wildfireSchemaFactory.schema,
                              formFieldEvaluationDecisioning,
                              tab.id === PlusTabId
                            )}
                            wildfireSchemaFactory={props.wildfireSchemaFactory}
                            onChangeRequestCompleted={async () =>
                              await getChangeRequests?.()
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </TabComponent>
            </div>
          </div>
        </FieldSchemaFactoryProvider>
      )}
    </section>
  );
};
