import React from "react";
import { Button, Intent } from "@blueprintjs/core";
import { useNavigate } from "react-router";

interface GoToIterationEngineButtonProps {
  evaluationId: string;
  productTypeRoute: string;
}

const GoToIterationEngineButton: React.FC<GoToIterationEngineButtonProps> = (
  props
) => {
  const navigate = useNavigate();
  return (
    <Button
      intent={Intent.PRIMARY}
      text="View Iteration Engine"
      small
      onClick={() => {
        navigate(
          `${props.productTypeRoute}/iteration-engine/${props.evaluationId}`
        );
      }}
    />
  );
};

export default GoToIterationEngineButton;
