import { BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormModel } from "../../common/types/BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormModel";
import { FormChecklistFields } from "./HomeStandardEvaluationBasicInformationFormModel";

export interface HomeStandardEvaluationSilverGoldComplianceGeneralInformationFormModel
  extends BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormModel,
    HomeStandardEvaluationSilverGoldComplianceGeneralInformationFormBase {}

export interface HomeStandardEvaluationSilverGoldComplianceGeneralInformationFormBase
  extends FormChecklistFields {
  id: string;
  homeStandardEvaluationId: string;
  evaluationId: string;

  // computed properties
  siteDesignInformationRequiredCount: number;
  siteDesignInformationLastSubmittedAt: boolean;
  isSiteDesignInformationComplete: boolean;

  windowsAndSkylightsRequiredCount: number;
  windowsAndSkylightsLastSubmittedAt: boolean;
  isWindowsAndSkylightsComplete: boolean;

  entryDoorsRequiredCount: number;
  entryDoorsLastSubmittedAt: boolean;
  isEntryDoorsComplete: boolean;

  garageRequiredCount: number;
  garageLastSubmittedAt: boolean;
  isGarageComplete: boolean;

  gableEndsRequiredCount: number;
  gableEndsLastSubmittedAt: boolean;
  isGableEndsComplete: boolean;

  soffitsRequiredCount: number;
  soffitsLastSubmittedAt: boolean;
  isSoffitsComplete: boolean;

  chimneysRequiredCount: number;
  chimneysLastSubmittedAt: boolean;
  isChimneysComplete: boolean;

  attachedStructuresRequiredCount: number;
  attachedStructuresLastSubmittedAt: boolean;
  isAttachedStructuresComplete: boolean;

  designPressureRatedOpeningsRequiredCount: number;
  designPressureRatedOpeningsLastSubmittedAt: boolean;
  isDesignPressureRatedOpeningsComplete: boolean;

  wallSheathingRequiredCount: number;
  wallSheathingLastSubmittedAt: boolean;
  isWallSheathingComplete: boolean;

  continuousLoadPathRequiredCount: number;
  continuousLoadPathLastSubmittedAt: boolean;
  isContinuousLoadPathComplete: boolean;

  areAllRequiredFieldsComplete: boolean;
}

export enum SoffitCoverTypes {
  Flexible = 1,
  Rigid = 2,
  NoSoffitCovers = 3,
}
