import {
  FieldSchemaFormBuilderConfigV2,
  FieldSchemaFormBuilderV2,
  SchemaFactoryV2,
} from "@ucl/library";
import {
  applicationEligibilityBuilder,
  applicationFormBuilder,
  applicationPhotoRequirementsBuilder,
  applicationPropertyInformationBuilder,
  applicationServiceOptionsBuilder,
} from "./ApplicationForm/ApplicationFormBuilders";
import {
  roofFieldEvaluationCoverMaterialBuilder,
  roofFieldEvaluationEavesAndSoffitsBuilder,
  roofFieldEvaluationFormBuilder,
  roofFieldEvaluationGuttersAndDownspoutsBuilder,
  roofFieldEvaluationOtherRoofCoverConditionsBuilder,
  roofFieldEvaluationVentsBuilder,
} from "./RoofFieldEvaluationForm/RoofFieldEvaluationFormBuilders";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  homeExteriorFieldEvaluationDecksBuilder,
  homeExteriorFieldEvaluationWindowsBuilder,
  homeExteriorFieldEvaluationDoorsBuilder,
  homeExteriorFieldEvaluationFormBuilder,
  homeExteriorFieldEvaluationSidingMaterialBuilder,
  homeExteriorFieldEvaluationSixInchVerticalBuilder,
  homeExteriorFieldEvaluationVentsBuilder,
} from "./HomeExteriorFieldEvaluationForm/HomeExteriorFieldEvaluationFormBuilders";
import {
  landscapeFieldEvaluationFiveToThirtyFeetBuilder,
  landscapeFieldEvaluationFormBuilder,
  landscapeFieldEvaluationZeroToFiveFeetFieldsBuilder,
} from "./LandscapeFieldEvaluationForm/LandscapeFieldEvaluationFormBuilders";
import {
  otherFieldEvaluationFormBuilder,
  otherFieldEvaluationOtherStructuresAndOutbuildingsBuilder,
} from "./OtherFieldEvaluationForm/OtherFieldEvaluationFormBuilders";
import {
  landscapeReviewFormBuilder,
  landscapeReviewPhotoRequirementsBuilder,
  landscapeReviewPropertyInformationBuilder,
} from "./LandscapeReviewForm/LandscapeReviewFormBuilders";

export interface FieldSchemaModel {}
export type FieldSchemaFormType = typeof FieldSchemaFormTypeList[number];
export type FieldSchemaConfiguration = Record<
  FieldSchemaFormType,
  FieldSchemaFormBuilderConfigV2 | undefined
>;

export const FieldSchemaFormTypeList = [
  "ApplicationForm",
  "ApplicationFormPropertyInformationPart",
  "ApplicationFormServiceOptionsPart",
  "ApplicationFormEligibilityPart",
  "ApplicationFormPhotoRequirementsPart",

  "RoofFieldEvaluationForm",
  "RoofFieldEvaluationFormCoverMaterialPart",
  "RoofFieldEvaluationFormOtherRoofCoverConditionsPart",
  "RoofFieldEvaluationFormGuttersAndDownspoutsPart",
  "RoofFieldEvaluationFormEavesAndSoffitsPart",
  "RoofFieldEvaluationFormVentsPart",

  "HomeExteriorFieldEvaluationForm",
  "HomeExteriorFieldEvaluationFormVentsPart",
  "HomeExteriorFieldEvaluationFormSixInchVerticalPart",
  "HomeExteriorFieldEvaluationFormSidingMaterialPart",
  "HomeExteriorFieldEvaluationFormWindowsPart",
  "HomeExteriorFieldEvaluationFormDoorsPart",
  "HomeExteriorFieldEvaluationFormDecksPart",

  "LandscapeFieldEvaluationForm",
  "LandscapeFieldEvaluationFormZeroToFiveFeetPart",
  "LandscapeFieldEvaluationFormFiveToThirtyFeetPart",

  "OtherFieldEvaluationForm",
  "OtherEvaluationFormOtherStructuresAndOutbuildings",

  "LandscapeReviewForm",
  "LandscapeReviewFormPropertyInformationPart",
  "LandscapeReviewFormPhotoRequirementsPart",
] as const;

export const fieldSchemaConfig: FieldSchemaConfiguration = {
  ApplicationForm: undefined,
  ApplicationFormPropertyInformationPart: undefined,
  ApplicationFormServiceOptionsPart: undefined,
  ApplicationFormEligibilityPart: undefined,
  ApplicationFormPhotoRequirementsPart: undefined,

  RoofFieldEvaluationForm: undefined,
  RoofFieldEvaluationFormCoverMaterialPart: undefined,
  RoofFieldEvaluationFormOtherRoofCoverConditionsPart: undefined,
  RoofFieldEvaluationFormGuttersAndDownspoutsPart: undefined,
  RoofFieldEvaluationFormEavesAndSoffitsPart: undefined,
  RoofFieldEvaluationFormVentsPart: undefined,

  HomeExteriorFieldEvaluationForm: undefined,
  HomeExteriorFieldEvaluationFormVentsPart: undefined,
  HomeExteriorFieldEvaluationFormSixInchVerticalPart: undefined,
  HomeExteriorFieldEvaluationFormSidingMaterialPart: undefined,
  HomeExteriorFieldEvaluationFormWindowsPart: undefined,
  HomeExteriorFieldEvaluationFormDoorsPart: undefined,
  HomeExteriorFieldEvaluationFormDecksPart: undefined,

  LandscapeFieldEvaluationForm: undefined,
  LandscapeFieldEvaluationFormZeroToFiveFeetPart: undefined,
  LandscapeFieldEvaluationFormFiveToThirtyFeetPart: undefined,

  OtherFieldEvaluationForm: undefined,
  OtherEvaluationFormOtherStructuresAndOutbuildings: undefined,

  LandscapeReviewForm: undefined,
  LandscapeReviewFormPropertyInformationPart: undefined,
  LandscapeReviewFormPhotoRequirementsPart: undefined,
};

export type CustomFieldSchemaFormBuildersConfig = Record<
  FieldSchemaFormType,
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ) => Partial<FieldSchemaFormBuilderV2<FieldSchemaModel>>
>;
export const customFieldSchemaFormBuilders: CustomFieldSchemaFormBuildersConfig =
  {
    ApplicationForm: applicationFormBuilder,
    ApplicationFormPropertyInformationPart:
      applicationPropertyInformationBuilder,
    ApplicationFormServiceOptionsPart: applicationServiceOptionsBuilder,
    ApplicationFormEligibilityPart: applicationEligibilityBuilder,
    ApplicationFormPhotoRequirementsPart: applicationPhotoRequirementsBuilder,

    RoofFieldEvaluationForm: roofFieldEvaluationFormBuilder,
    RoofFieldEvaluationFormCoverMaterialPart:
      roofFieldEvaluationCoverMaterialBuilder,
    RoofFieldEvaluationFormOtherRoofCoverConditionsPart:
      roofFieldEvaluationOtherRoofCoverConditionsBuilder,
    RoofFieldEvaluationFormGuttersAndDownspoutsPart:
      roofFieldEvaluationGuttersAndDownspoutsBuilder,
    RoofFieldEvaluationFormEavesAndSoffitsPart:
      roofFieldEvaluationEavesAndSoffitsBuilder,
    RoofFieldEvaluationFormVentsPart: roofFieldEvaluationVentsBuilder,

    HomeExteriorFieldEvaluationForm: homeExteriorFieldEvaluationFormBuilder,
    HomeExteriorFieldEvaluationFormVentsPart:
      homeExteriorFieldEvaluationVentsBuilder,
    HomeExteriorFieldEvaluationFormSixInchVerticalPart:
      homeExteriorFieldEvaluationSixInchVerticalBuilder,
    HomeExteriorFieldEvaluationFormSidingMaterialPart:
      homeExteriorFieldEvaluationSidingMaterialBuilder,
    HomeExteriorFieldEvaluationFormWindowsPart:
      homeExteriorFieldEvaluationWindowsBuilder,
    HomeExteriorFieldEvaluationFormDoorsPart:
      homeExteriorFieldEvaluationDoorsBuilder,
    HomeExteriorFieldEvaluationFormDecksPart:
      homeExteriorFieldEvaluationDecksBuilder,

    LandscapeFieldEvaluationForm: landscapeFieldEvaluationFormBuilder,
    LandscapeFieldEvaluationFormZeroToFiveFeetPart:
      landscapeFieldEvaluationZeroToFiveFeetFieldsBuilder,
    LandscapeFieldEvaluationFormFiveToThirtyFeetPart:
      landscapeFieldEvaluationFiveToThirtyFeetBuilder,

    OtherFieldEvaluationForm: otherFieldEvaluationFormBuilder,
    OtherEvaluationFormOtherStructuresAndOutbuildings:
      otherFieldEvaluationOtherStructuresAndOutbuildingsBuilder,

    LandscapeReviewForm: landscapeReviewFormBuilder,
    LandscapeReviewFormPropertyInformationPart:
      landscapeReviewPropertyInformationBuilder,
    LandscapeReviewFormPhotoRequirementsPart:
      landscapeReviewPhotoRequirementsBuilder,
  };
