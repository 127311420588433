import {
  EmailFieldProps,
  EnumMultiSelectFieldV2Props,
  EnumRadioFieldProps,
  FieldSchemaFormBuilderV2,
  SchemaFactoryV2,
  TextFieldProps,
} from "@ucl/library";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import {
  BuildingElementTypes,
  ContinuousLoadPathPathwayTypes,
  DesignationLevelTypes,
  DwellingTypes,
  NewConstructionOrExistingHomeTypes,
  NewOrExistingRoofTypes,
  requiredBuildingElementTypesForSilverAndGold,
  shouldNotHaveContinousLoadPathIfSilver,
} from "../../../../common/types/BaseHomeEvaluationEligibilityFormModel";
import { YesOrNoSelection } from "../../../../../../wildfire/types/FormFields";
import {
  ReadonlyWarningBannerField,
  ReadonlyWarningBannerFieldProps,
} from "../../../../../Common/components/Fields/ReadonlyWarningBanner/ReadonlyWarningBannerField";
import { HazardTypes } from "../../../../../Common/types/Evaluation/BaseEvaluation";
import { permissionStore } from "../../../../../../foritfied/stores/PermissionStore";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "EligibilityForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

//Part 1: Homeowner Information
const designationTypeAndLevelInformationFields = (
  form: Evaluation_HomeStandardEvaluationFormModel
) => [
  buildFieldKey("NewConstructionOrExistingHome"),
  buildFieldKey("NewOrExistingRoof"),
  {
    fieldKey: buildFieldKey("NewOrExistingRoofWarningBanner"),
    hidden: !(
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof ===
        NewOrExistingRoofTypes.ExistingRoof &&
      form.homeStandardEvaluation.eligibilityForm
        .newConstructionOrExistingHome ===
        NewConstructionOrExistingHomeTypes.NewConstruction
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("RooferEmail"),
    hidden:
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof !=
      NewOrExistingRoofTypes.NewRoof,
  } as EmailFieldProps,
  buildFieldKey("HasHailSupplement"),
  buildFieldKey("DesignationLevel"),
  {
    fieldKey: buildFieldKey("BuildingElementTypes"),
    hidden:
      form.homeStandardEvaluation.eligibilityForm.designationLevel !=
        DesignationLevelTypes.Silver &&
      form.homeStandardEvaluation.eligibilityForm.designationLevel !=
        DesignationLevelTypes.Gold,
  } as EnumMultiSelectFieldV2Props,
  buildFieldKey("DistanceToSaltWaterShorelineType"),
  {
    fieldKey: buildFieldKey("BuildingElementTypesWarningBanner"),
    hidden: !(
      form.homeStandardEvaluation.eligibilityForm.designationLevel ===
        DesignationLevelTypes.Silver &&
      !shouldNotHaveContinousLoadPathIfSilver.some(
        (type) =>
          form.homeStandardEvaluation.eligibilityForm.buildingElementTypes?.includes(
            type
          ) ||
          form.homeStandardEvaluation.eligibilityForm.buildingElementTypes
            ?.length === 0
      )
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("InstallingContractorEmail"),
    hidden: !requiredBuildingElementTypesForSilverAndGold.some((type) =>
      form.homeStandardEvaluation.eligibilityForm.buildingElementTypes?.includes(
        type
      )
    ),
  } as EmailFieldProps,
  {
    fieldKey: buildFieldKey(
      "IsEngineerProvidingComplianceDesignInfoForSilverOrGoldRequirements"
    ),
    hidden: !requiredBuildingElementTypesForSilverAndGold.some((type) =>
      form.homeStandardEvaluation.eligibilityForm.buildingElementTypes?.includes(
        type
      )
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("EngineerEmail"),
    hidden:
      form.homeStandardEvaluation.eligibilityForm
        .isEngineerProvidingComplianceDesignInfoForSilverOrGoldRequirements !=
      YesOrNoSelection.Yes,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "BuildingElementsEngineerIsProvidingComplianceDesignInfoFor"
    ),
    hidden:
      form.homeStandardEvaluation.eligibilityForm
        .isEngineerProvidingComplianceDesignInfoForSilverOrGoldRequirements !=
      YesOrNoSelection.Yes,
  } as EnumMultiSelectFieldV2Props,
  {
    fieldKey: buildFieldKey(
      "BuildingElementsEngineerIsProvidingComplianceDesignInfoForWarningBanner2"
    ),
    hidden: !(
      form.homeStandardEvaluation.eligibilityForm.buildingElementTypes?.includes(
        BuildingElementTypes.MultiLevelAttachedStructures
      ) &&
      !form.homeStandardEvaluation.eligibilityForm.buildingElementsEngineerIsProvidingComplianceDesignInfoFor?.includes(
        BuildingElementTypes.MultiLevelAttachedStructures
      ) &&
      form.homeStandardEvaluation.eligibilityForm
        .buildingElementsEngineerIsProvidingComplianceDesignInfoFor &&
      form.homeStandardEvaluation.eligibilityForm
        .buildingElementsEngineerIsProvidingComplianceDesignInfoFor?.length > 0
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("ContinuousLoadPathPathwayType"),
    hidden:
      form.homeStandardEvaluation.eligibilityForm.designationLevel !=
      DesignationLevelTypes.Gold,
  } as EnumMultiSelectFieldV2Props,
  {
    fieldKey: buildFieldKey("ContinuousLoadPathPathwayTypeWarningBanner1"),
    hidden: !(
      form.hazard !== HazardTypes.HighWind &&
      form.homeStandardEvaluation.eligibilityForm
        .continuousLoadPathPathwayType ===
        ContinuousLoadPathPathwayTypes.PrescriptiveHighWindOnlyForNewWoodFramedConstruction
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("ContinuousLoadPathPathwayTypeWarningBanner2"),
    hidden: !(
      form.homeStandardEvaluation.eligibilityForm
        .newConstructionOrExistingHome ===
        NewConstructionOrExistingHomeTypes.ExistingHome &&
      form.homeStandardEvaluation.eligibilityForm
        .continuousLoadPathPathwayType ===
        ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("ContinuousLoadPathPathwayTypeWarningBanner3"),
    hidden: !(
      form.homeStandardEvaluation.eligibilityForm
        .isEngineerProvidingComplianceDesignInfoForSilverOrGoldRequirements ===
        YesOrNoSelection.No &&
      form.homeStandardEvaluation.eligibilityForm
        .continuousLoadPathPathwayType ===
        ContinuousLoadPathPathwayTypes.EngineeredRetrofitOfExistingHome
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("ContinuousLoadPathPathwayTypeWarningBanner4"),
    hidden: !(
      form.homeStandardEvaluation.eligibilityForm
        .isEngineerProvidingComplianceDesignInfoForSilverOrGoldRequirements ===
        YesOrNoSelection.No &&
      form.homeStandardEvaluation.eligibilityForm
        .continuousLoadPathPathwayType ===
        ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction
    ),
  } as ReadonlyWarningBannerFieldProps,
];

const designationTypeAndLevelInformationRequiredFields = [
  buildFieldKey("NewConstructionOrExistingHome"),
  buildFieldKey("NewOrExistingRoof"),
  buildFieldKey("RooferEmail"),
  buildFieldKey("HasHailSupplement"),
  buildFieldKey("DesignationLevel"),
  buildFieldKey("BuildingElementTypes"),
  buildFieldKey("DistanceToSaltWaterShorelineType"),
  buildFieldKey("InstallingContractorEmail"),
  buildFieldKey(
    "IsEngineerProvidingComplianceDesignInfoForSilverOrGoldRequirements"
  ),
  buildFieldKey("EngineerEmail"),
  buildFieldKey("BuildingElementsEngineerIsProvidingComplianceDesignInfoFor"),
  buildFieldKey("ContinuousLoadPathPathwayType"),
];

export const homeStandardDesignationTypeAndLevelInformationFieldsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        designationTypeAndLevelInformationFields(form),
        onValueChange,
        form,
        errors,
        designationTypeAndLevelInformationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

//Part 2: Property Details

const eligibilityInformationFields = (
  form: Evaluation_HomeStandardEvaluationFormModel
) => [
  buildFieldKey("DwellingType"),
  {
    fieldKey: buildFieldKey("DwellingTypeWarningBanner"),
    hidden:
      form.homeStandardEvaluation.eligibilityForm.dwellingType !=
      DwellingTypes.NoneOfTheAbove,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("DwellingTypePhotos"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("IsHomeOnADryStackOrHudFoundation"),
    hidden:
      form.homeStandardEvaluation.eligibilityForm.designationLevel !=
      DesignationLevelTypes.Roof,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("IsHomeOnADryStackOrHudFoundationWarningBanner"),
    hidden:
      form.homeStandardEvaluation.eligibilityForm
        .isHomeOnADryStackOrHudFoundation != YesOrNoSelection.Yes,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("IsHomeConnectedToFoundation"),
    hidden:
      form.homeStandardEvaluation.eligibilityForm.designationLevel !=
        DesignationLevelTypes.Silver &&
      form.homeStandardEvaluation.eligibilityForm.designationLevel !=
        DesignationLevelTypes.Gold,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("IsHomeConnectedToFoundationWarningBanner"),
    hidden:
      form.homeStandardEvaluation.eligibilityForm.isHomeConnectedToFoundation !=
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("FoundationEligibilityPhotos"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
  } as WildfireBoxContentExplorerProps,
  buildFieldKey("IsProfessionalEngineerProvidingCAndCRoofUplifDesingPressures"),
  buildFieldKey("IsProjectAHomeBuiltByNonProfit"),
  {
    fieldKey: buildFieldKey("NonProfitEIN"),
    hidden:
      form.homeStandardEvaluation.eligibilityForm
        .isProjectAHomeBuiltByNonProfit != YesOrNoSelection.Yes,
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("LetterFromNonProfit"),
    defaultToUpload: false,
    hidden:
      form.homeStandardEvaluation.eligibilityForm
        .isProjectAHomeBuiltByNonProfit != YesOrNoSelection.Yes,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "IsThisProjectReleatedToFloridaExistingHomesProject"
    ),
    hidden:
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof !=
      NewOrExistingRoofTypes.ExistingRoof,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "IsThisProjectReleatedToFloridaExistingHomesProjectWarningBanner"
    ),
    hidden:
      form.homeStandardEvaluation.eligibilityForm
        .isThisProjectReleatedToFloridaExistingHomesProject !=
      YesOrNoSelection.Yes,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("FloridaExistingHomeProjectEligibilityDocuments"),
    defaultToUpload: false,
    hidden:
      form.homeStandardEvaluation.eligibilityForm
        .isThisProjectReleatedToFloridaExistingHomesProject !=
      YesOrNoSelection.Yes,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("OtherDocuments"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
  } as WildfireBoxContentExplorerProps,
];

const eligibilityInformationRequiredFields = [
  buildFieldKey("DwellingType"),
  buildFieldKey("DwellingTypePhotos"),
  buildFieldKey("IsHomeOnADryStackOrHudFoundation"),
  buildFieldKey("IsHomeConnectedToFoundation"),
  buildFieldKey("FoundationEligibilityPhotos"),
  buildFieldKey("IsProfessionalEngineerProvidingCAndCRoofUplifDesingPressures"),
  buildFieldKey("IsProjectAHomeBuiltByNonProfit"),
  buildFieldKey("NonProfitEIN"),
  buildFieldKey("LetterFromNonProfit"),
  buildFieldKey("IsThisProjectReleatedToFloridaExistingHomesProject"),
  buildFieldKey("FloridaExistingHomeProjectEligibilityDocuments"),
];

export const homeStandardEligibilityInformationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        eligibilityInformationFields(form),
        onValueChange,
        form,
        errors,
        eligibilityInformationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included
export const homeStandardEligibilityFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...designationTypeAndLevelInformationFields(form),
          ...eligibilityInformationFields(form),
        ],
        onValueChange,
        form,
        errors,
        [
          ...designationTypeAndLevelInformationRequiredFields,
          ...eligibilityInformationRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeStandardIterationEngineEligibilityFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...designationTypeAndLevelInformationFields(form),
          ...eligibilityInformationFields(form),
        ],
        onValueChange,
        form,
        errors,
        [
          ...designationTypeAndLevelInformationRequiredFields,
          ...eligibilityInformationRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};
