import { Schema } from "@ucl/library";
import { useContext, useEffect, useRef, useState } from "react";
import wildfireBaseApiClient from "../../../../../../../wildfire/lib/wildfireBaseApiClient";
import { UnsavedChangesBlockerContext } from "../../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../../../../../common/types/Forms/FormChecklist";
import { HomeBasicInformationFormParts } from "../../../../../common/types/BaseHomeEvaluationBasicInformationFormModel";
import { HomeSchemaFactoryV2 } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { HomeSCSHBasicInformationFormContainerProps } from "./HomeSCSHBasicInformationFormContainer";
import { Evaluation_HomeSCSHEvaluationFormModel } from "../../../../types/HomeSCSHEvaluationFormModel";
import { debounce } from "lodash";
import { AddressFieldValue } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { homeSCSHEvaluationAPIClient } from "../../../../lib/apiClients/homeSCSHEvaluationAPIClient";
import { AppHeaderContext } from "../../../../../../../wildfire/components/Provider/AppHeaderContext";

function useHomeSCSHBasicInformationFormContainer(
  props: HomeSCSHBasicInformationFormContainerProps
) {
  const [homeSchemaFactory, setHomeSchemaFactory] = useState<
    HomeSchemaFactoryV2 | undefined
  >();
  const [formPart, setFormPart] = useState<
    HomeBasicInformationFormParts | undefined
  >(props.basicInformationFormPart);
  const [evaluationFormModel, setEvaluationFormModel] = useState<
    Evaluation_HomeSCSHEvaluationFormModel | undefined
  >(undefined);

  const [hasDuplicateAddress, setHasDuplicateAddress] =
    useState<boolean>(false);

  const [initialCheckDone, setInitialCheckDone] = useState<boolean>(false); //

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  // Fetch schema on component load if not already set
  const fetchSchema = async () => {
    if (!homeSchemaFactory) {
      const schema = await wildfireBaseApiClient.get<Schema>(
        `/wildfire/schema`
      );
      setHomeSchemaFactory(new HomeSchemaFactoryV2(schema));
    }
  };

  useEffect(() => {
    if (!homeSchemaFactory) {
      fetchSchema();
    }
    return () => {
      setDefaultHeader();
    };
  }, []);

  const { setFotifiedHomeEvaluationHeader, setDefaultHeader } =
    useContext(AppHeaderContext);

  useEffect(() => {
    if (evaluationFormModel) {
      setFotifiedHomeEvaluationHeader(evaluationFormModel);
    }
  }, [evaluationFormModel]);

  const checkForDuplicateAddress = useRef(
    debounce(async (address: AddressFieldValue) => {
      if (!address.street || !address.city || !address.state) return;

      const response =
        await homeSCSHEvaluationAPIClient.hasEvaluationsWithSameAddress(
          props.evaluationId
        );
      setHasDuplicateAddress(response);
    }, 2000)
  ).current;

  useEffect(() => {
    return () => {
      checkForDuplicateAddress.cancel();
    };
  }, [checkForDuplicateAddress]);

  useEffect(() => {
    if (
      !initialCheckDone &&
      evaluationFormModel?.homeSCSHEvaluation.basicInformationForm?.address
    ) {
      // Perform the initial duplicate address check
      checkForDuplicateAddress(
        evaluationFormModel.homeSCSHEvaluation.basicInformationForm.address
      );
      setInitialCheckDone(true); // Ensure the initial check is only done once
    }
  }, [evaluationFormModel, initialCheckDone]);

  const onFieldChange = async (fieldKey: string, value: any) => {
    if (
      fieldKey ===
      "Evaluation__HomeSCSHEvaluation__BasicInformationForm__Address"
    ) {
      checkForDuplicateAddress(value);
    }
  };

  // Set the best form part whenever the basic information form part changes
  useEffect(() => {
    setFormPart(getBestFormPart());
  }, [props.basicInformationFormPart, evaluationFormModel]);

  const basicInformationForm =
    evaluationFormModel?.homeSCSHEvaluation.basicInformationForm;

  // Determine the next unsubmitted form part
  const getNextUnsubmittedFormPart =
    (): HomeBasicInformationFormParts | null => {
      if (!basicInformationForm) return null;

      if (!basicInformationForm.isHomeownerInformationComplete) {
        return HomeBasicInformationFormParts.HomeownerInformation;
      }

      return null; // Form is complete
    };

  // Get the most appropriate form part
  const getBestFormPart = (): HomeBasicInformationFormParts => {
    if (props.basicInformationFormPart) return props.basicInformationFormPart;

    return (
      getNextUnsubmittedFormPart() ??
      Object.values(HomeBasicInformationFormParts)[0]
    );
  };

  // Get checklist status for each part
  const getApplicationPartChecklistStatus = (
    part: HomeBasicInformationFormParts
  ): FormChecklistStatus => {
    if (part === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    const isComplete = (complete: boolean | undefined) =>
      complete
        ? FormChecklistStatus.Submitted
        : FormChecklistStatus.Unsubmitted;

    switch (part) {
      case HomeBasicInformationFormParts.HomeownerInformation:
        return isComplete(basicInformationForm?.isHomeownerInformationComplete);
    }
  };

  // Define checklist parts with dynamic properties
  const checklistParts: FormChecklistPart<HomeBasicInformationFormParts>[] = [
    {
      formPart: HomeBasicInformationFormParts.HomeownerInformation,
      displayName: "Homeowner Information",
      requiredFieldCount:
        basicInformationForm?.homeownerInformationRequiredFieldCount,
    },
  ];

  return {
    checklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    formPart,
    getBestFormPart,
    homeSchemaFactory,
    onFieldChange,
    hasDuplicateAddress,
  };
}

export default useHomeSCSHBasicInformationFormContainer;
