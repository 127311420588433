import {
  MessageComponent,
  Severity,
} from "@syncfusion/ej2-react-notifications";
import { fortifiedUserStore } from "../../../../../foritfied/stores/FortifiedUserStore";
import { EvaluationStep } from "../../../../../foritfied/types/evaluation/Evaluation";
import AdminPanelButton from "../../../common/components/Buttons/AdminPanelButton/AdminPanelButton";
import { evaluationStore } from "../../../../../foritfied/stores/EvaluationStore";
import { ProceedToPaymentActionButtonV2 } from "../../../../Common/components/IterationEngine/ProceedToPaymentActionButtonV2";
import { ProductSubtypes } from "../../../../Common/types/Evaluation/BaseEvaluation";
import { permissionStore } from "../../../../../foritfied/stores/PermissionStore";
import WithdrawEvaluationActionButton from "../../../../../foritfied/components/Evaluation/EvaluationActions/WithdrawEvaluationActionButton";
import DownloadCertificateActionButton from "../../../../../foritfied/components/Evaluation/EvaluationActions/DownloadCertificateActionButton";
import ViewRejectionReasonsActionButton from "../../../../../foritfied/components/Evaluation/EvaluationActions/ViewRejectionReasonsActionButton";
import PostDesignationFilesActionButton from "../../../../../foritfied/components/Evaluation/EvaluationActions/PostDesignationFilesActionButton";
import ResubmitEvaluationActionButton from "../../../../../foritfied/components/Evaluation/EvaluationActions/ResubmitEvaluationActionButton";
import ClaimEvaluationActionButton from "../../../../../foritfied/components/Evaluation/EvaluationActions/ClaimEvaluationActionButton";
import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { dialogsViewerStore } from "../../../../../foritfied/stores/DialogsViewerStore";
import RequestChangesButton from "../../../../FCFMF/common/components/Button/RequestChanges/RequestChangesActionButton";
import TraineeClaimEvaluationActionButton from "../../../../../foritfied/components/Evaluation/EvaluationActions/TraineeClaimEvaluationActionButton";
import TraineeSendForReviewActionButton from "../../../../../foritfied/components/Evaluation/EvaluationActions/TraineeSendForReviewActionButton";
import TraineeReturnChangesRequiredActionButton from "../../../../../foritfied/components/Evaluation/EvaluationActions/TraineeReturnChangesRequiredActionButton";
import { homeNCIUAEvaluationAPIClient } from "../../evaluation/lib/apiClients/homeNCIUAEvaluationAPIClient";
import { useState } from "react";
import { showSuccessAppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { useNavigate } from "react-router";
import { fortifiedHomeRoutePrefix } from "../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { Evaluation_HomeNCIUAEvaluation } from "../../evaluation/types/HomeNCIUAEvaluation";
import { ChangeRequestV2 } from "../../../../../wildfire/components/IterationEngine/types/ChangeRequestV2";

interface HomeNCIUAIterationEngineHeaderProps {
  evaluationId: string;
  evaluation: Evaluation_HomeNCIUAEvaluation;
  setEvaluation: (evaluation: Evaluation_HomeNCIUAEvaluation) => void;
  allChangeRequestsAreResolved: boolean;
  hasNoUnaddressedChangeRequests: boolean;
  addressedAtLeastOneChangeRequest: boolean;
  isEvaluationClaimedByCurrentUser: boolean;
  refreshEvaluation: () => Promise<void>;
  longestOutstandingChangeRequest: ChangeRequestV2 | undefined;
}

const HomeNCIUAIterationEngineHeader: React.FC<
  HomeNCIUAIterationEngineHeaderProps
> = (props) => {
  const canViewAuditorButtons = () => {
    if (props.evaluation.hasFinalDetermination) {
      return false;
    }
    switch (props.evaluation.step) {
      case EvaluationStep.EligibilityReview:
      case EvaluationStep.FinalReview:
        return (
          fortifiedUserStore.hasRole("FC/FMF Auditor") ||
          fortifiedUserStore.hasRole("FH Auditor") ||
          fortifiedUserStore.hasRole("IBHS Admin") ||
          fortifiedUserStore.hasRole("Relativity Admin")
        );
      default:
        return false;
    }
  };

  const canAudit =
    props.isEvaluationClaimedByCurrentUser && canViewAuditorButtons();

  const [isSendingForward, setIsSendingForward] = useState(false);

  const navigate = useNavigate();

  return (
    <div className="wildfire-iteration-engine__header">
      <div className="left-panel">
        {props.evaluation.step === EvaluationStep.Payment &&
          props.evaluation.isPaymentApplicable && (
            <ProceedToPaymentActionButtonV2
              evaluationId={props.evaluationId}
              fortifiedId={props.evaluation.fortifiedId || ""}
              productSubtype={ProductSubtypes.HomeNCIUAEvaluation}
              evaluationStep={props.evaluation.step}
              getPaymentDetails={async () =>
                await homeNCIUAEvaluationAPIClient.getApplicationFeePaymentCreditDetails(
                  props.evaluationId
                )
              }
              setEvaluation={props.setEvaluation}
            />
          )}
      </div>
      <div className="right-panel">
        {permissionStore.canWithdraw(evaluationStore.evaluation) && (
          <WithdrawEvaluationActionButton />
        )}
        {props.evaluation.approvedAt && <DownloadCertificateActionButton />}
        {props.evaluation.isApproved === false && (
          <ViewRejectionReasonsActionButton />
        )}
        {props.evaluation.isApproved && (
          <PostDesignationFilesActionButton
            evaluationId={props.evaluation?.id}
            rootBoxFolderId={props.evaluation?.externalFolderId}
            postDesignationBoxFolderId={
              evaluationStore.evaluation?.postDesignationBoxFolderId
            }
          />
        )}
        {evaluationStore.canResubmit && <ResubmitEvaluationActionButton />}
        {permissionStore.canClaimEvaluations && (
          <ClaimEvaluationActionButton
            afterClaimed={props.refreshEvaluation}
            isEvaluationClaimedByCurrentUser={
              props.isEvaluationClaimedByCurrentUser
            }
          />
        )}
        {evaluationStore.isEvaluationOnRequiredPaymentStep && (
          <MessageComponent
            content={"Payment still needs to be submitted"}
            severity={Severity.Warning}
          />
        )}
        {evaluationStore.isEvaluationNonProfitOnPaymentStep && (
          <MessageComponent
            severity={Severity.Info}
            content={"Non-profit projects do not require payment"}
          />
        )}
        {canAudit && (
          <>
            {props.evaluation.step == EvaluationStep.FinalReview && (
              <Button
                text="Approve"
                intent={Intent.SUCCESS}
                icon={IconNames.TICK_CIRCLE}
                disabled={!props.allChangeRequestsAreResolved}
                minimal
                onClick={() => {
                  dialogsViewerStore.setIsAuditorApprovalDialogOpen(true, {
                    afterApproval: props.refreshEvaluation,
                  });
                }}
              />
            )}

            {props.evaluation.step == EvaluationStep.EligibilityReview && (
              <Button
                text="Send Forward"
                intent={Intent.SUCCESS}
                icon={IconNames.TICK_CIRCLE}
                disabled={!props.allChangeRequestsAreResolved}
                loading={isSendingForward}
                minimal
                onClick={async () => {
                  setIsSendingForward(true);
                  await homeNCIUAEvaluationAPIClient
                    .moveToApplicationFormsStep(props.evaluationId)
                    .then((evaluation) => {
                      props.setEvaluation(evaluation);
                      showSuccessAppToaster(
                        "Evaluation sent forward successfully"
                      );
                      navigate(`${fortifiedHomeRoutePrefix}`);
                    })
                    .finally(() => {
                      setIsSendingForward(false);
                    });
                }}
              />
            )}

            <RequestChangesButton
              isRequestChangesDisabled={props.hasNoUnaddressedChangeRequests}
              requestChanges={async () =>
                await homeNCIUAEvaluationAPIClient.sendRequestedChangesEmail(
                  props.evaluationId
                )
              }
            />

            <Button
              text="Reject"
              intent={Intent.DANGER}
              icon={IconNames.DELETE}
              minimal
              onClick={() =>
                dialogsViewerStore.setIsAuditorRejectionDialogOpen(true, {
                  afterRejection: props.refreshEvaluation,
                })
              }
              disabled={!props.isEvaluationClaimedByCurrentUser}
            />
          </>
        )}
        {!!evaluationStore.evaluation &&
          fortifiedUserStore.isAuditorTrainee &&
          evaluationStore.shouldShowTraineeAuditActionButtons &&
          !evaluationStore.isClaimedForTraineeAuditByCurrentUser && (
            <TraineeClaimEvaluationActionButton
              disabled={evaluationStore.isClaimedForTraineeAudit}
            />
          )}

        {!!evaluationStore.evaluation &&
          fortifiedUserStore.isAuditorTrainee &&
          evaluationStore.shouldShowTraineeAuditActionButtons &&
          evaluationStore.isClaimedForTraineeAuditByCurrentUser && (
            <TraineeSendForReviewActionButton
              evaluationId={evaluationStore.evaluation?.id}
              disabled={evaluationStore.isTraineeAuditActionByAdminRequired}
            />
          )}
        {!!evaluationStore.evaluation &&
          fortifiedUserStore.isTraineeAuditAdmin &&
          evaluationStore.shouldShowTraineeAuditActionButtons &&
          evaluationStore.isClaimedForTraineeAudit && (
            <div className="evaluation-container__content__heading">
              <TraineeReturnChangesRequiredActionButton
                evaluationId={evaluationStore.evaluation?.id}
                disabled={evaluationStore.isTraineeAuditActionByTraineeRequired}
              />
            </div>
          )}
        {}
        <AdminPanelButton evaluationId={props.evaluationId} />
        {!props.hasNoUnaddressedChangeRequests &&
          props.evaluation.shouldShowAutoRejectBanner &&
          props.longestOutstandingChangeRequest && (
            <MessageComponent
              className="message"
              content={`Evaluation will auto-reject in ${
                props.longestOutstandingChangeRequest
                  .daysTillEligibleForAutoRejection + 1
              } days, if all changes are not addressed`}
              severity={Severity.Warning}
              showCloseIcon={true}
            />
          )}
      </div>
    </div>
  );
};

export default HomeNCIUAIterationEngineHeader;
