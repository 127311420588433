import { FC } from "react";
import useWildfireEvaluationHistory from "./useWildfireEvaluationHistory";
import { Divider, Spinner, SpinnerSize } from "@blueprintjs/core";
import { SelectableLabelWithToolTip } from "../../../../foritfied/components/Fields/SelectableLabel/SelectableLabelWithToolTip";

export interface WildfireEvaluationHistoryProps {
  evaluationId: string;

  readonly?: boolean;
}

const WildfireEvaluationHistory: FC<WildfireEvaluationHistoryProps> = (
  props
) => {
  const {
    isLoading,
    shouldShowEvaluationHistory,
    historyEntries,
    shouldHideHyperlink,
    getEvaluationLink,
    getHistoryRowDisplayText,
  } = useWildfireEvaluationHistory(props);
  return (
    <>
      {shouldShowEvaluationHistory && (
        <div className="evaluation-history">
          <Divider className="evaluation-container__status__divider" />
          <SelectableLabelWithToolTip name="History" />

          {isLoading && <Spinner size={SpinnerSize.SMALL} />}
          {!isLoading && (
            <div className="history-list">
              {historyEntries.map((evaluation) => (
                <div key={evaluation.id}>
                  {!shouldHideHyperlink(evaluation) && (
                    <a key={evaluation.id} href={getEvaluationLink(evaluation)}>
                      {getHistoryRowDisplayText(evaluation)}
                    </a>
                  )}
                  {shouldHideHyperlink(evaluation) && (
                    <div key={evaluation.id}>
                      {getHistoryRowDisplayText(evaluation)}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default WildfireEvaluationHistory;
