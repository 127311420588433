import { FieldFormV2Module } from "@ucl/library";
import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../../../../common/Components/Error/ErrorStore";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { useNavigate } from "react-router";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../../types/HomeEvaluationFormModel";
import { homeStandardEvaluationAPIClient } from "../../../../lib/apiClients/homeStandardEvaluationAPIClient";
import { fortifiedHomeStandardRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { useHomeFieldSchemaFactory } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { HomeStandardEvaluationRooferConfirmationFormProps } from "./HomeStandardEvaluationRooferConfirmationForm";
import { BaseHomeEvaluationRooferConfirmationFormParts } from "../../../../../common/types/BaseHomeEvaluationRooferConfirmationFormModel";
import { homeStandardEvaluationRooferConfirmationAPIClient } from "../../../../lib/apiClients/homeStandardEvaluationRooferConfirmationAPIClient";

function useHomeStandardEvaluationRooferConfirmationForm(
  props: HomeStandardEvaluationRooferConfirmationFormProps
) {
  const { builders, wieBuilders } = useHomeFieldSchemaFactory();
  const navigate = useNavigate();

  const formRef =
    useRef<FieldFormV2Module<Evaluation_HomeStandardEvaluationFormModel>>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formModel, setFormModel] =
    useState<Evaluation_HomeStandardEvaluationFormModel>();

  const [isInitialized, setIsInitialized] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
      props.onInitialized?.();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isInitialized) {
      props.onInitialized?.();
    }
  }, [isInitialized]);

  useEffect(() => {
    //Used to React to External Changes Pushed on the Form (Iteration Engine)
    if (formModel) {
      setFormModel({ ...formModel });
    }
  }, [wieBuilders]);

  useEffect(() => {
    fetchApplicationForm();
  }, []);

  useEffect(() => {
    if (!!formModel) {
      setFormModel(formModel);

      if (props.setFormModel) {
        props.setFormModel(formModel);
      }
    }
  }, [formModel]);

  // Differentiates between save and submit
  let shouldSubmit = false;
  const setShouldSubmit = (value: boolean) => {
    shouldSubmit = value;
  };

  const isFirstPartOfForm =
    props.formPart ===
    Object.values(BaseHomeEvaluationRooferConfirmationFormParts)[0];

  const isLastPartOfForm =
    props.formPart ===
    Object.values(BaseHomeEvaluationRooferConfirmationFormParts).pop();

  const noBuilders = !builders && !wieBuilders;

  const fetchApplicationForm = async () => {
    setIsLoading(true);
    await homeStandardEvaluationAPIClient
      .getHomeStandardEvaluationFormModel(props.evaluationId)
      .then((response) => {
        setFormModel((prevModel) => ({
          ...prevModel,
          ...response,
        }));
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Debounce setup
  const handleFormSubmitDebounced = useCallback(
    debounce(
      async (
        form: Evaluation_HomeStandardEvaluationFormModel,
        _value: any,
        fieldProps: BaseFieldProps<any>
      ) => {
        if (fieldProps.fieldKey) {
          await handleFormSubmit(form, fieldProps.fieldKey).then(async () => {
            await props.onFormSave?.(fieldProps.fieldKey || "", _value);
          });
        }
      },
      2000
    ),
    []
  );

  useEffect(() => {
    // Cleanup the debounce function on component unmount
    return () => handleFormSubmitDebounced.cancel();
  }, [handleFormSubmitDebounced]);

  const onFormFieldChange = async (
    form: Evaluation_HomeStandardEvaluationFormModel,
    _value: any,
    fieldProps: BaseFieldProps<any>
  ) => {
    // Call the debounced submit function
    if (!!_value) {
      handleFormSubmitDebounced(form, _value, fieldProps);
      props.setHasUnsavedChanges(true);
    }
  };

  const getApplicationFormPartBuilder = () => {
    if (props.isIterationEnginePage) {
      return wieBuilders?.HomeStandardIterationEngineRooferConfirmationForm;
    }
    switch (props.formPart) {
      case BaseHomeEvaluationRooferConfirmationFormParts.RoofingConfirmation:
        return builders?.HomeStandardRooferConfirmationForm;
      default:
        return builders?.HomeStandardRooferConfirmationForm;
    }
  };

  const handleFormSubmit = async (
    form: Evaluation_HomeStandardEvaluationFormModel,
    fieldKey?: string
  ) => {
    setFormModel(form);
    await (shouldSubmit
      ? homeStandardEvaluationRooferConfirmationAPIClient.submitHomeStandardRooferConfirmationForm(
          form.id,
          form.homeStandardEvaluation.rooferConfirmationForm,
          props.formPart
        )
      : homeStandardEvaluationRooferConfirmationAPIClient.updateHomeStandardRooferConfirmationForm(
          form.id,
          form.homeStandardEvaluation.rooferConfirmationForm,
          fieldKey
        )
    )
      .then(async (response) => {
        handleFormResponse(response);
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while saving the form",
            intent: Intent.DANGER,
          });
        }
        throw error;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleFormResponse = async (
    response: Evaluation_HomeStandardEvaluationFormModel
  ) => {
    props.setHasUnsavedChanges(false);

    if (shouldSubmit) {
      setFormModel(response);

      if (isLastPartOfForm) {
        navigate(
          `${fortifiedHomeStandardRoutePrefix}/checklist/${props.evaluationId}`
        );
      } else {
        // Navigate to next part of form
        const nextFormPart = Object.values(
          BaseHomeEvaluationRooferConfirmationFormParts
        )[
          Object.values(BaseHomeEvaluationRooferConfirmationFormParts).indexOf(
            props.formPart!
          ) + 1
        ].valueOf();

        navigate(
          `${fortifiedHomeStandardRoutePrefix}/${props.evaluationId}/roofer-confirmation/${nextFormPart}`
        );
      }

      // }
    } else {
      handlePartialResponseFormUpdate(response);
    }
  };

  const handlePartialResponseFormUpdate = (
    response: Evaluation_HomeStandardEvaluationFormModel
  ) => {
    // This is used to combat save on blur issues. This only updates fields that are essential to the form.
    // These are set in the API response. Field Counts and sub section completion status
    setFormModel((prevState) => {
      if (!prevState) return prevState;

      const {
        areAllRequiredFieldsComplete: AreAllRequiredFieldsComplete,
        rooferConfirmationComplete: RooferConfirmationComplete,
        rooferConfirmationLastSubmittedAt: RooferConfirmationLastSubmittedAt,
        rooferConfirmationRequiredCount: RooferConfirmationRequiredCount,
        isCertifiedRoofingContractorAndComplianceAccurate,
        showWarning_IsCertifiedRoofingContractorAndComplianceAccurate,
        understandsFraudulentInformationPenalties,
        showWarning_UnderstandsFraudulentInformationPenalties,
        printedName,
        companyName,
        phoneNumber,
        address,
        licenseOrRegistrationNumber,
        fortifiedWiseRoofingConactorID,
        signedRoofingComplianceForms,
      } = response.homeStandardEvaluation.rooferConfirmationForm;

      const { canProceedToApplicationFee } = response.homeStandardEvaluation;

      return {
        ...prevState,
        homeStandardEvaluation: {
          ...prevState.homeStandardEvaluation,
          canProceedToApplicationFee,
          rooferConfirmationForm: {
            ...prevState.homeStandardEvaluation.rooferConfirmationForm,
            areAllRequiredFieldsComplete: AreAllRequiredFieldsComplete,
            rooferConfirmationComplete: RooferConfirmationComplete,
            rooferConfirmationLastSubmittedAt:
              RooferConfirmationLastSubmittedAt,
            rooferConfirmationRequiredCount: RooferConfirmationRequiredCount,
            isCertifiedRoofingContractorAndComplianceAccurate,
            showWarning_IsCertifiedRoofingContractorAndComplianceAccurate,
            understandsFraudulentInformationPenalties,
            showWarning_UnderstandsFraudulentInformationPenalties,
            printedName,
            companyName,
            phoneNumber,
            address,
            licenseOrRegistrationNumber,
            fortifiedWiseRoofingConactorID,
            signedRoofingComplianceForms,
          },
        },
      };
    });
  };

  return {
    builders,
    wieBuilders,
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    formModel,
    isFirstPartOfForm,
    isLastPartOfForm,
    getApplicationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    noBuilders,
    containerRef,
    setShouldSubmit,
  };
}

export default useHomeStandardEvaluationRooferConfirmationForm;
