import {
  ApplicationFormModel,
  ApplicationFormParts,
} from "../../../forms/ApplicationForm/ApplicationFormModel";
import {
  WildfireEvaluation,
  WildfireEvaluationHistoryEntry,
  WildfireEvaluationOverview,
} from "../../../types/WildfireApplication/WildfireApplication";
import wildfireBaseApiClient from "../../wildfireBaseApiClient";
import {
  RoofFieldEvaluationFormModel,
  RoofFieldEvaluationFormParts,
} from "../../../forms/RoofFieldEvaluationForm/RoofFieldEvaluationFormModel";
import {
  AddressFieldValue,
  Option,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  HomeExteriorFieldEvaluationFormModel,
  HomeExteriorFieldEvaluationFormParts,
} from "../../../forms/HomeExteriorFieldEvaluationForm/HomeExteriorFieldEvaluationFormModel";
import { EvaluationFormModel } from "../../../forms/EvaluationFormModel";
import {
  LandscapeFieldEvaluationFormModel,
  LandscapeFieldEvaluationFormParts,
} from "../../../forms/LandscapeFieldEvaluationForm/LandscapeFieldEvaluationFormModel";
import {
  OtherFieldEvaluationFormModel,
  OtherFieldEvaluationFormParts,
} from "../../../forms/OtherFieldEvaluationForm/OtherFieldEvaluationFormModel";
import { WildfireApplicationIterationEngineFormModel } from "../../../forms/IterationEngine/WildfireApplicationIterationEngineFormModel";
import { WildfireLandscapeReviewIterationEngineFormModel } from "../../../forms/IterationEngine/WildfireLandscapeReviewIterationEngineFormModel";
import {
  LandscapeReviewFormModel,
  LandscapeReviewFormParts,
} from "../../../forms/LandscapeReviewForm/LandscapeReviewFormModel";

class WildfireEvaluationApiClient {
  GetWildfireEvaluationsForApplicantCards = async () => {
    return wildfireBaseApiClient.get<WildfireEvaluationOverview[]>(
      `wildfire-evaluation/applicant-cards`
    );
  };

  getWildfireEvaluationWithFormsById = async (id: string) => {
    return wildfireBaseApiClient.get<EvaluationFormModel>(
      `wildfire-evaluation/${id}`
    );
  };

  getWildfireEvaluationById = async (id: string) => {
    return wildfireBaseApiClient.get<WildfireEvaluation>(
      `wildfire-evaluation/${id}`
    );
  };

  getWildfireEvaluationCommentAndChangeRequestFieldKeys = async (
    id: string
  ) => {
    return wildfireBaseApiClient.get<string[]>(
      `wildfire-evaluation/${id}/comment-change-request-field-keys`
    );
  };

  duplicateAddressCheck = async (
    wildfireEvaluationId: string,
    address: AddressFieldValue
  ) => {
    return wildfireBaseApiClient.post<boolean>(
      `/wildfire-evaluation/${wildfireEvaluationId}/duplicate-address-check`,
      address
    );
  };

  cancelMadronusIntegration = async (wildfireEvaluationId: string) => {
    return wildfireBaseApiClient.post(
      `/wildfire-evaluation/${wildfireEvaluationId}/madronus-integration/cancel`
    );
  };

  downloadCertificate = async (wildfireEvaluationId: string) => {
    return wildfireBaseApiClient.download(
      `/wildfire-evaluation/${wildfireEvaluationId}/get-certificate`
    );
  };

  regenerateCertificate = async (wildfireEvaluationId: string) => {
    return wildfireBaseApiClient.download(
      `/wildfire-evaluation/${wildfireEvaluationId}/regenerate-evaluation-certificate`
    );
  };

  getEvaluationHistorySummary = async (wildfireEvaluationId: string) => {
    return wildfireBaseApiClient.get<WildfireEvaluationHistoryEntry[]>(
      `/wildfire-evaluation/${wildfireEvaluationId}/history`
    );
  };

  getEvaluationCompanies = async (evaluationId: string) => {
    return wildfireBaseApiClient.get<Option<number>[]>(
      `/wildfire-evaluation/${evaluationId}/evaluation-company-zip-codes`
    );
  };

  getRejectionReasonOptionValues = async () => {
    return wildfireBaseApiClient.get<Option<number>[]>(
      `/wildfire-evaluation/rejection-reason-options`
    );
  };

  claimEvaluation = async (evaluationId: string) => {
    return await wildfireBaseApiClient.put(
      `/wildfire-evaluation/${evaluationId}/claim`
    );
  };

  // Iteration Engine
  updateWildfireApplicationIterationEngine = async (
    wildfireApplicationId: string,
    wildfireApplicationIterationEngineForm: WildfireApplicationIterationEngineFormModel,
    fieldKey?: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.put<EvaluationFormModel>(
      `/wildfire/form/application-iteration-engine/${wildfireApplicationId}`,
      {
        applicationForm: wildfireApplicationIterationEngineForm.applicationForm,
        homeExteriorFieldEvaluationForm:
          wildfireApplicationIterationEngineForm.homeExteriorFieldEvaluationForm,
        landscapeFieldEvaluationForm:
          wildfireApplicationIterationEngineForm.landscapeFieldEvaluationForm,
        otherFieldEvaluationForm:
          wildfireApplicationIterationEngineForm.otherFieldEvaluationForm,
        roofFieldEvaluationForm:
          wildfireApplicationIterationEngineForm.roofFieldEvaluationForm,
        fieldKey,
      }
    );
  };

  updateWildfireLandscapeReviewIterationEngine = async (
    wildfireLandscapeReviewId: string,
    wildfireLandscapeReviewIterationEngineForm: WildfireLandscapeReviewIterationEngineFormModel,
    fieldKey?: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.put<EvaluationFormModel>(
      `/wildfire/form/landscape-review-iteration-engine/${wildfireLandscapeReviewId}`,
      {
        landscapeReviewForm:
          wildfireLandscapeReviewIterationEngineForm.landscapeReviewForm,
        fieldKey,
      }
    );
  };

  // Application Form
  getWildfireEvaluationApplicationForm = async (
    wildfireEvaluationId: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.get<EvaluationFormModel>(
      `/wildfire/form/${wildfireEvaluationId}/application-form`
    );
  };

  updateApplicationForm = async (
    wildfireApplicationId: string,
    applicationForm: ApplicationFormModel,
    fieldKey?: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.put<EvaluationFormModel>(
      `/wildfire/form/${wildfireApplicationId}/application`,
      {
        applicationForm,
        fieldKey,
      }
    );
  };

  submitApplicationForm = async (
    wildfireApplicationId: string,
    applicationForm: ApplicationFormModel,
    applicationFormPart?: ApplicationFormParts
  ): Promise<EvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const applicationFormPartValue = (
      applicationFormPart ??
      (Object.values(ApplicationFormParts).pop() as ApplicationFormParts)
    ).valueOf();
    return wildfireBaseApiClient.post<EvaluationFormModel>(
      `/wildfire/form/${wildfireApplicationId}/application/${applicationFormPartValue}`,
      applicationForm
    );
  };

  getRoofFieldEvaluationForm = async (
    wildfireEvaluationId: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.get<EvaluationFormModel>(
      `/wildfire/form/${wildfireEvaluationId}/roof-field-evaluation`
    );
  };

  updateRoofFieldEvaluationForm = async (
    wildfireApplicationId: string,
    roofFieldEvaluationForm: RoofFieldEvaluationFormModel,
    fieldKey?: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.put<EvaluationFormModel>(
      `/wildfire/form/${wildfireApplicationId}/roof-field-evaluation`,
      {
        roofFieldEvaluationForm,
        fieldKey,
      }
    );
  };

  submitRoofFieldEvaluationForm = async (
    wildfireApplicationId: string,
    roofFieldEvaluationForm: RoofFieldEvaluationFormModel,
    roofFieldEvaluationFormPart?: RoofFieldEvaluationFormParts
  ): Promise<EvaluationFormModel> => {
    // If roofFieldEvaluationFormPart is not provided, default to the last part of the form
    const roofFieldEvaluationFormPartValue = (
      roofFieldEvaluationFormPart ??
      (Object.values(
        RoofFieldEvaluationFormParts
      ).pop() as RoofFieldEvaluationFormParts)
    ).valueOf();
    return wildfireBaseApiClient.post<EvaluationFormModel>(
      `/wildfire/form/${wildfireApplicationId}/roof-field-evaluation/${roofFieldEvaluationFormPartValue}`,
      roofFieldEvaluationForm
    );
  };

  getHomeExteriorFieldEvaluationForm = async (
    wildfireEvaluationId: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.get<EvaluationFormModel>(
      `/wildfire/form/${wildfireEvaluationId}/home-exterior-field-evaluation`
    );
  };

  updateHomeExteriorFieldEvaluationForm = async (
    wildfireApplicationId: string,
    homeExteriorFieldEvaluationForm: HomeExteriorFieldEvaluationFormModel,
    fieldKey?: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.put<EvaluationFormModel>(
      `/wildfire/form/${wildfireApplicationId}/home-exterior-field-evaluation`,
      {
        homeExteriorFieldEvaluationForm,
        fieldKey,
      }
    );
  };

  submitHomeExteriorFieldEvaluationForm = async (
    wildfireApplicationId: string,
    homeExteriorFieldEvaluationForm: HomeExteriorFieldEvaluationFormModel,
    homeExteriorFieldEvaluationFormPart?: HomeExteriorFieldEvaluationFormParts
  ): Promise<EvaluationFormModel> => {
    // If homeExteriorFieldEvaluationFormPart is not provided, default to the last part of the form
    const homeExteriorFieldEvaluationFormPartValue = (
      homeExteriorFieldEvaluationFormPart ??
      (Object.values(
        HomeExteriorFieldEvaluationFormParts
      ).pop() as HomeExteriorFieldEvaluationFormParts)
    ).valueOf();
    return wildfireBaseApiClient.post<EvaluationFormModel>(
      `/wildfire/form/${wildfireApplicationId}/home-exterior-field-evaluation/${homeExteriorFieldEvaluationFormPartValue}`,
      homeExteriorFieldEvaluationForm
    );
  };

  getLandscapeFieldEvaluationForm = async (
    wildfireEvaluationId: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.get<EvaluationFormModel>(
      `/wildfire/form/${wildfireEvaluationId}/landscape-field-evaluation`
    );
  };

  updateLandscapeFieldEvaluationForm = async (
    wildfireApplicationId: string,
    landscapeFieldEvaluationForm: LandscapeFieldEvaluationFormModel,
    fieldKey?: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.put<EvaluationFormModel>(
      `/wildfire/form/${wildfireApplicationId}/landscape-field-evaluation`,
      {
        landscapeFieldEvaluationForm,
        fieldKey,
      }
    );
  };

  submitLandscapeFieldEvaluationForm = async (
    wildfireApplicationId: string,
    landscapeFieldEvaluationForm: LandscapeFieldEvaluationFormModel,
    landscapeFieldEvaluationFormPart?: LandscapeFieldEvaluationFormParts
  ): Promise<EvaluationFormModel> => {
    // If landscapeFieldEvaluationFormPart is not provided, default to the last part of the form
    const landscapeFieldEvaluationFormPartValue = (
      landscapeFieldEvaluationFormPart ??
      (Object.values(
        LandscapeFieldEvaluationFormParts
      ).pop() as LandscapeFieldEvaluationFormParts)
    ).valueOf();
    return wildfireBaseApiClient.post<EvaluationFormModel>(
      `/wildfire/form/${wildfireApplicationId}/landscape-field-evaluation/${landscapeFieldEvaluationFormPartValue}`,
      landscapeFieldEvaluationForm
    );
  };

  getOtherFieldEvaluationForm = async (
    wildfireEvaluationId: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.get<EvaluationFormModel>(
      `/wildfire/form/${wildfireEvaluationId}/other-field-evaluation`
    );
  };

  updateOtherFieldEvaluationForm = async (
    wildfireApplicationId: string,
    otherFieldEvaluationForm: OtherFieldEvaluationFormModel,
    fieldKey?: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.put<EvaluationFormModel>(
      `/wildfire/form/${wildfireApplicationId}/other-field-evaluation`,
      {
        otherFieldEvaluationForm,
        fieldKey,
      }
    );
  };

  submitOtherFieldEvaluationForm = async (
    wildfireApplicationId: string,
    otherFieldEvaluationForm: OtherFieldEvaluationFormModel,
    otherFieldEvaluationFormPart?: OtherFieldEvaluationFormParts
  ): Promise<EvaluationFormModel> => {
    // If roofFieldEvaluationFormPart is not provided, default to the last part of the form
    const otherFieldEvaluationFormPartValue = (
      otherFieldEvaluationFormPart ??
      (Object.values(
        OtherFieldEvaluationFormParts
      ).pop() as OtherFieldEvaluationFormParts)
    ).valueOf();
    return wildfireBaseApiClient.post<EvaluationFormModel>(
      `/wildfire/form/${wildfireApplicationId}/other-field-evaluation/${otherFieldEvaluationFormPartValue}`,
      otherFieldEvaluationForm
    );
  };

  getLandscapeReviewForm = async (
    wildfireEvaluationId: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.get<EvaluationFormModel>(
      `/wildfire/form/${wildfireEvaluationId}/landscape-review`
    );
  };

  updateLandscapeReviewForm = async (
    wildfireApplicationId: string,
    landscapeReviewForm: LandscapeReviewFormModel,
    fieldKey?: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.put<EvaluationFormModel>(
      `/wildfire/form/${wildfireApplicationId}/landscape-review`,
      {
        landscapeReviewForm,
        fieldKey,
      }
    );
  };

  submitLandscapeReviewForm = async (
    wildfireApplicationId: string,
    landscapeReviewForm: LandscapeReviewFormModel,
    landscapeReviewFormPart?: LandscapeReviewFormParts
  ): Promise<EvaluationFormModel> => {
    // If landscapeReviewFormPart is not provided, default to the last part of the form
    const landscapeReviewFormPartValue = (
      landscapeReviewFormPart ??
      (Object.values(
        LandscapeReviewFormParts
      ).pop() as LandscapeReviewFormParts)
    ).valueOf();
    return wildfireBaseApiClient.post<EvaluationFormModel>(
      `/wildfire/form/${wildfireApplicationId}/landscape-review/${landscapeReviewFormPartValue}`,
      landscapeReviewForm
    );
  };
}

export const wildfireEvaluationApiClient = new WildfireEvaluationApiClient();
