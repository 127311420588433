import { FieldFormV2Module } from "@ucl/library";
import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../../../../common/Components/Error/ErrorStore";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { useNavigate } from "react-router";
import { useHomeFieldSchemaFactory } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { fortifiedHomeSCSHRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { Evaluation_HomeSCSHEvaluationFormModel } from "../../../../types/HomeSCSHEvaluationFormModel";
import { homeSCSHEvaluationAPIClient } from "../../../../lib/apiClients/homeSCSHEvaluationAPIClient";
import { HomeSCSHEvaluationFormProps } from "./HomeSCSHEvaluationForm";
import { SCSHEvaluationForm_FormParts } from "../../../../types/HomeSCSHEvaluation_EvaluationFormModel";
import { homeSCSHEvaluationFormAPIClient } from "../../../../lib/apiClients/homeSCSHEvaluationFormAPIClient";

function useHomeSCSHEvaluationForm(props: HomeSCSHEvaluationFormProps) {
  const { builders, wieBuilders } = useHomeFieldSchemaFactory();
  const navigate = useNavigate();

  const formRef =
    useRef<FieldFormV2Module<Evaluation_HomeSCSHEvaluationFormModel>>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [applicationFormModel, setApplicationFormModel] =
    useState<Evaluation_HomeSCSHEvaluationFormModel>();

  const [isInitialized, setIsInitialized] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
      props.onInitialized?.();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isInitialized) {
      props.onInitialized?.();
    }
  }, [isInitialized]);

  useEffect(() => {
    //Used to React to External Changes Pushed on the Form (Iteration Engine)
    if (applicationFormModel) {
      setApplicationFormModel({ ...applicationFormModel });
    }
  }, [wieBuilders]);

  useEffect(() => {
    fetchApplicationForm();
  }, []);

  useEffect(() => {
    if (!!applicationFormModel) {
      setApplicationFormModel(applicationFormModel);

      if (props.setFormModel) {
        props.setFormModel(applicationFormModel);
      }
    }
  }, [applicationFormModel]);

  // Differentiates between save and submit
  let shouldSubmit = false;
  const setShouldSubmit = (value: boolean) => {
    shouldSubmit = value;
  };

  const isFirstPartOfForm =
    props.formPart === Object.values(SCSHEvaluationForm_FormParts)[0];

  const isLastPartOfForm =
    props.formPart === Object.values(SCSHEvaluationForm_FormParts).pop();

  const noBuilders = !builders && !wieBuilders;

  const fetchApplicationForm = async () => {
    setIsLoading(true);
    await homeSCSHEvaluationAPIClient
      .getHomeSCSHEvaluationFormModel(props.evaluationId)
      .then((response) => {
        setApplicationFormModel((prevModel) => ({
          ...prevModel,
          ...response,
        }));
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Debounce setup
  const handleFormSubmitDebounced = useCallback(
    debounce(
      async (
        form: Evaluation_HomeSCSHEvaluationFormModel,
        _value: any,
        fieldProps: BaseFieldProps<any>
      ) => {
        if (fieldProps.fieldKey) {
          await handleFormSubmit(form, fieldProps.fieldKey).then(async () => {
            await props.onFormSave?.(fieldProps.fieldKey || "", _value);
          });
        }
      },
      2000
    ),
    []
  );

  useEffect(() => {
    // Cleanup the debounce function on component unmount
    return () => handleFormSubmitDebounced.cancel();
  }, [handleFormSubmitDebounced]);

  const onFormFieldChange = async (
    form: Evaluation_HomeSCSHEvaluationFormModel,
    _value: any,
    fieldProps: BaseFieldProps<any>
  ) => {
    // Call the debounced submit function
    if (!!_value) {
      handleFormSubmitDebounced(form, _value, fieldProps);
      props.setHasUnsavedChanges(true);
    }
  };

  const getApplicationFormPartBuilder = () => {
    if (props.isIterationEnginePage) {
      return wieBuilders?.HomeSCSHIterationEngineEvaluationForm;
    }
    switch (props.formPart) {
      case SCSHEvaluationForm_FormParts.Information:
        return builders?.HomeSCSHEvaluationInformation;
      default:
        return builders?.HomeSCSHEvaluationForm;
    }
  };

  const handleFormSubmit = async (
    form: Evaluation_HomeSCSHEvaluationFormModel,
    fieldKey?: string
  ) => {
    setApplicationFormModel(form);
    await (shouldSubmit
      ? homeSCSHEvaluationFormAPIClient.submitHomeSCSHEvaluationForm(
          form.id,
          form.homeSCSHEvaluation.homeSCSHEvaluationForm
        )
      : homeSCSHEvaluationFormAPIClient.updateHomeSCSHEvaluationForm(
          form.id,
          form.homeSCSHEvaluation.homeSCSHEvaluationForm,
          fieldKey
        )
    )
      .then(async (response) => {
        handleFormResponse(response);
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while saving the form",
            intent: Intent.DANGER,
          });
        }
        throw error;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleFormResponse = async (
    response: Evaluation_HomeSCSHEvaluationFormModel
  ) => {
    props.setHasUnsavedChanges(false);

    if (shouldSubmit) {
      setApplicationFormModel(response);

      if (isLastPartOfForm) {
        navigate(
          `${fortifiedHomeSCSHRoutePrefix}/checklist/${props.evaluationId}`
        );
      } else {
        // Navigate to next part of form
        const nextFormPart = Object.values(SCSHEvaluationForm_FormParts)[
          Object.values(SCSHEvaluationForm_FormParts).indexOf(props.formPart!) +
            1
        ].valueOf();

        navigate(
          `${fortifiedHomeSCSHRoutePrefix}/${props.evaluationId}/evaluation-form/${nextFormPart}`
        );
      }
    } else {
      handlePartialResponseFormUpdate(response);
    }
  };

  const handlePartialResponseFormUpdate = (
    response: Evaluation_HomeSCSHEvaluationFormModel
  ) => {
    //This is used to combat save on blur issues. This only updates fields that are essetnial to the form.
    //These are set in the API response. Field Counts and sub section completion status
    setApplicationFormModel((prevState) => {
      if (!prevState) return prevState;

      const {
        informationRequiredFieldCount,
        informationLastSubmittedAt,
        informationHasBeenSubmitted,
        isInformationComplete,
        areAllRequiredFieldsComplete,
      } = response.homeSCSHEvaluation.homeSCSHEvaluationForm;

      return {
        ...prevState,
        homeSCSHEvaluation: {
          ...prevState.homeSCSHEvaluation,
          homeSCSHEvaluationForm: {
            ...prevState.homeSCSHEvaluation.homeSCSHEvaluationForm,
            informationRequiredFieldCount,
            informationLastSubmittedAt,
            informationHasBeenSubmitted,
            isInformationComplete,
            areAllRequiredFieldsComplete,
          },
        },
      };
    });
  };

  return {
    builders,
    wieBuilders,
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    applicationFormModel,
    isFirstPartOfForm,
    isLastPartOfForm,
    getApplicationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    noBuilders,
    containerRef,
    setShouldSubmit,
  };
}

export default useHomeSCSHEvaluationForm;
