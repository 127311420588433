import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { HomeBasicInformationFormParts } from "../../../common/types/BaseHomeEvaluationBasicInformationFormModel";
import { HomeEligibilityFormParts } from "../../../common/types/BaseHomeEvaluationEligibilityFormModel";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";
import { HomeStandardEvaluationEligibilityFormModel } from "../../types/HomeStandardEvaluationEligibilityFormModel";

class HomeStandardEvaluationEligibilityAPIClient {
  updateHomeStandardEligibilityForm = async (
    evaluationId: string,
    eligibilityForm: HomeStandardEvaluationEligibilityFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/eligibility-form/${evaluationId}`,
      {
        eligibilityForm,
        fieldKey,
      }
    );
  };

  submitHomeStandardEligibilityForm = async (
    evaluationId: string,
    eligibilityForm: HomeStandardEvaluationEligibilityFormModel,
    formPart?: HomeEligibilityFormParts
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        HomeBasicInformationFormParts
      ).pop() as HomeBasicInformationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/eligibility-form/${evaluationId}/${formPartValue}`,
      eligibilityForm
    );
  };
}

export const homeStandardEvaluationEligibilityAPIClient =
  new HomeStandardEvaluationEligibilityAPIClient();
