import {
  EnumMultiSelectFieldV2Value,
  EnumRadioFieldValue,
  EnumSingleSelectFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorerValue } from "../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

export enum BaseHomeEvaluationRedesignationFormParts {
  RedesignationForm = "redesignation-form",
}

export interface BaseHomeEvaluationRedesignationFormModel
  extends IRedesignationFormInformation,
    IRedesignationFormFileUploaders {}

export interface IRedesignationFormInformation {
  designationLevel?: EnumSingleSelectFieldValue;
  hasTheRoofCoverBeenReplacedInLast5Years?: EnumRadioFieldValue;
  showWarning_HasTheRoofCoverBeenReplacedInLast5Years?: boolean;
  hasAnyPortionOfRoofBeenRepairedInLast5Years?: EnumRadioFieldValue;
  wereAnyStructuralMembersRepaired?: EnumRadioFieldValue;
  wereAnyRoofSheathingPanelsReplaced?: EnumRadioFieldValue;
  wasAnyPortionOfSealedRoofDeckRepaired?: EnumRadioFieldValue;
  wasAnyPortionOfDripEdgeReplaced?: EnumRadioFieldValue;
  wasAnyPortionOfRoofCoverReplaced?: EnumRadioFieldValue;
  doesNewRoofCoverMeetHailImpactRequirements?: EnumRadioFieldValue;
  haveThereBeenAnyStructuralAdditionsToHomeInLast5Years?: EnumRadioFieldValue;
  doAnyNewAdditionsQualifyAsAttachedStructure?: EnumRadioFieldValue;
  redesignationUpdatedSystemTypes?: EnumMultiSelectFieldV2Value;
  isCorrosionRustVisible?: EnumRadioFieldValue;
}

export interface IRedesignationFormFileUploaders {
  overallRedesignationElevationPhotos?: WildfireBoxContentExplorerValue;
  roofStructuralMemberRepairPhoto?: WildfireBoxContentExplorerValue;
  signedAndSealedRepairDesignFromProfessionalEngineer?: WildfireBoxContentExplorerValue;
  replacedRoofSheathingTypeThicknessPhoto?: WildfireBoxContentExplorerValue;
  replacedRoofSheathingNailsPhoto?: WildfireBoxContentExplorerValue;
  replacedRoofSheathingNailSpacingPhotos?: WildfireBoxContentExplorerValue;
  repairedSealedRoofDeckPhotos?: WildfireBoxContentExplorerValue;
  replacedUnderlaymentLabelPhoto?: WildfireBoxContentExplorerValue;
  certifiedDesignPressureReportForMetalPanelSystem?: WildfireBoxContentExplorerValue;
  replacedDripEdgePhoto?: WildfireBoxContentExplorerValue;
  replacedRoofCoverOverallSectionsPhoto?: WildfireBoxContentExplorerValue;
  replacedRoofCoverInstallationPhotos?: WildfireBoxContentExplorerValue;
  replacedRoofCoverProductLabelPhoto?: WildfireBoxContentExplorerValue;
  replacedRoofCoverHailImpactRatingPhoto?: WildfireBoxContentExplorerValue;
  structuralAdditionsRenovationsPhotos?: WildfireBoxContentExplorerValue;
  structuralAdditionsRenovationsDocumentation?: WildfireBoxContentExplorerValue;
  modifiedRoofMountedVentsOrSoffitsPhoto?: WildfireBoxContentExplorerValue;
  modifiedRoofMountedVentsOrSoffitsDocumentation?: WildfireBoxContentExplorerValue;
  modifiedRoofMountedPhotovoltaicPVSystemPhoto?: WildfireBoxContentExplorerValue;
  modifiedRoofMountedPhotovoltaicPVSystemDocumentation?: WildfireBoxContentExplorerValue;
  modifiedSkylightsPhotos?: WildfireBoxContentExplorerValue;
  modifiedSkylightsDocumentation?: WildfireBoxContentExplorerValue;
  modifiedEntryDoorsPhotos?: WildfireBoxContentExplorerValue;
  modifiedEntryDoorsDocumentation?: WildfireBoxContentExplorerValue;
  modifiedWindowsPhotos?: WildfireBoxContentExplorerValue;
  modifiedWindowsDocumentation?: WildfireBoxContentExplorerValue;
  modifiedGarageDoorsPhotos?: WildfireBoxContentExplorerValue;
  modifiedGarageDoorsDocumentation?: WildfireBoxContentExplorerValue;
  modifiedWindowAndDoorShutterSystemsPhotos?: WildfireBoxContentExplorerValue;
  modifiedWindowAndDoorShutterSystemsDocumentation?: WildfireBoxContentExplorerValue;
  visibleCorrosionPhotos?: WildfireBoxContentExplorerValue;
}

export enum RedesignationUpdatedSystemTypes {
  RoofMountedVentsOrSoffits = 1,
  RoofMountedPhotovoltaicSystem = 2,
  Skylights = 3,
  EntryDoors = 4,
  Windows = 5,
  GarageDoors = 6,
  WindowAndDoorShutterSystems = 7,
  NoneOfTheAbove = 8,
}
