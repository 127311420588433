import { Icon, Intent, Tag, TagProps } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Classes, Popover2 } from "@blueprintjs/popover2";
import "./styles.scss";
import { CompletionStatus } from "../../../../../../foritfied/types/viewModels/Checklist";

const ChecklistItemStatusConfig: { [key: string]: TagProps } = {
  complete: {
    minimal: true,
    title: "Complete",
    intent: Intent.SUCCESS,
  },
  incomplete: {
    minimal: true,
    title: "Incomplete",
    intent: Intent.DANGER,
  },
  disabled: {},
};

const buildDisabledMessage = (formsNeedingCompletion: string[]) => {
  return (
    <div>
      <span>
        This form cannot be accessed until the following forms are completed:
        <ul>
          {formsNeedingCompletion.map((formName, idx) => {
            return (
              formName && (
                <li key={idx} className="status-disabled_item">
                  <span className="status-disabled_item_name">{formName}</span>
                </li>
              )
            );
          })}
        </ul>
      </span>
    </div>
  );
};

export interface ChecklistItemStatusV2Props {
  isCompleted: boolean;
  isDisabled: boolean;
  formsNeedingCompletion: string[];
}

export const ChecklistItemStatusV2: React.FC<ChecklistItemStatusV2Props> = (
  props
) => {
  const checklistItemStatus: CompletionStatus = props.isCompleted
    ? "complete"
    : props.isDisabled
    ? "disabled"
    : "incomplete";

  switch (checklistItemStatus) {
    case "complete":
    case "incomplete":
      const { title, ...checklistItemStatusConfig } =
        ChecklistItemStatusConfig[checklistItemStatus];
      return (
        <Tag round={true} {...checklistItemStatusConfig}>
          {title}
        </Tag>
      );
    case "disabled":
      return (
        <div className="checklist-item__status-disabled">
          <Popover2
            interactionKind="hover"
            placement="bottom-end"
            portalClassName="tooltip-popover checklist-item-tooltip-popover__status-disabled"
            popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
            content={buildDisabledMessage(props.formsNeedingCompletion)}
            renderTarget={({ isOpen: _, ...targetProps }) => (
              <div {...targetProps}>
                <Icon icon={IconNames.LOCK} size={14} />
                Disabled
              </div>
            )}
          />
        </div>
      );
    default:
      return null;
  }
};
