import { useState } from "react";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { ChangeRequestV2 } from "../../../../../../wildfire/components/IterationEngine/types/ChangeRequestV2";
import { EvaluationStep } from "../../../../../../foritfied/types/evaluation/Evaluation";
import HomeSCSHIterationEngineForms from "./HomeSCSHIterationEngineForms";

interface HomeSCSHIterationEngineFormsProps {
  selectedTabId: string;
  changeRequests: ChangeRequestV2[];
  evaluationId: string;
  evaluationStep: EvaluationStep;
  getChangeRequests?: () => Promise<void>;
  onEvaluationFormInitialized?: () => void;
  fieldsContainerClassName: string;
}

const HomeSCSHIterationEngineFormsContainer: React.FC<
  HomeSCSHIterationEngineFormsProps
> = (props) => {
  const onFormSave = async (fieldKey: string) => {
    const fieldKeyHasChangeRequest = props.changeRequests.some(
      (cr) => cr.evaluationFieldKey === fieldKey
    );
    if (fieldKeyHasChangeRequest) {
      props.getChangeRequests && (await props.getChangeRequests());
    }
  };

  const [ready, setReady] = useState(false);

  const onAllFormsInitialized = () => {
    props.onEvaluationFormInitialized?.();
    setTimeout(() => {
      setReady(true);
    }, 800);
  };

  return (
    <>
      {!ready && <Loading />}

      <div style={{ visibility: ready ? "visible" : "hidden" }}>
        <HomeSCSHIterationEngineForms
          selectedTabId={props.selectedTabId}
          fieldsContainerClassName={props.fieldsContainerClassName}
          evaluationId={props.evaluationId}
          evaluationStep={props.evaluationStep}
          onAllFormsInitialized={onAllFormsInitialized}
          onFormSave={async (fieldKey) => {
            onFormSave(fieldKey);
          }}
        />
      </div>
    </>
  );
};

export default HomeSCSHIterationEngineFormsContainer;
