import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { BaseHomeEvaluationEngineeringComplianceChimneyFormParts } from "../../common/types/BaseHomeEvaluationEngineeringComplianceChimneyFormModel";
import HomeStandardEngineeringComplianceChimneyFormContainer from "../components/Forms/EngineeringComplianceChimneyForm/Container/HomeStandardEngineeringComplianceChimneyFormContainer";

export interface HomeStandardEngineeringComplianceChimneyPageProps {
  evaluationId?: string;
  engineeringComplianceChimneyFormPart?: BaseHomeEvaluationEngineeringComplianceChimneyFormParts;
}

const HomeStandardEngineeringComplianceChimneyPageComponent: FC<
  HomeStandardEngineeringComplianceChimneyPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeStandardEngineeringComplianceChimneyFormContainer
            evaluationId={props.evaluationId}
            formParts={props.engineeringComplianceChimneyFormPart}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeStandardEngineeringComplianceChimneyPage = withAITracking(
  reactPlugin,
  HomeStandardEngineeringComplianceChimneyPageComponent,
  "HomeStandardEngineeringComplianceChimneyPage",
  "tracking-container"
);
