import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { HomeBasicInformationFormParts } from "../../common/types/BaseHomeEvaluationBasicInformationFormModel";
import HomeSCSHBasicInformationFormContainer from "../components/Forms/BasicInformationForm/Container/HomeSCSHBasicInformationFormContainer";

export interface HomeSCSHBasicInformationFormPageProps {
  evaluationId?: string;
  homeSCSHBasicInformationFormPart?: HomeBasicInformationFormParts;
}

const HomeSCSHBasicInformationFormPageComponent: FC<
  HomeSCSHBasicInformationFormPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeSCSHBasicInformationFormContainer
            evaluationId={props.evaluationId}
            basicInformationFormPart={props.homeSCSHBasicInformationFormPart}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeSCSHBasicInformationFormPage = withAITracking(
  reactPlugin,
  HomeSCSHBasicInformationFormPageComponent,
  "HomeSCSHBasicInformationFormPage",
  "tracking-container"
);
