import {
  EmailFieldProps,
  FieldSchemaFormBuilderV2,
  NumericFieldProps,
  SchemaFactoryV2,
  TextFieldProps,
} from "@ucl/library";
import { EvaluationFormModel } from "../EvaluationFormModel";
import {
  BaseFieldProps,
  PhoneNumberProps,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { YesOrNoSelection } from "../../types/FormFields";
import { WildfireEvaluationSteps } from "../../../foritfied/types/evaluation/Evaluation";

const modelName = "Evaluation";
const subModelName = "WildfireLandscapeReview";
const formName = "LandscapeReviewForm";
const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

// Part 1: Property Information
const propertyInformationFields = (form: EvaluationFormModel) => [
  buildFieldKey("OwnershipAttestation"),

  buildFieldKey("HasCoOwner"),
  {
    fieldKey: buildFieldKey("CoOwnerFirstName"),
    hidden:
      form.wildfireLandscapeReview.landscapeReviewForm.hasCoOwner !=
      YesOrNoSelection.Yes,
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("CoOwnerLastName"),
    hidden:
      form.wildfireLandscapeReview.landscapeReviewForm.hasCoOwner !=
      YesOrNoSelection.Yes,
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("CoOwnerEmail"),
    hidden:
      form.wildfireLandscapeReview.landscapeReviewForm.hasCoOwner !=
      YesOrNoSelection.Yes,
  } as EmailFieldProps,
  {
    fieldKey: buildFieldKey("CoOwnerPhoneNumber"),
    hidden:
      form.wildfireLandscapeReview.landscapeReviewForm.hasCoOwner !=
      YesOrNoSelection.Yes,
  } as PhoneNumberProps,

  buildFieldKey("HasSecondaryContact"),
  {
    fieldKey: buildFieldKey("SecondaryContactFirstName"),
    hidden:
      form.wildfireLandscapeReview.landscapeReviewForm.hasSecondaryContact !=
      YesOrNoSelection.Yes,
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("SecondaryContactLastName"),
    hidden:
      form.wildfireLandscapeReview.landscapeReviewForm.hasSecondaryContact !=
      YesOrNoSelection.Yes,
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("SecondaryContactEmail"),
    hidden:
      form.wildfireLandscapeReview.landscapeReviewForm.hasSecondaryContact !=
      YesOrNoSelection.Yes,
  } as EmailFieldProps,
  {
    fieldKey: buildFieldKey("SecondaryContactPhoneNumber"),
    hidden:
      form.wildfireLandscapeReview.landscapeReviewForm.hasSecondaryContact !=
      YesOrNoSelection.Yes,
  } as PhoneNumberProps,

  buildFieldKey("RoofInstallationYearKnown"),
  {
    fieldKey: buildFieldKey("RoofInstallationYear"),
    min: 1900,
    max: new Date().getFullYear(),
    format: "0000.",
    validateDecimalOnType: true,
    decimals: 0,
    hidden:
      form.wildfireLandscapeReview.landscapeReviewForm
        .roofInstallationYearKnown != YesOrNoSelection.Yes,
  } as NumericFieldProps,

  buildFieldKey("AddedOrChangedExistingPergolaOrGazebo"),
  buildFieldKey("AddedOrChangedExistingCoveredPorch"),
  buildFieldKey("AddedOrChangedExistingShed"),
  buildFieldKey("AddedOrChangedExistingGreenhouse"),
  buildFieldKey("AddedOrChangedExistingGarage"),
  buildFieldKey("AddedOrChangedExistingPlayset"),
  buildFieldKey("AddedOrChangedExistingHotTub"),
  buildFieldKey("AddedOrChangedExistingDeck"),
  buildFieldKey("AddedOrChangedExistingOtherStructure"),
  buildFieldKey("AddedOrChangedExistingFence"),
  buildFieldKey("AddedOrChangedExistingPropaneOrFuelTank"),
  buildFieldKey("AddedOrChangedExistingOutdoorKitchenBarOrBuiltIn"),

  buildFieldKey("HasOtherSignificantPropertyChanges"),
  {
    fieldKey: buildFieldKey("OtherSignificantPropertyChangesDescription"),
    hidden:
      form.wildfireLandscapeReview.landscapeReviewForm
        .hasOtherSignificantPropertyChanges != YesOrNoSelection.Yes,
  } as TextFieldProps,
];

const propertyInformationRequiredFields: string[] = [
  buildFieldKey("OwnershipAttestation"),
  buildFieldKey("HasCoOwner"),
  buildFieldKey("CoOwnerFirstName"),
  buildFieldKey("CoOwnerLastName"),
  buildFieldKey("CoOwnerEmail"),
  buildFieldKey("CoOwnerPhoneNumber"),
  buildFieldKey("HasSecondaryContact"),
  buildFieldKey("SecondaryContactFirstName"),
  buildFieldKey("SecondaryContactLastName"),
  buildFieldKey("SecondaryContactEmail"),
  buildFieldKey("SecondaryContactPhoneNumber"),
  buildFieldKey("RoofInstallationYearKnown"),
  buildFieldKey("RoofInstallationYear"),
  buildFieldKey("AddedOrChangedExistingPergolaOrGazebo"),
  buildFieldKey("AddedOrChangedExistingCoveredPorch"),
  buildFieldKey("AddedOrChangedExistingShed"),
  buildFieldKey("AddedOrChangedExistingGreenhouse"),
  buildFieldKey("AddedOrChangedExistingGarage"),
  buildFieldKey("AddedOrChangedExistingPlayset"),
  buildFieldKey("AddedOrChangedExistingHotTub"),
  buildFieldKey("AddedOrChangedExistingDeck"),
  buildFieldKey("AddedOrChangedExistingOtherStructure"),
  buildFieldKey("AddedOrChangedExistingFence"),
  buildFieldKey("AddedOrChangedExistingPropaneOrFuelTank"),
  buildFieldKey("AddedOrChangedExistingOutdoorKitchenBarOrBuiltIn"),
  buildFieldKey("HasOtherSignificantPropertyChanges"),
  buildFieldKey("OtherSignificantPropertyChangesDescription"),
];

export const landscapeReviewPropertyInformationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        propertyInformationFields(form),
        onValueChange,
        form,
        errors,
        propertyInformationRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Part 2: Photo Requirements
const photoRequirementsFields = (
  form: EvaluationFormModel,
  isIEBuilder: boolean
) => [
  {
    fieldKey: buildFieldKey("FrontPhotoFolder"),
    defaultToUpload: !isIEBuilder,
    hasLimitedFileTypes: true,
    canDelete:
      form.wildfireLandscapeReview.step ===
      WildfireEvaluationSteps.WildfireApplicationEligibilityPhotos,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("RightSidePhotoFolder"),
    defaultToUpload: !isIEBuilder,
    hasLimitedFileTypes: true,
    canDelete:
      form.wildfireLandscapeReview.step ===
      WildfireEvaluationSteps.WildfireApplicationEligibilityPhotos,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("BackPhotoFolder"),
    defaultToUpload: !isIEBuilder,
    hasLimitedFileTypes: true,
    canDelete:
      form.wildfireLandscapeReview.step ===
      WildfireEvaluationSteps.WildfireApplicationEligibilityPhotos,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("LeftSidePhotoFolder"),
    defaultToUpload: !isIEBuilder,
    hasLimitedFileTypes: true,
    canDelete:
      form.wildfireLandscapeReview.step ===
      WildfireEvaluationSteps.WildfireApplicationEligibilityPhotos,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("StructuresAndOutbuildingsPhotosFolder"),
    defaultToUpload: !isIEBuilder,
    hasLimitedFileTypes: true,
    canDelete:
      form.wildfireLandscapeReview.step ===
      WildfireEvaluationSteps.WildfireApplicationEligibilityPhotos,
    hidden: !(
      form.wildfireLandscapeReview.landscapeReviewForm
        .addedOrChangedExistingPergolaOrGazebo === YesOrNoSelection.Yes ||
      form.wildfireLandscapeReview.landscapeReviewForm
        .addedOrChangedExistingCoveredPorch === YesOrNoSelection.Yes ||
      form.wildfireLandscapeReview.landscapeReviewForm
        .addedOrChangedExistingShed === YesOrNoSelection.Yes ||
      form.wildfireLandscapeReview.landscapeReviewForm
        .addedOrChangedExistingGreenhouse === YesOrNoSelection.Yes ||
      form.wildfireLandscapeReview.landscapeReviewForm
        .addedOrChangedExistingGarage === YesOrNoSelection.Yes ||
      form.wildfireLandscapeReview.landscapeReviewForm
        .addedOrChangedExistingPlayset === YesOrNoSelection.Yes ||
      form.wildfireLandscapeReview.landscapeReviewForm
        .addedOrChangedExistingHotTub === YesOrNoSelection.Yes ||
      form.wildfireLandscapeReview.landscapeReviewForm
        .addedOrChangedExistingDeck === YesOrNoSelection.Yes ||
      form.wildfireLandscapeReview.landscapeReviewForm
        .addedOrChangedExistingOtherStructure === YesOrNoSelection.Yes ||
      form.wildfireLandscapeReview.landscapeReviewForm
        .addedOrChangedExistingFence === YesOrNoSelection.Yes ||
      form.wildfireLandscapeReview.landscapeReviewForm
        .addedOrChangedExistingPropaneOrFuelTank === YesOrNoSelection.Yes ||
      form.wildfireLandscapeReview.landscapeReviewForm
        .addedOrChangedExistingOutdoorKitchenBarOrBuiltIn ===
        YesOrNoSelection.Yes ||
      form.wildfireLandscapeReview.landscapeReviewForm
        .hasOtherSignificantPropertyChanges === YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
];

const photoRequirementsRequiredFields: string[] = [
  buildFieldKey("StructuresAndOutbuildingsPhotosFolder"),
  buildFieldKey("FrontPhotoFolder"),
  buildFieldKey("RightSidePhotoFolder"),
  buildFieldKey("BackPhotoFolder"),
  buildFieldKey("LeftSidePhotoFolder"),
];

export const landscapeReviewPhotoRequirementsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        photoRequirementsFields(form, false),
        onValueChange,
        form,
        errors,
        photoRequirementsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Full form builder with all parts included
export const landscapeReviewFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        [
          ...propertyInformationFields(form),
          ...photoRequirementsFields(form, false),
        ],
        onValueChange,
        form,
        errors,
        [
          ...propertyInformationRequiredFields,
          ...photoRequirementsRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const wieLandscapeReviewFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        [
          ...propertyInformationFields(form),
          ...photoRequirementsFields(form, true),
        ],
        onValueChange,
        form,
        errors,
        [
          ...propertyInformationRequiredFields,
          ...photoRequirementsRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};
