import {
  FieldSchemaFormBuilderV2,
  SchemaFactoryV2,
  TextFieldProps,
} from "@ucl/library";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorer } from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import {
  GableConnectedBracedBottomTypes,
  GableConnectedBracedTopTypes,
  PrescriptiveCodeForGableBracinngInstallationTypes,
} from "../../../../common/types/BaseHomeEvaluationInstallerComplianceGablesFormModel";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "InstallerComplianceGablesForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

// Part 1: Installer Compliance Form General Information
const installerComplianceFormGeneralInformationFields = () => [
  buildFieldKey("PermitNumber"),
  buildFieldKey("DateInstallationWasCompleted"),
];

const installerComplianceFormGeneralInformationRequiredFields = [
  buildFieldKey("PermitNumber"),
  buildFieldKey("DateInstallationWasCompleted"),
];

export const installerComplianceFormGeneralInformationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        installerComplianceFormGeneralInformationFields(),
        onValueChange,
        form,
        errors,
        installerComplianceFormGeneralInformationRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 2: Installer Compliance Form Engineered Gables
const installerComplianceFormEngineeredGablesFields = (
  form: Evaluation_HomeStandardEvaluationFormModel
) => [
  buildFieldKey("Attestation281"),
  buildFieldKey("Attestation282"),
  buildFieldKey("Attestation283"),
  buildFieldKey("Attestation284"),
  buildFieldKey("GableConnectedBraceTopType"),
  {
    fieldKey: buildFieldKey("GableConnectedBraceTopTypeOther"),
    hidden: !(
      form.homeStandardEvaluation.installerComplianceGablesForm
        .gableConnectedBraceTopType === GableConnectedBracedTopTypes.Other
    ),
  } as TextFieldProps,
  buildFieldKey("Attestation285"),
  buildFieldKey("GableConnectedBraceBottomType"),
  {
    fieldKey: buildFieldKey("GableConnectedBraceBottomTypeOther"),
    hidden: !(
      form.homeStandardEvaluation.installerComplianceGablesForm
        .gableConnectedBraceBottomType === GableConnectedBracedBottomTypes.Other
    ),
  } as TextFieldProps,
  buildFieldKey("Attestation286"),
  buildFieldKey("StrapManufacturerAndModel"),
  buildFieldKey("MaxAllowableUpliftLoadPerStrapPerManufacturer"),
];

const installerComplianceFormEngineeredGablesRequiredFields = [
  buildFieldKey("Attestation281"),
  buildFieldKey("Attestation282"),
  buildFieldKey("Attestation283"),
  buildFieldKey("Attestation284"),
  buildFieldKey("GableConnectedBraceTopType"),
  buildFieldKey("GableConnectedBraceTopTypeOther"),
  buildFieldKey("Attestation285"),
  buildFieldKey("GableConnectedBraceBottomType"),
  buildFieldKey("GableConnectedBraceBottomTypeOther"),
  buildFieldKey("Attestation286"),
  buildFieldKey("StrapManufacturerAndModel"),
  buildFieldKey("MaxAllowableUpliftLoadPerStrapPerManufacturer"),
];

export const installerComplianceFormEngineeredGablesBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        installerComplianceFormEngineeredGablesFields(form),
        onValueChange,
        form,
        errors,
        installerComplianceFormEngineeredGablesRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 3: Installer Compliance Form Prescriptive Gables
const installerComplianceFormPrescriptiveGablesFields = (
  form: Evaluation_HomeStandardEvaluationFormModel
) => [
  buildFieldKey("Attestation287"),
  buildFieldKey("Attestation288"),
  buildFieldKey("PrescriptiveCodeForGableBracingInstallation"),
  {
    fieldKey: buildFieldKey(
      "PrescriptiveCodeForGableBracingInstallationDescription"
    ),
    hidden: !(
      form.homeStandardEvaluation.installerComplianceGablesForm
        .prescriptiveCodeForGableBracingInstallation ===
      PrescriptiveCodeForGableBracinngInstallationTypes.Other
    ),
  } as TextFieldProps,
  buildFieldKey("Attestation291"),
];

const installerComplianceFormPrescriptiveGablesRequiredFields = [
  buildFieldKey("Attestation287"),
  buildFieldKey("Attestation288"),
  buildFieldKey("PrescriptiveCodeForGableBracingInstallation"),
  buildFieldKey("PrescriptiveCodeForGableBracingInstallationDescription"),
  buildFieldKey("Attestation291"),
];

export const installerComplianceFormPrescriptiveGablesBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        installerComplianceFormPrescriptiveGablesFields(form),
        onValueChange,
        form,
        errors,
        installerComplianceFormPrescriptiveGablesRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included
export const homeStandardInstallerComplianceGablesBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...installerComplianceFormGeneralInformationFields(),
          ...installerComplianceFormEngineeredGablesFields(form),
          ...installerComplianceFormPrescriptiveGablesFields(form),
        ],
        onValueChange,
        form,
        errors,
        [
          ...installerComplianceFormGeneralInformationRequiredFields,
          ...installerComplianceFormEngineeredGablesRequiredFields,
          ...installerComplianceFormPrescriptiveGablesRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeStandardIterationEngineInstallerComplianceGablesFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...installerComplianceFormGeneralInformationFields(),
          ...installerComplianceFormEngineeredGablesFields(form),
          ...installerComplianceFormPrescriptiveGablesFields(form),
        ],
        onValueChange,
        form,
        errors,
        [
          ...installerComplianceFormGeneralInformationRequiredFields,
          ...installerComplianceFormEngineeredGablesRequiredFields,
          ...installerComplianceFormPrescriptiveGablesRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};
