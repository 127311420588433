import baseApiClient from "../../../../../../foritfied/lib/apiClients/baseApiClient";
import { HomeBasicInformationFormParts } from "../../../../common/types/BaseHomeEvaluationBasicInformationFormModel";
import { HomeNCIUAEvaluationBasicInformationFormModel } from "../../types/HomeNCIUAEvaluationBasicInformationFormModel";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../types/HomeNCIUAEvaluationFormModel";

class HomeNCIUAEvaluationBasicInformationAPIClient {
  updateHomeNCIUABasicInformationForm = async (
    evaluationId: string,
    basicInformationForm: HomeNCIUAEvaluationBasicInformationFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeNCIUAEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeNCIUAEvaluationFormModel>(
      `/home-nciua/basic-information-form/${evaluationId}`,
      {
        basicInformationForm,
        fieldKey,
      }
    );
  };

  submitHomeStandardBasicInformationForm = async (
    evaluationId: string,
    basicInformationForm: HomeNCIUAEvaluationBasicInformationFormModel,
    basicInformationFormPart?: HomeBasicInformationFormParts
  ): Promise<Evaluation_HomeNCIUAEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const basicInformationFormPartValue = (
      basicInformationFormPart ??
      (Object.values(
        HomeBasicInformationFormParts
      ).pop() as HomeBasicInformationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeNCIUAEvaluationFormModel>(
      `/home-nciua/basic-information-form/${evaluationId}/${basicInformationFormPartValue}`,
      basicInformationForm
    );
  };
}

export const homeNCIUAEvaluationBasicInformationAPIClient =
  new HomeNCIUAEvaluationBasicInformationAPIClient();
