import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FormTypes } from "../../../../../common/types/FormTypes";
import { Button, FormGroup, Intent } from "@blueprintjs/core";
import {
  EnumSingleSelectFieldV2,
  Footer,
  RichTextEditorField,
  RichTextEditorValueType,
} from "@ucl/library";
import { commentTemplateApiClient } from "../../../../../foritfied/lib/apiClients/commentTemplate/commentTemplateApiClient";
import { closeCommentTemplateBrowseDialog } from "./CommentTemplateBrowseDialog";
import { CommentTemplateDialogFormModel } from "./types";
import "./styles.scss";
import { MinimalSelectableLabel } from "../../../../../foritfied/components/Fields/SelectableLabel/MinimalSelectableLabel";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";

interface CommentTemplateFormProps {
  formType: FormTypes;
  setSelectedTemplate: Dispatch<SetStateAction<string>>;
}

export const CommentTemplateBrowseForm: React.FC<CommentTemplateFormProps> = ({
  formType,
  setSelectedTemplate,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formModels, setFormModels] = useState<
    CommentTemplateDialogFormModel[]
  >([]);
  const [selectedFormModel, setSelectedFormModel] =
    useState<CommentTemplateDialogFormModel>();

  useEffect(() => {
    if (formType) {
      commentTemplateApiClient
        .getCommentTemplatesByFormType(formType)
        .then((response) => {
          const commentTemplates = response?.map((commentTemplate) => {
            const { formType, productType, templateContent, templateName } =
              commentTemplate;

            return {
              formType: String(formType),
              productType: String(productType),
              templateContent,
              templateName,
            };
          });

          setFormModels(commentTemplates);
        });
    }
  }, []);

  return (
    <>
      <div>
        <form
          id="comment-template-browse-form"
          onSubmit={async (e) => {
            e.preventDefault();
            setIsLoading(true);

            setSelectedTemplate(selectedFormModel?.templateContent || "");

            setIsLoading(false);
            closeCommentTemplateBrowseDialog();
            AppToaster.show({
              message: (
                <div>
                  <h3>Success</h3>Comment Template applied.
                </div>
              ),
              intent: Intent.SUCCESS,
            });
          }}
        >
          <FormGroup className="base-field">
            <EnumSingleSelectFieldV2
              type="EnumSingleSelect"
              label="Templates"
              value={selectedFormModel?.templateName}
              optionValues={formModels.map((form) => ({
                value: form.templateName,
                label: form.templateName,
              }))}
              onSubmit={(value) => {
                setSelectedFormModel(
                  formModels.find((form) => form.templateName === value)
                );
              }}
            />
          </FormGroup>
          {selectedFormModel && (
            <FormGroup className="base-field">
              <MinimalSelectableLabel label={"Content"} />
              <div className="template-content-field">
                <div>
                  <RichTextEditorField
                    className="comment-template-browse-form-rte"
                    valueType={RichTextEditorValueType.html}
                    value={selectedFormModel?.templateContent}
                    richTextEditorSettings={{
                      readonly: true,
                      readOnly: true,
                    }}
                  />
                </div>
              </div>
            </FormGroup>
          )}
        </form>
      </div>
      <Footer>
        <Button
          minimal
          intent={Intent.NONE}
          text={"Cancel"}
          onClick={closeCommentTemplateBrowseDialog}
        />
        <Button
          intent={Intent.SUCCESS}
          text={"Apply Template"}
          type="submit"
          form="comment-template-browse-form"
          loading={isLoading}
        />
      </Footer>
    </>
  );
};
