import { Tab, Tabs } from "@blueprintjs/core";
import EvaluationHistory from "../../../../../foritfied/components/Evaluation/History/EvaluationHistory";
import AdminPanelButton from "../../../common/components/Buttons/AdminPanelButton/AdminPanelButton";
import GoToIterationEngineButton from "../../../common/components/Buttons/GoToIterationEngine/GoToIterationEngineButton";
import { evaluationStore } from "../../../../../foritfied/stores/EvaluationStore";
import { permissionStore } from "../../../../../foritfied/stores/PermissionStore";
import LegacyEvaluationActionLink from "../../../../../foritfied/components/Evaluation/EvaluationActions/LegacyEvaluationActionLink";
import LegacyEvaluationDataActionLink from "../../../../../foritfied/components/Evaluation/EvaluationActions/LegacyEvaluationDataActionLink";
import ChecklistTabPanelV2, { FormRowProps } from "./ChecklistTabPanelV2";
import { UserRole } from "../../../../../foritfied/stores/FortifiedUserStore";
import { NotifySubmitterLink } from "../../../../../foritfied/components/Checklist/NotifySubmitter/NotifySubmitterLink";
import WithdrawEvaluationActionButton from "../../../../../foritfied/components/Evaluation/EvaluationActions/WithdrawEvaluationActionButton";
import "./styles.scss";
import { EvaluationStep } from "../../../../../foritfied/types/evaluation/Evaluation";
import { ProceedToPaymentActionButtonV2 } from "../../../../Common/components/IterationEngine/ProceedToPaymentActionButtonV2";
import { ProductSubtypes } from "../../../../Common/types/Evaluation/BaseEvaluation";
import DownloadRooferConfirmationFormContainer from "../Buttons/DownloadRooferConfirmationForm/DownloadRooferConfirmationFormContainer";
import { NotifySubmitterFormLink } from "../../../../../foritfied/components/Checklist/NotifySubmitter/NotifySubmitterLinkBuilder";
import { PaymentCreditResponseReadDTO } from "../../../../Common/types/PaymentCredit/PaymentCreditResponseReadDTO";
import DownloadInstallerConfirmationFormContainer from "../Buttons/DownloadInstallerConfirmationForm/DownloadInstallerConfirmationFormContainer";
import DownloadEngineeringConfirmationFormContainer from "../Buttons/DownloadEngineeringConfirmationForm/DownloadEngineeringConfirmationFormContainer";

interface ChecklistContainerProps {
  evaluationId: string;
  filteredForms: FormRowProps[];
  selectedUserRoleFormLinks: NotifySubmitterFormLink[];
  hasFormsLoading: boolean;
  setEvaluation: (evaluation: any) => void;
  fortifiedId: string;
  buildingAddress_AsString: string;
  selectedEmail: string | undefined;
  evaluationStep: EvaluationStep;
  selectedUserRoleTab: ChecklistTabItem;
  shouldShowPaymentButton: boolean;
  visibleTabs: ChecklistTabItem[];
  selectedTabId: number;
  setSelectedTabId: (value: React.SetStateAction<number>) => void;
  productTypeRoute: string;
  getApplicationFeePaymentCreditDetails: (
    evaluationId: string
  ) => Promise<PaymentCreditResponseReadDTO>;
  productSubtype?: ProductSubtypes;
}
export interface ChecklistTabItem {
  id: number;
  label: string;
  role?: UserRole;
}

const ChecklistContainerV2: React.FC<ChecklistContainerProps> = (props) => {
  return (
    <div className="checklist-container-v2">
      <div>
        <div className="checklist-header">
          <div className="checklist-header__left-panel">
            <GoToIterationEngineButton
              evaluationId={props.evaluationId}
              productTypeRoute={props.productTypeRoute}
            />
          </div>
          <div className="checklist-header__right-panel">
            {props.selectedEmail && props.fortifiedId && (
              <NotifySubmitterLink
                mailTo={props.selectedEmail}
                address={props.buildingAddress_AsString}
                fortifiedId={props.fortifiedId}
                formLinks={props.selectedUserRoleFormLinks.filter(
                  (x) => !x.hidden
                )}
              />
            )}
            {evaluationStore.isLegacyEvaluation &&
              permissionStore.canViewLegacyEvaluationFiles && (
                <LegacyEvaluationActionLink />
              )}
            {evaluationStore.isLegacyEvaluation &&
              permissionStore.canViewLegacyEvaluationFiles && (
                <LegacyEvaluationDataActionLink />
              )}
            {permissionStore.canWithdraw(evaluationStore.evaluation) && (
              <WithdrawEvaluationActionButton />
            )}
            {props.filteredForms.some(
              (form) => form.formName === "Roofer Confirmation"
            ) && (
              <DownloadRooferConfirmationFormContainer
                evaluationId={props.evaluationId}
                fortifiedId={props.fortifiedId}
              />
            )}
            {props.filteredForms.some(
              (form) => form.formName === "Installing Contractor Confirmation"
            ) && (
              <DownloadInstallerConfirmationFormContainer
                evaluationId={props.evaluationId}
                fortifiedId={props.fortifiedId}
              />
            )}
            {props.filteredForms.some(
              (form) => form.formName === "Engineer Confirmation"
            ) && (
              <DownloadEngineeringConfirmationFormContainer
                evaluationId={props.evaluationId}
                fortifiedId={props.fortifiedId}
              />
            )}
            {props.shouldShowPaymentButton && props.productSubtype && (
              <ProceedToPaymentActionButtonV2
                evaluationId={props.evaluationId}
                fortifiedId={props.fortifiedId}
                productSubtype={props.productSubtype}
                evaluationStep={props.evaluationStep}
                getPaymentDetails={async () =>
                  await props.getApplicationFeePaymentCreditDetails(
                    props.evaluationId
                  )
                }
                setEvaluation={props.setEvaluation}
              />
            )}
            <AdminPanelButton evaluationId={props.evaluationId} />
          </div>
        </div>
        <Tabs
          vertical
          selectedTabId={props.selectedTabId}
          onChange={(newTabId: number) => props.setSelectedTabId(newTabId)}
        >
          {props.visibleTabs.map((tab) => {
            return (
              <Tab
                key={tab.id}
                id={tab.id}
                title={tab.label}
                panel={
                  <ChecklistTabPanelV2
                    forms={props.filteredForms}
                    selectedUserRoleTab={props.selectedUserRoleTab}
                    hasFormsLoading={props.hasFormsLoading}
                  />
                }
              />
            );
          })}
          <EvaluationHistory evaluationId={props.evaluationId} />
        </Tabs>
      </div>
    </div>
  );
};

export default ChecklistContainerV2;
