import baseApiClient from "../../../../../../foritfied/lib/apiClients/baseApiClient";
import { BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts } from "../../../../common/types/BaseHomeEvaluationRoofingComplianceGeneralInformationFormModel";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../types/HomeNCIUAEvaluationFormModel";
import { HomeNCIUAEvaluationRoofingComplianceGeneralInformationFormModel } from "../../types/HomeNCIUAEvaluationRoofingComplianceGeneralInformationFormModel";

class HomeNCIUAEvaluationRoofingComplianceGeneralInformationAPIClient {
  updateHomeNCIUARoofingComplianceGeneralInformationForm = async (
    evaluationId: string,
    form: HomeNCIUAEvaluationRoofingComplianceGeneralInformationFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeNCIUAEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeNCIUAEvaluationFormModel>(
      `/home-nciua/roofing-compliance-general-information-form/${evaluationId}`,
      {
        roofingComplianceGeneralInformationForm: form,
        fieldKey,
      }
    );
  };

  submitHomeNCIUARoofingComplianceGeneralInformationForm = async (
    evaluationId: string,
    form: HomeNCIUAEvaluationRoofingComplianceGeneralInformationFormModel,
    formPart?: BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts
  ): Promise<Evaluation_HomeNCIUAEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts
      ).pop() as BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeNCIUAEvaluationFormModel>(
      `/home-nciua/roofing-compliance-general-information-form/${evaluationId}/${formPartValue}`,
      form
    );
  };
}

export const homeNCIUAEvaluationRoofingComplianceGeneralInformationAPIClient =
  new HomeNCIUAEvaluationRoofingComplianceGeneralInformationAPIClient();
