import { Intent } from "@blueprintjs/core";
import { FieldFormV2Module } from "@ucl/library";
import { useFieldSchemaFactory } from "../../../customHooks/useFieldSchemaFactory";
import { useCallback, useEffect, useRef, useState } from "react";
import { wildfireEvaluationApiClient } from "../../../lib/apiClients/evaluation/wildfireEvaluationApiClient";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../common/Components/Error/ErrorStore";
import { debounce } from "lodash";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { useNavigate } from "react-router";
import { EvaluationFormModel } from "../../../forms/EvaluationFormModel";
import { OtherFieldEvaluationFormProps } from "./OtherFieldEvaluationForm";
import { OtherFieldEvaluationFormParts } from "../../../forms/OtherFieldEvaluationForm/OtherFieldEvaluationFormModel";
import { wildfireRoutePrefix } from "../../../pages/index/wildfireRouteConfig";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { getScrollContainer } from "../../../utils/getScrollContainer";

export function useOtherFieldEvaluationForm(
  props: OtherFieldEvaluationFormProps
) {
  const navigate = useNavigate();

  const { builders, wieBuilders } = useFieldSchemaFactory();
  const formRef = useRef<FieldFormV2Module<EvaluationFormModel>>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
      props.onInitialized?.();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isInitialized) {
      props.onInitialized?.();
    }
  }, [isInitialized]);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!props.isWiePage && !!containerRef.current) {
      const scrollableParent = getScrollContainer(containerRef.current);

      scrollableParent?.scrollTo(0, 0);
    }
  }, [props.formPart]);

  const [otherFieldEvaluationFormModel, setOtherFieldEvaluationFormModel] =
    useState<EvaluationFormModel>();

  // Differentiates between save and submit
  let shouldSubmit = false;
  const setShouldSubmit = (value: boolean) => {
    shouldSubmit = value;
  };

  useEffect(() => {
    if (otherFieldEvaluationFormModel) {
      setOtherFieldEvaluationFormModel({ ...otherFieldEvaluationFormModel });
    }
  }, [wieBuilders]);

  const isFirstPartOfForm =
    props.formPart === Object.values(OtherFieldEvaluationFormParts)[0];

  const isLastPartOfForm =
    props.formPart === Object.values(OtherFieldEvaluationFormParts).pop();

  const fetchOtherFieldEvaluationForm = async () => {
    setIsLoading(true);
    await wildfireEvaluationApiClient
      .getOtherFieldEvaluationForm(props.wildfireEvaluationId)
      .then((response) => {
        setOtherFieldEvaluationFormModel((prevModel) => ({
          ...prevModel,
          ...response,
        }));
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!!props.formModel) {
      setOtherFieldEvaluationFormModel(props.formModel);
      setIsLoading(false);
    } else {
      fetchOtherFieldEvaluationForm();
    }
  }, [props.formModel]);

  useEffect(() => {
    //Used to React to External Changes Pushed on the Form (Iteration Engine)
    if (!!otherFieldEvaluationFormModel) {
      setOtherFieldEvaluationFormModel(otherFieldEvaluationFormModel);

      if (props.setFormModel) {
        props.setFormModel(otherFieldEvaluationFormModel);
      }
    }
  }, [otherFieldEvaluationFormModel]);

  // Debounce setup
  const handleFormSubmitDebounced = useCallback(
    debounce(
      async (
        form: EvaluationFormModel,
        _value: any,
        fieldProps: BaseFieldProps<any>
      ) => {
        if (fieldProps.fieldKey) {
          await handleFormSubmit(form, fieldProps.fieldKey).then(async () => {
            await props.onFormSave?.(fieldProps.fieldKey || "", _value);
          });
        }
      },
      2000
    ),
    []
  );

  useEffect(() => {
    // Cleanup the debounce function on component unmount
    return () => handleFormSubmitDebounced.cancel();
  }, [handleFormSubmitDebounced]);

  const onFormFieldChange = async (
    form: EvaluationFormModel,
    _value: any,
    fieldProps: BaseFieldProps<any>
  ) => {
    // Call the debounced submit function
    if (!!_value) {
      handleFormSubmitDebounced(form, _value, fieldProps);
      props.setHasUnsavedChanges(true);
    }
  };

  const getOtherFieldEvaluationFormPartBuilder = () => {
    if (props.isWiePage) {
      return wieBuilders?.OtherFieldEvaluationForm;
    }
    switch (props.formPart) {
      case OtherFieldEvaluationFormParts.OtherStructuresAndOutbuildings:
        return builders?.OtherEvaluationFormOtherStructuresAndOutbuildings;
      default:
        return builders?.OtherFieldEvaluationForm;
    }
  };

  const handleFormSubmit = async (
    form: EvaluationFormModel,
    fieldKey?: string
  ) => {
    setOtherFieldEvaluationFormModel(form);

    await (shouldSubmit
      ? wildfireEvaluationApiClient.submitOtherFieldEvaluationForm(
          form.wildfireApplication.id,
          form.wildfireApplication.otherFieldEvaluationForm,
          props.formPart
        )
      : wildfireEvaluationApiClient.updateOtherFieldEvaluationForm(
          form.wildfireApplication.id,
          form.wildfireApplication.otherFieldEvaluationForm,
          fieldKey
        )
    )
      .then(async (response) => {
        handleFormResponse(response);
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while saving the form",
            intent: Intent.DANGER,
          });
        }
        throw error;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleFormResponse = (response: EvaluationFormModel) => {
    props.setHasUnsavedChanges(false);

    if (shouldSubmit) {
      if (isLastPartOfForm || !props.formPart) {
        navigate(`${wildfireRoutePrefix}/board`);
      } else {
        // Navigate to next part of form
        navigate(
          `${wildfireRoutePrefix}/${
            props.wildfireEvaluationId
          }/other-field-evaluation-form/${Object.values(
            OtherFieldEvaluationFormParts
          )[
            Object.values(OtherFieldEvaluationFormParts).indexOf(
              props.formPart
            ) + 1
          ].valueOf()}`
        );
      }
    } else {
      handlePartialResponseFormUpdate(response);
    }
  };

  const handlePartialResponseFormUpdate = (response: EvaluationFormModel) => {
    //This is used to combat save on blur issues. This only updates fields that are essetnial to the form.
    //These are set in the API response. Field Counts and sub section completion status
    setOtherFieldEvaluationFormModel((prevState) => {
      if (!prevState) return prevState;

      const {
        otherStructuresAndOutbuildingsRequiredFieldCount,
        submitted,
        otherStructuresAndOutbuildingsSubmitted,
        miscPhotosFolder,
        structuresAndOutbuildingsPhotosFolder,
        isOtherStructuresAndOutbuildingsComplete,
      } = response.wildfireApplication.otherFieldEvaluationForm;

      const { areFieldEvaluationFormsReadyForIBHSReview } =
        response.wildfireApplication;

      return {
        ...prevState,
        wildfireApplication: {
          ...prevState.wildfireApplication,
          areFieldEvaluationFormsReadyForIBHSReview,
          otherFieldEvaluationForm: {
            ...prevState.wildfireApplication.otherFieldEvaluationForm,
            otherStructuresAndOutbuildingsRequiredFieldCount,
            submitted,
            otherStructuresAndOutbuildingsSubmitted,
            miscPhotosFolder,
            structuresAndOutbuildingsPhotosFolder,
            isOtherStructuresAndOutbuildingsComplete,
          },
        },
      };
    });
  };

  return {
    builders,
    wieBuilders,
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    otherFieldEvaluationFormModel,
    setShouldSubmit,
    isFirstPartOfForm,
    isLastPartOfForm,
    getOtherFieldEvaluationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
  };
}

export default useOtherFieldEvaluationForm;
