import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { BaseHomeEvaluationRooferConfirmationFormParts } from "../../../common/types/BaseHomeEvaluationRooferConfirmationFormModel";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";
import { HomeStandardEvaluationRooferConfirmationFormModel } from "../../types/HomeStandardEvaluationRooferConfirmationFormModel";

class HomeStandardEvaluationRooferConfirmationAPIClient {
  updateHomeStandardRooferConfirmationForm = async (
    evaluationId: string,
    rooferConfirmationForm: HomeStandardEvaluationRooferConfirmationFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/roofer-confirmation-form/${evaluationId}`,
      {
        rooferConfirmationForm,
        fieldKey,
      }
    );
  };

  submitHomeStandardRooferConfirmationForm = async (
    evaluationId: string,
    rooferConfirmationForm: HomeStandardEvaluationRooferConfirmationFormModel,
    formPart?: BaseHomeEvaluationRooferConfirmationFormParts
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationRooferConfirmationFormParts
      ).pop() as BaseHomeEvaluationRooferConfirmationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/roofer-confirmation-form/${evaluationId}/${formPartValue}`,
      rooferConfirmationForm
    );
  };
}

export const homeStandardEvaluationRooferConfirmationAPIClient =
  new HomeStandardEvaluationRooferConfirmationAPIClient();
