import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { BaseHomeEvaluationHomeownerAuthorizationFormParts } from "../../../common/types/BaseHomeEvaluationHomeownerAuthorizationFormModel";

import { Evaluation_HomeStandardRedesignationFormModel } from "../../types/HomeStandardRedesignationFormModel_Model";
import { HomeStandardRedesignationHomeownerAuthorizationFormModel } from "../../types/HomeStandardRedesignationHomeownerAuthorizationFormModel";

class HomeStandardRedesignationHomeownerAuthorizationAPIClient {
  updateHomeStandardHomeownerAuthorizationForm = async (
    evaluationId: string,
    homeownerAuthorizationForm: HomeStandardRedesignationHomeownerAuthorizationFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeStandardRedesignationFormModel> => {
    return baseApiClient.put<Evaluation_HomeStandardRedesignationFormModel>(
      `/home-standard/redesignation/homeowner-authorization-form/${evaluationId}`,
      {
        homeownerAuthorizationForm,
        fieldKey,
      }
    );
  };

  submitHomeStandardHomeownerAuthorizationForm = async (
    evaluationId: string,
    homeownerAuthorizationForm: HomeStandardRedesignationHomeownerAuthorizationFormModel,
    formPart?: BaseHomeEvaluationHomeownerAuthorizationFormParts
  ): Promise<Evaluation_HomeStandardRedesignationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationHomeownerAuthorizationFormParts
      ).pop() as BaseHomeEvaluationHomeownerAuthorizationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeStandardRedesignationFormModel>(
      `/home-standard/redesignation/homeowner-authorization-form/${evaluationId}/${formPartValue}`,
      homeownerAuthorizationForm
    );
  };
}

export const homeStandardRedesignationHomeownerAuthorizationAPIClient =
  new HomeStandardRedesignationHomeownerAuthorizationAPIClient();
