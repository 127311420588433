import { PrimaryCard } from "@ucl/library";
import { useContext, useEffect, useState } from "react";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import classNames from "classnames";
import { EvaluationStep } from "../../../../../../foritfied/types/evaluation/Evaluation";
import { UnsavedChangesBlockerContext } from "../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import HomeStandardEvaluationBasicInformationForm from "../BasicInformationForm/Form/HomeStandardEvaluationBasicInformationForm";
import HomeStandardEvaluationRooferConfirmationForm from "../RooferConfirmationForm/Form/HomeStandardEvaluationRooferConfirmationForm";
import HomeStandardEvaluationRoofingComplianceGeneralInformationForm from "../RoofingComplianceGeneralInformationForm/Form/HomeStandardEvaluationRoofingComplianceGeneralInformationForm";
import HomeStandardEvaluationRoofingComplianceRoofCoverForm from "../RoofingComplianceRoofCoverForm/Form/HomeStandardEvaluationRoofingComplianceRoofCoverForm";
import HomeStandardEvaluationEngineerConfirmationForm from "../EngineerConfirmationForm/Form/HomeStandardEvaluationEngineerConfirmationForm";
import HomeStandardEvaluationSilverGoldComplianceGeneralInformationForm from "../SilverGoldComplianceGeneralInformationForm/Form/HomeStandardEvaluationSilverGoldComplianceGeneralInformationForm";
import HomeStandardEvaluationInstallingContractorConfirmationForm from "../InstallingContractorConfirmationForm/Form/HomeStandardEvaluationInstallingContractorConfirmationForm";
import HomeStandardEvaluationInstallerComplianceGablesForm from "../InstallerComplianceGablesForm/Form/HomeStandardEvaluationInstallerComplianceGablesForm";
import HomeStandardEvaluationInstallerComplianceContinuousLoadPathForm from "../InstallerComplianceContinuousLoadPathForm/Form/HomeStandardEvaluationInstallerComplianceContinuousLoadPathForm";
import HomeStandardEvaluationInstallerComplianceChimneyForm from "../InstallerComplianceChimneyForm/Form/HomeStandardEvaluationInstallerComplianceChimneyForm";
import HomeStandardEvaluationInstallerComplianceAttachedStructureForm from "../InstallerComplianceAttachedStructureForm/Form/HomeStandardEvaluationInstallerComplianceAttachedStructureForm";
import HomeStandardEvaluationEngineeringComplianceGeneralInformationForm from "../EngineeringComplianceGeneralInformationForm/Form/HomeStandardEvaluationEngineeringComplianceGeneralInformationForm";
import HomeStandardEvaluationEngineeringComplianceGablesForm from "../EngineeringComplianceGablesForm/Form/HomeStandardEvaluationEngineeringComplianceGablesForm";
import HomeStandardEvaluationEngineeringComplianceContinuousLoadPathForm from "../EngineeringComplianceContinuousLoadPathForm/Form/HomeStandardEvaluationEngineeringComplianceContinuousLoadPathForm";
import HomeStandardEvaluationEngineeringComplianceChimneyForm from "../EngineeringComplianceChimneyForm/Form/HomeStandardEvaluationEngineeringComplianceChimneyForm";
import HomeStandardEvaluationEngineeringComplianceAttachedStructureForm from "../EngineeringComplianceAttachedStructureForm/Form/HomeStandardEvaluationEngineeringComplianceAttachedStructureForm";
import HomeStandardEvaluationEligibilityForm from "../EligibilityForm/Form/HomeStandardEvaluationEligibilityForm";

interface HomeStandardIterationEngineFormsProps {
  selectedTabId: string;
  evaluationId: string;
  evaluationStep: EvaluationStep;
  onFormSave: (fieldKey: string) => Promise<void>;
  onAllFormsInitialized: () => void;
  fieldsContainerClassName: string;
}

const HomeStandardIterationEngineForms: React.FC<
  HomeStandardIterationEngineFormsProps
> = (props) => {
  const [applicationFormInitialized, setApplicationFormInitialized] =
    useState<boolean>();

  const [
    rooferConfirmationFormInitialized,
    setRooferConfirmationFormInitialized,
  ] = useState<boolean>();

  const [
    roofingComplianceGeneralInformationForm,
    setRoofingComplianceGeneralInformationFormInitialized,
  ] = useState<boolean>(false);

  const [
    roofingComplianceRoofCoverForm,
    setRoofingComplianceRoofCoverFormInitialized,
  ] = useState<boolean>(false);

  const [
    engineerConfirmationFormInitialized,
    setEngineerConfirmationFormInitialized,
  ] = useState<boolean>();

  const [
    silverGoldComplianceGeneralInformationFormInitialized,
    setSilverGoldComplianceGeneralInformationFormInitialized,
  ] = useState<boolean>();

  const [
    installingContractorConfirmationFormInitialized,
    setInstallingContractorConfirmationFormInitialized,
  ] = useState<boolean>();

  const [
    installerComplianceGablesFormInitialized,
    setInstallerComplianceGablesFormInitialized,
  ] = useState<boolean>();

  const [
    installerComplianceContinuousLoadPathFormInitialized,
    setInstallerComplianceContinuousLoadPathFormInitialized,
  ] = useState<boolean>();

  const [
    installerComplianceChimneyFormInitialized,
    setInstallerComplianceChimneyFormInitialized,
  ] = useState<boolean>();

  const [
    installerComplianceAttachedStructureFormInitialized,
    setInstallerComplianceAttachedStructureFormInitialized,
  ] = useState<boolean>();

  const [
    engineeringComplianceGeneralInformationFormInitialized,
    setEngineeringComplianceGeneralInformationFormInitialized,
  ] = useState<boolean>();

  const [
    engineeringComplianceGablesFormInitialized,
    setEngineeringComplianceGablesFormInitialized,
  ] = useState<boolean>();

  const [
    engineeringComplianceContinuousLoadPathFormInitialized,
    setEngineeringComplianceContinuousLoadPathFormInitialized,
  ] = useState<boolean>();

  const [
    engineeringComplianceChimneyFormInitialized,
    setEngineeringComplianceChimneyFormInitialized,
  ] = useState<boolean>();

  const [
    engineeringComplianceAttachedStructureFormInitialized,
    setEngineeringComplianceAttachedStructureFormInitialized,
  ] = useState<boolean>();

  const [eligibilityFormInitialized, setEligibilityFormInitialized] =
    useState<boolean>();

  const [allFormsInitialized, setAllFormsInitialized] = useState(false);

  useEffect(() => {
    const formsInitialized =
      applicationFormInitialized ||
      roofingComplianceGeneralInformationForm ||
      roofingComplianceRoofCoverForm ||
      rooferConfirmationFormInitialized ||
      engineerConfirmationFormInitialized ||
      silverGoldComplianceGeneralInformationFormInitialized ||
      installingContractorConfirmationFormInitialized ||
      installerComplianceGablesFormInitialized ||
      installerComplianceContinuousLoadPathFormInitialized ||
      installerComplianceChimneyFormInitialized ||
      installerComplianceAttachedStructureFormInitialized ||
      engineeringComplianceGeneralInformationFormInitialized ||
      engineeringComplianceGablesFormInitialized ||
      engineeringComplianceContinuousLoadPathFormInitialized ||
      engineeringComplianceChimneyFormInitialized ||
      engineeringComplianceAttachedStructureFormInitialized ||
      eligibilityFormInitialized;

    if (formsInitialized) {
      setTimeout(() => {
        setAllFormsInitialized(true);
        props.onAllFormsInitialized();
      }, 600);
    } else {
      setAllFormsInitialized(false);
    }
  }, [
    applicationFormInitialized,
    roofingComplianceGeneralInformationForm,
    roofingComplianceRoofCoverForm,
    rooferConfirmationFormInitialized,
    engineerConfirmationFormInitialized,
    silverGoldComplianceGeneralInformationFormInitialized,
    installingContractorConfirmationFormInitialized,
    installerComplianceGablesFormInitialized,
    installerComplianceContinuousLoadPathFormInitialized,
    installerComplianceChimneyFormInitialized,
    installerComplianceAttachedStructureFormInitialized,
    engineeringComplianceGeneralInformationFormInitialized,
    engineeringComplianceGablesFormInitialized,
    engineeringComplianceContinuousLoadPathFormInitialized,
    engineeringComplianceChimneyFormInitialized,
    engineeringComplianceAttachedStructureFormInitialized,
  ]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const getFormAndHeaderContent = () => {
    switch (props.selectedTabId) {
      case "BasicInformationForm":
        return {
          headerContent: "Basic Information Form",
          form: (
            <HomeStandardEvaluationBasicInformationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setApplicationFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "EligibilityForm":
        return {
          headerContent: "Eligibility Form",
          form: (
            <HomeStandardEvaluationEligibilityForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setEligibilityFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "RooferConfirmationForm":
        return {
          headerContent: "Roofer Confirmation Form",
          form: (
            <HomeStandardEvaluationRooferConfirmationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setRooferConfirmationFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "RoofingComplianceGeneralInformationForm":
        return {
          headerContent: "Roofing Compliance General Information Form",
          form: (
            <HomeStandardEvaluationRoofingComplianceGeneralInformationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setRoofingComplianceGeneralInformationFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "RoofingComplianceRoofCoverForm":
        return {
          headerContent: "Roofing Compliance Roof Cover Form",
          form: (
            <HomeStandardEvaluationRoofingComplianceRoofCoverForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setRoofingComplianceRoofCoverFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "EngineerConfirmationForm":
        return {
          headerContent: "Engineer Confirmation Form",
          form: (
            <HomeStandardEvaluationEngineerConfirmationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setEngineerConfirmationFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "SilverGoldComplianceGeneralInformationForm":
        return {
          headerContent: "Silver/Gold Compliance General Information Form",
          form: (
            <HomeStandardEvaluationSilverGoldComplianceGeneralInformationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setSilverGoldComplianceGeneralInformationFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "InstallingContractorConfirmationForm":
        return {
          headerContent: "Installing Contractor Confirmation Form",
          form: (
            <HomeStandardEvaluationInstallingContractorConfirmationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setInstallingContractorConfirmationFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "InstallerComplianceGablesForm":
        return {
          headerContent: "Installer Compliance Gables Form",
          form: (
            <HomeStandardEvaluationInstallerComplianceGablesForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setInstallerComplianceGablesFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "InstallerComplianceContinuousLoadPathForm":
        return {
          headerContent: "Installer Compliance Continuous Load Path Form",
          form: (
            <HomeStandardEvaluationInstallerComplianceContinuousLoadPathForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setInstallerComplianceContinuousLoadPathFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "InstallerComplianceChimneyForm":
        return {
          headerContent: "Installer Compliance Chimney Form",
          form: (
            <HomeStandardEvaluationInstallerComplianceChimneyForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setInstallerComplianceChimneyFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "InstallerComplianceAttachedStructureForm":
        return {
          headerContent: "Installer Compliance Attached Structure Form",
          form: (
            <HomeStandardEvaluationInstallerComplianceAttachedStructureForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setInstallerComplianceAttachedStructureFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "EngineeringComplianceGeneralInformationForm":
        return {
          headerContent: "Engineering Compliance General Information Form",
          form: (
            <HomeStandardEvaluationEngineeringComplianceGeneralInformationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setEngineeringComplianceGeneralInformationFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "EngineeringComplianceGablesForm":
        return {
          headerContent: "Engineering Compliance Gables Form",
          form: (
            <HomeStandardEvaluationEngineeringComplianceGablesForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setEngineeringComplianceGablesFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "EngineeringComplianceContinuousLoadPathForm":
        return {
          headerContent: "Engineering Compliance Continuous Load Path Form",
          form: (
            <HomeStandardEvaluationEngineeringComplianceContinuousLoadPathForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setEngineeringComplianceContinuousLoadPathFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "EngineeringComplianceChimneyForm":
        return {
          headerContent: "Engineering Compliance Chimney Form",
          form: (
            <HomeStandardEvaluationEngineeringComplianceChimneyForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setEngineeringComplianceChimneyFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "EngineeringComplianceAttachedStructureForm":
        return {
          headerContent: "Engineering Compliance Attached Structure Form",
          form: (
            <HomeStandardEvaluationEngineeringComplianceAttachedStructureForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setEngineeringComplianceAttachedStructureFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      default:
        return null;
    }
  };

  const formContent = getFormAndHeaderContent();
  const headerContent = formContent?.headerContent;
  const form = formContent?.form;

  return (
    <>
      {!allFormsInitialized && <Loading />}
      <div
        className={classNames(props.fieldsContainerClassName, {
          "evaluation-forms-container-initialized": allFormsInitialized,
        })}
      >
        <PrimaryCard headerContent={headerContent}>{form}</PrimaryCard>
      </div>
    </>
  );
};

export default HomeStandardIterationEngineForms;
