import { FieldFormV2Module } from "@ucl/library";
import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../../../../common/Components/Error/ErrorStore";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { useNavigate } from "react-router";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../../types/HomeEvaluationFormModel";
import { homeStandardEvaluationAPIClient } from "../../../../lib/apiClients/homeStandardEvaluationAPIClient";
import { fortifiedHomeStandardRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { useHomeFieldSchemaFactory } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { HomeStandardEvaluationRoofingComplianceGeneralInformationFormProps } from "./HomeStandardEvaluationRoofingComplianceGeneralInformationForm";
import { BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts } from "../../../../../common/types/BaseHomeEvaluationRoofingComplianceGeneralInformationFormModel";
import { homeStandardEvaluationRoofingComplianceGeneralInformationAPIClient } from "../../../../lib/apiClients/homeStandardEvaluationRoofingComplianceGeneralInformationAPIClient";

function useHomeStandardEvaluationRoofingComplianceGeneralInformationForm(
  props: HomeStandardEvaluationRoofingComplianceGeneralInformationFormProps
) {
  const { builders, wieBuilders } = useHomeFieldSchemaFactory();
  const navigate = useNavigate();

  const formRef =
    useRef<FieldFormV2Module<Evaluation_HomeStandardEvaluationFormModel>>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formModel, setFormModel] =
    useState<Evaluation_HomeStandardEvaluationFormModel>();

  const [isInitialized, setIsInitialized] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
      props.onInitialized?.();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isInitialized) {
      props.onInitialized?.();
    }
  }, [isInitialized]);

  useEffect(() => {
    //Used to React to External Changes Pushed on the Form (Iteration Engine)
    if (formModel) {
      setFormModel({ ...formModel });
    }
  }, [wieBuilders]);

  useEffect(() => {
    fetchApplicationForm();
  }, []);

  useEffect(() => {
    if (!!formModel) {
      setFormModel(formModel);

      if (props.setFormModel) {
        props.setFormModel(formModel);
      }
    }
  }, [formModel]);

  const isFirstPartOfForm =
    props.formPart ===
    Object.values(
      BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts
    )[0];

  const isLastPartOfForm =
    props.formPart ===
    Object.values(
      BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts
    ).pop();

  const noBuilders = !builders && !wieBuilders;

  const fetchApplicationForm = async () => {
    setIsLoading(true);
    await homeStandardEvaluationAPIClient
      .getHomeStandardEvaluationFormModel(props.evaluationId)
      .then((response) => {
        setFormModel((prevModel) => ({
          ...prevModel,
          ...response,
        }));
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Debounce setup
  const handleFormSubmitDebounced = useCallback(
    debounce(
      async (
        form: Evaluation_HomeStandardEvaluationFormModel,
        _value: any,
        fieldProps: BaseFieldProps<any>
      ) => {
        if (fieldProps.fieldKey) {
          await handleFormUpdate(form, fieldProps.fieldKey).then(async () => {
            await props.onFormSave?.(fieldProps.fieldKey || "", _value);
          });
        }
      },
      2000
    ),
    []
  );

  useEffect(() => {
    // Cleanup the debounce function on component unmount
    return () => handleFormSubmitDebounced.cancel();
  }, [handleFormSubmitDebounced]);

  const onFormFieldChange = async (
    form: Evaluation_HomeStandardEvaluationFormModel,
    _value: any,
    fieldProps: BaseFieldProps<any>
  ) => {
    // Call the debounced submit function
    if (!!_value) {
      handleFormSubmitDebounced(form, _value, fieldProps);
      props.setHasUnsavedChanges(true);
    }
  };

  const handleFormUpdateResponse = async (
    response: Evaluation_HomeStandardEvaluationFormModel
  ) => {
    props.setHasUnsavedChanges(false);

    handlePartialResponseFormUpdate(response);
  };

  const handleFormUpdate = async (
    form: Evaluation_HomeStandardEvaluationFormModel,
    fieldKey?: string
  ) => {
    await homeStandardEvaluationRoofingComplianceGeneralInformationAPIClient
      .updateHomeStandardRoofingComplianceGeneralInformationForm(
        form.id,
        form.homeStandardEvaluation.roofingComplianceGeneralInformationForm,
        fieldKey
      )
      .then(async (response) => {
        await handleFormUpdateResponse(response);
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while saving the form",
            intent: Intent.DANGER,
          });
        }
        throw error;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleFormSubmit = async (
    form: Evaluation_HomeStandardEvaluationFormModel
  ) => {
    setFormModel(form);
    await homeStandardEvaluationRoofingComplianceGeneralInformationAPIClient
      .submitHomeStandardRoofingComplianceGeneralInformationForm(
        form.id,
        form.homeStandardEvaluation.roofingComplianceGeneralInformationForm,
        props.formPart
      )
      .then(async (response) => {
        handleFormSubmitResponse(response);
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while saving the form",
            intent: Intent.DANGER,
          });
        }
        throw error;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const findVisibleFormPart = (direction: "next" | "previous") => {
    const allFormParts = Object.values(
      BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts
    );
    const currentIndex = allFormParts.indexOf(props.formPart!);

    // Determine start, end, and increment based on direction
    const start = direction === "next" ? currentIndex + 1 : currentIndex - 1;
    const end = direction === "next" ? allFormParts.length : -1;
    const increment = direction === "next" ? 1 : -1;

    for (
      let i = start;
      direction === "next" ? i < end : i > end;
      i += increment
    ) {
      const candidatePart = allFormParts[i];
      const checklistPart = props.formChecklistParts?.find(
        (part) => part.formPart === candidatePart
      );

      if (!checklistPart?.hidden) {
        return candidatePart;
      }
    }

    return null;
  };

  const handleFormSubmitResponse = async (
    response: Evaluation_HomeStandardEvaluationFormModel
  ) => {
    props.setHasUnsavedChanges(false);

    setFormModel(response);

    if (isLastPartOfForm) {
      navigate(
        `${fortifiedHomeStandardRoutePrefix}/checklist/${props.evaluationId}`
      );
      return;
    }

    const nextVisiblePart = findVisibleFormPart("next");

    if (nextVisiblePart) {
      navigate(
        `${fortifiedHomeStandardRoutePrefix}/${props.evaluationId}/roofing-compliance-general-information/${nextVisiblePart}`
      );
    } else {
      // If no visible parts left, go to checklist
      navigate(
        `${fortifiedHomeStandardRoutePrefix}/checklist/${props.evaluationId}`
      );
    }
  };

  const getApplicationFormPartBuilder = () => {
    if (props.isIterationEnginePage) {
      return wieBuilders?.HomeStandardIterationEngineRoofingComplianceGeneralInformationForm;
    }
    switch (props.formPart) {
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.BasicInformation:
        return builders?.HomeStandardRoofingComplianceBasicInformationForm;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.PermitInformation:
        return builders?.HomeStandardPermitInformationForm;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.SiteConditions:
        return builders?.HomeStandardSiteConditionsForm;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.RoofStructure:
        return builders?.HomeStandardRoofStructureForm;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.RoofSheathing:
        return builders?.HomeStandardRoofSheathingForm;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.SealedRoofDeck:
        return builders?.HomeStandardSealedRoofDeckForm;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.DripEdge:
        return builders?.HomeStandardDripEdgeForm;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.AtticVents:
        return builders?.HomeStandardAtticVentsForm;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.PhotoVoltaicPanels:
        return builders?.HomeStandardPhotoVoltaicPanelsForm;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.SkyLights:
        return builders?.HomeStandardSkyLightsForm;
      default:
        return builders?.HomeStandardRoofingComplianceGeneralInformationForm;
    }
  };

  const areAllRequiredFieldsOnFormSectionComplete = () => {
    if (!props.formPart || !formModel) {
      return false;
    }

    switch (props.formPart) {
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.BasicInformation:
        return formModel.homeStandardEvaluation
          .roofingComplianceGeneralInformationForm.isBasicInformationComplete;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.PermitInformation:
        return formModel.homeStandardEvaluation
          .roofingComplianceGeneralInformationForm.isPermitInformationComplete;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.SiteConditions:
        return formModel.homeStandardEvaluation
          .roofingComplianceGeneralInformationForm.isSiteConditionsComplete;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.RoofStructure:
        return formModel.homeStandardEvaluation
          .roofingComplianceGeneralInformationForm.isRoofStructureComplete;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.RoofSheathing:
        return formModel.homeStandardEvaluation
          .roofingComplianceGeneralInformationForm.isRoofSheathingComplete;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.SealedRoofDeck:
        return formModel.homeStandardEvaluation
          .roofingComplianceGeneralInformationForm.isSealedRoofDeckComplete;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.DripEdge:
        return formModel.homeStandardEvaluation
          .roofingComplianceGeneralInformationForm.isDripEdgeComplete;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.AtticVents:
        return formModel.homeStandardEvaluation
          .roofingComplianceGeneralInformationForm.isAtticVentsComplete;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.PhotoVoltaicPanels:
        return formModel.homeStandardEvaluation
          .roofingComplianceGeneralInformationForm.isPhotoVoltaicPanelsComplete;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.SkyLights:
        return formModel.homeStandardEvaluation
          .roofingComplianceGeneralInformationForm.isSkyLightsComplete;
      default:
        return false;
    }
  };

  const areAllRequiredFieldsComplete =
    areAllRequiredFieldsOnFormSectionComplete();

  const handlePartialResponseFormUpdate = (
    response: Evaluation_HomeStandardEvaluationFormModel
  ) => {
    // This is used to combat save on blur issues. This only updates fields that are essential to the form.
    // These are set in the API response. Field Counts and sub section completion status
    setFormModel((prevState) => {
      if (!prevState) return prevState;

      const {
        areAllRequiredFieldsComplete,
        basicInformationRequiredFieldCount,
        roofStructureRequiredFieldCount,
        roofSheathingRequiredFieldCount,
        dripEdgeRequiredFieldCount,
        photoVoltaicPanelsRequiredFieldCount,
        skyLightsRequiredFieldCount,
        permitInformationRequiredFieldCount,
        siteConditionsRequiredFieldCount,
        sealedRoofDeckRequiredFieldCount,
        atticVentsRequiredFieldCount,
        basicInformationHasBeenSubmitted,
        roofStructureHasBeenSubmitted,
        roofSheathingHasBeenSubmitted,
        dripEdgeHasBeenSubmitted,
        photoVoltaicPanelsHasBeenSubmitted,
        skyLightsHasBeenSubmitted,
        permitInformationHasBeenSubmitted,
        siteConditionsHasBeenSubmitted,
        sealedRoofDeckHasBeenSubmitted,
        atticVentsHasBeenSubmitted,
        isBasicInformationComplete,
        isRoofStructureComplete,
        isRoofSheathingComplete,
        isDripEdgeComplete,
        isPhotoVoltaicPanelsComplete,
        isSkyLightsComplete,
        isPermitInformationComplete,
        isSiteConditionsComplete,
        isSealedRoofDeckComplete,
        isAtticVentsComplete,
      } =
        response.homeStandardEvaluation.roofingComplianceGeneralInformationForm;

      const { canProceedToApplicationFee } = response.homeStandardEvaluation;

      return {
        ...prevState,
        homeStandardEvaluation: {
          ...prevState.homeStandardEvaluation,
          canProceedToApplicationFee,
          roofingComplianceGeneralInformationForm: {
            ...prevState.homeStandardEvaluation
              .roofingComplianceGeneralInformationForm,
            areAllRequiredFieldsComplete,
            basicInformationRequiredFieldCount,
            roofStructureRequiredFieldCount,
            roofSheathingRequiredFieldCount,
            dripEdgeRequiredFieldCount,
            photoVoltaicPanelsRequiredFieldCount,
            skyLightsRequiredFieldCount,
            permitInformationRequiredFieldCount,
            siteConditionsRequiredFieldCount,
            sealedRoofDeckRequiredFieldCount,
            atticVentsRequiredFieldCount,
            basicInformationHasBeenSubmitted,
            roofStructureHasBeenSubmitted,
            roofSheathingHasBeenSubmitted,
            dripEdgeHasBeenSubmitted,
            photoVoltaicPanelsHasBeenSubmitted,
            skyLightsHasBeenSubmitted,
            permitInformationHasBeenSubmitted,
            siteConditionsHasBeenSubmitted,
            sealedRoofDeckHasBeenSubmitted,
            atticVentsHasBeenSubmitted,
            isBasicInformationComplete,
            isRoofStructureComplete,
            isRoofSheathingComplete,
            isDripEdgeComplete,
            isPhotoVoltaicPanelsComplete,
            isSkyLightsComplete,
            isPermitInformationComplete,
            isSiteConditionsComplete,
            isSealedRoofDeckComplete,
            isAtticVentsComplete,
          },
        },
      };
    });
  };

  return {
    builders,
    wieBuilders,
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    formModel,
    isFirstPartOfForm,
    isLastPartOfForm,
    getApplicationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    noBuilders,
    containerRef,
    areAllRequiredFieldsComplete,
    findVisibleFormPart,
  };
}

export default useHomeStandardEvaluationRoofingComplianceGeneralInformationForm;
