import {
  EmailFieldProps,
  FieldSchemaFormBuilderV2,
  SchemaFactoryV2,
  SmartAddressFieldProps,
  TextFieldProps,
} from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { addressApiClient } from "../../../../../../foritfied/lib/apiClients/address/addressApiClient";
import { Evaluation_CommercialRedesignationFormModel } from "../../../types/CommercialRedesignationFormModel";
import { YesOrNoSelection } from "../../../../../../wildfire/types/FormFields";
import { get } from "lodash";

const modelName = "Evaluation";
const subModelName = "CommercialRedesignation";
const formName = "RedesignationForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

//Part 1: Application Information
const applicationInformationFields = (
  form: Evaluation_CommercialRedesignationFormModel
) => [
  buildFieldKey("ApplicantFirstName"),
  buildFieldKey("ApplicantLastName"),
  buildFieldKey("BusinessName"),
  {
    fieldKey: buildFieldKey("BusinessAddress"),
    getAddressesByQuery: addressApiClient.getAddressesByQuery,
    getAddressByQuery: addressApiClient.getAddressByQuery,
    isSmartOnly: false,
    shouldSetCountyOnSelect: true,
  } as SmartAddressFieldProps,
  buildFieldKey("HasTheBuildingNameChanged"),
  {
    fieldKey: buildFieldKey("BuildingName"),
    hidden:
      form.commercialRedesignation.redesignationForm
        .hasTheBuildingNameChanged != YesOrNoSelection.Yes,
  } as TextFieldProps,
  buildFieldKey("BusinessPhoneNumber"),
  buildFieldKey("BusinessEmail"),
  buildFieldKey("AreYouTheBuildingOwner"),
  {
    fieldKey: buildFieldKey("Title"),
    hidden:
      form.commercialRedesignation.redesignationForm.areYouTheBuildingOwner !=
      YesOrNoSelection.No,
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("CompanyName"),
    hidden:
      form.commercialRedesignation.redesignationForm.areYouTheBuildingOwner !=
      YesOrNoSelection.No,
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("Phone"),
    hidden:
      form.commercialRedesignation.redesignationForm.areYouTheBuildingOwner !=
      YesOrNoSelection.No,
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("Email"),
    hidden:
      form.commercialRedesignation.redesignationForm.areYouTheBuildingOwner !=
      YesOrNoSelection.No,
  } as EmailFieldProps,
];

const applicationInformationRequiredFields = [
  buildFieldKey("ApplicantFirstName"),
  buildFieldKey("ApplicantLastName"),
  buildFieldKey("BusinessName"),
  buildFieldKey("BusinessAddress"),
  buildFieldKey("HasTheBuildingNameChanged"),
  buildFieldKey("BuildingName"),
  buildFieldKey("BusinessPhoneNumber"),
  buildFieldKey("BusinessEmail"),
  buildFieldKey("AreYouTheBuildingOwner"),
  buildFieldKey("Title"),
  buildFieldKey("CompanyName"),
  buildFieldKey("Phone"),
  buildFieldKey("Email"),
];

export const commercialRedesignationApplicationInformationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_CommercialRedesignationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialRedesignationFormModel>(
        modelName,
        applicationInformationFields(form),
        onValueChange,
        form,
        errors,
        applicationInformationRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialRedesignationFormModel>;
};

//Part 3 General Project Details
const generalProjectDetailsFields = (
  form: Evaluation_CommercialRedesignationFormModel,
  errors?: { [key: string]: string[] }
) => [
  buildFieldKey("HasRenovationsModificationsAdditionsOrRepairs"),
  {
    fieldKey: buildFieldKey(
      "RenovationsModificationsAdditionsOrRepairsDescription"
    ),
    hidden:
      form.commercialRedesignation.redesignationForm
        .hasRenovationsModificationsAdditionsOrRepairs != YesOrNoSelection.Yes,
  } as TextFieldProps,
  buildFieldKey("HasContactedIBHSAndEvaluator"),
  {
    fieldKey: buildFieldKey("EvaluatorDocumentationConfirmationPhotos"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "evaluatorDocumentationConfirmationPhotos.FileCount"
    ),
    canDelete: false,
    hidden:
      form.commercialRedesignation.redesignationForm
        .hasContactedIBHSAndEvaluator != YesOrNoSelection.Yes,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "BuildingRenovationsModificationsOrAdditionsPhotos"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "buildingRenovationsModificationsOrAdditionsPhotos.FileCount"
    ),
    canDelete: false,
    hidden:
      form.commercialRedesignation.redesignationForm
        .hasContactedIBHSAndEvaluator != YesOrNoSelection.No,
  } as WildfireBoxContentExplorerProps,
];

const generalProjectDetailsRequiredFields = [
  buildFieldKey("HasRenovationsModificationsAdditionsOrRepairs"),
  buildFieldKey("RenovationsModificationsAdditionsOrRepairsDescription"),
  buildFieldKey("HasContactedIBHSAndEvaluator"),
];

export const commercialRedesignationGeneralDetailsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_CommercialRedesignationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialRedesignationFormModel>(
        modelName,
        generalProjectDetailsFields(form, errors),
        onValueChange,
        form,
        errors,
        generalProjectDetailsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialRedesignationFormModel>;
};

// Full form builder with all parts included
export const commercialRedesignationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_CommercialRedesignationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialRedesignationFormModel>(
        modelName,
        [
          ...applicationInformationFields(form),
          ...generalProjectDetailsFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        [
          ...applicationInformationRequiredFields,
          ...generalProjectDetailsRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialRedesignationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const commercialIterationEngineRedesignationFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_CommercialRedesignationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialRedesignationFormModel>(
        modelName,
        [
          ...applicationInformationFields(form),
          ...generalProjectDetailsFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        [
          ...applicationInformationRequiredFields,
          ...generalProjectDetailsRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialRedesignationFormModel>;
};
