import React from "react";
import { Callout } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

interface DuplicateAddressWarningProps {
  hasDuplicateAddress: boolean;
  warningText: string;
}

const DuplicateAddressWarning: React.FC<DuplicateAddressWarningProps> = ({
  hasDuplicateAddress,
  warningText,
}) => {
  if (!hasDuplicateAddress) {
    return null; // Do not render anything if there's no duplicate address
  }

  return (
    <Callout
      className="wildfire-form-warning-callout"
      icon={IconNames.WARNING_SIGN}
      intent="danger"
      title="Duplicate Address Warning"
    >
      {warningText}
    </Callout>
  );
};

export default DuplicateAddressWarning;
