import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { evaluationStore } from "../../../../foritfied/stores/EvaluationStore";
import HomeStandardChecklist from "../components/Checklist/HomeStandardChecklist";

export interface HomeStandardChecklistPageProps {
  evaluationId?: string;
}

const HomeStandardChecklistPageComponent: FC<HomeStandardChecklistPageProps> = (
  props
) => {
  //In the future, we want to remove all dependencies on the global store
  evaluationStore.init(props.evaluationId || "");
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeStandardChecklist evaluationId={props.evaluationId} />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeStandardChecklistPage = withAITracking(
  reactPlugin,
  HomeStandardChecklistPageComponent,
  "HomeStandardChecklistPage",
  "tracking-container"
);
