import { useContext, useEffect, useState } from "react";
import { WildfireEvaluationSteps } from "../../../../foritfied/types/evaluation/Evaluation";
import { ChangeRequestV2 } from "../types/ChangeRequestV2";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { PrimaryCard } from "@ucl/library";
import ApplicationForm from "../../Forms/ApplicationForm/ApplicationForm";
import RoofFieldEvaluationForm from "../../Forms/RoofFieldEvaluationForm/RoofFieldEvaluationForm";
import HomeExteriorFieldEvaluationForm from "../../Forms/HomeExteriorFieldEvaluationForm/HomeExteriorFieldEvaluationForm";
import LandscapeFieldEvaluationForm from "../../Forms/LandscapeFieldEvaluationForm/LandscapeFieldEvaluationForm";
import OtherFieldEvaluationForm from "../../Forms/OtherFieldEvaluationForm/OtherFieldEvaluationForm";
import classNames from "classnames";
import { UnsavedChangesBlockerContext } from "../../Provider/UnsavedChangesBlockerContext";

interface IterationEngineFormsProps {
  selectedTabId: string;
  changeRequests: ChangeRequestV2[];
  evaluationId: string;
  evaluationStep: WildfireEvaluationSteps;
  isPlus?: boolean;
  getChangeRequests?: () => Promise<void>;
  fetchFormFieldEvaluationDecisioning: (evaluationId: string) => Promise<void>;
  onEvaluationFormInitialized?: () => void;
  fieldsContainerClassName: string;
}

const WildfireApplicationAdminIterationEngineForms: React.FC<
  IterationEngineFormsProps
> = (props) => {
  const onFormSave = async (fieldKey: string) => {
    const fieldKeyHasChangeRequest = props.changeRequests.some(
      (cr) => cr.evaluationFieldKey === fieldKey
    );
    if (fieldKeyHasChangeRequest) {
      props.getChangeRequests && (await props.getChangeRequests());
    }

    props.fetchFormFieldEvaluationDecisioning(props.evaluationId);
  };

  const [ready, setReady] = useState(false);

  const onAllFormsInitialized = () => {
    props.onEvaluationFormInitialized?.();
    setTimeout(() => {
      setReady(true);
    }, 800);
  };

  const loadEvaluationForms =
    props.evaluationStep ===
    WildfireEvaluationSteps.WildfireApplicationIBHSReview;

  const [applicationFormInitiaized, setApplicationFormInitiaized] = useState(
    props.isPlus
  );
  const [
    roofFieldEvaluationFormInitiaized,
    setRoofFieldEvaluationFormInitiaized,
  ] = useState(!loadEvaluationForms);
  const [
    homeExteriorFieldEvaluationFormInitiaized,
    setHomeExteriorFieldEvaluationFormInitiaized,
  ] = useState(!loadEvaluationForms);
  const [
    landscapeFieldEvaluationFormInitiaized,
    setLandscapeFieldEvaluationFormInitiaized,
  ] = useState(!loadEvaluationForms);
  const [
    otherFieldEvaluationFormInitiaized,
    setOtherFieldEvaluationFormInitiaized,
  ] = useState(!loadEvaluationForms);

  const [allFormsInitialized, setAllFormsInitialized] = useState(false);

  useEffect(() => {
    const formsInitialized =
      applicationFormInitiaized &&
      roofFieldEvaluationFormInitiaized &&
      homeExteriorFieldEvaluationFormInitiaized &&
      landscapeFieldEvaluationFormInitiaized &&
      otherFieldEvaluationFormInitiaized;

    if (formsInitialized) {
      setTimeout(() => {
        setAllFormsInitialized(true);
        onAllFormsInitialized();
      }, 600);
    } else {
      setAllFormsInitialized(false);
    }
  }, [
    applicationFormInitiaized,
    roofFieldEvaluationFormInitiaized,
    homeExteriorFieldEvaluationFormInitiaized,
    landscapeFieldEvaluationFormInitiaized,
    otherFieldEvaluationFormInitiaized,
  ]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  return (
    <>
      {!ready && <Loading />}

      <div style={{ visibility: ready ? "visible" : "hidden" }}>
        {!allFormsInitialized && <Loading />}
        <div
          className={classNames(props.fieldsContainerClassName, {
            "evaluation-forms-container-initialized": allFormsInitialized,
          })}
        >
          {!props.isPlus && (
            <PrimaryCard headerContent="Application Form">
              <ApplicationForm
                wildfireEvaluationId={props.evaluationId}
                isWiePage={true}
                showFormControls={false}
                onInitialized={() => {
                  setApplicationFormInitiaized(true);
                }}
                onFormSave={onFormSave}
                setHasUnsavedChanges={setHasUnsavedChanges}
              />
            </PrimaryCard>
          )}

          {loadEvaluationForms && (
            <>
              <PrimaryCard headerContent="Roof">
                <RoofFieldEvaluationForm
                  wildfireEvaluationId={props.evaluationId}
                  isWiePage={true}
                  showFormControls={false}
                  onInitialized={() => {
                    setRoofFieldEvaluationFormInitiaized(true);
                  }}
                  onFormSave={onFormSave}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                />
              </PrimaryCard>

              <PrimaryCard headerContent="Home Exterior">
                <HomeExteriorFieldEvaluationForm
                  wildfireEvaluationId={props.evaluationId}
                  isWiePage={true}
                  showFormControls={false}
                  onInitialized={() => {
                    setHomeExteriorFieldEvaluationFormInitiaized(true);
                  }}
                  onFormSave={onFormSave}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                />
              </PrimaryCard>

              <PrimaryCard headerContent="Landscape">
                <LandscapeFieldEvaluationForm
                  wildfireEvaluationId={props.evaluationId}
                  isWiePage={true}
                  showFormControls={false}
                  onInitialized={() => {
                    setLandscapeFieldEvaluationFormInitiaized(true);
                  }}
                  onFormSave={onFormSave}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                />
              </PrimaryCard>

              <PrimaryCard headerContent="Other">
                <OtherFieldEvaluationForm
                  wildfireEvaluationId={props.evaluationId}
                  isWiePage={true}
                  showFormControls={false}
                  onInitialized={() => {
                    setOtherFieldEvaluationFormInitiaized(true);
                  }}
                  onFormSave={onFormSave}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                />
              </PrimaryCard>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default WildfireApplicationAdminIterationEngineForms;
