import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { HomeBasicInformationFormParts } from "../../common/types/BaseHomeEvaluationBasicInformationFormModel";
import HomeNCIUABasicInformationFormContainer from "../components/Forms/BasicInformationForm/Container/HomeNCIUABasicInformationFormContainer";

export interface HomeNCIUABasicInformationPageProps {
  evaluationId?: string;
  homeNCIUABasicInformationFormPart?: HomeBasicInformationFormParts;
}

const HomeNCIUABasicInformationPageComponent: FC<
  HomeNCIUABasicInformationPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeNCIUABasicInformationFormContainer
            evaluationId={props.evaluationId}
            basicInformationFormPart={props.homeNCIUABasicInformationFormPart}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeNCIUABasicInformationPage = withAITracking(
  reactPlugin,
  HomeNCIUABasicInformationPageComponent,
  "HomeNCIUABasicInformationPage",
  "tracking-container"
);
