import React from "react";
import { WildfireEvaluation } from "../../types/WildfireApplication/WildfireApplication";
import { EvaluationFormModel } from "../../forms/EvaluationFormModel";
import { formatSecondaryAddressInfo } from "../../lib/application/applicationUtil";
import "./styles.scss";

interface WildfireAppHeaderProps {
  wildfireEvaluation: WildfireEvaluation | EvaluationFormModel;
}

const WildfireAppHeader: React.FC<WildfireAppHeaderProps> = (props) => {
  const wfpApplicationId =
    props.wildfireEvaluation.wildfireApplication
      ?.wildfirePreparedApplicationId ??
    props.wildfireEvaluation.wildfireLandscapeReview
      .wildfirePreparedApplicationId;

  const hasAddress =
    !!props.wildfireEvaluation.buildingAddress &&
    !!props.wildfireEvaluation.buildingCity &&
    !!props.wildfireEvaluation.buildingState_AsString &&
    !!props.wildfireEvaluation.buildingZip;

  const getFormatedSecondaryAddressInfo = (): string => {
    // WFP ID and Address (if it exists)
    return hasAddress
      ? `, ${formatSecondaryAddressInfo(
          props.wildfireEvaluation.buildingAddress2,
          props.wildfireEvaluation.buildingCity ?? "",
          props.wildfireEvaluation.buildingState_AsString ?? "",
          props.wildfireEvaluation.buildingZip ?? ""
        )}`
      : "";
  };

  const getPrimaryHeaderInfo = (): string => {
    // WFP ID and Address (if it exists)
    return hasAddress ? `${props.wildfireEvaluation.buildingAddress}` : "";
  };

  return (
    <div className="wildfire-app-header">
      <div className="wildfire-app-header__primary">Wildfire Prepared - </div>
      <div className="wildfire-app-header__address">
        <div className="wildfire-app-header__address__primary">{`${getPrimaryHeaderInfo()}`}</div>
        <div className="wildfire-app-header__address__secondary">
          {`${getFormatedSecondaryAddressInfo()}`}
        </div>
      </div>
      <div className="wildfire-app-header__application-id">
        {` (${wfpApplicationId})`}
      </div>
    </div>
  );
};

export default WildfireAppHeader;
