import { useContext, useEffect, useState } from "react";
import { useHomeStandardEvaluation } from "../../customHooks/useHomeStandardEvaluation";
import { fortifiedHomeStandardRoutePrefix } from "../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { UserRole } from "../../../../../foritfied/stores/FortifiedUserStore";
import { NotifySubmitterFormLink } from "../../../../../foritfied/components/Checklist/NotifySubmitter/NotifySubmitterLinkBuilder";
import { EvaluationStep } from "../../../../../foritfied/types/evaluation/Evaluation";
import { homeStandardEvaluationAPIClient } from "../../lib/apiClients/homeStandardEvaluationAPIClient";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../../common/Components/Error/ErrorStore";
import { FormRowProps } from "../../../common/components/Checklist/ChecklistTabPanelV2";
import ChecklistContainerV2, {
  ChecklistTabItem,
} from "../../../common/components/Checklist/ChecklistContainerV2";
import { ProductSubtypes } from "../../../../Common/types/Evaluation/BaseEvaluation";
import { AppHeaderContext } from "../../../../../wildfire/components/Provider/AppHeaderContext";

interface ChecklistContainerProps {
  evaluationId: string;
}

const HomeStandardChecklist: React.FC<ChecklistContainerProps> = (props) => {
  const [selectedTabId, setSelectedTabId] = useState(0);

  const { homeStandardEvaluation, setHomeStandardEvaluation } =
    useHomeStandardEvaluation(props.evaluationId);

  const [hasFormsLoading, setHasFormsLoading] = useState(false);

  useEffect(() => {
    return () => {
      setDefaultHeader();
    };
  }, []);

  const { setFotifiedHomeEvaluationHeader, setDefaultHeader } =
    useContext(AppHeaderContext);

  useEffect(() => {
    if (
      homeStandardEvaluation?.homeStandardEvaluation?.eligibilityForm
        ?.isEvaluationFormCreationInProgress
    ) {
      setHasFormsLoading(true);
    }

    if (homeStandardEvaluation) {
      setFotifiedHomeEvaluationHeader(homeStandardEvaluation);
    }
  }, [homeStandardEvaluation]);

  useEffect(() => {
    if (hasFormsLoading) {
      const interval = setInterval(async () => {
        await homeStandardEvaluationAPIClient
          .getHomeStandardEvaluation(props.evaluationId)
          .then((response) => {
            setHomeStandardEvaluation(response);

            //Set Loading and fetching state if an event is occuring in the background.
            //Currently used for form generation and payment step movement
            const isLoading =
              response?.homeStandardEvaluation?.eligibilityForm
                ?.isEvaluationFormCreationInProgress === true ||
              response.homeStandardEvaluation?.canMoveToPaymentStep === true;

            if (!isLoading) {
              setHasFormsLoading(false);
              clearInterval(interval);
            }
          })
          .catch(() => {
            errorStore.setErrorMessage(genericErrorMessage);
          });
      }, 3000); // Check every 3 seconds

      return () => clearInterval(interval);
    }
  }, [hasFormsLoading]);

  const forms: FormRowProps[] = [
    {
      formName: "Basic Information Form",
      formURL: `${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/basic-information`,
      isCompleted:
        homeStandardEvaluation?.homeStandardEvaluation?.basicInformationForm
          ?.areAllRequiredFieldsComplete || false,
      isDisabled: false,
      formsNeedingCompletion: [],
      completerRoles:
        homeStandardEvaluation?.homeStandardEvaluation?.basicInformationForm
          ?.completerRoles || [],
      completerRoles_AsString:
        homeStandardEvaluation?.homeStandardEvaluation?.basicInformationForm
          ?.completerRoles_AsString || [],
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation?.basicInformationForm,
    },
    {
      formName: "Eligibility Form",
      formURL: `${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/eligibility`,
      isCompleted:
        homeStandardEvaluation?.homeStandardEvaluation?.eligibilityForm
          ?.areAllRequiredFieldsComplete || false,
      isDisabled: false,
      formsNeedingCompletion: [],
      completerRoles:
        homeStandardEvaluation?.homeStandardEvaluation?.eligibilityForm
          ?.completerRoles || [],
      completerRoles_AsString:
        homeStandardEvaluation?.homeStandardEvaluation?.eligibilityForm
          ?.completerRoles_AsString || [],
      hidden: !homeStandardEvaluation?.homeStandardEvaluation?.eligibilityForm,
    },
    {
      formName: "Roofing Compliance Form: General Information",
      formURL: `${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/roofing-compliance-general-information`,
      isCompleted:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.roofingComplianceGeneralInformationForm
          ?.areAllRequiredFieldsComplete || false,
      isDisabled: false,
      formsNeedingCompletion: [],
      completerRoles:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.roofingComplianceGeneralInformationForm?.completerRoles || [],
      completerRoles_AsString:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.roofingComplianceGeneralInformationForm?.completerRoles_AsString ||
        [],
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.roofingComplianceGeneralInformationForm,
    },
    {
      formName: "Roof Cover Compliance Form",
      formURL: `${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/roofing-compliance-roof-cover`,
      isCompleted:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.roofingComplianceRoofCoverForm?.areAllRequiredFieldsComplete ||
        false,
      isDisabled:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.canCompleteRoofCoverComplianceForm,
      formsNeedingCompletion: ["Roofing Compliance Form: General Information"],
      completerRoles:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.roofingComplianceRoofCoverForm?.completerRoles || [],
      completerRoles_AsString:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.roofingComplianceRoofCoverForm?.completerRoles_AsString || [],
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.roofingComplianceRoofCoverForm,
    },
    {
      formName: "Roofer Confirmation",
      formURL: `${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/roofer-confirmation`,
      isCompleted:
        homeStandardEvaluation?.homeStandardEvaluation?.rooferConfirmationForm
          ?.areAllRequiredFieldsComplete || false,
      isDisabled:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.canCompleteRooferConfirmationForm,
      formsNeedingCompletion: [
        "Roofing Compliance Form: General Information",
        "Roof Cover Compliance Form",
      ],
      completerRoles:
        homeStandardEvaluation?.homeStandardEvaluation?.rooferConfirmationForm
          ?.completerRoles || [],
      completerRoles_AsString:
        homeStandardEvaluation?.homeStandardEvaluation?.rooferConfirmationForm
          ?.completerRoles_AsString || [],
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation?.rooferConfirmationForm,
    },
    {
      formName: "Silver and Gold Compliance Form: General Information",
      formURL: `${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/silver-gold-compliance-general-information`,
      isCompleted:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.silverGoldComplianceGeneralInformationForm
          ?.areAllRequiredFieldsComplete || false,
      isDisabled: false,
      formsNeedingCompletion: [],
      completerRoles:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.silverGoldComplianceGeneralInformationForm?.completerRoles || [],
      completerRoles_AsString:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.silverGoldComplianceGeneralInformationForm
          ?.completerRoles_AsString || [],
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.silverGoldComplianceGeneralInformationForm,
    },
    {
      formName: "Engineering Compliance Form: General Information",
      formURL: `${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/engineering-compliance-general-information`,
      isCompleted:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceGeneralInformationForm
          ?.areAllRequiredFieldsComplete || false,
      isDisabled: false,
      formsNeedingCompletion: [],
      completerRoles:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceGeneralInformationForm?.completerRoles || [],
      completerRoles_AsString:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceGeneralInformationForm
          ?.completerRoles_AsString || [],
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceGeneralInformationForm,
    },
    {
      formName: "Engineering Compliance Form: Gables",
      formURL: `${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/engineering-compliance-gables`,
      isCompleted:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceGablesForm?.areAllRequiredFieldsComplete ||
        false,
      isDisabled: false,
      formsNeedingCompletion: [],
      completerRoles:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceGablesForm?.completerRoles || [],
      completerRoles_AsString:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceGablesForm?.completerRoles_AsString || [],
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceGablesForm,
    },
    {
      formName: "Engineering Compliance Form: Chimney",
      formURL: `${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/engineering-compliance-chimney`,
      isCompleted:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceChimneyForm?.areAllRequiredFieldsComplete ||
        false,
      isDisabled: false,
      formsNeedingCompletion: [],
      completerRoles:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceChimneyForm?.completerRoles || [],
      completerRoles_AsString:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceChimneyForm?.completerRoles_AsString || [],
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceChimneyForm,
    },
    {
      formName: "Engineering Compliance Form: Attached Structures",
      formURL: `${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/engineering-compliance-attached-structure`,
      isCompleted:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceAttachedStructureForm
          ?.areAllRequiredFieldsComplete || false,
      isDisabled: false,
      formsNeedingCompletion: [],
      completerRoles:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceAttachedStructureForm?.completerRoles || [],
      completerRoles_AsString:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceAttachedStructureForm
          ?.completerRoles_AsString || [],
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceAttachedStructureForm,
    },
    {
      formName: "Engineering Compliance Form: Continuous Load Path",
      formURL: `${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/engineering-compliance-continuous-load-path`,
      isCompleted:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceContinuousLoadPathForm
          ?.areAllRequiredFieldsComplete || false,
      isDisabled: false,
      formsNeedingCompletion: [],
      completerRoles:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceContinuousLoadPathForm?.completerRoles || [],
      completerRoles_AsString:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceContinuousLoadPathForm
          ?.completerRoles_AsString || [],
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceContinuousLoadPathForm,
    },
    {
      formName: "Engineer Confirmation",
      formURL: `${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/engineer-confirmation`,
      isCompleted:
        homeStandardEvaluation?.homeStandardEvaluation?.engineerConfirmationForm
          ?.areAllRequiredFieldsComplete || false,
      isDisabled:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.canCompleteEngineerConfirmationForm,
      formsNeedingCompletion: [
        "Engineering Compliance Form: General Information",
        "Engineering Compliance Form: Gables",
        "Engineering Compliance Form: Chimney",
        "Engineering Compliance Form: Attached Structures",
        "Engineering Compliance Form: Continuous Load Path",
      ],
      completerRoles:
        homeStandardEvaluation?.homeStandardEvaluation?.engineerConfirmationForm
          ?.completerRoles || [],
      completerRoles_AsString:
        homeStandardEvaluation?.homeStandardEvaluation?.engineerConfirmationForm
          ?.completerRoles_AsString || [],
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.engineerConfirmationForm,
    },
    {
      formName: "Installer Compliance Form: Gables",
      formURL: `${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/installer-compliance-gables`,
      isCompleted:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.installerComplianceGablesForm?.areAllRequiredFieldsComplete ||
        false,
      isDisabled: false,
      formsNeedingCompletion: [],
      completerRoles:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.installerComplianceGablesForm?.completerRoles || [],
      completerRoles_AsString:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.installerComplianceGablesForm?.completerRoles_AsString || [],
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.installerComplianceGablesForm,
    },
    {
      formName: "Installer Compliance Form: Chimney",
      formURL: `${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/installer-compliance-chimney`,
      isCompleted:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.installerComplianceChimneyForm?.areAllRequiredFieldsComplete ||
        false,
      isDisabled: false,
      formsNeedingCompletion: [],
      completerRoles:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.installerComplianceChimneyForm?.completerRoles || [],
      completerRoles_AsString:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.installerComplianceChimneyForm?.completerRoles_AsString || [],
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.installerComplianceChimneyForm,
    },
    {
      formName: "Installer Compliance Form: Attached Structures",
      formURL: `${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/installer-compliance-attached-structure`,
      isCompleted:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.installerComplianceAttachedStructureForm
          ?.areAllRequiredFieldsComplete || false,
      isDisabled: false,
      formsNeedingCompletion: [],
      completerRoles:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.installerComplianceAttachedStructureForm?.completerRoles || [],
      completerRoles_AsString:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.installerComplianceAttachedStructureForm?.completerRoles_AsString ||
        [],
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.installerComplianceAttachedStructureForm,
    },
    {
      formName: "Installer Compliance Form: Continuous Load Path",
      formURL: `${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/installer-compliance-continuous-load-path`,
      isCompleted:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.installerComplianceContinuousLoadPathForm
          ?.areAllRequiredFieldsComplete || false,
      isDisabled: false,
      formsNeedingCompletion: [],
      completerRoles:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.installerComplianceContinuousLoadPathForm?.completerRoles || [],
      completerRoles_AsString:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.installerComplianceContinuousLoadPathForm
          ?.completerRoles_AsString || [],
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.installerComplianceContinuousLoadPathForm,
    },
    {
      formName: "Installing Contractor Confirmation",
      formURL: `${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/installing-contractor-confirmation`,
      isCompleted:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.installingContractorConfirmationForm
          ?.areAllRequiredFieldsComplete || false,
      isDisabled:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.canCompleteInstallingContractorConfirmationForm,
      formsNeedingCompletion: [
        "Installer Compliance Form: Gables",
        "Installer Compliance Form: Chimney",
        "Installer Compliance Form: Attached Structures",
        "Installer Compliance Form: Continuous Load Path",
      ],
      completerRoles:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.installingContractorConfirmationForm?.completerRoles || [],
      completerRoles_AsString:
        homeStandardEvaluation?.homeStandardEvaluation
          ?.installingContractorConfirmationForm?.completerRoles_AsString || [],
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.installingContractorConfirmationForm,
    },
  ];

  const baseTab: ChecklistTabItem[] = [{ id: 0, label: "All" }];

  const tabs: ChecklistTabItem[] = [
    { id: 1, label: "FH Evaluator", role: UserRole["FH Evaluator"] },
    { id: 2, label: "FH Roofer", role: UserRole["FH Roofer"] },
    { id: 3, label: "FH Engineer", role: UserRole["FH Engineer"] },
    { id: 4, label: "FH Contractor", role: UserRole["FH Contractor"] },
    { id: 5, label: "IBHS Admin", role: UserRole["IBHS Admin"] },
    {
      id: 6,
      label: "FH Evaluator Admin",
      role: UserRole["FH Evaluator Admin"],
    },
  ];

  const allTabs = baseTab.concat(tabs);

  // Find the selected tab by its ID
  const selectedUserRoleTab =
    allTabs.find((tab) => tab.id === selectedTabId) || baseTab[0];

  const getSelectedUserRoleEmailAddress = () => {
    switch (selectedUserRoleTab.role) {
      case UserRole["FH Roofer"]:
        return homeStandardEvaluation?.homeStandardEvaluation?.eligibilityForm
          ?.rooferEmail;

      case UserRole["FH Engineer"]:
        return homeStandardEvaluation?.homeStandardEvaluation?.eligibilityForm
          ?.engineerEmail;

      case UserRole["FH Contractor"]:
        return homeStandardEvaluation?.homeStandardEvaluation?.eligibilityForm
          ?.installingContractorEmail;

      default:
        return undefined;
    }
  };

  const selectedEmail = getSelectedUserRoleEmailAddress();

  const rooferNotifySubmitterLinks: NotifySubmitterFormLink[] = [
    {
      name: "Roofing Compliance Form: General Information",
      link: `${process.env.PUBLIC_URL}${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/roofing-compliance-general-information`,
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.roofingComplianceGeneralInformationForm,
    },
    {
      name: "Roof Cover Compliance Form",
      link: `${process.env.PUBLIC_URL}${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/roofing-compliance-roof-cover`,
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.roofingComplianceRoofCoverForm,
    },
    {
      name: "Roofer Confirmation",
      link: `${process.env.PUBLIC_URL}${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/roofer-confirmation`,
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation?.rooferConfirmationForm,
    },
  ];

  const engineerNotifySubmitterLinks: NotifySubmitterFormLink[] = [
    {
      name: "Engineering Compliance Form: General Information",
      link: `${process.env.PUBLIC_URL}${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/engineering-compliance-general-information`,
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceGeneralInformationForm,
    },
    {
      name: "Engineering Compliance Form: Gables",
      link: `${process.env.PUBLIC_URL}${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/engineering-compliance-gables`,
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceGablesForm,
    },
    {
      name: "Engineering Compliance Form: Chimney",
      link: `${process.env.PUBLIC_URL}${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/engineering-compliance-chimney`,
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceChimneyForm,
    },
    {
      name: "Engineering Compliance Form: Attached Structures",
      link: `${process.env.PUBLIC_URL}${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/engineering-compliance-attached-structure`,
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceAttachedStructureForm,
    },
    {
      name: "Engineering Compliance Form: Continuous Load Path",
      link: `${process.env.PUBLIC_URL}${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/engineering-compliance-continuous-load-path`,
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.engineeringComplianceContinuousLoadPathForm,
    },
    {
      name: "Engineer Confirmation",
      link: `${process.env.PUBLIC_URL}${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/engineer-confirmation`,
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.engineerConfirmationForm,
    },
  ];

  const contractorNotifySubmitterLinks: NotifySubmitterFormLink[] = [
    {
      name: "Installer Compliance Form: Gables",
      link: `${process.env.PUBLIC_URL}${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/installer-compliance-gables`,
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.installerComplianceGablesForm,
    },
    {
      name: "Installer Compliance Form: Chimney",
      link: `${process.env.PUBLIC_URL}${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/installer-compliance-chimney`,
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.installerComplianceChimneyForm,
    },
    {
      name: "Installer Compliance Form: Attached Structures",
      link: `${process.env.PUBLIC_URL}${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/installer-compliance-attached-structure`,
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.installerComplianceAttachedStructureForm,
    },
    {
      name: "Installer Compliance Form: Continuous Load Path",
      link: `${process.env.PUBLIC_URL}${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/installer-compliance-continuous-load-path`,
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.installerComplianceContinuousLoadPathForm,
    },
    {
      name: "Installing Contractor Confirmation",
      link: `${process.env.PUBLIC_URL}${fortifiedHomeStandardRoutePrefix}/${homeStandardEvaluation?.id}/installing-contractor-confirmation`,
      hidden:
        !homeStandardEvaluation?.homeStandardEvaluation
          ?.installingContractorConfirmationForm,
    },
  ];

  const getSelectedUserRoleFormLinks = () => {
    switch (selectedUserRoleTab.role) {
      case UserRole["FH Roofer"]:
        return rooferNotifySubmitterLinks;
      case UserRole["FH Engineer"]:
        return engineerNotifySubmitterLinks;
      case UserRole["FH Contractor"]:
        return contractorNotifySubmitterLinks;
      default:
        return [];
    }
  };

  const selectedUserRoleFormLinks = getSelectedUserRoleFormLinks();

  const isAllSelected = !selectedUserRoleTab || selectedUserRoleTab.id === 0;

  const filteredForms = forms.filter(
    (form) =>
      !form.hidden &&
      (isAllSelected ||
        form.completerRoles.includes(selectedUserRoleTab.role || 0))
  );

  const filteredTabs = allTabs.filter((tab) =>
    forms.some((form) => form.completerRoles.includes(tab.role || 0))
  );

  // Always include the "All" tab
  const visibleTabs = [baseTab[0], ...filteredTabs];

  return (
    <div>
      {!homeStandardEvaluation && <Loading />}
      {homeStandardEvaluation && (
        <ChecklistContainerV2
          evaluationId={props.evaluationId}
          filteredForms={filteredForms}
          selectedUserRoleFormLinks={selectedUserRoleFormLinks}
          hasFormsLoading={hasFormsLoading}
          setEvaluation={setHomeStandardEvaluation}
          fortifiedId={homeStandardEvaluation.fortifiedId || ""}
          buildingAddress_AsString={
            homeStandardEvaluation.buildingAddress_AsString
          }
          selectedEmail={selectedEmail}
          evaluationStep={homeStandardEvaluation?.step}
          selectedUserRoleTab={selectedUserRoleTab}
          shouldShowPaymentButton={
            homeStandardEvaluation.step === EvaluationStep.Payment &&
            homeStandardEvaluation.isPaymentApplicable
          }
          visibleTabs={visibleTabs}
          selectedTabId={selectedTabId}
          setSelectedTabId={setSelectedTabId}
          productTypeRoute={`${fortifiedHomeStandardRoutePrefix}`}
          getApplicationFeePaymentCreditDetails={
            homeStandardEvaluationAPIClient.getApplicationFeePaymentCreditDetails
          }
          productSubtype={ProductSubtypes.HomeStandardEvaluation}
        />
      )}
    </div>
  );
};

export default HomeStandardChecklist;
