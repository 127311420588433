import { Schema } from "@ucl/library";
import { useContext, useEffect, useState } from "react";
import wildfireBaseApiClient from "../../../../../../../wildfire/lib/wildfireBaseApiClient";
import { UnsavedChangesBlockerContext } from "../../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../../../../../common/types/Forms/FormChecklist";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../../types/HomeEvaluationFormModel";
import { HomeSchemaFactoryV2 } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { HomeStandardEligibilityFormContainerProps } from "./HomeStandardEligibilityFormContainer";
import { HomeEligibilityFormParts } from "../../../../../common/types/BaseHomeEvaluationEligibilityFormModel";
import { AppHeaderContext } from "../../../../../../../wildfire/components/Provider/AppHeaderContext";

function useHomeStandardEligibilityFormContainer(
  props: HomeStandardEligibilityFormContainerProps
) {
  const [schemaFactory, setSchemaFactory] = useState<HomeSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await wildfireBaseApiClient.get<Schema>(`/wildfire/schema`);
    const newSchemaFactory = new HomeSchemaFactoryV2(schema);
    setSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!schemaFactory) {
      getSchema();
    }
    return () => {
      setDefaultHeader();
    };
  }, []);

  useEffect(() => {
    setFormPart(getBestFormPart());
  }, [props.formPart]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const [formPart, setFormPart] = useState<
    HomeEligibilityFormParts | undefined
  >(props.formPart);

  const [formModel, setFormModel] =
    useState<Evaluation_HomeStandardEvaluationFormModel>();

  const { setFotifiedHomeEvaluationHeader, setDefaultHeader } =
    useContext(AppHeaderContext);

  useEffect(() => {
    if (formModel) {
      setFotifiedHomeEvaluationHeader(formModel);
    }
  }, [formModel]);

  const form = formModel?.homeStandardEvaluation.eligibilityForm;

  const getNextUnsubmittedFormPart = () => {
    if (!form) {
      return null;
    }

    if (!form.isDesignationTypeAndLevelInformationComplete) {
      return HomeEligibilityFormParts.DesignationTypeAndLevelInformation;
    } else if (!form.isEligibilityInformationComplete) {
      return HomeEligibilityFormParts.EligibilityInformation;
    }

    // Form is complete
    return null;
  };

  const getBestFormPart = (): HomeEligibilityFormParts => {
    // Default to the first part of the form if we can't find a better option
    const defaultFormPart = Object.values(HomeEligibilityFormParts)[0];

    // If the application form part is provided (by URL), use it
    if (!!props.formPart) {
      return props.formPart;
    }

    // Otherwise, try to find the first unsubmitted part of the form
    // TODO: This doesn't work yet, needs to wait for applicationFormModel to load
    return getNextUnsubmittedFormPart() ?? defaultFormPart;
  };

  const getApplicationPartChecklistStatus = (
    formPart: HomeEligibilityFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case HomeEligibilityFormParts.DesignationTypeAndLevelInformation:
        return form?.isDesignationTypeAndLevelInformationComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case HomeEligibilityFormParts.EligibilityInformation:
        return form?.isEligibilityInformationComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
    }
  };

  const checklistParts: FormChecklistPart<HomeEligibilityFormParts>[] = [
    {
      formPart: HomeEligibilityFormParts.DesignationTypeAndLevelInformation,
      displayName: "Designation Type And Level Information",
      requiredFieldCount:
        form?.designationTypeAndLevelInformationRequiredFieldCount,
    },
    {
      formPart: HomeEligibilityFormParts.EligibilityInformation,
      displayName: "Eligibility Information",
      requiredFieldCount: form?.eligibilityInformationRequiredFieldCount,
    },
  ];

  return {
    checklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel: setFormModel,
    setHasUnsavedChanges,
    formPart,
    getBestFormPart,
    schemaFactory,
  };
}

export default useHomeStandardEligibilityFormContainer;
