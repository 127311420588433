import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { BaseHomeEvaluationInstallerComplianceChimneyFormParts } from "../../../common/types/BaseHomeEvaluationInstallerComplianceChimneyFormModel";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";
import { HomeStandardEvaluationInstallerComplianceChimneyFormModel } from "../../types/HomeStandardEvaluationInstallerComplianceChimneyFormModel";

class HomeStandardEvaluationInstallerComplianceChimneyAPIClient {
  updateHomeStandardInstallerComplianceChimneyForm = async (
    evaluationId: string,
    installerComplianceChimneyForm: HomeStandardEvaluationInstallerComplianceChimneyFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/installer-compliance-chimney-form/${evaluationId}`,
      {
        installerComplianceChimneyForm,
        fieldKey,
      }
    );
  };

  submitHomeStandardInstallerComplianceChimneyForm = async (
    evaluationId: string,
    installerComplianceChimneyForm: HomeStandardEvaluationInstallerComplianceChimneyFormModel,
    formPart?: BaseHomeEvaluationInstallerComplianceChimneyFormParts
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationInstallerComplianceChimneyFormParts
      ).pop() as BaseHomeEvaluationInstallerComplianceChimneyFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/installer-compliance-chimney-form/${evaluationId}/${formPartValue}`,
      installerComplianceChimneyForm
    );
  };
}

export const homeStandardEvaluationInstallerComplianceChimneyAPIClient =
  new HomeStandardEvaluationInstallerComplianceChimneyAPIClient();
