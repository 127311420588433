import { Classes, Tab, Tabs } from "@blueprintjs/core";
import { PaymentCreditGrid } from "../components/PaymentCreditGrid/PaymentCreditGrid";
import { UserGrid } from "../components/UserGrid/UserGrid";
import { CommentTemplatesGrid } from "../components/CommentTemplatesGrid/CommentTemplatesGrid";

export interface AdminTabsProps {}

export const AdminTabs: React.FC<AdminTabsProps> = ({}) => {
  return (
    <Tabs
      large
      animate
      id="admin-tabs"
      renderActiveTabPanelOnly
      className={`${Classes.FOCUS_DISABLED} admin-tabs`}
    >
      <Tab id="users" title="Users" panel={<UserGrid />} />
      <Tab
        id="paymentcredits"
        title="Payment Credits"
        panel={<PaymentCreditGrid />}
      />
      <Tab
        id="comment-templates"
        title="Comment Templates"
        panel={<CommentTemplatesGrid />}
      />
    </Tabs>
  );
};
