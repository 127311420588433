import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import HomeStandardEngineerConfirmationFormContainer from "../components/Forms/EngineerConfirmationForm/Container/HomeStandardEngineerConfirmationFormContainer";
import { BaseHomeEvaluationEngineerConfirmationFormParts } from "../../common/types/BaseHomeEvaluationEngineerConfirmationFormModel";

export interface HomeStandardEngineerConfirmationPageProps {
  evaluationId?: string;
  engineerConfirmationFormPart?: BaseHomeEvaluationEngineerConfirmationFormParts;
}

const HomeStandardEngineerConfirmationPageComponent: FC<
  HomeStandardEngineerConfirmationPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeStandardEngineerConfirmationFormContainer
            evaluationId={props.evaluationId}
            engineerConfirmationFormPart={props.engineerConfirmationFormPart}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeStandardEngineerConfirmationPage = withAITracking(
  reactPlugin,
  HomeStandardEngineerConfirmationPageComponent,
  "HomeStandardEngineerConfirmationPage",
  "tracking-container"
);
