import React from "react";
import { parseDate } from "../../../../../common/utils/date";

// Define the prop types
interface EvaluationExpirationProps {
  isRedesignation: boolean;
  evaluationExpirationDateType_AsString?: string;
  expiresAt?: string;
}

// Functional component
const EvaluationExpiration: React.FC<EvaluationExpirationProps> = (props) => {
  if (
    props.isRedesignation &&
    props.evaluationExpirationDateType_AsString &&
    props.expiresAt
  ) {
    return (
      <div className="evaluation-expiration-container">
        <div className="evaluation-expiration-container__field">
          <div className="evaluation-expiration-container__label">
            Expiration Status:
          </div>
          <div>{props.evaluationExpirationDateType_AsString}</div>
        </div>
        <div className="evaluation-expiration-container__field">
          <div className="evaluation-expiration-container__label">
            Expiration Date:
          </div>
          <div>{parseDate(props.expiresAt)}</div>
        </div>
      </div>
    );
  } else {
    return null; // Render nothing if the conditions are not met
  }
};

export default EvaluationExpiration;
