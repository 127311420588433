import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts } from "../../common/types/BaseHomeEvaluationEngineeringComplianceGeneralInformationFormModel";
import HomeStandardEngineeringComplianceGeneralInformationFormContainer from "../components/Forms/EngineeringComplianceGeneralInformationForm/Container/HomeStandardEngineeringComplianceGeneralInformationFormContainer";

export interface HomeStandardEngineeringComplianceGeneralInformationPageProps {
  evaluationId?: string;
  engineeringComplianceGeneralInformationFormPart?: BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts;
}

const HomeStandardEngineeringComplianceGeneralInformationPageComponent: FC<
  HomeStandardEngineeringComplianceGeneralInformationPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeStandardEngineeringComplianceGeneralInformationFormContainer
            evaluationId={props.evaluationId}
            engineeringComplianceGeneralInformationFormPart={
              props.engineeringComplianceGeneralInformationFormPart
            }
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeStandardEngineeringComplianceGeneralInformationPage =
  withAITracking(
    reactPlugin,
    HomeStandardEngineeringComplianceGeneralInformationPageComponent,
    "HomeStandardEngineeringComplianceGeneralInformationPage",
    "tracking-container"
  );
