import baseApiClient from "../../../../../../foritfied/lib/apiClients/baseApiClient";
import { BaseHomeEvaluationRooferConfirmationFormParts } from "../../../../common/types/BaseHomeEvaluationRooferConfirmationFormModel";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../types/HomeNCIUAEvaluationFormModel";
import { HomeNCIUAEvaluationRooferConfirmationFormModel } from "../../types/HomeNCIUAEvaluationRooferConfirmationFormModel";

class HomeNCIUAEvaluationRooferConfirmationAPIClient {
  updateHomeNCIUARooferConfirmationForm = async (
    evaluationId: string,
    rooferConfirmationForm: HomeNCIUAEvaluationRooferConfirmationFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeNCIUAEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeNCIUAEvaluationFormModel>(
      `/home-nciua/roofer-confirmation-form/${evaluationId}`,
      {
        rooferConfirmationForm,
        fieldKey,
      }
    );
  };

  submitHomeNCIUARooferConfirmationForm = async (
    evaluationId: string,
    rooferConfirmationForm: HomeNCIUAEvaluationRooferConfirmationFormModel,
    formPart?: BaseHomeEvaluationRooferConfirmationFormParts
  ): Promise<Evaluation_HomeNCIUAEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationRooferConfirmationFormParts
      ).pop() as BaseHomeEvaluationRooferConfirmationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeNCIUAEvaluationFormModel>(
      `/home-nciua/roofer-confirmation-form/${evaluationId}/${formPartValue}`,
      rooferConfirmationForm
    );
  };
}

export const homeNCIUAEvaluationRooferConfirmationAPIClient =
  new HomeNCIUAEvaluationRooferConfirmationAPIClient();
