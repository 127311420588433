import { ContentExplorerProps, PreviewBoxFileEvent } from "box-ui-elements";
import { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPencil } from "@fortawesome/free-solid-svg-icons";
import ReactDOMServer from "react-dom/server";
import { getScrollContainer } from "../../../wildfire/utils/getScrollContainer";
import { ImageAnnotationEditorProps } from "./ImageAnnotationEditor";
import {
  closeImageAnnotationDialog,
  openImageAnnotationDialog,
} from "../../../wildfire/components/Dialogs/ImageAnnotation/ImageAnnotationDialog";

// For Javascript implemntation, see: https://github.com/box/box-content-preview
export interface BoxContentExplorerProps extends ContentExplorerProps {
  defaultToUpload?: boolean;
  hasAutoUpload?: boolean;
  hasModifiedUploadButton?: boolean;
}

export const BoxContentExplorer: React.FC<BoxContentExplorerProps> = (
  props
) => {
  const contentExplorerRef = useRef<HTMLDivElement>(null);
  const annotationEditorSettingsRef = useRef<ImageAnnotationEditorProps>();

  const containerClassName = `box-content-explorer-container-${props.rootFolderId}`;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const contentExplorer = new Box.ContentExplorer();

    loadContentExplorer(contentExplorer);

    if (props.defaultToUpload) {
      waitForReadyToSetUploadState();
    }

    if (props.hasAutoUpload) {
      waitForAutoUploadState();
    }

    if (props.hasModifiedUploadButton) {
      waitForUploadButton();
    }
  }, [
    props.token,
    props.rootFolderId,
    props.canDelete,
    props.canDownload,
    props.canPreview,
    props.canRename,
    props.canUpload,
    props.defaultToUpload,
  ]);

  const refreshContentExplorer = (contentExplorer: any) => {
    contentExplorer.hide();
    loadContentExplorer(contentExplorer);
  };

  const loadContentExplorer = (contentExplorer: any) => {
    contentExplorer.show(props.rootFolderId, props.token, {
      container: `.${containerClassName}`,
      ...props,
    });

    contentExplorer.addListener("preview", (data: PreviewBoxFileEvent) => {
      if (props.rootFolderId) {
        annotationEditorSettingsRef.current = {
          token: props.token,
          fileId: data.file.id,
          fileName: data.file.name,
          parentFolderId: props.rootFolderId,
          onSave: () => refreshContentExplorer(contentExplorer),
        };
      }

      //Only valid file types can be annotated
      const validFileExtensions = [".png", ".jpeg", ".jpg", ".svg"];

      const isValidFileType = validFileExtensions.some((ext) =>
        data.file.name.toLowerCase().endsWith(ext)
      );

      if (isValidFileType) {
        injectAnnotationButton();
      }
    });
  };

  const loadImageAnnotationDialog = () => {
    if (annotationEditorSettingsRef.current) {
      openImageAnnotationDialog(
        annotationEditorSettingsRef.current,
        closeImageAnnotationDialog
      );
    }
  };

  const waitForReadyToSetUploadState = () => {
    const observer: MutationObserver = new MutationObserver(() =>
      handleUploadMutation(observer)
    );
    observer.observe(contentExplorerRef.current as Node, {
      childList: true,
      subtree: true,
    });
  };

  const waitForUploadButton = () => {
    const observer: MutationObserver = new MutationObserver(() =>
      handleUploadButtonMutation(observer)
    );
    observer.observe(contentExplorerRef.current as Node, {
      childList: true,
      subtree: true,
    });
  };

  const waitForAutoUploadState = () => {
    const observer: MutationObserver = new MutationObserver(() => autoUpload());
    observer.observe(contentExplorerRef.current as Node, {
      childList: true,
      subtree: true,
    });
  };

  const handleUploadMutation = (observer: MutationObserver) => {
    if (setUploadState()) {
      observer.disconnect();
    }
  };

  const handleUploadButtonMutation = (observer: MutationObserver) => {
    if (injectUploadButtonText()) {
      observer.disconnect();
    }
  };

  // Returns the first parent of an element that has a scrollbar

  const setUploadState = (): boolean => {
    // When main Box Content Explorer container is rendered, it is ready to receive keyboard events
    const container = contentExplorerRef.current?.querySelector(
      `div.bce-content`
    ) as HTMLDivElement;

    if (container) {
      // Emulate the keyboard shortcut that navigates to the upload state
      container.dispatchEvent(
        new KeyboardEvent("keydown", { key: "g", bubbles: true })
      );
      container.dispatchEvent(
        new KeyboardEvent("keydown", { key: "u", bubbles: true })
      );
      // The above keyboard events were causing the page to scroll down, which we don't want
      // To fix this, we find the scroll container and set it to the top position
      const scrollableParent = getScrollContainer(
        contentExplorerRef.current as HTMLElement
      );
      scrollableParent?.scrollTo(0, 0);
      return true;
    }
    return false;
  };

  const autoUpload = () => {
    // Ensure contentExplorerRef and buttons are defined
    const buttons =
      contentExplorerRef.current?.querySelectorAll(`button.btn-primary`);

    if (!buttons) return;

    // Look for a button with the text "Upload" and ensure it's not disabled
    const uploadButton = Array.from(buttons).find((button) => {
      const buttonContent = button
        .querySelector("span.btn-content")
        ?.textContent?.trim();
      return (
        buttonContent === "Upload" &&
        button.getAttribute("aria-disabled") !== "true"
      );
    }) as HTMLButtonElement | undefined;

    if (uploadButton) {
      uploadButton.click();

      setTimeout(() => {
        autoClose();
      }, 5000);
    }
  };

  const autoClose = () => {
    const closeButtonContainer = contentExplorerRef.current?.querySelector(
      ".bcu-footer-left"
    ) as HTMLDivElement;
    const closeButton = closeButtonContainer?.querySelector(
      "button.btn span.btn-content"
    ) as HTMLSpanElement;

    if (
      closeButton &&
      closeButton.textContent === "Close" &&
      !closeButton.parentElement?.ariaDisabled &&
      closeButton.parentElement
    ) {
      closeButton.parentElement.click();
    }
  };

  const injectUploadButtonText = (): boolean => {
    const uploadButton = contentExplorerRef.current?.querySelector(
      `button.btn.be-btn-add`
    ) as HTMLButtonElement;
    if (uploadButton) {
      const span = uploadButton.querySelector(".btn-content");
      if (span && !span.textContent) {
        const iconHtml = ReactDOMServer.renderToString(
          <FontAwesomeIcon icon={faPlus} />
        );
        span.innerHTML += `${iconHtml} Upload`;

        return true;
      }
    }
    return false;
  };

  const injectAnnotationButton = (): boolean => {
    const INJECTED_BUTTON_CLASS = "be-btn-annotate";
    const PREVIEW_HEADER_SELECTOR = "div.bcpr-PreviewHeader-content";
    const PREVIEW_CONTROLS_SELECTOR = "div.bcpr-PreviewHeader-controls";

    const createAnnotateButton = (): HTMLButtonElement => {
      const annotateButton = document.createElement("button");
      annotateButton.className = `btn ${INJECTED_BUTTON_CLASS}`;
      annotateButton.type = "button";
      annotateButton.ariaLabel = "Draw";
      annotateButton.ariaHasPopup = "true";
      annotateButton.addEventListener("click", loadImageAnnotationDialog);

      const span = document.createElement("span");
      span.classList.add("btn-content");

      const icon = <FontAwesomeIcon icon={faPencil} />;
      const iconHtml = ReactDOMServer.renderToString(icon);
      span.innerHTML += `${iconHtml} Annotate`;

      annotateButton.appendChild(span);

      return annotateButton;
    };

    type OptionalHTMLDivElement = HTMLDivElement | undefined | null;
    const preview: OptionalHTMLDivElement =
      contentExplorerRef.current?.querySelector(PREVIEW_HEADER_SELECTOR);

    if (!preview || preview.querySelector(`.${INJECTED_BUTTON_CLASS}`)) {
      return false;
    }

    const headerRight: OptionalHTMLDivElement =
      preview.querySelector<HTMLDivElement>(PREVIEW_CONTROLS_SELECTOR);

    if (!headerRight) {
      return false;
    }

    const annotateButton = createAnnotateButton();
    headerRight.prepend(annotateButton);

    return true;
  };

  return (
    <div className="box-content-explorer">
      <div ref={contentExplorerRef} className={containerClassName}></div>
    </div>
  );
};
