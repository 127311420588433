import { Card } from "@blueprintjs/core";
import { ChecklistItemStatusV2 } from "./ChecklistItemStatus/ChecklistItemStatusV2";
import { ChecklistTabItem } from "./ChecklistContainerV2";
import { useNavigate } from "react-router";
import {
  SkeletonComponent,
  SkeletonType,
} from "@syncfusion/ej2-react-notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

export interface FormRowProps {
  formName: string;
  formURL: string;
  isCompleted: boolean;
  isDisabled: boolean;
  formsNeedingCompletion: string[];
  completerRoles: number[];
  completerRoles_AsString: string[];
  hidden?: boolean;
}

export interface ChecklistTabPanelProps {
  forms: FormRowProps[];
  selectedUserRoleTab: ChecklistTabItem;
  hasFormsLoading: boolean;
}

const ChecklistTabPanelV2: React.FC<ChecklistTabPanelProps> = (props) => {
  const navigate = useNavigate();

  return (
    <div className="form-tab-v2">
      <Card>
        <table>
          <thead>
            <tr className="form-tab__header">
              <td>View</td>
              <td>Form</td>
              <td>Submitter</td>
              <td className="form-tab__status">Status</td>
            </tr>
          </thead>
          <tbody>
            {props.forms.map((form, idx) => (
              <tr key={idx}>
                <td>
                  <div className="view-form">
                    {!form.isDisabled && (
                      <FontAwesomeIcon
                        className="view-icon"
                        icon={faEye}
                        onClick={() => navigate(form.formURL)}
                      />
                    )}
                  </div>
                </td>
                <td>
                  <div>
                    <span>{form.formName}</span>
                  </div>
                </td>
                <td className="submitter">
                  {form.completerRoles_AsString.map(
                    (completerRole) => completerRole
                  )}
                </td>
                <td className="form-tab__status">
                  <ChecklistItemStatusV2
                    isCompleted={form.isCompleted}
                    isDisabled={form.isDisabled}
                    formsNeedingCompletion={form.formsNeedingCompletion}
                  />
                </td>
              </tr>
            ))}
            {props.hasFormsLoading && (
              <tr className="checklist-loading">
                <td colSpan={4}>
                  <SkeletonComponent
                    className="loading-skeleton"
                    type={SkeletonType.Rectangle}
                  >
                    <span>Loading...</span>
                  </SkeletonComponent>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Card>
    </div>
  );
};

export default ChecklistTabPanelV2;
