import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts } from "../../../common/types/BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormModel";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";
import { HomeStandardEvaluationSilverGoldComplianceGeneralInformationFormModel } from "../../types/HomeStandardEvaluationSilverGoldComplianceGeneralInformationFormModel";

class HomeStandardEvaluationSilverGoldComplianceGeneralInformationAPIClient {
  updateHomeStandardSilverGoldComplianceGeneralInformationForm = async (
    evaluationId: string,
    silverGoldComplianceGeneralInformationForm: HomeStandardEvaluationSilverGoldComplianceGeneralInformationFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/silver-gold-compliance-general-information-form/${evaluationId}`,
      {
        silverGoldComplianceGeneralInformationForm,
        fieldKey,
      }
    );
  };

  submitHomeStandardSilverGoldComplianceGeneralInformationForm = async (
    evaluationId: string,
    silverGoldComplianceGeneralInformationForm: HomeStandardEvaluationSilverGoldComplianceGeneralInformationFormModel,
    formPart?: BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts
      ).pop() as BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/silver-gold-compliance-general-information-form/${evaluationId}/${formPartValue}`,
      silverGoldComplianceGeneralInformationForm
    );
  };
}

export const homeStandardEvaluationSilverGoldComplianceGeneralInformationAPIClient =
  new HomeStandardEvaluationSilverGoldComplianceGeneralInformationAPIClient();
