import { PrimaryButton } from "@ucl/library";
import { useState } from "react";
import { wildfireApplicationApiClient } from "../../../lib/apiClients/evaluation/wildfireApplicationApiClient";

interface ResubmitEvalautionButtonProps {
  evaluationId: string;
  refresh: () => Promise<void>;
}

const ResubmitEvalautionButton: React.FC<ResubmitEvalautionButtonProps> = (
  props
) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  return (
    <PrimaryButton
      content="Re-open Application"
      isLoading={isSubmitting}
      loadingSuccessContent="Application Re-opened"
      onClick={async () => {
        setIsSubmitting(true);
        await wildfireApplicationApiClient
          .resubmitEvaluation(props.evaluationId)
          .then(() => {
            props.refresh();
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      }}
    ></PrimaryButton>
  );
};

export default ResubmitEvalautionButton;
