import { ReactNode } from "react";
import parse from "html-react-parser";
import DOMPurify, { sanitize } from "dompurify";

// 12/4/24 - CB
// NOTE: Adding `target="_blank"` after sanitization was flagged as a potential security risk.
// DOMPurify explicitly advises against modifying attributes post-sanitization, as this can introduce vulnerabilities.
// This implementation could potentially expose the application to XSS attacks.
// If a security issue arises, please review this code and consider removing or revising it.
// IBHS wants this feature to be implemented even after being told the security risk.

// Hook to ensure that <a> tags all open in a new tab by default
DOMPurify.addHook("afterSanitizeAttributes", (node) => {
  if (node.tagName === "A") {
    // Add or ensure target="_blank"
    node.setAttribute("target", "_blank");

    // Add or ensure rel="noopener noreferrer"
    const existingRel = node.getAttribute("rel") || "";
    const newRel = existingRel
      .split(" ")
      .filter(Boolean)
      .concat(["noopener", "noreferrer"])
      .filter((value, index, self) => self.indexOf(value) === index) // Deduplicate
      .join(" ");
    node.setAttribute("rel", newRel);
  }
});

export const sanitizeHtml = (html: string): string => {
  return sanitize(html, {
    USE_PROFILES: { html: true },
  });
};

export const parseUntrustedHtml = (html: string): ReactNode => {
  return parse(sanitizeHtml(html));
};

export const parseUntrustedHtmlAsPlaintext = (html: string): ReactNode => {
  return parse(
    sanitize(html, {
      ALLOWED_TAGS: [],
      ALLOWED_ATTR: [],
      KEEP_CONTENT: true,
    })
  );
};
