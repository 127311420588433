import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts } from "../../../common/types/BaseHomeEvaluationRoofingComplianceGeneralInformationFormModel";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";
import { HomeStandardEvaluationRoofingComplianceGeneralInformationFormModel } from "../../types/HomeStandardEvaluationRoofingComplianceGeneralInformationFormModel";

class HomeStandardEvaluationRoofingComplianceGeneralInformationAPIClient {
  updateHomeStandardRoofingComplianceGeneralInformationForm = async (
    evaluationId: string,
    form: HomeStandardEvaluationRoofingComplianceGeneralInformationFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/roofing-compliance-general-information-form/${evaluationId}`,
      {
        roofingComplianceGeneralInformationForm: form,
        fieldKey,
      }
    );
  };

  submitHomeStandardRoofingComplianceGeneralInformationForm = async (
    evaluationId: string,
    form: HomeStandardEvaluationRoofingComplianceGeneralInformationFormModel,
    formPart?: BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts
      ).pop() as BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/roofing-compliance-general-information-form/${evaluationId}/${formPartValue}`,
      form
    );
  };
}

export const homeStandardEvaluationRoofingComplianceGeneralInformationAPIClient =
  new HomeStandardEvaluationRoofingComplianceGeneralInformationAPIClient();
