import {
  EnumSingleSelectFieldProps,
  FieldFormV2,
  FieldFormV2Module,
  FieldSchemaFormBuilderV2,
  Footer,
} from "@ucl/library";
import { FC, useRef, useState } from "react";
import { EvaluationCompanies } from "../../../types/FormFields";
import { Intent } from "@blueprintjs/core";
import { AppToaster } from "../../../../foritfied/components/Toast/Toast";
import { closeEvaluatorAssignmentDialog } from "./EvaluatorAssignmentDialog";
import { Option } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import WildfireSecondaryButton from "../../Button/DialogFooterButtons/SecondaryButton/SecondaryButton";
import WildfireSubmitButton from "../../Button/DialogFooterButtons/SubmitButton/SubmitButton";
import { wildfireApplicationApiClient } from "../../../lib/apiClients/evaluation/wildfireApplicationApiClient";

export interface EvaluatorAssignmentFormProps {
  evaluationId: string;
  evaluationCompanies: Option<number>[];
  onSubmit?: () => void;
}

export interface EvaluatorAssignmentFormModel {
  evaluationCompany: number;

  availableEvaluationCompanies: Option<number>[];
}

const evaluatorAssignmentFormBuilder: FieldSchemaFormBuilderV2<
  EvaluatorAssignmentFormModel
> = (onValueChange, form, _) => {
  return [
    {
      type: "EnumSingleSelect",
      fieldName: "evaluationCompanySelection",
      fieldKey: "evaluationCompanySelection",
      label: "Evaluation Company",
      placeholder: "Please select an option",
      noResultsLabel: "Item not found",
      value: form.evaluationCompany,
      optionValues: form.availableEvaluationCompanies,
      onSubmit: function (value) {
        onValueChange(
          { ...form, evaluationCompany: value as number },
          value,
          this
        );
      },
    } as EnumSingleSelectFieldProps,
  ];
};

export const EvaluatorAssignmentForm: FC<EvaluatorAssignmentFormProps> = ({
  evaluationId,
  evaluationCompanies,
  onSubmit,
}: EvaluatorAssignmentFormProps) => {
  const evaluatorAssignmentFormRef =
    useRef<FieldFormV2Module<EvaluatorAssignmentFormModel>>(null);

  const [evaluatorAssignmentFormDefaults] =
    useState<EvaluatorAssignmentFormModel>({
      evaluationCompany: EvaluationCompanies.IBHS.valueOf(),
      availableEvaluationCompanies: evaluationCompanies,
    });

  const handleFormSubmit = async (form: EvaluatorAssignmentFormModel) => {
    await wildfireApplicationApiClient
      .submitEvaluatorAssignment(evaluationId, form)
      .then(async () => {
        AppToaster.show({
          message: "Successfully assigned Evaluation Company",
          intent: Intent.SUCCESS,
        });
        closeEvaluatorAssignmentDialog(evaluationId);
        onSubmit?.();
      })
      .catch((error) => {
        AppToaster.show({
          message: "Failed to submit evaluator assignment",
          intent: Intent.DANGER,
        });
        console.error(error);
      });
  };

  return (
    <>
      <FieldFormV2<EvaluatorAssignmentFormModel>
        ref={evaluatorAssignmentFormRef}
        value={evaluatorAssignmentFormDefaults}
        fieldSchemaFormBuilder={evaluatorAssignmentFormBuilder}
        onFormSubmit={async (form) => {
          await handleFormSubmit(form);
        }}
      />
      <Footer>
        <WildfireSecondaryButton
          buttonText="Cancel"
          onClick={() => {
            closeEvaluatorAssignmentDialog(evaluationId);
          }}
        />
        <WildfireSubmitButton
          buttonText="Send"
          onClick={() => {
            evaluatorAssignmentFormRef.current?.submit();
          }}
        />
      </Footer>
    </>
  );
};
