import {
  FieldFormV2,
  FieldSchemaFormBuilderV2,
  RelativityTooltip,
} from "@ucl/library";
import { Button, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { useNavigate } from "react-router";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../../types/HomeEvaluationFormModel";
import { fortifiedHomeStandardRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts } from "../../../../../common/types/BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormModel";
import useHomeStandardEvaluationSilverGoldComplianceGeneralInformationForm from "./useHomeStandardEvaluationSilverGoldComplianceGeneralInformationForm";
import { FormChecklistPart } from "../../../../../../../common/types/Forms/FormChecklist";
import classNames from "classnames";

export interface HomeStandardEvaluationSilverGoldComplianceGeneralInformationFormProps {
  evaluationId: string;
  isIterationEnginePage?: boolean;
  formPart?: BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts;
  onFormSave?: (fieldKey: string, value: any) => Promise<void>;
  setFormModel?: (
    formModel: Evaluation_HomeStandardEvaluationFormModel
  ) => void;
  disableForm?: boolean;
  onInitialized?: () => void;
  setHasUnsavedChanges: (value: boolean) => void;
  hideFormControls?: boolean;
  applicationChecklistParts?: FormChecklistPart<BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts>[];
}

export const HomeStandardEvaluationSilverGoldComplianceGeneralInformationForm =
  (
    props: HomeStandardEvaluationSilverGoldComplianceGeneralInformationFormProps
  ) => {
    const {
      formRef,
      isLoading,
      isSubmitting,
      setIsSubmitting,
      applicationFormModel,
      isFirstPartOfForm,
      isLastPartOfForm,
      getApplicationFormPartBuilder,
      handleFormSubmit,
      onFormFieldChange,
      noBuilders,
      containerRef,
      setShouldSubmit,
      areAllRequiredFieldsComplete,
    } =
      useHomeStandardEvaluationSilverGoldComplianceGeneralInformationForm(
        props
      );

    const navigate = useNavigate();

    return (
      <div ref={containerRef} className="wildfire-form-container">
        {noBuilders || isLoading ? (
          <Loading />
        ) : !applicationFormModel ? (
          <NonIdealState />
        ) : (
          <>
            <FieldFormV2<Evaluation_HomeStandardEvaluationFormModel>
              isDisabled={isSubmitting || props.disableForm}
              value={applicationFormModel}
              fieldSchemaFormBuilder={
                getApplicationFormPartBuilder() as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>
              }
              onFormSubmit={async (form) => {
                await handleFormSubmit(form);
              }}
              ref={formRef}
              onFieldChanged={async (form, value, fieldProps) => {
                await onFormFieldChange(form, value, fieldProps);
              }}
            />
            {!props.hideFormControls && (
              <div className="wildfire-form-footer">
                {!isFirstPartOfForm && (
                  <Button
                    className={
                      "wildfire-form-back-button " +
                      "wildfire-form-navigation-button"
                    }
                    text="Back"
                    icon={IconNames.ARROW_LEFT}
                    onClick={() => {
                      // Navigate to the previous visible form part
                      const currentFormPartIndex = Object.values(
                        BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts
                      ).indexOf(props.formPart!);

                      // Find the previous visible form part
                      let previousFormPart = null;
                      for (let i = currentFormPartIndex - 1; i >= 0; i--) {
                        const formPart = Object.values(
                          BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts
                        )[i];

                        // Check if formChecklistParts exists and if the form part is hidden
                        const checklistPart =
                          props.applicationChecklistParts?.find(
                            (part) => part.formPart === formPart
                          );

                        // If formChecklistParts doesn't exist, assume the part is not hidden
                        if (!checklistPart || !checklistPart.hidden) {
                          previousFormPart = formPart;
                          break;
                        }
                      }

                      if (previousFormPart) {
                        navigate(
                          `${fortifiedHomeStandardRoutePrefix}/${props.evaluationId}/silver-gold-compliance-general-information/${previousFormPart}`
                        );
                      }
                    }}
                  />
                )}
                {!isLastPartOfForm && (
                  <RelativityTooltip shouldShowTooltip={false} content={""}>
                    <Button
                      text={"Continue"}
                      className={classNames({
                        "wildfire-form-submit-button":
                          areAllRequiredFieldsComplete,
                        "wildfire-form-wide-button":
                          areAllRequiredFieldsComplete,
                        "wildfire-form-continue-button":
                          !areAllRequiredFieldsComplete,
                        "wildfire-form-navigation-button":
                          !areAllRequiredFieldsComplete,
                      })}
                      rightIcon={IconNames.ARROW_RIGHT}
                      loading={isSubmitting}
                      onClick={() => {
                        if (formRef.current) {
                          props.setHasUnsavedChanges(false);
                          setShouldSubmit(true);
                          setIsSubmitting(true);
                          formRef.current.submit();
                        }
                      }}
                    />
                  </RelativityTooltip>
                )}
                {isLastPartOfForm && (
                  <RelativityTooltip shouldShowTooltip={false} content={""}>
                    <Button
                      className="wildfire-form-submit-button"
                      text={
                        "Submit Silver/Gold Compliance General Information Form"
                      }
                      rightIcon={IconNames.ARROW_RIGHT}
                      loading={isSubmitting}
                      onClick={() => {
                        if (formRef.current) {
                          props.setHasUnsavedChanges(false);
                          setShouldSubmit(true);
                          setIsSubmitting(true);
                          formRef.current.submit();
                        }
                      }}
                    />
                  </RelativityTooltip>
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

export default HomeStandardEvaluationSilverGoldComplianceGeneralInformationForm;
