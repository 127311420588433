import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts } from "../../common/types/BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormModel";
import HomeStandardSilverGoldComplianceGeneralInformationFormContainer from "../components/Forms/SilverGoldComplianceGeneralInformationForm/Container/HomeStandardSilverGoldComplianceGeneralInformationFormContainer";

export interface HomeStandardSilverGoldComplianceGeneralInformationPageProps {
  evaluationId?: string;
  silverGoldComplianceGeneralInformationFormPart?: BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts;
}

const HomeStandardSilverGoldComplianceGeneralInformationPageComponent: FC<
  HomeStandardSilverGoldComplianceGeneralInformationPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeStandardSilverGoldComplianceGeneralInformationFormContainer
            evaluationId={props.evaluationId}
            silverGoldComplianceGeneralInformationFormPart={
              props.silverGoldComplianceGeneralInformationFormPart
            }
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeStandardSilverGoldComplianceGeneralInformationPage =
  withAITracking(
    reactPlugin,
    HomeStandardSilverGoldComplianceGeneralInformationPageComponent,
    "HomeStandardSilverGoldComplianceGeneralInformationPage",
    "tracking-container"
  );
