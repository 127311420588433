export enum ProductTypes {
  FORTIFIEDCommercial = 1,
  FORTIFIEDMultifamily = 2,
  FORTIFIEDHome = 3,
  WildFirePrepared = 4,
}

export interface ProductTypeOption {
  label: string;
  value: ProductTypes;
}

export const ProductTypeOptions: ProductTypeOption[] = [
  {
    label: "FORTIFIED Commercial",
    value: ProductTypes.FORTIFIEDCommercial,
  },
  {
    label: "FORTIFIED Multifamily",
    value: ProductTypes.FORTIFIEDMultifamily,
  },
  {
    label: "FORTIFIED Home",
    value: ProductTypes.FORTIFIEDHome,
  },
  {
    label: "WildFire Prepared",
    value: ProductTypes.WildFirePrepared,
  },
];
