import { AdminUpdateFormModel } from "../../../components/Dialogs/AdminUpdate/AdminUpdateForm";
import { WildfireRejectionFormModel } from "../../../components/Dialogs/Rejection/WildfireRejectionForm";
import { WildfireEvaluation } from "../../../types/WildfireApplication/WildfireApplication";
import wildfireBaseApiClient from "../../wildfireBaseApiClient";

class WildfireLandscapeReviewApiClient {
  createWildfireLandscapeReview = async (originEvaluationId: string) => {
    return wildfireBaseApiClient.post<WildfireEvaluation>(
      `wildfire-landscape-review/${originEvaluationId}`
    );
  };

  // Workflow
  requestChanges = async (id: string) => {
    // Sends email only, doesn't change status
    return wildfireBaseApiClient.post(
      `wildfire-landscape-review/${id}/workflow/request-changes`
    );
  };

  approve = async (id: string) => {
    return wildfireBaseApiClient.post<WildfireEvaluation>(
      `wildfire-landscape-review/${id}/workflow/approve`
    );
  };

  reject = async (
    wildfireApplicationId: string,
    form: WildfireRejectionFormModel
  ) => {
    return wildfireBaseApiClient.post<WildfireEvaluation>(
      `wildfire-landscape-review/${wildfireApplicationId}/workflow/reject`,
      form
    );
  };

  closeEvaluation = async (evaluationId: string) => {
    return await wildfireBaseApiClient.put<WildfireEvaluation>(
      `wildfire-landscape-review/${evaluationId}/close`
    );
  };

  cancelEvaluation = async (evaluationId: string) => {
    return await wildfireBaseApiClient.put<WildfireEvaluation>(
      `wildfire-landscape-review/${evaluationId}/cancel`
    );
  };

  reopenEvaluation = async (evaluationId: string) => {
    return await wildfireBaseApiClient.put<WildfireEvaluation>(
      `wildfire-landscape-review/${evaluationId}/re-open`
    );
  };

  resubmitEvaluation = async (evaluationId: string) => {
    return await wildfireBaseApiClient.put<WildfireEvaluation>(
      `wildfire-landscape-review/${evaluationId}/resubmit`
    );
  };

  // Admin Update
  submitLandscapeReviewAdminUpdate = async (
    wildfireEvaluationId: string,
    form: AdminUpdateFormModel
  ) => {
    return wildfireBaseApiClient.post<WildfireEvaluation>(
      `/wildfire-landscape-review/${wildfireEvaluationId}/admin-update`,
      form
    );
  };

  // Payment
  getLandscapeReviewFeeStripeCheckoutLink = async (evaluationId: string) => {
    return await wildfireBaseApiClient.get<string>(
      `/wildfire-landscape-review/${evaluationId}/payment/stripe-checkout`
    );
  };
}

export const wildfireLandscapeReviewApiClient =
  new WildfireLandscapeReviewApiClient();
