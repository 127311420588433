import { computed, makeObservable } from "mobx";
import { ChangeRequestStatus } from "../types/evaluation/ChangeRequest";
import {
  Evaluation,
  EvaluationStep,
  ProductTypes,
} from "../types/evaluation/Evaluation";
import { evaluationChangeRequestStore } from "./EvaluationChangeRequestStore";
import { evaluationStore } from "./EvaluationStore";
import { fortifiedUserStore } from "./FortifiedUserStore";
import { EvaluationTypes } from "../types/evaluation/EvaluationTypes";

export class PermissionStore {
  constructor() {
    makeObservable(this, {
      canViewAuditorActionButtons: computed,
      canApprove: computed,
      canRequestChanges: computed,
      canReject: computed,
      canSendForward: computed,
      canViewSendForReview: computed,
      canSendForReviewByChangeRequests: computed,
      canViewSendForward: computed,
      canViewAdminButton: computed,
      canRegenerateCertificate: computed,
    });
  }

  get canViewAuditorActionButtons() {
    if (!evaluationStore.evaluation) {
      return false;
    }

    if (evaluationStore.doesEvaluationHaveFinalDetermination) {
      return false;
    }

    switch (evaluationStore.evaluation.step) {
      case EvaluationStep.EligibilityReview:
      case EvaluationStep.FinalReview:
        return (
          fortifiedUserStore.hasRole("FC/FMF Auditor") ||
          fortifiedUserStore.hasRole("FH Auditor") ||
          fortifiedUserStore.hasRole("IBHS Admin") ||
          fortifiedUserStore.hasRole("Relativity Admin") ||
          fortifiedUserStore.hasRole("FC/FMF Admin")
        );
      case EvaluationStep.ComplianceFormSubmitted:
      case EvaluationStep.FieldInspectionsSubmitted:
        return (
          fortifiedUserStore.hasRole("FC/FMF Evaluator") ||
          fortifiedUserStore.hasRole("IBHS Admin") ||
          fortifiedUserStore.hasRole("Relativity Admin") ||
          fortifiedUserStore.hasRole("FC/FMF Admin")
        );
      default:
        return false;
    }
  }

  get canCreateBoardComments() {
    return !fortifiedUserStore.isAuditorTrainee;
  }

  get canViewCommentModeSelector() {
    return (
      fortifiedUserStore.hasRole("FH Auditor") ||
      fortifiedUserStore.hasRole("IBHS Admin") ||
      fortifiedUserStore.hasRole("Relativity Admin")
    );
  }

  canDeleteFilesFromUploader(itemDataId: string): boolean {
    // Don't allow file deletion by FH Evaluators when Evaluation status is "Changes Required"
    return !(
      evaluationChangeRequestStore.getFieldChangeRequestState(itemDataId)
        .isActiveAndUnresolved &&
      (fortifiedUserStore.hasRole("FH Evaluator") ||
        fortifiedUserStore.hasRole("FH Evaluator Admin"))
    );
  }

  canDeleteFilesFromUploaderV2(step: EvaluationStep | undefined): boolean {
    return (
      // Allow deletion by Relativity Admins at any time
      fortifiedUserStore.hasRole("Relativity Admin") ||
      fortifiedUserStore.hasRole("IBHS Admin") ||
      // Allow deletion by FH Evaluators prior to IBHS Review
      ((fortifiedUserStore.hasRole("FH Evaluator") ||
        fortifiedUserStore.hasRole("FH Evaluator Admin")) &&
        !!step &&
        step !== EvaluationStep.FinalReview &&
        step !== EvaluationStep.CertificateFee)
    );
  }

  get canApprove() {
    if (
      evaluationStore.isComplexCommercialEvaluationBlockedAtFinalApprovalByBuilding
    ) {
      return false;
    }

    const allChangeRequestsResolved =
      evaluationChangeRequestStore.changeRequests.every(
        (c) => c.status === ChangeRequestStatus.Resolved
      );

    return (
      this.canViewAuditorActionButtons &&
      allChangeRequestsResolved &&
      this.doesProductTypeAuditorActionsLogicPass
    );
  }

  get doesProductTypeAuditorActionsLogicPass() {
    // Buttons are enabled WHEN Evaluation is claimed && Product = Home

    switch (evaluationStore.evaluationProductType) {
      case 3:
        return this.isEvaluationClaimedAndProductTypeIsHome;
      default: {
        return true;
      }
    }
  }

  get isEvaluationClaimedAndProductTypeIsHome() {
    return evaluationStore.isEvaluationClaimed;
  }

  get canRequestChanges() {
    const hasSomeUnresolvedChangeRequests =
      evaluationChangeRequestStore.unresolvedChangeRequest.length > 0;

    return (
      this.canViewAuditorActionButtons &&
      hasSomeUnresolvedChangeRequests &&
      this.doesProductTypeAuditorActionsLogicPass
    );
  }

  get canReject() {
    return (
      this.canViewAuditorActionButtons &&
      this.doesProductTypeAuditorActionsLogicPass
    );
  }

  get canViewSendForReview() {
    if (!evaluationStore.evaluation) {
      return false;
    }

    if (evaluationStore.doesEvaluationHaveFinalDetermination) {
      return false;
    }

    switch (evaluationStore.evaluation.step) {
      case EvaluationStep.EligibilityReview:
      case EvaluationStep.FinalReview:
        return (
          fortifiedUserStore.hasRole("FC/FMF Applicant") ||
          fortifiedUserStore.hasRole("FC/FMF Evaluator") ||
          fortifiedUserStore.hasRole("FH Evaluator") ||
          fortifiedUserStore.hasRole("FH Direct Submit Roofer") ||
          fortifiedUserStore.hasRole("FH Evaluator Admin") ||
          fortifiedUserStore.hasRole("FH Engineer") ||
          fortifiedUserStore.hasRole("FH Contractor") ||
          fortifiedUserStore.hasRole("FH Roofer") ||
          fortifiedUserStore.hasRole("FC/FMF Admin")
        );
      case EvaluationStep.ComplianceFormSubmitted:
      case EvaluationStep.FieldInspectionsSubmitted:
        return fortifiedUserStore.hasRole("FC/FMF Applicant");
      default:
        return false;
    }
  }

  get canSendForReview() {
    const hasStepOverride =
      evaluationStore.evaluation?.step ===
      EvaluationStep.ComplianceFormSubmitted;

    return (
      hasStepOverride ||
      (this.canViewSendForReview && this.canSendForReviewByChangeRequests)
    );
  }

  get canSendForReviewByChangeRequests() {
    return (
      evaluationChangeRequestStore.addressedAtLeastOneChangeRequest &&
      evaluationChangeRequestStore.hasNoUnaddressedChangeRequests
    );
  }

  get canViewPendingLabels() {
    return (
      evaluationChangeRequestStore.changeRequests.length === 0 ||
      evaluationChangeRequestStore.hasNoUnaddressedChangeRequests
    );
  }

  get canViewSendForwardV2() {
    if (!evaluationStore.evaluation) {
      return false;
    }

    if (evaluationStore.doesEvaluationHaveFinalDetermination) {
      return false;
    }

    return (
      fortifiedUserStore.hasRole("FC/FMF Applicant") ||
      fortifiedUserStore.hasRole("IBHS Admin") ||
      fortifiedUserStore.hasRole("Relativity Admin") ||
      fortifiedUserStore.hasRole("FC/FMF Admin")
    );
  }

  get canViewSendForward() {
    if (!evaluationStore.evaluation) {
      return false;
    }

    if (evaluationStore.doesEvaluationHaveFinalDetermination) {
      return false;
    }

    switch (evaluationStore.evaluation.step) {
      case EvaluationStep.EvaluatorAssignment:
        return (
          fortifiedUserStore.hasRole("FC/FMF Applicant") ||
          fortifiedUserStore.hasRole("IBHS Admin") ||
          fortifiedUserStore.hasRole("Relativity Admin") ||
          fortifiedUserStore.hasRole("FC/FMF Admin")
        );
      case EvaluationStep.EvaluatorComplianceLetterSubmitted:
        return (
          fortifiedUserStore.hasRole("FC/FMF Evaluator") ||
          fortifiedUserStore.hasRole("IBHS Admin") ||
          fortifiedUserStore.hasRole("Relativity Admin") ||
          fortifiedUserStore.hasRole("FC/FMF Admin")
        );
      default:
        return false;
    }
  }

  get canSendForward() {
    return this.canViewSendForward;
  }

  get canCreateNewEvaluation() {
    return (
      fortifiedUserStore.hasRole("FC/FMF Applicant") ||
      fortifiedUserStore.hasRole("FH Evaluator") ||
      fortifiedUserStore.hasRole("FH Direct Submit Roofer") ||
      fortifiedUserStore.hasRole("IBHS Admin") ||
      fortifiedUserStore.hasRole("FH Evaluator Admin") ||
      fortifiedUserStore.hasRole("Relativity Admin")
    );
  }

  get shouldShowCertificate() {
    return (
      evaluationStore.evaluation?.isApproved &&
      (evaluationStore.evaluation.productType === 2 ||
        (evaluationStore.evaluation.productType === 1 &&
          evaluationStore.evaluation?.buildingState_AsString.indexOf(
            "Alabama"
          ) > 0))
    );
  }

  get canClaimEvaluations() {
    if (!fortifiedUserStore.user) {
      return false;
    }

    return fortifiedUserStore.user.canClaimEvaluations;
  }

  // Page Permissions
  get canViewReportsPage() {
    return (
      fortifiedUserStore.hasRole("IBHS Admin") ||
      fortifiedUserStore.hasRole("FC/FMF Auditor") ||
      fortifiedUserStore.hasRole("FH Auditor") ||
      fortifiedUserStore.hasRole("Relativity Admin") ||
      fortifiedUserStore.hasRole("NCIUA Partner") ||
      fortifiedUserStore.hasRole("FC/FMF Admin")
    );
  }

  get canViewAdminPage() {
    return (
      fortifiedUserStore.hasRole("IBHS Admin") ||
      fortifiedUserStore.hasRole("FC/FMF Auditor") ||
      fortifiedUserStore.hasRole("FH Auditor") ||
      fortifiedUserStore.hasRole("Relativity Admin") ||
      fortifiedUserStore.hasRole("FC/FMF Admin")
    );
  }

  get canEditUsers() {
    return (
      fortifiedUserStore.hasRole("IBHS Admin") ||
      fortifiedUserStore.hasRole("FC/FMF Auditor") ||
      fortifiedUserStore.hasRole("Relativity Admin") ||
      fortifiedUserStore.hasRole("FC/FMF Admin")
    );
  }

  get canDeleteEvaluations() {
    return (
      fortifiedUserStore.hasRole("IBHS Admin") ||
      fortifiedUserStore.hasRole("Relativity Admin")
    );
  }

  get canCreatePaymentCredits() {
    return (
      fortifiedUserStore.hasRole("IBHS Admin") ||
      fortifiedUserStore.hasRole("FC/FMF Auditor") ||
      fortifiedUserStore.hasRole("FH Auditor") ||
      fortifiedUserStore.hasRole("Relativity Admin") ||
      fortifiedUserStore.hasRole("FC/FMF Admin")
    );
  }

  get canSelectPaymentCreditType() {
    return (
      fortifiedUserStore.hasRole("IBHS Admin") ||
      fortifiedUserStore.hasRole("Relativity Admin")
    );
  }

  get canUpdatePaymentCredits() {
    return (
      fortifiedUserStore.hasRole("IBHS Admin") ||
      fortifiedUserStore.hasRole("FC/FMF Auditor") ||
      fortifiedUserStore.hasRole("FH Auditor") ||
      fortifiedUserStore.hasRole("Relativity Admin") ||
      fortifiedUserStore.hasRole("FC/FMF Admin")
    );
  }

  canWithdraw(evaluation?: Evaluation) {
    return (
      (evaluation?.canWithdraw &&
        (fortifiedUserStore.hasRole("IBHS Admin") ||
          fortifiedUserStore.hasRole("FH Evaluator Admin") ||
          fortifiedUserStore.hasRole("FH Evaluator") ||
          fortifiedUserStore.hasRole("FH Direct Submit Roofer") ||
          fortifiedUserStore.hasRole("FC/FMF Applicant") ||
          fortifiedUserStore.hasRole("Relativity Admin"))) ||
      fortifiedUserStore.hasRole("FC/FMF Admin")
    );
  }

  get canViewLegacyEvaluationFiles() {
    return (
      fortifiedUserStore.hasRole("IBHS Admin") ||
      fortifiedUserStore.hasRole("FC/FMF Auditor") ||
      fortifiedUserStore.hasRole("FH Auditor") ||
      fortifiedUserStore.hasRole("FH Auditor Trainee") ||
      fortifiedUserStore.hasRole("Relativity Admin") ||
      fortifiedUserStore.hasRole("FC/FMF Evaluator") ||
      fortifiedUserStore.hasRole("FH Evaluator") ||
      fortifiedUserStore.hasRole("FH Evaluator Admin") ||
      fortifiedUserStore.hasRole("FC/FMF Admin")
    );
  }

  get canViewAdminButton() {
    return (
      fortifiedUserStore.hasRole("IBHS Admin") ||
      fortifiedUserStore.hasRole("Relativity Admin") ||
      fortifiedUserStore.hasRole("FH Auditor") ||
      fortifiedUserStore.hasRole("FH Evaluator Admin") ||
      fortifiedUserStore.hasRole("FC/FMF Admin") ||
      fortifiedUserStore.hasRole("NCIUA Partner")
    );
  }

  get canViewUpdateEvaluatorAdminButton() {
    return (
      fortifiedUserStore.user?.canUpdateEvaluatorFromAdmin ||
      (fortifiedUserStore.hasRole("NCIUA Partner") &&
        (evaluationStore.evaluation?.step === EvaluationStep.Application ||
          evaluationStore.evaluation?.step ===
            EvaluationStep.EligibilityReview ||
          evaluationStore.evaluation?.step ===
            EvaluationStep.ApplicationForms ||
          evaluationStore.evaluation?.step === EvaluationStep.Payment))
    );
  }

  get canViewUpdateAuditorAdminButton() {
    return (
      fortifiedUserStore.hasRole("IBHS Admin") ||
      fortifiedUserStore.hasRole("Relativity Admin")
    );
  }

  get canViewUpdateNewRoofAdminButton() {
    return (
      fortifiedUserStore.hasRole("IBHS Admin") ||
      fortifiedUserStore.hasRole("Relativity Admin") ||
      fortifiedUserStore.hasRole("FH Auditor")
    );
  }

  get canViewUpdateBuildingAddressAdminButton() {
    return (
      fortifiedUserStore.hasRole("IBHS Admin") ||
      fortifiedUserStore.hasRole("Relativity Admin") ||
      fortifiedUserStore.hasRole("FH Auditor")
    );
  }

  get canViewUpdateDesignationLevelAdminButton() {
    return (
      fortifiedUserStore.hasRole("IBHS Admin") ||
      fortifiedUserStore.hasRole("Relativity Admin")
    );
  }

  get canViewUpdateHazardAdminButton() {
    return (
      fortifiedUserStore.hasRole("FH Auditor") ||
      fortifiedUserStore.hasRole("IBHS Admin") ||
      fortifiedUserStore.hasRole("Relativity Admin")
    );
  }

  get canViewUpdateStatusAdminButton() {
    return (
      fortifiedUserStore.hasRole("IBHS Admin") ||
      fortifiedUserStore.hasRole("Relativity Admin") ||
      fortifiedUserStore.hasRole("FC/FMF Admin")
    );
  }

  get canViewUpdateApprovedDateAdminButton() {
    return (
      fortifiedUserStore.hasRole("IBHS Admin") ||
      fortifiedUserStore.hasRole("Relativity Admin") ||
      fortifiedUserStore.hasRole("FC/FMF Admin")
    );
  }

  get canViewUpdateExpirationDateAdminButton() {
    return (
      fortifiedUserStore.hasRole("IBHS Admin") ||
      fortifiedUserStore.hasRole("Relativity Admin") ||
      fortifiedUserStore.hasRole("FC/FMF Admin") ||
      fortifiedUserStore.hasRole("FH Auditor")
    );
  }

  get canRegenerateCertificate() {
    return (
      fortifiedUserStore.hasRole("Relativity Admin") ||
      fortifiedUserStore.hasRole("IBHS Admin") ||
      fortifiedUserStore.hasRole("FH Auditor") ||
      fortifiedUserStore.hasRole("FC/FMF Auditor") ||
      fortifiedUserStore.hasRole("FC/FMF Admin")
    );
  }

  get canViewFCFMFApprovalDocuments() {
    const isNotApplicant = !fortifiedUserStore.hasRole("FC/FMF Applicant");
    const isFeeExemptOrProcessed =
      // Exempt from payment
      evaluationStore.isRedesignationEvaluation ||
      evaluationStore.isEvaluationCreatorExemptFromPayment ||
      // Payment has been processed
      evaluationStore.hasFinalCertificateFeePaymentBeenProcessed;
    const isEligibleForCertificate =
      evaluationStore.isApproved && isFeeExemptOrProcessed;

    return isNotApplicant || isEligibleForCertificate;
  }

  get canDeletePostDesignationFiles() {
    return (
      fortifiedUserStore.hasRole("Relativity Admin") ||
      fortifiedUserStore.hasRole("IBHS Admin") ||
      fortifiedUserStore.hasRole("FH Auditor") ||
      fortifiedUserStore.hasRole("FC/FMF Admin")
    );
  }

  get canViewAuditorName() {
    return !(
      fortifiedUserStore.hasRole("FC/FMF Evaluator") ||
      fortifiedUserStore.hasRole("FH Evaluator") ||
      fortifiedUserStore.hasRole("FH Evaluator Admin")
    );
  }

  get canViewBidsPage() {
    return fortifiedUserStore.hasRole("FC/FMF Applicant");
  }

  get shouldNavigateToBidsPage() {
    return (
      evaluationStore.evaluation?.step === EvaluationStep.EvaluatorAssignment &&
      !evaluationStore.evaluation.hasAnEvaluationBidBeenAccepted &&
      !evaluationStore.evaluation.isComplexCommercialEvaluationBuilding &&
      this.canViewBidsPage &&
      (evaluationStore.evaluation.productType ===
        ProductTypes.FortifiedCommercial ||
        evaluationStore.evaluation.productType ===
          ProductTypes.FortifiedMultifamily)
    );
  }

  get getProductTypeByUserRole() {
    switch (fortifiedUserStore.user?.role) {
      case "FC/FMF Applicant":
      case "FC/FMF Admin":
        return EvaluationTypes.Commercial;
      case "FH Evaluator":
      case "FH Direct Submit Roofer":
      default:
        return EvaluationTypes.Home;
    }
  }
}

export const permissionStore = new PermissionStore();
