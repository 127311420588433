import React from "react";
import "./styles.scss";
import { Evaluation_HomeStandardEvaluation } from "../../../standard/types/HomeEvaluation";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../standard/types/HomeEvaluationFormModel";
import { Evaluation_HomeStandardRedesignationFormModel } from "../../../standardRedesignation/types/HomeStandardRedesignationFormModel_Model";
import { Evaluation_HomeStandardRedesignation } from "../../../standardRedesignation/types/HomeStandardRedesignation";
import { Evaluation_HomeNCIUAEvaluation } from "../../../nciua/evaluation/types/HomeNCIUAEvaluation";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../../nciua/evaluation/types/HomeNCIUAEvaluationFormModel";
import { Evaluation_HomeNCIUARedesignation } from "../../../nciuaRedesignation/evaluation/types/HomeNCIUARedesignation";
import { Evaluation_HomeNCIUARedesignationFormModel } from "../../../nciuaRedesignation/evaluation/types/HomeNCIUARedesignationFormModel_Model";
import { Evaluation_HomeSCSHEvaluation } from "../../../scsh/types/HomeSCSHEvaluation";
import { Evaluation_HomeSCSHEvaluationFormModel } from "../../../scsh/types/HomeSCSHEvaluationFormModel";

interface FortifiedHomeAppHeaderProps {
  homeEvaluation:
    | Evaluation_HomeStandardEvaluation
    | Evaluation_HomeStandardEvaluationFormModel
    | Evaluation_HomeStandardRedesignationFormModel
    | Evaluation_HomeStandardRedesignation
    | Evaluation_HomeNCIUAEvaluation
    | Evaluation_HomeNCIUAEvaluationFormModel
    | Evaluation_HomeNCIUARedesignation
    | Evaluation_HomeNCIUARedesignationFormModel
    | Evaluation_HomeSCSHEvaluation
    | Evaluation_HomeSCSHEvaluationFormModel;
}

const FortifiedHomeAppHeader: React.FC<FortifiedHomeAppHeaderProps> = (
  props
) => {
  const fortifiedId = props.homeEvaluation.fortifiedId;
  const address = props.homeEvaluation.buildingAddress_AsString;

  return (
    <div className="fortified-home-app-header">
      <div className="fortified-home-app-header__primary">
        Fortified Home -{" "}
      </div>
      <div className="fortified-home-app-header__address">
        <div className="fortified-home-app-header__address__primary">
          {address}
        </div>
      </div>
      <div className="fortified-home-app-header__application-id">
        {` (${fortifiedId})`}
      </div>
    </div>
  );
};

export default FortifiedHomeAppHeader;
