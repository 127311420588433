import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { BaseHomeEvaluationHomeownerAuthorizationFormParts } from "../../common/types/BaseHomeEvaluationHomeownerAuthorizationFormModel";
import HomeStandardHomeownerAuthorizationFormContainer from "../components/Forms/HomeownerAuthorizationForm/Container/HomeStandardHomeownerAuthorizationFormContainer";

export interface HomeStandardHomeownerAuthorizationPageProps {
  evaluationId?: string;
  homeownerAuthorizationFormPart?: BaseHomeEvaluationHomeownerAuthorizationFormParts;
}

const HomeStandardHomeownerAuthorizationPageComponent: FC<
  HomeStandardHomeownerAuthorizationPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeStandardHomeownerAuthorizationFormContainer
            evaluationId={props.evaluationId}
            homeownerAuthorizationFormPart={
              props.homeownerAuthorizationFormPart
            }
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeStandardHomeownerAuthorizationPage = withAITracking(
  reactPlugin,
  HomeStandardHomeownerAuthorizationPageComponent,
  "HomeStandardHomeownerAuthorizationPage",
  "tracking-container"
);
