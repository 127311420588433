import { AdminUpdateFormModel } from "../../../components/Dialogs/AdminUpdate/AdminUpdateForm";
import { EvaluatorAssignmentFormModel } from "../../../components/Dialogs/EvaluatorAssignment/EvaluatorAssignmentForm";
import { WildfireRejectionFormModel } from "../../../components/Dialogs/Rejection/WildfireRejectionForm";
import { WildfireEvaluation } from "../../../types/WildfireApplication/WildfireApplication";
import { WildfireFieldEvaluationCompliance } from "../../../types/WildfireFieldComplianceDTO";
import wildfireBaseApiClient from "../../wildfireBaseApiClient";

class WildfireApplicationApiClient {
  createWildfireApplication = async () => {
    return wildfireBaseApiClient.post<WildfireEvaluation>(
      `wildfire-application`
    );
  };

  submitEvaluatorAssignment = async (
    wildfireApplicationId: string,
    form: EvaluatorAssignmentFormModel
  ) => {
    return wildfireBaseApiClient.post(
      `wildfire-application/${wildfireApplicationId}/assign-evaluation-company`,
      form
    );
  };

  getFormFieldDecisioning = async (
    wildfireEvalautionId: string
  ): Promise<WildfireFieldEvaluationCompliance> => {
    return wildfireBaseApiClient.get<WildfireFieldEvaluationCompliance>(
      `/wildfire-application/${wildfireEvalautionId}/form-field-decisioning`
    );
  };

  // Workflow
  sendForward = async (id: string) => {
    return wildfireBaseApiClient.post(
      `wildfire-application/${id}/workflow/send-forward`
    );
  };

  requestChanges = async (id: string) => {
    // Sends email only, doesn't change status
    return wildfireBaseApiClient.post(
      `wildfire-application/${id}/workflow/request-changes`
    );
  };

  approve = async (id: string) => {
    return wildfireBaseApiClient.post<WildfireEvaluation>(
      `wildfire-application/${id}/workflow/approve`
    );
  };

  reject = async (
    wildfireApplicationId: string,
    form: WildfireRejectionFormModel
  ) => {
    return wildfireBaseApiClient.post<WildfireEvaluation>(
      `wildfire-application/${wildfireApplicationId}/workflow/reject`,
      form
    );
  };

  closeEvaluation = async (evaluationId: string) => {
    return await wildfireBaseApiClient.put<WildfireEvaluation>(
      `/wildfire-application/${evaluationId}/close`
    );
  };

  cancelEvaluation = async (evaluationId: string) => {
    return await wildfireBaseApiClient.put<WildfireEvaluation>(
      `/wildfire-application/${evaluationId}/cancel`
    );
  };

  reopenEvaluation = async (evaluationId: string) => {
    return await wildfireBaseApiClient.put<WildfireEvaluation>(
      `/wildfire-application/${evaluationId}/re-open`
    );
  };

  resubmitEvaluation = async (evaluationId: string) => {
    return await wildfireBaseApiClient.put<WildfireEvaluation>(
      `/wildfire-application/${evaluationId}/resubmit`
    );
  };

  // Admin Update
  submitApplicationAdminUpdate = async (
    wildfireEvaluationId: string,
    form: AdminUpdateFormModel
  ) => {
    return wildfireBaseApiClient.post<WildfireEvaluation>(
      `/wildfire-application/${wildfireEvaluationId}/admin-update`,
      form
    );
  };

  // Payment
  getApplicationFeeStripeCheckoutLink = async (evaluationId: string) => {
    return await wildfireBaseApiClient.get<string>(
      `/wildfire-application/${evaluationId}/payment/stripe-checkout`
    );
  };
}

export const wildfireApplicationApiClient = new WildfireApplicationApiClient();
