import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ColumnModel } from "@syncfusion/ej2-react-grids";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import { fortifiedUserStore } from "../../stores/FortifiedUserStore";
import FortifiedGridColumn from "../../types/fortifiedGrid/FortifiedGridColumn";
import { CommentType } from "../../../common/apiClients/comments/types";

interface GridCommentsColumnOptions extends ColumnModel {
  unreadCommentCount: string;
  commentType: CommentType;
}

interface UnreadCommentsDTO {
  UserId: string;
  UnreadCount: number;
}

export default class GridCommentsColumn extends FortifiedGridColumn {
  constructor(opts: GridCommentsColumnOptions) {
    const { unreadCommentCount, commentType, ...superOpts } = opts;
    super({
      ...superOpts,
      template: ((model: Record<string, string>) => {
        const modelId = model["id"];
        const fortifiedId = model["fortifiedId"];
        const productType = model["productType_AsString"];

        const rawFieldValue = model[unreadCommentCount];
        let fieldValue = 0;

        if (rawFieldValue) {
          const unreadCommentsDTO = JSON.parse(
            rawFieldValue
          ) as UnreadCommentsDTO[];

          if (unreadCommentsDTO) {
            fieldValue = unreadCommentsDTO
              .filter((x) => x.UserId !== fortifiedUserStore.user?.id)
              .map((x) => x.UnreadCount)
              .reduce((partialSum, a) => partialSum + a, 0);
          }
        }

        const fieldValue_AsNumber: number = +fieldValue;
        const hasUnreadMessages = fieldValue_AsNumber > 0;
        const commentDisplay = hasUnreadMessages
          ? "active-grid-icon"
          : "base-grid-icon";

        return (
          <div
            className="icon-grid-column"
            onClick={(e) => {
              e.stopPropagation();
              dialogsViewerStore.setIsCommentsDialogOpen(true, {
                evaluationId: modelId,
                fortifiedId: fortifiedId,
                productType_AsString: productType,
                commentType: commentType,
              });
            }}
          >
            <Icon
              className={commentDisplay}
              icon={
                commentType === CommentType.Auditor
                  ? IconNames.EDIT
                  : IconNames.COMMENT
              }
            ></Icon>

            {/* Only show unread count for general comments */}
            {commentType === CommentType.General && hasUnreadMessages && (
              <div className="grid-icon-value">
                {Math.min(fieldValue_AsNumber, 99)}
              </div>
            )}
          </div>
        );
      }) as any,
    });
  }
}
