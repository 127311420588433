import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC, useEffect, useState } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import wildfireBaseApiClient from "../../../../wildfire/lib/wildfireBaseApiClient";
import { Schema } from "@ucl/library";
import { HomeSchemaFactoryV2 } from "../../customHooks/useHomeFieldSchemaFactory";
import HomeNCIUAIterationEngine from "../components/IterationEngine/HomeNCIUAIterationEngine";
import { evaluationStore } from "../../../../foritfied/stores/EvaluationStore";

export interface HomeSCSHIterationEnginePageProps {
  evaluationId?: string;
}

const HomeNCIUAIterationEnginePageComponent: FC<
  HomeSCSHIterationEnginePageProps
> = (props) => {
  const [schemaFactory, setschemaFactory] = useState<HomeSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await wildfireBaseApiClient.get<Schema>(`/wildfire/schema`);
    const newSchemaFactory = new HomeSchemaFactoryV2(schema);
    setschemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!schemaFactory) {
      getSchema();
    }
  }, []);

  //In the future, we want to remove all dependencies on the global store
  evaluationStore.init(props.evaluationId || "");

  return (
    <>
      {props.evaluationId && schemaFactory && (
        <UnSavedChangesBlockerProvider>
          <HomeNCIUAIterationEngine
            evaluationId={props.evaluationId}
            homeSchemaFactory={schemaFactory}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeNCIUAIterationEnginePage = withAITracking(
  reactPlugin,
  HomeNCIUAIterationEnginePageComponent,
  "HomeNCIUAIterationEnginePage",
  "tracking-container"
);
