import {
  ImageEditorComponent,
  ToolbarEventArgs,
} from "@syncfusion/ej2-react-image-editor";
import React, { useEffect, useRef, useState } from "react";
import { boxApiClient } from "../../apiClients/box/boxApiClient";
import "./styles.scss";
import { Intent } from "@blueprintjs/core";
import { Footer } from "@ucl/library";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { documentApiClient } from "../../apiClients/document/documentApiClient";
import { DocumentUploadRequest } from "../../apiClients/document/types";
import WildfireSecondaryButton from "../../../wildfire/components/Button/DialogFooterButtons/SecondaryButton/SecondaryButton";
import WildfireSubmitButton from "../../../wildfire/components/Button/DialogFooterButtons/SubmitButton/SubmitButton";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { SkeletonComponent } from "@syncfusion/ej2-react-notifications";
import { ItemModel } from "@syncfusion/ej2-react-splitbuttons";

export interface ImageAnnotationEditorProps {
  token: string;
  fileId: string;
  fileName: string;
  parentFolderId: string;
  onClose?: () => void;
  onSave?: () => void;
}

interface ImageSettings {
  width: number;
  height: number;
}

export const ImageAnnotationEditor: React.FC<ImageAnnotationEditorProps> = ({
  fileId,
  fileName,
  parentFolderId,
  token,
  onClose,
  onSave,
}) => {
  const [imageSettings, setImageSettings] = useState<ImageSettings | null>();
  const imageEditorRef = useRef<ImageEditorComponent | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [base64Image, setBase64Image] = useState<string | null>(null);

  const toolbar = [
    { text: "Arrow", id: "ArrowTool", tooltipText: "Draw Arrow" },
    "Rectangle",
    "Annotate",
    "Pen",
    "ZoomIn",
    "ZoomOut",
    "Undo",
    "Redo",
    "Reset",
    "Text",
    "Ellipse",
  ] as ItemModel[];

  const handleToolbarClick = (args: ToolbarEventArgs) => {
    if (
      args &&
      args.item &&
      args.item.id === "ArrowTool" &&
      imageEditorRef.current
    ) {
      handleDrawArrow();
    }
  };

  const handleDrawArrow = () => {
    if (imageEditorRef.current && imageSettings) {
      // Calculate center of the image
      const centerX = imageSettings.width / 2;
      const centerY = imageSettings.height / 2;

      // Define the arrow dimensions (e.g., from the center outwards)
      const arrowLength = 50; // Length of the arrow
      const startX = centerX - arrowLength / 2;
      const startY = centerY - arrowLength / 2;
      const endX = centerX + arrowLength / 2;
      const endY = centerY + arrowLength / 2;

      imageEditorRef.current.drawArrow(
        startX, // startX
        startY, // startY
        endX, // endX
        endY, // endY
        3, // strokeWidth
        "#000000"
      );
    }
  };

  const fetchData = async () => {
    try {
      const data = await boxApiClient.getImage({ token, fileId });

      if (data) {
        const blob = await data.blob();
        const base64Data = await convertBlobToBase64(blob);

        if (base64Data && typeof base64Data === "string") {
          const imgDimensions = await getImageDimensions(base64Data);
          if (imgDimensions) {
            const { width, height } = imgDimensions;

            setBase64Image(base64Data);
            setImageSettings({ width, height });
          }
        }
      }
    } catch (error) {
      AppToaster.show({
        message: "Failed to fetch image.",
        intent: Intent.DANGER,
      });
    }
  };

  // Utility function to convert Blob to Base64
  const convertBlobToBase64 = (blob: Blob): Promise<string | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string | null);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  // Utility function to get image dimensions from Base64
  const getImageDimensions = (
    base64: string
  ): Promise<{ width: number; height: number } | null> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = base64;

      img.onload = () => resolve({ width: img.width, height: img.height });
      img.onerror = (error) => {
        console.error("Error loading image:", error);
        AppToaster.show({
          message: "Error loading image.",
          intent: Intent.DANGER,
        });
        reject(null);
      };
    });
  };

  useEffect(() => {
    fetchData().then(() => setInitialized(true));
    return () => {
      imageEditorRef.current?.reset();
    };
  }, []);

  useEffect(() => {
    if (imageEditorRef.current && base64Image) {
      imageEditorRef.current.open(base64Image);
    }
  }, [imageEditorRef.current, base64Image]);

  const getAnnotatedImage = async (imgData: ImageData) => {
    try {
      const tempCanvas = document.createElement("canvas");
      tempCanvas.width = imgData.width;
      tempCanvas.height = imgData.height;

      const tempCtx = tempCanvas.getContext("2d");
      if (!tempCtx) {
        console.error("Failed to get 2D context from the canvas.");
        return null;
      }

      tempCtx.putImageData(imgData, 0, 0);

      // Convert to base64
      const base64Data = tempCanvas.toDataURL("image/png");

      return base64Data;
    } catch (e) {
      console.error("Failed to get annotated image:", e);
      return null;
    }
  };

  function base64ToFile(base64: string, fileName: string): File | null {
    try {
      // Extract the base64 data from the string
      const [metadata, base64Data] = base64.split(",");
      if (!base64Data) {
        throw new Error("Invalid base64 string.");
      }

      const mimeType =
        metadata.match(/:(.*?);/)?.[1] || "application/octet-stream";

      // Decode the base64 data
      const binaryString = window.atob(base64Data);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);

      // Convert binary string to byte array
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      // Create a Blob from the byte array
      const blob = new Blob([bytes], { type: mimeType });

      // Create and return a File object from the Blob
      return new File([blob], fileName, { type: mimeType });
    } catch (e) {
      console.error("Failed to convert base64 to file:", e);
      return null;
    }
  }

  const replaceExistingBoxImage = async (base64Image: string) => {
    setIsLoading(true);
    const annotatedFileName = fileName.includes("_annotated.png")
      ? fileName
      : fileName.replace(/(\.[\w\d_-]+)$/i, "_annotated.png");

    const annotatedFile = base64ToFile(base64Image, annotatedFileName);

    if (annotatedFile === null) {
      throw new Error("Could not convert base 64 image to file.");
    }

    const payload: DocumentUploadRequest = {
      fileName: annotatedFileName,
      file: annotatedFile,
      existingFileId: fileId,
      parentFolderId,
    };

    await documentApiClient.uploadAnnotatedImage(payload);
    setIsLoading(false);
  };

  const saveAnnotatedImage = async () => {
    try {
      const imgData = imageEditorRef.current?.getImageData();

      if (imgData) {
        const base64AnnotatedImage = await getAnnotatedImage(imgData);
        if (base64AnnotatedImage) {
          await replaceExistingBoxImage(base64AnnotatedImage);
          onSave && onSave();
        }

        onClose && onClose();
      }
    } catch (err) {
      AppToaster.show({
        message: "Error saving image.",
        intent: Intent.DANGER,
      });
    }
  };

  return (
    <div className="image-annotation-editor">
      {initialized ? (
        <ImageEditorComponent
          ref={imageEditorRef}
          toolbar={toolbar}
          height={`${imageSettings?.height}px`}
          width={`100%`}
          disabled={isLoading}
          toolbarItemClicked={handleToolbarClick}
        />
      ) : (
        <SkeletonComponent>
          <Loading />
        </SkeletonComponent>
      )}
      <div className="image-annotation-editor-footer">
        <Footer>
          <WildfireSecondaryButton
            buttonText="Cancel"
            onClick={onClose}
            disabled={isLoading}
          />
          <WildfireSubmitButton
            className="wildfire-admin-update-dialog-submit-button"
            buttonText="Submit"
            onClick={saveAnnotatedImage}
            isLoading={isLoading}
          />
        </Footer>
      </div>
    </div>
  );
};
