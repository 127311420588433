import { PrimaryCard, SecondaryCard } from "@ucl/library";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { Navigate } from "react-router-dom";
import FormChecklistProgress from "../../../../../../Common/components/Forms/FormChecklistProgress/FormChecklistProgress";
import { FieldSchemaFactoryProvider } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { BaseHomeEvaluationRooferConfirmationFormParts } from "../../../../../common/types/BaseHomeEvaluationRooferConfirmationFormModel";
import useHomeNCIUARooferConfirmationFormContainer from "./useHomeNCIUARooferConfirmationFormContainer";
import { fortifiedHomeNCIUARoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import HomeNCIUAEvaluationRooferConfirmationForm from "../Form/HomeNCIUAEvaluationRooferConfirmationForm";
import GoToChecklistButton from "../../../../../common/components/Buttons/GoToChecklist/GoToIterationEngineButton";
import DownloadRooferConfirmationFormContainer from "../../../../../common/components/Buttons/DownloadRooferConfirmationForm/DownloadRooferConfirmationFormContainer";

export interface HomeNCIUARooferConfirmationFormContainerProps {
  evaluationId: string;
  rooferConfirmationFormPart?: BaseHomeEvaluationRooferConfirmationFormParts;
}

export const HomeNCIUARooferConfirmationFormContainer = (
  props: HomeNCIUARooferConfirmationFormContainerProps
) => {
  const {
    applicationChecklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    applicationFormPart,
    getBestFormPart,
    commercialSchemaFactory,
    evaluationFormModel,
  } = useHomeNCIUARooferConfirmationFormContainer(props);

  return (
    <>
      {!applicationFormPart ||
      !Object.values(BaseHomeEvaluationRooferConfirmationFormParts).includes(
        applicationFormPart
      ) ? (
        // TODO: Show error page if the application form part is not valid
        <Navigate
          to={`${fortifiedHomeNCIUARoutePrefix}/${
            props.evaluationId
          }/roofer-confirmation/${getBestFormPart().valueOf()}`}
        />
      ) : !commercialSchemaFactory ? (
        <Loading />
      ) : (
        <div className="wildfire-form-page">
          <div className="wildfire-form-progress">
            <div className="wildfire-form-progress__steps">
              <FormChecklistProgress
                checklistParts={applicationChecklistParts}
                evaluationId={props.evaluationId}
                formURL={`${fortifiedHomeNCIUARoutePrefix}/${props.evaluationId}/roofer-confirmation`}
                title={"Roofer Confirmation"}
                activeFormPart={applicationFormPart}
                getChecklistStatus={getApplicationPartChecklistStatus}
              />
            </div>
          </div>
          <section className="wildfire-form-content">
            <div className="wildfire-form-content__header"></div>
            <PrimaryCard
              className="wildfire-form-primary-card"
              headerContent={
                <div className="wildfire-form-primary-card__header">
                  Home NCIUA Roofer Confirmation:{" "}
                  {
                    applicationChecklistParts.find(
                      (value) => value.formPart === applicationFormPart
                    )?.displayName
                  }
                  <div className="wildfire-form-primary-card__header-buttons">
                    <GoToChecklistButton
                      evaluationId={props.evaluationId}
                      productTypeRoute={fortifiedHomeNCIUARoutePrefix}
                    />
                  </div>
                </div>
              }
            >
              <SecondaryCard
                className="wildfire-form-secondary-card"
                hideBody={false}
                isCollapsible={false}
              >
                <FieldSchemaFactoryProvider
                  schemaFactory={commercialSchemaFactory}
                >
                  <DownloadRooferConfirmationFormContainer
                    evaluationId={props.evaluationId}
                    asBannerLink={true}
                    fortifiedId={evaluationFormModel?.fortifiedId ?? ""}
                  />
                  <HomeNCIUAEvaluationRooferConfirmationForm
                    key={applicationFormPart}
                    evaluationId={props.evaluationId}
                    isIterationEnginePage={false}
                    formPart={applicationFormPart}
                    disableForm={false}
                    setFormModel={setEvaluationFormModel}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                  />
                </FieldSchemaFactoryProvider>
              </SecondaryCard>
            </PrimaryCard>
          </section>
        </div>
      )}
    </>
  );
};
export default HomeNCIUARooferConfirmationFormContainer;
