import { Classes, Tab, Tabs } from "@blueprintjs/core";
import { WildfireUserGrid } from "../Grid/UserGrid/WildfireUserGrid";
import { WildfireCommentTemplateGrid } from "../Grid/CommentTemplateGrid/WildfireCommentTemplateGrid";

export interface WildfireAdminTabsProps {}

export const WildfireAdminTabs: React.FC<WildfireAdminTabsProps> = ({}) => {
  return (
    <Tabs
      large
      animate
      id="wildfire-admin-tabs"
      renderActiveTabPanelOnly
      className={`${Classes.FOCUS_DISABLED} admin-tabs`}
    >
      <Tab id="users" title="Users" panel={<WildfireUserGrid />} />
      <Tab
        id="comment-templates"
        title="Comment Templates"
        panel={<WildfireCommentTemplateGrid />}
      />
    </Tabs>
  );
};
