import {
  AppToaster,
  showSuccessAppToaster,
} from "@ucl/library/lib/components/Toast/Toast";
import { useState } from "react";
import { genericErrorMessage } from "../../../../common/Components/Error/ErrorStore";
import { Button, Intent } from "@blueprintjs/core";
import { wildfireEvaluationApiClient } from "../../../lib/apiClients/evaluation/wildfireEvaluationApiClient";

interface ClaimApplicationActionButtonProps {
  evaluationId: string;

  isEvaluationClaimedByCurrentUser: boolean;
  refreshWildfireEvaluation: () => Promise<void>;
}

const ClaimApplicationActionButton: React.FC<
  ClaimApplicationActionButtonProps
> = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const submitEvaluationClaim = async () => {
    const result = { isSuccess: true, error: undefined };

    await wildfireEvaluationApiClient
      .claimEvaluation(props.evaluationId)
      .then(() => {
        showSuccessAppToaster("Evaluation Claimed.");
        props.refreshWildfireEvaluation();
      })
      .catch((error) => {
        result.isSuccess = false;
        result.error = error;

        AppToaster.show({
          message: <div>{genericErrorMessage}</div>,
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
    return result;
  };

  const buttonText = props.isEvaluationClaimedByCurrentUser
    ? "Claimed Evaluation"
    : "Claim Evaluation";
  const isButtonDisabled = props.isEvaluationClaimedByCurrentUser;

  return (
    <Button
      minimal
      intent={Intent.PRIMARY}
      disabled={isButtonDisabled}
      loading={isLoading}
      onClick={async () => {
        setIsLoading(true);
        submitEvaluationClaim();
      }}
    >
      {buttonText}
    </Button>
  );
};

export default ClaimApplicationActionButton;
