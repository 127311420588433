import baseApiClient from "../../../../../../foritfied/lib/apiClients/baseApiClient";
import { BaseHomeEvaluationHomeownerAuthorizationFormParts } from "../../../../common/types/BaseHomeEvaluationHomeownerAuthorizationFormModel";
import { Evaluation_HomeNCIUARedesignationFormModel } from "../../types/HomeNCIUARedesignationFormModel_Model";
import { HomeNCIUARedesignationHomeownerAuthorizationFormModel } from "../../types/HomeNCIUARedesignationHomeownerAuthorizationFormModel";

class HomeNCIUARedesignationHomeownerAuthorizationAPIClient {
  updateHomeStandardHomeownerAuthorizationForm = async (
    evaluationId: string,
    homeownerAuthorizationForm: HomeNCIUARedesignationHomeownerAuthorizationFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeNCIUARedesignationFormModel> => {
    return baseApiClient.put<Evaluation_HomeNCIUARedesignationFormModel>(
      `/home-nciua/redesignation/homeowner-authorization-form/${evaluationId}`,
      {
        homeownerAuthorizationForm,
        fieldKey,
      }
    );
  };

  submitHomeStandardHomeownerAuthorizationForm = async (
    evaluationId: string,
    homeownerAuthorizationForm: HomeNCIUARedesignationHomeownerAuthorizationFormModel,
    formPart?: BaseHomeEvaluationHomeownerAuthorizationFormParts
  ): Promise<Evaluation_HomeNCIUARedesignationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationHomeownerAuthorizationFormParts
      ).pop() as BaseHomeEvaluationHomeownerAuthorizationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeNCIUARedesignationFormModel>(
      `/home-nciua/redesignation/homeowner-authorization-form/${evaluationId}/${formPartValue}`,
      homeownerAuthorizationForm
    );
  };
}

export const homeNCIUARedesignationHomeownerAuthorizationAPIClient =
  new HomeNCIUARedesignationHomeownerAuthorizationAPIClient();
