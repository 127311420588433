import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { BaseHomeRoofingComplianceRoofCoverFormParts } from "../../common/types/BaseHomeEvaluationRoofingComplianceRoofCoverFormModel";
import HomeStandardRoofingComplianceRoofCoverContainer from "../components/Forms/RoofingComplianceRoofCoverForm/Container/HomeStandardRoofingComplianceRoofCoverContainer";

export interface HomeStandardRoofingComplianceGeneralInformationPageProps {
  evaluationId?: string;
  formParts?: BaseHomeRoofingComplianceRoofCoverFormParts;
}

const HomeStandardRoofingComplianceRoofCoverPageComponent: FC<
  HomeStandardRoofingComplianceGeneralInformationPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeStandardRoofingComplianceRoofCoverContainer
            evaluationId={props.evaluationId}
            formParts={props.formParts}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeStandardRoofingComplianceRoofCoverPage = withAITracking(
  reactPlugin,
  HomeStandardRoofingComplianceRoofCoverPageComponent,
  "HomeStandardRoofingComplianceRoofCoverPage",
  "tracking-container"
);
