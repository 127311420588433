import { wildfireRoutePrefix } from "../../pages/index/wildfireRouteConfig";
import { wildfireApplicationApiClient } from "../apiClients/evaluation/wildfireApplicationApiClient";

export const createNewWildfireApplication = async (): Promise<string> => {
  const evaluation =
    await wildfireApplicationApiClient.createWildfireApplication();
  return `${wildfireRoutePrefix}/${evaluation.id}/application-form`;
};

export const formatSecondaryAddressInfo = (
  street2: string | undefined,
  city: string,
  state_AsString: string,
  zip: string
): string => {
  const longState = state_AsString; // e.g. FL - Florida
  const shortState = longState
    ? longState.substring(0, longState.indexOf(" "))
    : ""; // e.g. FL

  return ` ${street2 ? `${street2}, ` : ""}${city}, ${shortState} ${zip}`;
};
