import { Icon, IconProps } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { WildfireEvaluationSteps } from "../../../../foritfied/types/evaluation/Evaluation";
import { EvaluationStatus } from "../../../types/WildfireApplication/WildfireApplication";
import "./styles.scss";
import { WildfireFieldEvaluationCompliance } from "../../../types/WildfireFieldComplianceDTO";

interface LandscapeReviewIterationEngineNavigatorProps {
  evaluationStep: WildfireEvaluationSteps;
  evaluationStatus: EvaluationStatus;
  selectedTabId: string;
  setSelectedTabId: (tabId: string) => void;
  formTabs: { id: string; label: string }[];
  formFieldEvaluationDecisioning: WildfireFieldEvaluationCompliance | undefined;
  getCountOfNonCompliantFields: (isPlus?: boolean) => number | undefined;
}

const orderedSteps = [
  WildfireEvaluationSteps.WildfireApplication,
  WildfireEvaluationSteps.WildfireApplicationPayment,
  WildfireEvaluationSteps.WildfireApplicationEligibilityPhotos,
  WildfireEvaluationSteps.WildfireApplicationIBHSReview,
];

const evaluationStepDetails = [
  {
    id: WildfireEvaluationSteps.WildfireApplication,
    label: "Application",
  },
  {
    id: WildfireEvaluationSteps.WildfireApplicationPayment,
    label: "Application Payment",
  },
  {
    id: WildfireEvaluationSteps.WildfireApplicationEligibilityPhotos,
    label: "Photo Requirements",
  },
  {
    id: WildfireEvaluationSteps.WildfireApplicationIBHSReview,
    label: "IBHS Review",
  },
];

const getIcon = (
  step: WildfireEvaluationSteps,
  evaluationStep: WildfireEvaluationSteps,
  evaluationStatus: EvaluationStatus
): IconProps => {
  const isFinalReview =
    step === WildfireEvaluationSteps.WildfireApplicationIBHSReview;

  const shouldDisplayTickCircle =
    orderedSteps.indexOf(evaluationStep) > orderedSteps.indexOf(step) ||
    (isFinalReview && evaluationStatus === "Designated");

  const shouldDisplayCross =
    isFinalReview &&
    step === evaluationStep &&
    evaluationStatus === "Not Designated";

  const shouldDisplayCircle = evaluationStep === step;

  if (shouldDisplayTickCircle) {
    return { icon: IconNames.TICK_CIRCLE };
  }

  if (shouldDisplayCross) {
    return { icon: IconNames.CROSS_CIRCLE };
  }

  if (shouldDisplayCircle) {
    return { icon: IconNames.CIRCLE };
  }

  return { icon: IconNames.FULL_CIRCLE };
};

const LandscapeReviewIterationEngineNavigator: React.FC<
  LandscapeReviewIterationEngineNavigatorProps
> = (props) => {
  return (
    <div>
      <div className="evaluation-steps-container">
        <div className="evaluation-steps-container__header">Status</div>

        <div className="evaluation-steps-container__steps">
          {evaluationStepDetails.map((step) => {
            return (
              <div className="evaluation-steps-container-step" key={step.id}>
                <Icon
                  size={20}
                  {...getIcon(
                    step.id,
                    props.evaluationStep,
                    props.evaluationStatus
                  )}
                />
                <div className="evaluation-steps-container-step__label">
                  {step.label}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LandscapeReviewIterationEngineNavigator;
