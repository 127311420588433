import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { SecondaryButton, dialogStore } from "@ucl/library";
import { useState } from "react";
import { getCloseDialogProps } from "../../Dialogs/Close/CloseDialog";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";
import { wildfireApplicationApiClient } from "../../../lib/apiClients/evaluation/wildfireApplicationApiClient";

interface WildfireCloseEvaluationButtonProps {
  evaluationId: string;
  isClosed?: boolean;
  close: () => Promise<void>;
  cancel: () => Promise<void>;
  reopen: () => Promise<void>;
  refreshWildfireEvaluation: () => Promise<void>;
  hasWithdrawPermission: boolean;
  auditorNotes?: string;
}

const WildfireCloseEvaluationButton: React.FC<
  WildfireCloseEvaluationButtonProps
> = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const cancelOrCloseText = props.hasWithdrawPermission
    ? "Close Application"
    : "Cancel Application";

  const openCloseDialog = () => {
    dialogStore.openDialog(
      getCloseDialogProps(
        props.evaluationId,
        props.close,
        props.cancel,
        props.refreshWildfireEvaluation,
        props.hasWithdrawPermission,
        props.auditorNotes
      )
    );
  };

  const reopenEvaluation = async () => {
    setIsLoading(true);

    await wildfireApplicationApiClient
      .reopenEvaluation(props.evaluationId)
      .then(async () => {
        AppToaster.show({
          message: (
            <div>
              <h3>Success</h3>Evaluation Reapplied.
            </div>
          ),
          intent: Intent.SUCCESS,
        });
        await props.refreshWildfireEvaluation();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return !!props.isClosed ? (
    <Button
      minimal
      icon={IconNames.Repeat}
      loading={isLoading}
      intent={Intent.SUCCESS}
      onClick={async () => {
        await reopenEvaluation();
      }}
    >
      Reapply
    </Button>
  ) : (
    <SecondaryButton
      onClick={() => {
        openCloseDialog();
      }}
      cssClass="wildfire-close-evaluation-button"
    >
      <FontAwesomeIcon icon={faClose} /> {cancelOrCloseText}
    </SecondaryButton>
  );
};

export default WildfireCloseEvaluationButton;
