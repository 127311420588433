import { dialogStore } from "@ucl/library";
import { DialogProps } from "@ucl/library/lib/components/Dialogs/Dialog/Dialog";
import { AppToaster } from "../../../../foritfied/components/Toast/Toast";
import { evaluationApiClient } from "../../../../foritfied/lib/apiClients/evaluation/evaluationApiClient";
import "./styles.scss";
import { AuditorNotesUpdateForm } from "../AuditorNotesUpdate/AuditorNotesUpdateForm";

export const getCloseDialogProps = (
  evaluationId: string,
  close: () => Promise<void>,
  cancel: () => Promise<void>,
  refreshWildfireEvaluation: () => Promise<void>,
  hasWithdrawPermission: boolean,
  auditorNotes?: string
): DialogProps => {
  const dialogIdBase = "wildfire-close-dialog";

  const getDialogId = (): string => `${dialogIdBase}_${evaluationId}`;

  const cancelOrCloseText = hasWithdrawPermission ? "Close" : "Cancel";
  const cancelOrCloseMessage = hasWithdrawPermission ? "Closed" : "Canceled";

  const cancelOrCloseBody = hasWithdrawPermission
    ? "Are you sure you want to close? This will remove the application from the designation process"
    : "Are you sure you want to cancel? This will remove the application from the designation process. If you have already paid, this fee is non-refundable.";

  const onCancelDialog = () => {
    dialogStore.closeDialog(getDialogId());
  };

  const onSubmitDialog = async (auditorNotes: string) => {
    if (hasWithdrawPermission) {
      await evaluationApiClient.updateAuditorNotes(evaluationId, {
        auditorNotes,
      });
    }

    if (hasWithdrawPermission) {
      await close();
    } else {
      await cancel();
    }

    await refreshWildfireEvaluation();
    AppToaster.show({
      message: (
        <div>
          <h3>Success</h3> Application has been {cancelOrCloseMessage}.
        </div>
      ),
      intent: "success",
    });

    dialogStore.closeDialog(getDialogId());
  };

  return {
    id: getDialogId(),
    header: `${cancelOrCloseText} Application`,
    allowDragging: false,
    content: () => (
      <div className="wildfire-close-or-cancel-dialog-container">
        <p>{cancelOrCloseBody}</p>
        <AuditorNotesUpdateForm
          auditorNotes={auditorNotes}
          onCancel={onCancelDialog}
          onSubmit={onSubmitDialog}
          cancelButtonText="Close"
          submitButtonText={`Proceed with ${cancelOrCloseText}`}
          canViewAuditorNotes={hasWithdrawPermission}
        />
      </div>
    ),
  };
};
