import { FormGroup } from "@blueprintjs/core";
import { fieldTypes } from "@ucl/library";
import { FieldLabeledProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { MessageComponent } from "@syncfusion/ej2-react-notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";

export interface ReadonlyWarningBannerFieldValue {}

export interface ReadonlyWarningBannerFieldProps
  extends fieldTypes.BaseFieldProps<ReadonlyWarningBannerFieldValue> {
  labelProps?: FieldLabeledProps;
}

export const ReadonlyWarningBannerField: React.FC<
  ReadonlyWarningBannerFieldProps
> = (props) => {
  const warningHtml = `<div><b>WARNING - </b>${props.description}</div>`;

  return (
    <div>
      <div className="readonly-warning-banner-field">
        {props.description && (
          <FormGroup className={"base-field"}>
            <div className="readonly-warning-banner">
              <FontAwesomeIcon
                className="readonly-warning-banner__icon"
                icon={faExclamationTriangle}
                size={"lg"}
              />
              <MessageComponent
                className="readonly-warning-banner__message"
                content={warningHtml}
                showCloseIcon={false}
                showIcon={false}
                visible={true}
              />
            </div>
          </FormGroup>
        )}
      </div>
    </div>
  );
};
