import { Schema } from "@ucl/library";
import { useContext, useEffect, useRef, useState } from "react";
import wildfireBaseApiClient from "../../../../../../../wildfire/lib/wildfireBaseApiClient";
import { UnsavedChangesBlockerContext } from "../../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../../../../../common/types/Forms/FormChecklist";
import { HomeBasicInformationFormParts } from "../../../../../common/types/BaseHomeEvaluationBasicInformationFormModel";
import { HomeSchemaFactoryV2 } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { debounce } from "lodash";
import { AddressFieldValue } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { HomeNCIUABasicInformationFormContainerProps } from "./HomeNCIUABasicInformationFormContainer";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../../../evaluation/types/HomeNCIUAEvaluationFormModel";
import { homeNCIUAEvaluationAPIClient } from "../../../../evaluation/lib/apiClients/homeNCIUAEvaluationAPIClient";
import { AppHeaderContext } from "../../../../../../../wildfire/components/Provider/AppHeaderContext";

function useHomeNCIUABasicInformationFormContainer(
  props: HomeNCIUABasicInformationFormContainerProps
) {
  const [schemaFactory, setSchemaFactory] = useState<
    HomeSchemaFactoryV2 | undefined
  >(undefined);

  const [evaluationFormModel, setEvaluationFormModel] = useState<
    Evaluation_HomeNCIUAEvaluationFormModel | undefined
  >(undefined);

  const [formPart, setFormPart] = useState<
    HomeBasicInformationFormParts | undefined
  >(props.basicInformationFormPart);

  const [hasDuplicateAddress, setHasDuplicateAddress] =
    useState<boolean>(false);

  const [initialCheckDone, setInitialCheckDone] = useState<boolean>(false); // Tracks if the check was done on load

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const fetchSchema = async () => {
    const schema = await wildfireBaseApiClient.get<Schema>(`/wildfire/schema`);
    setSchemaFactory(new HomeSchemaFactoryV2(schema));
  };

  useEffect(() => {
    if (!schemaFactory) {
      fetchSchema();
    }
    return () => {
      setDefaultHeader();
    };
  }, [schemaFactory]);

  const { setFotifiedHomeEvaluationHeader, setDefaultHeader } =
    useContext(AppHeaderContext);

  useEffect(() => {
    if (evaluationFormModel) {
      setFotifiedHomeEvaluationHeader(evaluationFormModel);
    }
  }, [evaluationFormModel]);

  const checkForDuplicateAddress = useRef(
    debounce(async (address: AddressFieldValue) => {
      if (!address.street || !address.city || !address.state) return;

      const response =
        await homeNCIUAEvaluationAPIClient.hasEvaluationsWithSameAddress(
          props.evaluationId
        );
      setHasDuplicateAddress(response);
    }, 2000)
  ).current;

  useEffect(() => {
    return () => {
      checkForDuplicateAddress.cancel();
    };
  }, [checkForDuplicateAddress]);

  useEffect(() => {
    if (
      !initialCheckDone &&
      evaluationFormModel?.homeNCIUAEvaluation.basicInformationForm?.address
    ) {
      // Perform the initial duplicate address check
      checkForDuplicateAddress(
        evaluationFormModel.homeNCIUAEvaluation.basicInformationForm.address
      );
      setInitialCheckDone(true); // Ensure the initial check is only done once
    }
  }, [evaluationFormModel, initialCheckDone]);

  const onFieldChange = async (fieldKey: string, value: any) => {
    if (
      fieldKey ===
      "Evaluation__HomeNCIUAEvaluation__BasicInformationForm__Address"
    ) {
      checkForDuplicateAddress(value);
    }
  };

  const basicInformationForm =
    evaluationFormModel?.homeNCIUAEvaluation.basicInformationForm;

  const getNextUnsubmittedFormPart =
    (): HomeBasicInformationFormParts | null => {
      if (!basicInformationForm) return null;

      if (!basicInformationForm.isHomeownerInformationComplete) {
        return HomeBasicInformationFormParts.HomeownerInformation;
      }
      return null;
    };

  const getBestFormPart = (): HomeBasicInformationFormParts => {
    if (props.basicInformationFormPart) {
      return props.basicInformationFormPart;
    }
    return (
      getNextUnsubmittedFormPart() ??
      Object.values(HomeBasicInformationFormParts)[0]
    );
  };

  useEffect(() => {
    setFormPart(getBestFormPart());
  }, [props.basicInformationFormPart, evaluationFormModel]);

  const getApplicationPartChecklistStatus = (
    part: HomeBasicInformationFormParts
  ): FormChecklistStatus => {
    if (part === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    const isComplete = (complete: boolean | undefined) =>
      complete
        ? FormChecklistStatus.Submitted
        : FormChecklistStatus.Unsubmitted;

    switch (part) {
      case HomeBasicInformationFormParts.HomeownerInformation:
        return isComplete(basicInformationForm?.isHomeownerInformationComplete);
    }
  };

  const checklistParts: FormChecklistPart<HomeBasicInformationFormParts>[] = [
    {
      formPart: HomeBasicInformationFormParts.HomeownerInformation,
      displayName: "Homeowner Information",
      requiredFieldCount:
        basicInformationForm?.homeownerInformationRequiredFieldCount,
    },
  ];

  return {
    checklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    formPart,
    getBestFormPart,
    schemaFactory,
    hasDuplicateAddress,
    onFieldChange,
  };
}

export default useHomeNCIUABasicInformationFormContainer;
