import { useNavigate } from "react-router";
import { LandscapeReviewFormProps } from "./LandscapeReviewForm";
import { useFieldSchemaFactory } from "../../../customHooks/useFieldSchemaFactory";
import { FieldFormV2Module } from "@ucl/library";
import { useCallback, useEffect, useRef, useState } from "react";
import { EvaluationFormModel } from "../../../forms/EvaluationFormModel";
import { getScrollContainer } from "../../../utils/getScrollContainer";
import {
  activeLandscapeReviewFormParts,
  LandscapeReviewFormParts,
} from "../../../forms/LandscapeReviewForm/LandscapeReviewFormModel";
import { WildfireEvaluationSteps } from "../../../../foritfied/types/evaluation/Evaluation";
import { wildfireEvaluationApiClient } from "../../../lib/apiClients/evaluation/wildfireEvaluationApiClient";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../common/Components/Error/ErrorStore";
import { debounce } from "lodash";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { getWildfireLandscapeReviewFeePaymentPageRoute } from "../../../../common/utils/paymentRouteUtils";
import { wildfireRoutePrefix } from "../../../pages/index/wildfireRouteConfig";

function useLandscapeReviewForm(props: LandscapeReviewFormProps) {
  const navigate = useNavigate();
  const { builders, wieBuilders } = useFieldSchemaFactory();
  const formRef = useRef<FieldFormV2Module<EvaluationFormModel>>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [landscapeReviewFormModel, setLandscapeReviewFormModel] =
    useState<EvaluationFormModel>();

  const [isInitialized, setIsInitialized] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!props.isWiePage && !!containerRef.current) {
      const scrollableParent = getScrollContainer(containerRef.current);

      scrollableParent?.scrollTo(0, 0);
    }
  }, [props.formPart]);

  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
      props.onInitialized?.();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isInitialized) {
      props.onInitialized?.();
    }
  }, [isInitialized]);

  // Differentiates between save and submit
  let shouldSubmit = false;
  const setShouldSubmit = (value: boolean) => {
    shouldSubmit = value;
  };

  useEffect(() => {
    //Used to React to External Changes Pushed on the Form (Iteration Engine)
    if (landscapeReviewFormModel) {
      setLandscapeReviewFormModel({ ...landscapeReviewFormModel });
    }
  }, [wieBuilders]);

  useEffect(() => {
    fetchLandscapeReviewForm();
  }, []);

  useEffect(() => {
    if (!!landscapeReviewFormModel) {
      setLandscapeReviewFormModel(landscapeReviewFormModel);

      if (props.setFormModel) {
        props.setFormModel(landscapeReviewFormModel);
      }
    }
  }, [landscapeReviewFormModel]);

  const isFirstPartOfForm =
    activeLandscapeReviewFormParts.indexOf(props.formPart!) === 0;
  const isLastPartOfForm =
    activeLandscapeReviewFormParts.indexOf(props.formPart!) ===
    activeLandscapeReviewFormParts.length - 1;

  const shouldShowPaymentButton =
    props.formPart === LandscapeReviewFormParts.PropertyInformation &&
    (landscapeReviewFormModel?.wildfireLandscapeReview.step ===
      WildfireEvaluationSteps.WildfireApplicationPayment ||
      landscapeReviewFormModel?.wildfireLandscapeReview.step ===
        WildfireEvaluationSteps.WildfireApplication);

  const noBuilders = !builders && !wieBuilders;

  const hasPaymentDisableLogic =
    props.formPart === LandscapeReviewFormParts.PropertyInformation &&
    (landscapeReviewFormModel?.wildfireLandscapeReview.landscapeReviewForm
      .propertyInformationRequiredFieldCount === undefined ||
      landscapeReviewFormModel?.wildfireLandscapeReview.landscapeReviewForm
        .propertyInformationRequiredFieldCount > 0);

  const hasLastPartOfFormDisableLogic =
    isLastPartOfForm &&
    !landscapeReviewFormModel?.wildfireLandscapeReview.landscapeReviewForm
      .allRequiredFieldsCompleted;

  const isSubmitDisabled =
    isSubmitting || hasPaymentDisableLogic || hasLastPartOfFormDisableLogic;

  const toolTipText = hasPaymentDisableLogic
    ? "Please complete all required fields before submitting Payment."
    : hasLastPartOfFormDisableLogic
    ? "Please complete all required fields before submitting for IBHS Review."
    : "Submitting";

  const buttonText = shouldShowPaymentButton
    ? "Proceed to Payment"
    : isLastPartOfForm
    ? "Submit for IBHS Review"
    : "Continue";

  const fetchLandscapeReviewForm = async () => {
    setIsLoading(true);
    await wildfireEvaluationApiClient
      .getLandscapeReviewForm(props.wildfireEvaluationId)
      .then((response) => {
        setLandscapeReviewFormModel((prevModel) => ({
          ...prevModel,
          ...response,
        }));
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Debounce setup
  const handleFormSubmitDebounced = useCallback(
    debounce(
      async (
        form: EvaluationFormModel,
        _value: any,
        fieldProps: BaseFieldProps<any>
      ) => {
        if (fieldProps.fieldKey) {
          await handleFormSubmit(form, fieldProps.fieldKey).then(async () => {
            await props.onFormSave?.(fieldProps.fieldKey || "", _value);
          });
        }
      },
      2000
    ),
    []
  );

  useEffect(() => {
    // Cleanup the debounce function on component unmount
    return () => handleFormSubmitDebounced.cancel();
  }, [handleFormSubmitDebounced]);

  const onFormFieldChange = async (
    form: EvaluationFormModel,
    _value: any,
    fieldProps: BaseFieldProps<any>
  ) => {
    // Call the debounced submit function
    if (!!_value) {
      handleFormSubmitDebounced(form, _value, fieldProps);
      props.setHasUnsavedChanges(true);
    }
  };

  const getLandscapeReviewFormPartBuilder = () => {
    if (props.isWiePage) {
      return wieBuilders?.LandscapeReviewForm;
    }
    switch (props.formPart) {
      case LandscapeReviewFormParts.PropertyInformation:
        return builders?.LandscapeReviewFormPropertyInformationPart;
      case LandscapeReviewFormParts.PhotoRequirements:
        return builders?.LandscapeReviewFormPhotoRequirementsPart;
      default:
        return builders?.LandscapeReviewForm;
    }
  };

  const handleFormSubmit = async (
    form: EvaluationFormModel,
    fieldKey?: string
  ) => {
    setLandscapeReviewFormModel(form);

    await (shouldSubmit
      ? wildfireEvaluationApiClient.submitLandscapeReviewForm(
          form.wildfireLandscapeReview.id,
          form.wildfireLandscapeReview.landscapeReviewForm,
          props.formPart
        )
      : wildfireEvaluationApiClient.updateLandscapeReviewForm(
          form.wildfireLandscapeReview.id,
          form.wildfireLandscapeReview.landscapeReviewForm,
          fieldKey
        )
    )
      .then(async (response) => {
        handleFormResponse(response);
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while saving the form",
            intent: Intent.DANGER,
          });
        }
        throw error;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleFormResponse = (response: EvaluationFormModel) => {
    props.setHasUnsavedChanges(false);

    if (shouldSubmit) {
      setLandscapeReviewFormModel(response);

      if (shouldShowPaymentButton) {
        navigate(
          getWildfireLandscapeReviewFeePaymentPageRoute(
            props.wildfireEvaluationId
          )
        );
      } else if (isLastPartOfForm || !props.formPart) {
        navigate(`${wildfireRoutePrefix}/board`);
      } else {
        // Navigate to next part of form
        const nextFormPart =
          props.formPart === LandscapeReviewFormParts.PropertyInformation
            ? LandscapeReviewFormParts.PhotoRequirements
            : Object.values(LandscapeReviewFormParts)[
                Object.values(LandscapeReviewFormParts).indexOf(
                  props.formPart
                ) + 1
              ].valueOf();

        navigate(
          `${wildfireRoutePrefix}/${props.wildfireEvaluationId}/landscape-review-form/${nextFormPart}`
        );
      }
    } else {
      handlePartialResponseFormUpdate(response);
    }
  };

  const handlePartialResponseFormUpdate = (response: EvaluationFormModel) => {
    //This is used to combat save on blur issues. This only updates fields that are essetnial to the form.
    //These are set in the API response. Field Counts and sub section completion status
    setLandscapeReviewFormModel((prevState) => {
      if (!prevState) return prevState;

      const {
        allRequiredFieldsCompleted,
        propertyInformationRequiredFieldCount,
        photoRequirementsRequiredFieldCount,
        propertyInformationSubmitted,
        photoRequirementsSubmitted,
        submitted,
        frontPhotoFolder,
        rightSidePhotoFolder,
        backPhotoFolder,
        leftSidePhotoFolder,
        structuresAndOutbuildingsPhotosFolder,
      } = response.wildfireLandscapeReview.landscapeReviewForm;

      return {
        ...prevState,
        wildfireLandscapeReview: {
          ...prevState.wildfireLandscapeReview,
          landscapeReviewForm: {
            ...prevState.wildfireLandscapeReview.landscapeReviewForm,
            allRequiredFieldsCompleted,
            propertyInformationRequiredFieldCount,
            photoRequirementsRequiredFieldCount,
            propertyInformationSubmitted,
            photoRequirementsSubmitted,
            submitted,
            frontPhotoFolder,
            rightSidePhotoFolder,
            backPhotoFolder,
            leftSidePhotoFolder,
            structuresAndOutbuildingsPhotosFolder,
          },
        },
      };
    });
  };

  return {
    builders,
    wieBuilders,
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    landscapeReviewFormModel,
    setShouldSubmit,
    isFirstPartOfForm,
    isLastPartOfForm,
    getLandscapeReviewFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    noBuilders,
    isSubmitDisabled,
    toolTipText,
    containerRef,
    buttonText,
  };
}

export default useLandscapeReviewForm;
