import { useEffect, useState } from "react";
import { WildfireEvaluationHistoryProps } from "./WildfireEvaluationHistory";
import { WildfireEvaluationHistoryEntry } from "../../../types/WildfireApplication/WildfireApplication";
import { wildfireRoutePrefix } from "../../../pages/index/wildfireRouteConfig";
import { WildfireEvaluationSteps } from "../../../../foritfied/types/evaluation/Evaluation";
import { wildfireEvaluationApiClient } from "../../../lib/apiClients/evaluation/wildfireEvaluationApiClient";
import { parseDate } from "../../../../common/utils/date";

const useWildfireEvaluationHistory = (
  props: WildfireEvaluationHistoryProps
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [historyEntries, setHistoryEntries] = useState<
    WildfireEvaluationHistoryEntry[]
  >([]);

  const [currentEvaluation, setCurrentEvaluation] =
    useState<WildfireEvaluationHistoryEntry>();

  const shouldShowEvaluationHistory =
    !!currentEvaluation &&
    historyEntries.some((e) => e.id !== props.evaluationId);

  const shouldHideHyperlink = (evaluation: WildfireEvaluationHistoryEntry) => {
    return evaluation.id === props.evaluationId || props.readonly;
  };

  const getHistoryRowDisplayText = (
    evaluation: WildfireEvaluationHistoryEntry
  ) => {
    const display = evaluation.isLandscapeReview
      ? evaluation.evaluationPhase_AsString
      : "Application";

    return evaluation.isApproved && evaluation.approvedAt
      ? `${display}${" - "}${parseDate(evaluation.approvedAt)}`
      : `${display}${" - "}${evaluation.status_AsString}`;
  };

  const getEvaluationLink = (evaluation: WildfireEvaluationHistoryEntry) => {
    const {
      id,
      step,
      isLandscapeReview,
      currentApplicationFormPart,
      currentFieldEvaluationFormPartURL,
      currentLandscapeReviewFormPart,
    } = evaluation;

    switch (step) {
      case WildfireEvaluationSteps.WildfireApplication:
      case WildfireEvaluationSteps.WildfireApplicationPayment:
      case WildfireEvaluationSteps.WildfireApplicationEligibilityPhotos:
        return `${wildfireRoutePrefix}/${id}/${
          isLandscapeReview
            ? `landscape-review-form/${currentLandscapeReviewFormPart}`
            : `application-form/${currentApplicationFormPart}`
        }`;

      case WildfireEvaluationSteps.WildfireApplicationEvaluatorAssignment:
      case WildfireEvaluationSteps.WildfireApplicationIBHSEligibilityReview:
      case WildfireEvaluationSteps.WildfireApplicationIBHSReview:
        return `${wildfireRoutePrefix}/iteration-engine/${
          isLandscapeReview ? "landscape-review" : "application"
        }/${evaluation.id}`;

      case WildfireEvaluationSteps.WildfireApplicationFieldEvaluations:
        return `${wildfireRoutePrefix}/${id}/${currentFieldEvaluationFormPartURL}`;

      default:
        return `${wildfireRoutePrefix}/board`;
    }
  };

  const fetchHistoryEntries = async () => {
    setIsLoading(true);

    const historyEntries = await wildfireEvaluationApiClient
      .getEvaluationHistorySummary(props.evaluationId)
      .finally(() => setIsLoading(false));

    const currentEvaluation = historyEntries.find(
      (entry) => entry.id === props.evaluationId
    );

    setHistoryEntries(historyEntries);
    setCurrentEvaluation(currentEvaluation);
  };

  useEffect(() => {
    fetchHistoryEntries().catch(console.error);
  }, []);

  return {
    isLoading,
    shouldShowEvaluationHistory,
    historyEntries,
    shouldHideHyperlink,
    getEvaluationLink,
    getHistoryRowDisplayText,
  };
};

export default useWildfireEvaluationHistory;
