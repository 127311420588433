import { Button, Card, Classes, Dialog } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Observer } from "mobx-react";
import React, { useEffect } from "react";
import { useState } from "react";
import { commentApiClient } from "../../../common/apiClients/comments/commentApiClient";
import {
  Comment,
  CommentType,
  ExistingComment,
} from "../../../common/apiClients/comments/types";
import { evaluationApiClient } from "../../lib/apiClients/evaluation/evaluationApiClient";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import { fortifiedGridStore } from "../../stores/FortifiedGridStore";
import { fortifiedUserStore } from "../../stores/FortifiedUserStore";
import { parseLongDate } from "../../../common/utils/date";

import { DialogFooter } from "./DialogFooter";
import { BaseDialogProps } from "./types";
import { permissionStore } from "../../stores/PermissionStore";
import { RichTextEditorField, RichTextEditorValueType } from "@ucl/library";
import { parseUntrustedHtml } from "../../../common/utils/markup";
import { ToolbarSettingsModel } from "@syncfusion/ej2-dropdowns";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";

const defaultCommentFormValue: Comment = {
  evaluationId: "",
  message: "",
  type: CommentType.General,
};

const commentDialogTitle = () => {
  if (!!dialogsViewerStore.commentDialogOpenOptions) {
    const {
      productType_AsString,
      fortifiedId,
      commentType = CommentType.General,
    } = dialogsViewerStore.commentDialogOpenOptions;
    const dialogType =
      commentType === CommentType.Auditor ? "Auditor Comments" : "Comments";
    return `${productType_AsString} - ${fortifiedId} - ${dialogType}`;
  }
};

export interface CommentsDialogProps extends BaseDialogProps {}
export const CommentsDialog: React.FC<CommentsDialogProps> = ({
  isOpen,
  closeDialog,
}) => {
  const [localEvaluationComments, setLocalEvaluationComments] = useState<
    ExistingComment[]
  >([]);

  const [createCommentformValue, setCreateCommentFormValue] =
    React.useState<Comment>(defaultCommentFormValue);

  const [activeComment, setActiveComment] = useState<string>();
  const [activeCommentText, setActiveCommentText] = useState<string>();
  const isActiveComment = (comment: ExistingComment) => {
    return comment.id === activeComment;
  };

  const [needsRefresh, setNeedsRefresh] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen && dialogsViewerStore.commentDialogOpenOptions) {
      const { evaluationId, commentType = CommentType.General } =
        dialogsViewerStore.commentDialogOpenOptions;

      setLocalEvaluationComments([]); // Clear immediately
      setIsLoading(true);

      const getComments =
        commentType === CommentType.Auditor
          ? evaluationApiClient.getEvaluationAuditorComments(evaluationId)
          : evaluationApiClient.getEvaluationComments(evaluationId);

      getComments
        .then((comments) => {
          setLocalEvaluationComments(comments);
        })
        .finally(() => {
          setIsLoading(false);
          setNeedsRefresh(false);
        });
    }
  }, [
    isOpen,
    dialogsViewerStore.commentDialogOpenOptions?.commentType,
    needsRefresh,
  ]);

  return (
    <Dialog
      className="comment-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={commentDialogTitle() || "Evaluation Comments"}
      onClose={closeDialog}
    >
      <Observer>
        {() => (
          <div className={Classes.DIALOG_BODY}>
            {isLoading && <Loading />}

            {localEvaluationComments &&
              localEvaluationComments.map((comment) => {
                return (
                  <Card className="comment-card" key={comment.id}>
                    <div className="comment-card-header">
                      <div>
                        <div>
                          <div>
                            {!permissionStore.canViewAuditorName &&
                            fortifiedUserStore.isAnAuditor(comment.creatorRole)
                              ? `(${comment.creatorRole_AsString})`
                              : `${comment.createdBy_AsName} (${comment.creatorRole_AsString})`}
                          </div>
                        </div>
                        <div className="comment-card-date">
                          {parseLongDate(comment.createdAt)}
                        </div>
                      </div>
                      <div className="comment-card-actions">
                        {!comment.canModify && !comment.isRead && (
                          <Button
                            className="comment-card-view-button"
                            minimal={true}
                            icon={IconNames.EYE_ON}
                            onClick={async () => {
                              commentApiClient
                                .markCommentAsRead(comment.id)
                                .then(() => {
                                  fortifiedGridStore.refresh();
                                })
                                .finally(() => {
                                  setNeedsRefresh(true);
                                });
                            }}
                          >
                            Mark As Read
                          </Button>
                        )}

                        {comment.isRead &&
                          comment.viewedBy !== fortifiedUserStore.user?.id && (
                            <div className="comment-card-viewer-name">
                              {`Viewed By: ${comment.viewedBy_AsName}`}
                            </div>
                          )}
                        {!!comment.isRead &&
                          comment.viewedBy === fortifiedUserStore.user?.id && (
                            <div className="comment-card-viewer-name">
                              <Button
                                className="comment-card-view-button"
                                minimal={true}
                                icon={IconNames.EYE_OFF}
                                onClick={async () => {
                                  commentApiClient
                                    .markCommentAsUnread(comment.id)
                                    .then(() => {
                                      fortifiedGridStore.refresh();
                                    })
                                    .finally(() => {
                                      setNeedsRefresh(true);
                                    });
                                }}
                              >
                                Mark As Unread
                              </Button>
                            </div>
                          )}

                        {comment.canModify && (
                          <>
                            {isActiveComment(comment) && (
                              <Button
                                icon={IconNames.FLOPPY_DISK}
                                minimal={true}
                                onClick={async () => {
                                  await commentApiClient
                                    .updateComment(comment.id, {
                                      ...comment,
                                      message: activeCommentText,
                                    })
                                    .then(() => {
                                      setActiveComment(undefined);
                                    })
                                    .finally(() => {
                                      setNeedsRefresh(true);
                                    });
                                }}
                              />
                            )}
                            {!isActiveComment(comment) && (
                              <Button
                                icon={IconNames.EDIT}
                                minimal={true}
                                onClick={() => {
                                  setActiveComment(comment.id);
                                  setActiveCommentText(comment.message);
                                }}
                              />
                            )}

                            <Button
                              icon={IconNames.TRASH}
                              minimal={true}
                              onClick={async () => {
                                commentApiClient
                                  .deleteComment(comment.id)
                                  .finally(() => {
                                    setNeedsRefresh(true);
                                  });
                              }}
                            ></Button>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="base-field">
                      {isActiveComment(comment) ? (
                        <RichTextEditorField
                          className="comment-textarea"
                          valueType={RichTextEditorValueType.html}
                          value={activeCommentText}
                          onSubmit={(value) => {
                            setActiveCommentText(value ?? "");
                          }}
                          richTextEditorSettings={{
                            height: "200px",
                            enableHtmlSanitizer: true,
                            autoSaveOnIdle: true,
                            saveInterval: 50,
                            toolbarSettings: {
                              items: [
                                "OrderedList",
                                "UnorderedList",
                                "Bold",
                                "Underline",
                                "Italic",
                                "StrikeThrough",
                                "Formats",
                                "ClearFormat",
                              ],
                            } as ToolbarSettingsModel,
                            pasteCleanupSettings: {
                              prompt: false,
                              plainText: false,
                              keepFormat: true,
                              allowedStyleProps: [""],
                              deniedAttrs: ["class", "id", "style"],
                            },
                          }}
                        />
                      ) : (
                        <div>{parseUntrustedHtml(comment.message ?? "")}</div>
                      )}
                    </div>
                  </Card>
                );
              })}
          </div>
        )}
      </Observer>
      {permissionStore.canCreateBoardComments ? (
        <DialogFooter
          closeDialog={closeDialog}
          submitButtonText={"Add"}
          closeButtonText={"Cancel"}
          submitDialog={async () => {
            if (!!dialogsViewerStore.commentDialogOpenOptions) {
              createCommentformValue.evaluationId =
                dialogsViewerStore.commentDialogOpenOptions.evaluationId;
              createCommentformValue.type =
                dialogsViewerStore.commentDialogOpenOptions.commentType ===
                CommentType.Auditor
                  ? CommentType.Auditor
                  : CommentType.General;

              await commentApiClient
                .createComment(createCommentformValue)
                .finally(() => {
                  setCreateCommentFormValue(defaultCommentFormValue);
                  setNeedsRefresh(true);
                });
            }
          }}
        >
          <RichTextEditorField
            className="comment-textarea"
            valueType={RichTextEditorValueType.html}
            value={createCommentformValue.message}
            onSubmit={(value) => {
              setCreateCommentFormValue((prev) => ({
                ...prev,
                message: value ?? "",
              }));
            }}
            richTextEditorSettings={{
              height: "200px",
              enableHtmlSanitizer: true,
              autoSaveOnIdle: true,
              saveInterval: 50,
              toolbarSettings: {
                items: [
                  "OrderedList",
                  "UnorderedList",
                  "Bold",
                  "Underline",
                  "Italic",
                  "StrikeThrough",
                  "Formats",
                  "ClearFormat",
                ],
              } as ToolbarSettingsModel,
              pasteCleanupSettings: {
                prompt: false,
                plainText: false,
                keepFormat: true,
                allowedStyleProps: [""],
                deniedAttrs: ["class", "id", "style"],
              },
            }}
          />
        </DialogFooter>
      ) : (
        <DialogFooter
          closeDialog={closeDialog}
          closeButtonText={"Close"}
          isSubmitButtonHidden={true}
          submitDialog={async () => undefined} // Do nothing
        />
      )}
    </Dialog>
  );
};
