import {
  EnumRadioFieldValue,
  EnumSingleSelectFieldValue,
  SingleCheckboxFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { IInstallerComplianceFormGeneralInformation } from "./BaseHomeEvaluationInstallerComplianceGablesFormModel";

export enum BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts {
  ContinuousLoadPathGeneralInformation = "continuous-load-path-general-information",
  ContinuousLoadPathForNewConstruction = "continuous-load-path-for-new-construction",
  ContinuousLoadPathForRetrofitOfExistingHome = "continuous-load-path-for-retrofit-of-existing-home",
  ContinuousLoadPathForWoodFraming = "continuous-load-path-for-wood-framing",
}

export interface BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormModel
  extends IInstallerComplianceFormGeneralInformation,
    IInstallerComplianceFormContinuousLoadPathForNewConstruction,
    IInstallerComplianceFormContinuousLoadPathForRetrofitOfExistingHome,
    IInstallerComplianceFormContinuousLoadPathForWoodFraming {}

export interface IInstallerComplianceFormContinuousLoadPathForNewConstruction {
  attestation321?: SingleCheckboxFieldValue;
  attestation322?: SingleCheckboxFieldValue;
  attestation323?: SingleCheckboxFieldValue;
  attestation324?: SingleCheckboxFieldValue;
  doesHomeHaveInstalledConcreteCMUWalls?: EnumRadioFieldValue;
  attestation326?: SingleCheckboxFieldValue;
  attestation327?: SingleCheckboxFieldValue;
  attestation328?: SingleCheckboxFieldValue;
  attestation329?: SingleCheckboxFieldValue;
  installedFoundationType?: EnumSingleSelectFieldValue;
  attestation331?: SingleCheckboxFieldValue;
  attestation332?: SingleCheckboxFieldValue;
  attestation333?: SingleCheckboxFieldValue;
  attestation334?: SingleCheckboxFieldValue;
  attestation335?: SingleCheckboxFieldValue;
  installedRoofToWallConnectionsDescriptionType?: EnumRadioFieldValue;
  attestation337?: SingleCheckboxFieldValue;
  isHomeMoreThan1Story?: EnumRadioFieldValue;
  installedWallAboveToWallBelowConnectionsDescriptionType?: EnumSingleSelectFieldValue;
  attestation340?: SingleCheckboxFieldValue;
  attestation341?: SingleCheckboxFieldValue;
  attestation342?: SingleCheckboxFieldValue;
}

export interface IInstallerComplianceFormContinuousLoadPathForRetrofitOfExistingHome {
  attestation343?: SingleCheckboxFieldValue;
}

export interface IInstallerComplianceFormContinuousLoadPathForWoodFraming {
  attestation3441?: SingleCheckboxFieldValue;
  attestation3442?: SingleCheckboxFieldValue;
}
