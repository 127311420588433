export enum FormTypes {
  // Home Standard Forms 1-100
  HomeStandardEvaluationBasicInformationForm = 1,
  HomeStandardEvaluationRoofingComplianceGeneralInformationForm = 2,
  HomeStandardEvaluationRoofingComplianceRoofCoverForm = 3,
  HomeStandardEvaluationRooferConfirmationForm = 4,
  HomeStandardRedesignationHomeownerAuthorizationForm = 5,
  HomeStandardEvaluationEngineerConfirmationForm = 6,
  HomeStandardEvaluationSilverGoldComplianceGeneralInformationForm = 7,
  HomeStandardEvaluationInstallingContractorConfirmationForm = 8,
  HomeStandardEvaluationInstallerComplianceGablesForm = 9,
  HomeStandardEvaluationInstallerComplianceContinuousLoadPathForm = 10,
  HomeStandardEvaluationInstallerComplianceChimneyForm = 11,
  HomeStandardEvaluationInstallerComplianceAttachedStructureForm = 12,
  HomeStandardEvaluationEngineeringComplianceGeneralInformationForm = 13,
  HomeStandardEvaluationEngineeringComplianceGablesForm = 14,
  HomeStandardEvaluationEngineeringComplianceContinuousLoadPathForm = 15,
  HomeStandardEvaluationEngineeringComplianceChimneyForm = 16,
  HomeStandardEvaluationEngineeringComplianceAttachedStructureForm = 17,
  HomeStandardEvaluationEligibilityForm = 18,
  HomeStandardRedesignationForm = 19,

  // Home NCIUA Forms 101-200
  HomeNCIUAEvaluationForm = 101,
  HomeNCIUAEvaluationRoofingComplianceRoofCoverForm = 102,
  HomeNCIUAEvaluationRoofingComplianceGeneralInformationForm = 103,
  HomeNCIUAEvaluationRooferConfirmationForm = 104,
  HomeNCIUAEvaluationEligibilityForm = 105,
  HomeNCIUARedesignationRedesignationForm = 106,
  HomeNCIUARedesignationHomeownerAuthorizationForm = 107,

  // Home SCSHE Forms 201-300
  HomeSCSHEvaluationForm = 201,
  HomeSCSHEvaluationBasicInformationForm = 202,

  // Wildfire Forms 301-400
  WildfireApplicationForm = 301,
  WildfireRoofFieldEvaluationForm = 302,
  WildfireHomeExteriorFieldEvaluationForm = 303,
  WildfireLandscapeFieldEvaluationForm = 304,
  WildfireOtherFieldEvaluationForm = 305,

  // Multifamily Evaluation Forms 401-500
  MultifamilyEvaluationApplicationForm = 401,
  MultifamilyEvaluationCompanyAssignmentForm = 402,
  MultifamilyEvaluationComplianceForm = 403,
  MultifamilyEvaluationComplianceLetterForm = 404,
  MultifamilyEvaluationFieldInspectionForm = 405,
  MultifamilyRedesignationRedesignationForm = 406,
  MultifamilyRedesignationEvaluatorEmailForm = 407,
  MultifamilyRedesignationComplianceLetterForm = 408,
  MultifamilyRedesignationFieldInspectionForm = 409,

  // Commercial Evaluation Forms 501-600
  CommercialEvaluationApplicationForm = 501,
  CommercialEvaluationCompanyAssignmentForm = 502,
  CommercialEvaluationFieldInspectionForm = 503,
  CommercialEvaluationComplianceForm = 504,
  CommercialEvaluationComplianceLetter = 505,
  CommercialRedesignationRedesignationForm = 506,
  CommercialRedesignationEvaluatorEmailForm = 507,
  CommercialRedesignationComplianceLetterForm = 508,
  CommercialRedesignationFieldInspectionForm = 509,
}

export interface FormTypeOption {
  label: string;
  subcategory?: string;
  value: FormTypes;
}

export const FormTypeOptions: { [key: number]: FormTypeOption[] } = {
  // Commercial Evaluation Forms
  1: [
    {
      label: "Application Form",
      value: FormTypes.CommercialEvaluationApplicationForm,
    },
    {
      label: "Company Assignment Form",
      value: FormTypes.CommercialEvaluationCompanyAssignmentForm,
    },
    {
      label: "Field Inspection Form",
      value: FormTypes.CommercialEvaluationFieldInspectionForm,
    },
    {
      label: "Compliance Form",
      value: FormTypes.CommercialEvaluationComplianceForm,
    },
    {
      label: "Compliance Letter Form",
      value: FormTypes.CommercialEvaluationComplianceLetter,
    },
    {
      label: "Redesignation Form",
      value: FormTypes.CommercialRedesignationRedesignationForm,
    },
    {
      label: "Evaluator Email Form",
      value: FormTypes.CommercialRedesignationEvaluatorEmailForm,
    },
    {
      label: "Redesignation Compliance Letter Form",
      value: FormTypes.CommercialRedesignationComplianceLetterForm,
    },
    {
      label: "Field Inspection Form",
      value: FormTypes.CommercialRedesignationFieldInspectionForm,
    },
  ],
  // Multifamily Evaluation Forms
  2: [
    {
      label: "Application Form",
      value: FormTypes.MultifamilyEvaluationApplicationForm,
    },
    {
      label: "Company Assignment Form",
      value: FormTypes.MultifamilyEvaluationCompanyAssignmentForm,
    },
    {
      label: "Field Inspection Form",
      value: FormTypes.MultifamilyEvaluationComplianceForm,
    },
    {
      label: "Compliance Form",
      value: FormTypes.MultifamilyEvaluationComplianceLetterForm,
    },
    {
      label: "Compliance Letter Form",
      value: FormTypes.MultifamilyEvaluationFieldInspectionForm,
    },
    {
      label: "Redesignation Form",
      value: FormTypes.MultifamilyRedesignationRedesignationForm,
    },
    {
      label: "Evaluator Email Form",
      value: FormTypes.MultifamilyRedesignationEvaluatorEmailForm,
    },
    {
      label: "Redesignation Compliance Letter Form",
      value: FormTypes.MultifamilyRedesignationComplianceLetterForm,
    },
    {
      label: "Field Inspection Form",
      value: FormTypes.MultifamilyRedesignationFieldInspectionForm,
    },
  ],
  // Home Evaluation Forms
  3: [
    {
      label: "Standard Basic Information Form",
      value: FormTypes.HomeStandardEvaluationBasicInformationForm,
    },
    {
      label: "Standard Roofing Compliance General Information Form",
      value:
        FormTypes.HomeStandardEvaluationRoofingComplianceGeneralInformationForm,
    },
    {
      label: "Standard Roofing Compliance Roof Cover Form",
      value: FormTypes.HomeStandardEvaluationRoofingComplianceRoofCoverForm,
    },
    {
      label: "Standard Roofer Confirmation Form",
      value: FormTypes.HomeStandardEvaluationRooferConfirmationForm,
    },
    {
      label: "Standard Homeowner Authorization Form",
      value: FormTypes.HomeStandardRedesignationHomeownerAuthorizationForm,
    },
    {
      label: "Standard Engineer Confirmation Form",
      value: FormTypes.HomeStandardEvaluationEngineerConfirmationForm,
    },
    {
      label: "Standard Silver Gold Compliance General Information Form",
      value:
        FormTypes.HomeStandardEvaluationSilverGoldComplianceGeneralInformationForm,
    },
    {
      label: "Standard Installing Contractor Confirmation Form",
      value:
        FormTypes.HomeStandardEvaluationInstallingContractorConfirmationForm,
    },
    {
      label: "Standard Installer Compliance Gables Form",
      value: FormTypes.HomeStandardEvaluationInstallerComplianceGablesForm,
    },
    {
      label: "Standard Installer Compliance Continuous Load Path Form",
      value:
        FormTypes.HomeStandardEvaluationInstallerComplianceContinuousLoadPathForm,
    },
    {
      label: "Standard Installer Compliance Chimney Form",
      value: FormTypes.HomeStandardEvaluationInstallerComplianceChimneyForm,
    },
    {
      label: "Standard Installer Compliance Attached Structure Form",
      value:
        FormTypes.HomeStandardEvaluationInstallerComplianceAttachedStructureForm,
    },
    {
      label: "Standard Engineering Compliance General Information Form",
      value:
        FormTypes.HomeStandardEvaluationEngineeringComplianceGeneralInformationForm,
    },
    {
      label: "Standard Engineering Compliance Gables Form",
      value: FormTypes.HomeStandardEvaluationEngineeringComplianceGablesForm,
    },
    {
      label: "Standard Engineering Compliance Continuous Load Path Form",
      value:
        FormTypes.HomeStandardEvaluationEngineeringComplianceContinuousLoadPathForm,
    },
    {
      label: "Standard Engineering Compliance Chimney Form",
      value: FormTypes.HomeStandardEvaluationEngineeringComplianceChimneyForm,
    },
    {
      label: "Standard Engineering Compliance Attached Structure Form",
      value:
        FormTypes.HomeStandardEvaluationEngineeringComplianceAttachedStructureForm,
    },
    {
      label: "NCIUA Evaluation Form",
      value: FormTypes.HomeNCIUAEvaluationForm,
    },
    {
      label: "NCIUA Roofing Compliance Roof Cover Form",
      value: FormTypes.HomeNCIUAEvaluationRoofingComplianceRoofCoverForm,
    },
    {
      label: "NCIUA Roofing Compliance General Information Form",
      value:
        FormTypes.HomeNCIUAEvaluationRoofingComplianceGeneralInformationForm,
    },
    {
      label: "NCIUA Roofer Confirmation Form",
      value: FormTypes.HomeNCIUAEvaluationRooferConfirmationForm,
    },
    {
      label: "NCIUA Redesignation Form",
      value: FormTypes.HomeNCIUARedesignationRedesignationForm,
    },
    {
      label: "NCIUA Redesignation Homeowner Authorization Form",
      value: FormTypes.HomeNCIUARedesignationHomeownerAuthorizationForm,
    },
    {
      label: "NCIUA Roofer Confirmation Form",
      value: FormTypes.HomeNCIUAEvaluationRooferConfirmationForm,
    },
    {
      label: "SCSH Evaluation Form",
      value: FormTypes.HomeSCSHEvaluationForm,
    },
    {
      label: "SCSH Basic Information Form",
      value: FormTypes.HomeSCSHEvaluationBasicInformationForm,
    },
  ],
  // Wildfire Evaluation Forms
  4: [
    { label: "Application Form", value: FormTypes.WildfireApplicationForm },
    {
      label: "Roof Field Evaluation Form",
      value: FormTypes.WildfireRoofFieldEvaluationForm,
    },
    {
      label: "Home Exterior Field Evaluation Form",
      value: FormTypes.WildfireHomeExteriorFieldEvaluationForm,
    },
    {
      label: "Landscape Field Evaluation Form",
      value: FormTypes.WildfireLandscapeFieldEvaluationForm,
    },
    {
      label: "Other Field Evaluation Form",
      value: FormTypes.WildfireOtherFieldEvaluationForm,
    },
  ],
};

export const getFormTypeFromField = (field: string): FormTypes | undefined => {
  // field is in the format of Evaluation__<SystemName/ProductType?>__<FormType>__<FieldName>
  const parts = field.split("__");
  if (parts.length < 3) {
    return undefined;
  }

  const productName = parts[1]; // can be HomeStandardEvaluation, HomeNCIUAEvaluation, HomeSCSHEvaluation, or WildfireApplication, etc.
  const exactForm = parts[2]; // form name (RoofingComplianceRoofCoverForm, RoofingComplianceGeneralInformationForm, etc.)
  let formTypeKey = ""; // should match a key in FormTypes enum

  switch (productName) {
    case "WildfireApplication":
      formTypeKey = "Wildfire" + exactForm;
      break;
    case "HomeStandardEvaluation":
      formTypeKey = `${productName}${exactForm}`;
      break;
    case "HomeSCSHEvaluation":
      formTypeKey = `${productName}${exactForm}`;
      break;
    case "HomeNCIUAEvaluation":
      formTypeKey = `${productName}${exactForm}`;
      break;
    case "HomeNCIUARedesignation":
      formTypeKey = `${productName}${exactForm}`;
      break;
    case "CommercialEvaluation":
      formTypeKey = `${exactForm}`;
      break;
    case "CommercialRedesignation":
      formTypeKey = `${productName}${exactForm}`;
      break;
    case "MultifamilyEvaluation":
      formTypeKey = `${exactForm}`;
      break;
    case "MultifamilyRedesignation":
      formTypeKey = `${productName}${exactForm}`;
      break;
    default:
      break;
  }

  return FormTypes[formTypeKey as keyof typeof FormTypes];
};
