import {
  homeNCIUABasicInformationFormBuilder,
  homeNCIUAHomeownerInformationBuilder,
} from "../../../nciua/components/Forms/BasicInformationForm/HomeNCIUABasicInformationFormBuilder";
import {
  homeNCIUADesignationTypeAndLevelInformationFieldsBuilder,
  homeNCIUAEligibilityFormBuilder,
  homeNCIUAEligibilityInformationBuilder,
} from "../../../nciua/components/Forms/EligibilityForm/HomeNCIUAEligibilityFormBuilder";
import {
  homeNCIUAEvaluationFormInformationBuilder,
  homeNCIUAEvaluationInformationBuilder,
} from "../../../nciua/components/Forms/NCIUAEvaluationForm/HomeNCIUAEvaluationFormBuilder";
import { rooferConfirmationFormBuilder } from "../../../nciua/components/Forms/RooferConfirmationForm/HomeNCIUARooferConfirmationFormBuilder";
import {
  homeNCIUARoofingComplianceGeneralInformationFormBuilder,
  homeStandardAtticVentsBuilderNCIUA,
  homeStandardBasicInformationBuilderNCIUA,
  homeStandardDripEdgeBuilderNCIUA,
  homeStandardPermitInformationBuilderNCIUA,
  homeStandardPhotoVoltaicPanelsBuilderNCIUA,
  homeStandardRoofSheathingBuilderNCIUA,
  homeStandardRoofStructureBuilderNCIUA,
  homeStandardSealedRoofDeckBuilderNCIUA,
  homeStandardSiteConditionsBuilderNCIUA,
  homeStandardSkyLightsBuilderNCIUA,
} from "../../../nciua/components/Forms/RoofingComplianceGeneralInformationForm/HomeNCIUARoofingComplianceGeneralInformationFormBuilder";
import {
  asphaltShingleRoofCoverDetailsBuilderNCIUA,
  clayConcreteTileRoofCoverDetailsBuilderNCIUA,
  homeNCIUARoofingComplianceRoofCoverFormBuilder,
  lowSlopeRoofCoverDetailsFieldsBuilderNCIUA,
  metalRoofCoverDetailsFieldsBuilderNCIUA,
  roofingComplianceRoofCoverInformationBuilderNCIUA,
  woodShakeShingleRoofCoverDetailsFieldsBuilderNCIUA,
} from "../../../nciua/components/Forms/RoofingComplianceRoofCoverForm/HomeNCIUARoofingComplianceRoofCoverFormBuilder";
import { homeAuthorizationFormBuilderNCIUARedesignation } from "../../../nciuaRedesignation/components/Forms/HomeownerAuthorizationForm/HomeNCIUARedesignationHomeownerAuthorizationFormBuilder";
import { homeNCIUARedesignationFormBuilder } from "../../../nciuaRedesignation/components/Forms/RedesignationForm/HomeNCIUARedesignationFormBuilder";

export const HomeNCIUARoofingComplianceRoofCoverForm = [
  "HomeNCIUARoofingComplianceRoofCoverForm",
  "HomeNCIUARoofCoverInformation",
  "HomeNCIUAAsphaltShingleRoofCoverDetails",
  "HomeNCIUAMetalRoofCoverDetails",
  "HomeNCIUAClayConcreteTileRoofCoverDetails",
  "HomeNCIUAWoodShakeSingleRoofCoverDetails",
  "HomeNCIUALowSlopeRoofCoverDetails",
];

export const HomeNCIUAEvaluationForm = [
  "HomeNCIUAEvaluationForm",
  "HomeNCIUAEvaluationInformation",
];

export const HomeNCIUARoofingComplianceGeneralInformationForm = [
  "HomeNCIUARoofingComplianceGeneralInformationForm",
  "HomeNCIUARoofingComplianceBasicInformationForm",
  "HomeNCIUARoofStructureForm",
  "HomeNCIUARoofSheathingForm",
  "HomeNCIUADripEdgeForm",
  "HomeNCIUASkyLightsForm",
  "HomeNCIUAPhotoVoltaicPanelsForm",
  "HomeNCIUAAtticVentsForm",
  "HomeNCIUAPermitInformationForm",
  "HomeNCIUASiteConditionsForm",
  "HomeNCIUASealedRoofDeckForm",
];

export const HomeNCIUARooferConfirmationForm = [
  "HomeNCIUARooferConfirmationForm",
];

export const HomeNCUIARedesignationHomeownerAuthorizationForm = [
  "HomeNCUIARedesignationHomeownerAuthorizationForm",
];

export const HomeNCUIARedesignationForm = ["HomeNCUIARedesignationForm"];

export const HomeNCIUAEligibilityForm = [
  "HomeNCIUAEligibilityForm",
  "HomeNCIUADesignationTypeAndLevelInformation",
  "HomeNCIUAEligibilityInformation",
];

export const HomeNCIUABasicInformationForm = [
  "HomeNCIUABasicInformationForm",
  "HomeNCIUAHomeownerInformation",
];

export const HomeNCIUAForms = [
  ...HomeNCIUABasicInformationForm,
  ...HomeNCIUAEvaluationForm,
  ...HomeNCIUARoofingComplianceRoofCoverForm,
  ...HomeNCIUARoofingComplianceGeneralInformationForm,
  ...HomeNCIUARooferConfirmationForm,
  ...HomeNCUIARedesignationHomeownerAuthorizationForm,
  ...HomeNCUIARedesignationForm,
  ...HomeNCIUAEligibilityForm,
];

export const HomeNCIUAFormsConfig = {
  HomeNCIUABasicInformationForm: undefined,
  HomeNCIUAHomeownerInformation: undefined,
  HomeNCIUAEvaluationForm: undefined,
  HomeNCIUAEvaluationInformation: undefined,
  HomeNCIUARoofingComplianceRoofCoverForm: undefined,
  HomeNCIUARoofCoverInformation: undefined,
  HomeNCIUAAsphaltShingleRoofCoverDetails: undefined,
  HomeNCIUAMetalRoofCoverDetails: undefined,
  HomeNCIUAClayConcreteTileRoofCoverDetails: undefined,
  HomeNCIUAWoodShakeSingleRoofCoverDetails: undefined,
  HomeNCIUALowSlopeRoofCoverDetails: undefined,
  HomeNCIUARooferConfirmationForm: undefined,
  HomeNCUIARedesignationHomeownerAuthorizationForm: undefined,
  HomeNCUIARedesignationForm: undefined,
  HomeNCIUAEligibilityForm: undefined,
  HomeNCIUADesignationTypeAndLevelInformation: undefined,
  HomeNCIUAEligibilityInformation: undefined,
};

export const HomeNCIUABuilders = {
  HomeNCIUABasicInformationForm: homeNCIUABasicInformationFormBuilder,
  HomeNCIUAHomeownerInformation: homeNCIUAHomeownerInformationBuilder,
  HomeNCIUAEvaluationForm: homeNCIUAEvaluationFormInformationBuilder,
  HomeNCIUAEvaluationInformation: homeNCIUAEvaluationInformationBuilder,
  HomeNCIUARoofingComplianceRoofCoverForm:
    homeNCIUARoofingComplianceRoofCoverFormBuilder,
  HomeNCIUARoofCoverInformation:
    roofingComplianceRoofCoverInformationBuilderNCIUA,
  HomeNCIUAAsphaltShingleRoofCoverDetails:
    asphaltShingleRoofCoverDetailsBuilderNCIUA,
  HomeNCIUAMetalRoofCoverDetails: metalRoofCoverDetailsFieldsBuilderNCIUA,
  HomeNCIUAClayConcreteTileRoofCoverDetails:
    clayConcreteTileRoofCoverDetailsBuilderNCIUA,
  HomeNCIUAWoodShakeSingleRoofCoverDetails:
    woodShakeShingleRoofCoverDetailsFieldsBuilderNCIUA,
  HomeNCIUALowSlopeRoofCoverDetails: lowSlopeRoofCoverDetailsFieldsBuilderNCIUA,
  HomeNCIUARoofingComplianceGeneralInformationForm:
    homeNCIUARoofingComplianceGeneralInformationFormBuilder,
  HomeNCIUARoofingComplianceBasicInformationForm:
    homeStandardBasicInformationBuilderNCIUA,
  HomeNCIUARoofStructureForm: homeStandardRoofStructureBuilderNCIUA,
  HomeNCIUARoofSheathingForm: homeStandardRoofSheathingBuilderNCIUA,
  HomeNCIUADripEdgeForm: homeStandardDripEdgeBuilderNCIUA,
  HomeNCIUASkyLightsForm: homeStandardSkyLightsBuilderNCIUA,
  HomeNCIUAPhotoVoltaicPanelsForm: homeStandardPhotoVoltaicPanelsBuilderNCIUA,
  HomeNCIUAAtticVentsForm: homeStandardAtticVentsBuilderNCIUA,
  HomeNCIUAPermitInformationForm: homeStandardPermitInformationBuilderNCIUA,
  HomeNCIUASiteConditionsForm: homeStandardSiteConditionsBuilderNCIUA,
  HomeNCIUASealedRoofDeckForm: homeStandardSealedRoofDeckBuilderNCIUA,
  HomeNCIUARooferConfirmationForm: rooferConfirmationFormBuilder,
  HomeNCUIARedesignationHomeownerAuthorizationForm:
    homeAuthorizationFormBuilderNCIUARedesignation,
  HomeNCUIARedesignationForm: homeNCIUARedesignationFormBuilder,
  HomeNCIUAEligibilityForm: homeNCIUAEligibilityFormBuilder,
  HomeNCIUADesignationTypeAndLevelInformation:
    homeNCIUADesignationTypeAndLevelInformationFieldsBuilder,
  HomeNCIUAEligibilityInformation: homeNCIUAEligibilityInformationBuilder,
};
