import { PrimaryCard } from "@ucl/library";
import { useContext, useEffect, useState } from "react";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import classNames from "classnames";
import { EvaluationStep } from "../../../../../../foritfied/types/evaluation/Evaluation";
import { UnsavedChangesBlockerContext } from "../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import HomeNCIUAEvaluationForm from "../NCIUAEvaluationForm/Form/HomeNCIUAEvaluationForm";
import HomeNCIUAEvaluationRoofingComplianceRoofCoverForm from "../RoofingComplianceRoofCoverForm/Form/HomeNCIUAEvaluationRoofingComplianceRoofCoverForm";
import HomeNCIUAEvaluationRoofingComplianceGeneralInformationForm from "../RoofingComplianceGeneralInformationForm/Form/HomeNCIUAEvaluationRoofingComplianceGeneralInformationForm";
import HomeNCIUAEvaluationRooferConfirmationForm from "../RooferConfirmationForm/Form/HomeNCIUAEvaluationRooferConfirmationForm";
import HomeNCIUAEvaluationEligibilityForm from "../EligibilityForm/Form/HomeNCIUAEvaluationEligibilityForm";
import HomeNCIUAEvaluationBasicInformationForm from "../BasicInformationForm/Form/HomeNCIUAEvaluationBasicInformationForm";

interface HomeNCIUAIterationEngineFormsProps {
  selectedTabId: string;
  evaluationId: string;
  evaluationStep: EvaluationStep;
  onFormSave: (fieldKey: string) => Promise<void>;
  onAllFormsInitialized: () => void;
  fieldsContainerClassName: string;
}

const HomeNCIUAIterationEngineForms: React.FC<
  HomeNCIUAIterationEngineFormsProps
> = (props) => {
  const [evaluationFormInitiaized, setEvaluationFormInitiaized] =
    useState<boolean>();

  const [
    roofingComplianceRoofCoverForm,
    setRoofingComplianceRoofCoverFormInitialized,
  ] = useState<boolean>(false);

  const [
    roofingComplianceGeneralInformationForm,
    setRoofingComplianceGeneralInformationFormInitialized,
  ] = useState<boolean>(false);

  const [rooferConfirmationForm, setRooferConfirmationFormInitialized] =
    useState<boolean>(false);

  const [eligibilityFormInitiaized, setEligibilityFormInitiaized] =
    useState<boolean>();

  const [basicInformationFormInitiaized, setBasicInformationFormInitiaized] =
    useState<boolean>();

  const [allFormsInitialized, setAllFormsInitialized] = useState(false);

  useEffect(() => {
    const formsInitialized =
      evaluationFormInitiaized ||
      roofingComplianceRoofCoverForm ||
      roofingComplianceGeneralInformationForm ||
      rooferConfirmationForm ||
      basicInformationFormInitiaized ||
      eligibilityFormInitiaized;

    if (formsInitialized) {
      setTimeout(() => {
        setAllFormsInitialized(true);
        props.onAllFormsInitialized();
      }, 600);
    } else {
      setAllFormsInitialized(false);
    }
  }, [
    evaluationFormInitiaized,
    roofingComplianceRoofCoverForm,
    roofingComplianceGeneralInformationForm,
    rooferConfirmationForm,
    eligibilityFormInitiaized,
    basicInformationFormInitiaized,
  ]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const getFormAndHeaderContent = () => {
    switch (props.selectedTabId) {
      case "RoofingComplianceRoofCoverForm":
        return {
          headerContent: "Roofing Compliance: Roof Cover Form",
          form: (
            <HomeNCIUAEvaluationRoofingComplianceRoofCoverForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setRoofingComplianceRoofCoverFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "EligibilityForm":
        return {
          headerContent: "Eligibility Form",
          form: (
            <HomeNCIUAEvaluationEligibilityForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setEligibilityFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "RoofingComplianceGeneralInformationForm":
        return {
          headerContent: "Roofing Compliance: General Information Form",
          form: (
            <HomeNCIUAEvaluationRoofingComplianceGeneralInformationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setRoofingComplianceGeneralInformationFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "RooferConfirmationForm":
        return {
          headerContent: "Roofer Confirmation Form",
          form: (
            <HomeNCIUAEvaluationRooferConfirmationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setRooferConfirmationFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "HomeNCIUAEvaluationForm":
        return {
          headerContent: "Home NCIUA Evaluation Form",
          form: (
            <HomeNCIUAEvaluationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setEvaluationFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      default:
        return {
          headerContent: "Home NCIUA Basic Information Form",
          form: (
            <HomeNCIUAEvaluationBasicInformationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setBasicInformationFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
    }
  };

  const { headerContent, form } = getFormAndHeaderContent();

  return (
    <>
      {!allFormsInitialized && <Loading />}
      <div
        className={classNames(props.fieldsContainerClassName, {
          "evaluation-forms-container-initialized": allFormsInitialized,
        })}
      >
        <PrimaryCard headerContent={headerContent}>{form}</PrimaryCard>
      </div>
    </>
  );
};

export default HomeNCIUAIterationEngineForms;
