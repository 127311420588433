import { FieldFormV2Module } from "@ucl/library";
import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../../../../common/Components/Error/ErrorStore";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { useNavigate } from "react-router";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../../types/HomeEvaluationFormModel";
import { HomeStandardEvaluationBasicInformationFormProps } from "./HomeStandardEvaluationBasicInformationForm";
import { HomeBasicInformationFormParts } from "../../../../../common/types/BaseHomeEvaluationBasicInformationFormModel";
import { homeStandardEvaluationAPIClient } from "../../../../lib/apiClients/homeStandardEvaluationAPIClient";
import { homeStandardEvaluationBasicInformationAPIClient } from "../../../../lib/apiClients/homeStandardEvaluationBasicInformationAPIClient";
import { fortifiedHomeStandardRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { useHomeFieldSchemaFactory } from "../../../../../customHooks/useHomeFieldSchemaFactory";

function useHomeStandardEvaluationBasicInformationForm(
  props: HomeStandardEvaluationBasicInformationFormProps
) {
  const { builders, wieBuilders } = useHomeFieldSchemaFactory();
  const navigate = useNavigate();

  const formRef =
    useRef<FieldFormV2Module<Evaluation_HomeStandardEvaluationFormModel>>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [applicationFormModel, setApplicationFormModel] =
    useState<Evaluation_HomeStandardEvaluationFormModel>();

  const [isInitialized, setIsInitialized] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
      props.onInitialized?.();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isInitialized) {
      props.onInitialized?.();
    }
  }, [isInitialized]);

  useEffect(() => {
    //Used to React to External Changes Pushed on the Form (Iteration Engine)
    if (applicationFormModel) {
      setApplicationFormModel({ ...applicationFormModel });
    }
  }, [wieBuilders]);

  useEffect(() => {
    fetchApplicationForm();
  }, []);

  useEffect(() => {
    if (!!applicationFormModel) {
      setApplicationFormModel(applicationFormModel);

      if (props.setFormModel) {
        props.setFormModel(applicationFormModel);
      }
    }
  }, [applicationFormModel]);

  // Differentiates between save and submit
  let shouldSubmit = false;
  const setShouldSubmit = (value: boolean) => {
    shouldSubmit = value;
  };

  const isFirstPartOfForm =
    props.formPart === Object.values(HomeBasicInformationFormParts)[0];

  const isLastPartOfForm =
    props.formPart === Object.values(HomeBasicInformationFormParts).pop();

  const noBuilders = !builders && !wieBuilders;

  const fetchApplicationForm = async () => {
    setIsLoading(true);
    await homeStandardEvaluationAPIClient
      .getHomeStandardEvaluationFormModel(props.evaluationId)
      .then((response) => {
        setApplicationFormModel((prevModel) => ({
          ...prevModel,
          ...response,
        }));
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Debounce setup
  const handleFormSubmitDebounced = useCallback(
    debounce(
      async (
        form: Evaluation_HomeStandardEvaluationFormModel,
        _value: any,
        fieldProps: BaseFieldProps<any>
      ) => {
        if (fieldProps.fieldKey) {
          await handleFormSubmit(form, fieldProps.fieldKey).then(async () => {
            await props.onFormSave?.(fieldProps.fieldKey || "", _value);
          });
        }
      },
      2000
    ),
    []
  );

  useEffect(() => {
    // Cleanup the debounce function on component unmount
    return () => handleFormSubmitDebounced.cancel();
  }, [handleFormSubmitDebounced]);

  const onFormFieldChange = async (
    form: Evaluation_HomeStandardEvaluationFormModel,
    _value: any,
    fieldProps: BaseFieldProps<any>
  ) => {
    // Call the debounced submit function
    if (!!_value) {
      handleFormSubmitDebounced(form, _value, fieldProps);
      props.setHasUnsavedChanges(true);
    }
  };

  const areAllRequiredFieldsOnFormSectionComplete = () => {
    if (!props.formPart || !applicationFormModel) {
      return false;
    }

    switch (props.formPart) {
      case HomeBasicInformationFormParts.HomeownerInformation:
        return applicationFormModel?.homeStandardEvaluation.basicInformationForm
          .isHomeownerInformationComplete;
      default:
        return false;
    }
  };

  const areAllRequiredFieldsComplete =
    areAllRequiredFieldsOnFormSectionComplete();

  const getApplicationFormPartBuilder = () => {
    if (props.isIterationEnginePage) {
      return wieBuilders?.HomeStandardIterationEngineBasicInformationForm;
    }
    switch (props.formPart) {
      case HomeBasicInformationFormParts.HomeownerInformation:
        return builders?.HomeStandardHomeownerInformation;
      default:
        return builders?.HomeStandardBasicInformationForm;
    }
  };

  const handleFormSubmit = async (
    form: Evaluation_HomeStandardEvaluationFormModel,
    fieldKey?: string
  ) => {
    setApplicationFormModel(form);
    await (shouldSubmit
      ? homeStandardEvaluationBasicInformationAPIClient.submitHomeStandardBasicInformationForm(
          form.id,
          form.homeStandardEvaluation.basicInformationForm,
          props.formPart
        )
      : homeStandardEvaluationBasicInformationAPIClient.updateHomeStandardBasicInformationForm(
          form.id,
          form.homeStandardEvaluation.basicInformationForm,
          fieldKey
        )
    )
      .then(async (response) => {
        handleFormResponse(response);
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while saving the form",
            intent: Intent.DANGER,
          });
        }
        throw error;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleFormResponse = async (
    response: Evaluation_HomeStandardEvaluationFormModel
  ) => {
    props.setHasUnsavedChanges(false);

    if (shouldSubmit) {
      setApplicationFormModel(response);

      if (isLastPartOfForm) {
        navigate(
          `${fortifiedHomeStandardRoutePrefix}/checklist/${props.evaluationId}`
        );
      } else {
        // Navigate to the next visible form part
        const currentFormPartIndex = Object.values(
          HomeBasicInformationFormParts
        ).indexOf(props.formPart!);

        // Find the next visible form part
        let nextFormPart = null;
        for (
          let i = currentFormPartIndex + 1;
          i < Object.values(HomeBasicInformationFormParts).length;
          i++
        ) {
          const formPart = Object.values(HomeBasicInformationFormParts)[i];

          // Check if formChecklistParts exists and if the form part is hidden
          const checklistPart = props.formChecklistParts?.find(
            (part) => part.formPart === formPart
          );

          // If formChecklistParts doesn't exist, assume the part is not hidden
          if (!checklistPart || !checklistPart.hidden) {
            nextFormPart = formPart;
            break;
          }
        }

        if (nextFormPart) {
          // Navigate to the next visible part
          navigate(
            `${fortifiedHomeStandardRoutePrefix}/${props.evaluationId}/basic-information/${nextFormPart}`
          );
        }
      }
    } else {
      handlePartialResponseFormUpdate(response);
    }
  };

  const handlePartialResponseFormUpdate = (
    response: Evaluation_HomeStandardEvaluationFormModel
  ) => {
    //This is used to combat save on blur issues. This only updates fields that are essetnial to the form.
    //These are set in the API response. Field Counts and sub section completion status
    setApplicationFormModel((prevState) => {
      if (!prevState) return prevState;

      const {
        homeownerInformationRequiredFieldCount,
        homeownerInformationHasBeenSubmitted,
        isHomeownerInformationComplete,
        areAllRequiredFieldsComplete,
      } = response.homeStandardEvaluation.basicInformationForm;

      const { canProceedToApplicationFee } = response.homeStandardEvaluation;

      const { hasDuplicateAddress } = response;

      return {
        ...prevState,
        hasDuplicateAddress,
        homeStandardEvaluation: {
          ...prevState.homeStandardEvaluation,
          canProceedToApplicationFee,
          basicInformationForm: {
            ...prevState.homeStandardEvaluation.basicInformationForm,
            homeownerInformationRequiredFieldCount,
            homeownerInformationHasBeenSubmitted,
            isHomeownerInformationComplete,
            areAllRequiredFieldsComplete,
          },
        },
      };
    });
  };

  return {
    builders,
    wieBuilders,
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    applicationFormModel,
    isFirstPartOfForm,
    isLastPartOfForm,
    getApplicationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    noBuilders,
    containerRef,
    setShouldSubmit,
    areAllRequiredFieldsComplete,
  };
}

export default useHomeStandardEvaluationBasicInformationForm;
