import {
  EmailFieldValue,
  EnumRadioFieldValue,
  NumericFieldValue,
  PhoneNumberFieldValue,
  SingleCheckboxFieldValue,
  TextFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorerValue } from "../../components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

export enum LandscapeReviewFormParts {
  PropertyInformation = "property-information",
  Payment = "payment",
  PhotoRequirements = "photo-requirements",
}

export const activeLandscapeReviewFormParts: LandscapeReviewFormParts[] = [
  LandscapeReviewFormParts.PropertyInformation,
  LandscapeReviewFormParts.PhotoRequirements,
];

export interface LandscapeReviewFormModel {
  // Relationships
  wildfireApplicationEvaluationId: string;

  // Metadata
  propertyInformationRequiredFieldCount: number;
  photoRequirementsRequiredFieldCount: number;

  propertyInformationSubmitted: boolean;
  photoRequirementsSubmitted: boolean;
  submitted: boolean;

  // Part 1: Property Information
  ownershipAttestation: SingleCheckboxFieldValue;

  hasCoOwner: EnumRadioFieldValue;
  coOwnerFirstName: TextFieldValue;
  coOwnerLastName: TextFieldValue;
  coOwnerEmail: EmailFieldValue;
  coOwnerPhoneNumber: PhoneNumberFieldValue;

  hasSecondaryContact: EnumRadioFieldValue;
  secondaryContactFirstName: TextFieldValue;
  secondaryContactLastName: TextFieldValue;
  secondaryContactEmail: EmailFieldValue;
  secondaryContactPhoneNumber: PhoneNumberFieldValue;

  roofInstallationYearKnown: EnumRadioFieldValue;
  roofInstallationYear: NumericFieldValue;

  addedOrChangedExistingPergolaOrGazebo: EnumRadioFieldValue;
  addedOrChangedExistingCoveredPorch: EnumRadioFieldValue;
  addedOrChangedExistingShed: EnumRadioFieldValue;
  addedOrChangedExistingGreenhouse: EnumRadioFieldValue;
  addedOrChangedExistingGarage: EnumRadioFieldValue;
  addedOrChangedExistingPlayset: EnumRadioFieldValue;
  addedOrChangedExistingHotTub: EnumRadioFieldValue;
  addedOrChangedExistingDeck: EnumRadioFieldValue;
  addedOrChangedExistingOtherStructure: EnumRadioFieldValue;
  addedOrChangedExistingFence: EnumRadioFieldValue;
  addedOrChangedExistingPropaneOrFuelTank: EnumRadioFieldValue;
  addedOrChangedExistingOutdoorKitchenBarOrBuiltIn: EnumRadioFieldValue;

  hasOtherSignificantPropertyChanges: EnumRadioFieldValue;
  otherSignificantPropertyChangesDescription: TextFieldValue;

  // Part 2: Photo Requirements
  frontPhotoFolder: WildfireBoxContentExplorerValue;
  rightSidePhotoFolder: WildfireBoxContentExplorerValue;
  backPhotoFolder: WildfireBoxContentExplorerValue;
  leftSidePhotoFolder: WildfireBoxContentExplorerValue;
  structuresAndOutbuildingsPhotosFolder: WildfireBoxContentExplorerValue;

  // Internal Fields
  hasPaymentBeenSubmitted: boolean;

  // Computed Fields
  allRequiredFieldsCompleted: boolean;
}
