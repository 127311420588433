import { useState } from "react";
import {
  errorStore,
  genericErrorMessage,
} from "../../common/Components/Error/ErrorStore";
import { WildfireRejectionFormModel } from "../components/Dialogs/Rejection/WildfireRejectionForm";
import { wildfireEvaluationApiClient } from "../lib/apiClients/evaluation/wildfireEvaluationApiClient";
import { WildfireEvaluation } from "../types/WildfireApplication/WildfireApplication";
import { wildfireApplicationApiClient } from "../lib/apiClients/evaluation/wildfireApplicationApiClient";
import { wildfireLandscapeReviewApiClient } from "../lib/apiClients/evaluation/wildfireLandscapeReviewApiClient";

export const useWildfireEvaluation = () => {
  const [isApplicationLoading, setIsApplicationLoading] = useState(false);
  const [areFieldKeysLoading, setAreFieldKeysLoading] = useState(false);
  const [isSendForwardLoading, setIsSendForwardLoading] = useState(false);
  const [isCloseLoading, setIsCloseLoading] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [isReopenLoading, setIsReopenLoading] = useState(false);
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [isRequestChangesLoading, setIsRequestChangesLoading] = useState(false);

  const [wildfireEvaluation, setWildfireEvaluation] =
    useState<WildfireEvaluation | null>();

  const [evaluationFieldkeys, setEvaluationFieldkeys] = useState<string[]>();

  const setWildfireEvaluationById = async (id?: string) => {
    setIsApplicationLoading(true);

    if (id) {
      await wildfireEvaluationApiClient
        .getWildfireEvaluationById(id)
        .then((response) => {
          setWildfireEvaluation(response);
        })
        .catch(() => {
          errorStore.setErrorMessage(genericErrorMessage);
        })
        .finally(() => {
          setIsApplicationLoading(false);
        });
    } else {
      setWildfireEvaluation(null);
    }
  };

  const refreshWildfireEvaluation = async () => {
    setIsApplicationLoading(true);
    if (wildfireEvaluation?.id) {
      await wildfireEvaluationApiClient
        .getWildfireEvaluationById(wildfireEvaluation?.id)
        .then((response) => {
          setWildfireEvaluation(response);
          //setWildfireEvaluationHeader(response);
        })
        .catch(() => {
          errorStore.setErrorMessage(genericErrorMessage);
        })
        .finally(() => {
          setIsApplicationLoading(false);
        });
    }
  };

  const getEvaluationFieldkeys = async (id?: string) => {
    setAreFieldKeysLoading(true);
    if (id) {
      await wildfireEvaluationApiClient
        .getWildfireEvaluationCommentAndChangeRequestFieldKeys(id)
        .then((response) => {
          setEvaluationFieldkeys(response);
        })
        .catch(() => {
          errorStore.setErrorMessage(genericErrorMessage);
        })
        .finally(() => {
          setAreFieldKeysLoading(false);
        });
    }
  };

  const downloadCertificate = async () => {
    if (!wildfireEvaluation) {
      return;
    }

    return wildfireEvaluationApiClient.downloadCertificate(
      wildfireEvaluation.id
    );
  };

  const regenerateCertificate = async () => {
    if (!wildfireEvaluation) {
      return;
    }

    return wildfireEvaluationApiClient.regenerateCertificate(
      wildfireEvaluation.id
    );
  };

  const sendForward = async () => {
    if (!wildfireEvaluation) {
      return;
    }
    setIsSendForwardLoading(true);

    await wildfireApplicationApiClient
      .sendForward(wildfireEvaluation.id)
      .finally(() => {
        setIsSendForwardLoading(false);
      });

    await setWildfireEvaluationById(wildfireEvaluation.id);
  };

  const approve = async () => {
    if (!wildfireEvaluation) {
      return;
    }
    setIsApproveLoading(true);

    const approve = wildfireEvaluation.wildfire_IsLandscapeReview
      ? wildfireLandscapeReviewApiClient.approve
      : wildfireApplicationApiClient.approve;

    await approve(wildfireEvaluation.id)
      .then((response) => {
        setWildfireEvaluation(response);
      })
      .finally(() => {
        setIsApproveLoading(false);
      });
  };

  const reject = async (form: WildfireRejectionFormModel) => {
    if (!wildfireEvaluation) {
      return;
    }
    setIsRejectLoading(true);

    const reject = wildfireEvaluation.wildfire_IsLandscapeReview
      ? wildfireLandscapeReviewApiClient.reject
      : wildfireApplicationApiClient.reject;

    await reject(wildfireEvaluation.id, form)
      .then((response) => {
        setWildfireEvaluation(response);
      })
      .finally(() => {
        setIsRejectLoading(false);
      });
  };

  const close = async () => {
    if (!wildfireEvaluation) {
      return;
    }
    setIsCloseLoading(true);

    const closeEvaluation = wildfireEvaluation.wildfire_IsLandscapeReview
      ? wildfireLandscapeReviewApiClient.closeEvaluation
      : wildfireApplicationApiClient.closeEvaluation;

    await closeEvaluation(wildfireEvaluation.id).finally(() => {
      setIsCloseLoading(false);
    });
  };

  const cancel = async () => {
    if (!wildfireEvaluation) {
      return;
    }
    setIsCancelLoading(true);

    const cancelEvaluation = wildfireEvaluation.wildfire_IsLandscapeReview
      ? wildfireLandscapeReviewApiClient.cancelEvaluation
      : wildfireApplicationApiClient.cancelEvaluation;

    await cancelEvaluation(wildfireEvaluation.id).finally(() => {
      setIsCancelLoading(false);
    });
  };

  const reopen = async () => {
    if (!wildfireEvaluation) {
      return;
    }
    setIsReopenLoading(true);

    const reopenEvaluation = wildfireEvaluation.wildfire_IsLandscapeReview
      ? wildfireLandscapeReviewApiClient.reopenEvaluation
      : wildfireApplicationApiClient.reopenEvaluation;

    await reopenEvaluation(wildfireEvaluation.id).finally(() => {
      setIsReopenLoading(false);
    });
  };

  const requestChanges = async () => {
    if (!wildfireEvaluation) {
      return;
    }
    setIsRequestChangesLoading(true);

    const requestChanges = wildfireEvaluation.wildfire_IsLandscapeReview
      ? wildfireLandscapeReviewApiClient.requestChanges
      : wildfireApplicationApiClient.requestChanges;

    await requestChanges(wildfireEvaluation.id).finally(() => {
      setIsRequestChangesLoading(false);
    });
  };

  return {
    wildfireEvaluation,
    evaluationFieldkeys,
    setWildfireEvaluationById,
    refreshWildfireEvaluation,
    isApplicationLoading,
    areFieldKeysLoading,
    sendForward,
    isSendForwardLoading,
    approve,
    isApproveLoading,
    reject,
    isRejectLoading,
    close,
    isCloseLoading,
    cancel,
    isCancelLoading,
    reopen,
    isReopenLoading,
    requestChanges,
    isRequestChangesLoading,
    downloadCertificate,
    regenerateCertificate,
    getEvaluationFieldkeys,
  };
};
