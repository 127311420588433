import baseApiClient from "../../../../../../foritfied/lib/apiClients/baseApiClient";
import { HomeEligibilityFormParts } from "../../../../common/types/BaseHomeEvaluationEligibilityFormModel";
import { HomeNCIUAEvaluationEligibilityFormModel } from "../../types/HomeNCIUAEvaluationEligibilityFormModel";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../types/HomeNCIUAEvaluationFormModel";

class HomeNCIUAEvaluationEligibilityAPIClient {
  updateHomeNCIUAEligibilityForm = async (
    evaluationId: string,
    eligibilityForm: HomeNCIUAEvaluationEligibilityFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeNCIUAEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeNCIUAEvaluationFormModel>(
      `/home-nciua/eligibility-form/${evaluationId}`,
      {
        eligibilityForm,
        fieldKey,
      }
    );
  };

  submitHomeNCIUAEligibilityForm = async (
    evaluationId: string,
    eligibilityForm: HomeNCIUAEvaluationEligibilityFormModel,
    formPart?: HomeEligibilityFormParts
  ): Promise<Evaluation_HomeNCIUAEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        HomeEligibilityFormParts
      ).pop() as HomeEligibilityFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeNCIUAEvaluationFormModel>(
      `/home-nciua/eligibility-form/${evaluationId}/${formPartValue}`,
      eligibilityForm
    );
  };
}

export const homeNCIUAEvaluationEligibilityAPIClient =
  new HomeNCIUAEvaluationEligibilityAPIClient();
