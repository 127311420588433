import { dialogStore } from "@ucl/library";
import { DialogProps } from "@ucl/library/lib/components/Dialogs/Dialog/Dialog";
import { CommentTemplateForm } from "./CommentTemplateForm";
import { CommentTemplateWildfireForm } from "./CommentTemplateWildfireForm";

const dialogIdBase = "comment-template-dialog";

const getDialogId = (): string => `${dialogIdBase}`;

const buildCommentTemplateDialog = (
  refreshGrid: () => void,
  commentTemplateId?: string,
  isWildfireSystem?: boolean
): DialogProps => {
  return {
    id: getDialogId(),
    className: dialogIdBase,
    header: "Comment Template",
    allowDragging: false,
    content: () => (
      <div>
        {isWildfireSystem && (
          <CommentTemplateWildfireForm
            id={commentTemplateId}
            refreshGrid={refreshGrid}
          />
        )}
        {!isWildfireSystem && (
          <CommentTemplateForm
            id={commentTemplateId}
            refreshGrid={refreshGrid}
          />
        )}
      </div>
    ),
  };
};

export interface CommentTemplateDialogProps {
  commentTemplateId?: string;
  isWildfireSystem?: boolean;
  refreshGrid: () => void;
}

export const openCommentTemplateDialog = ({
  commentTemplateId,
  isWildfireSystem,
  refreshGrid,
}: CommentTemplateDialogProps) => {
  dialogStore.openDialog(
    buildCommentTemplateDialog(refreshGrid, commentTemplateId, isWildfireSystem)
  );
};

export const closeCommentTemplateDialog = () =>
  dialogStore.closeDialog(getDialogId());
