import {
  EmailFieldValue,
  EnumMultiSelectFieldV2Value,
  EnumRadioFieldValue,
  EnumSingleSelectFieldValue,
  TextFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorerValue } from "../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

export enum HomeEligibilityFormParts {
  DesignationTypeAndLevelInformation = "designation-type-and-level-information",
  EligibilityInformation = "eligibility-information",
}

export enum NewOrExistingRoofTypes {
  NewRoof = 1,
  ExistingRoof = 2,
}

export enum NewConstructionOrExistingHomeTypes {
  NewConstruction = 1,
  ExistingHome = 2,
}

export enum DesignationLevelTypes {
  Roof = 1,
  Silver = 2,
  Gold = 3,
}

export enum BuildingElementTypes {
  GablesOver3Feet = 1,
  GableOverhangs = 2,
  Chimneys = 3,
  SingleLevelAttachedStructures = 4,
  MultiLevelAttachedStructures = 5,
  ContinuousLoadPath = 6,
  NoneOfTheAbove = 7,
}

export const buildingElementTypesRequiredByProfessionalEngineer = [
  BuildingElementTypes.GableOverhangs,
  BuildingElementTypes.GablesOver3Feet,
  BuildingElementTypes.Chimneys,
  BuildingElementTypes.SingleLevelAttachedStructures,
  BuildingElementTypes.ContinuousLoadPath,
  BuildingElementTypes.NoneOfTheAbove,
];

export const shouldNotHaveContinousLoadPathIfSilver = [
  BuildingElementTypes.GableOverhangs,
  BuildingElementTypes.GablesOver3Feet,
  BuildingElementTypes.Chimneys,
  BuildingElementTypes.SingleLevelAttachedStructures,
  BuildingElementTypes.MultiLevelAttachedStructures,
  BuildingElementTypes.NoneOfTheAbove,
];

export const requiredBuildingElementTypesForSilverAndGold = [
  BuildingElementTypes.GablesOver3Feet,
  BuildingElementTypes.GableOverhangs,
  BuildingElementTypes.Chimneys,
  BuildingElementTypes.SingleLevelAttachedStructures,
  BuildingElementTypes.MultiLevelAttachedStructures,
  BuildingElementTypes.ContinuousLoadPath,
];

export enum ContinuousLoadPathPathwayTypes {
  /**
   * Engineered CLP for New Construction
   * External Source: engineered_clp_for_new_construction
   */
  EngineeredCLPForNewConstruction = 1,

  /**
   * Engineered Retrofit of Existing Home
   * External Source: engineered_retrofit_of_existing_home
   */
  EngineeredRetrofitOfExistingHome = 2,

  /**
   * Prescriptive High Wind Only for New Wood Framed Construction
   * External Source: prescriptive_high_wind_only_for_new_wood_framed_construction
   */
  PrescriptiveHighWindOnlyForNewWoodFramedConstruction = 3,
}

export enum DwellingTypes {
  /**
   * Single Family Detached Home
   * External Source: single_family_detached_home
   */
  SingleFamilyDetachedHome = 1,

  /**
   * Two-Family Dwelling Unit (Duplex)
   * External Source: two_family_dwelling_unit
   */
  TwoFamilyDwellingUnitDuplex = 2,

  /**
   * HUD Manufactured Home
   * External Source: hud_manufactured_home
   */
  HUDManufacturedHome = 3,

  /**
   * Townhouse
   * External Source: townhouse
   */
  Townhouse = 4,

  /**
   * Modular Home
   * External Source: modular_home
   */
  ModularHome = 5,

  /**
   * None of the Above
   * External Source: none_of_the_above
   */
  NoneOfTheAbove = 6,
}

export interface BaseHomeEvaluationEligibilityFormModel {
  newConstructionOrExistingHome?: EnumSingleSelectFieldValue;
  newOrExistingRoof?: EnumSingleSelectFieldValue;
  rooferEmail?: EmailFieldValue;
  hasHailSupplement?: EnumRadioFieldValue;
  designationLevel?: EnumSingleSelectFieldValue;
  buildingElementTypes?: EnumMultiSelectFieldV2Value;
  distanceToSaltWaterShorelineType?: EnumSingleSelectFieldValue;
  installingContractorEmail?: EmailFieldValue;
  isEngineerProvidingComplianceDesignInfoForSilverOrGoldRequirements?: EnumRadioFieldValue;
  engineerEmail?: EmailFieldValue;
  buildingElementsEngineerIsProvidingComplianceDesignInfoFor?: EnumMultiSelectFieldV2Value;
  continuousLoadPathPathwayType?: EnumSingleSelectFieldValue;
  dwellingType?: EnumSingleSelectFieldValue;
  dwellingTypePhotos?: WildfireBoxContentExplorerValue;
  isHomeOnADryStackOrHudFoundation?: EnumRadioFieldValue;
  isHomeConnectedToFoundation?: EnumRadioFieldValue;
  foundationEligibilityPhotos?: WildfireBoxContentExplorerValue;
  isProfessionalEngineerProvidingCAndCRoofUplifDesingPressures?: EnumRadioFieldValue;
  isProjectAHomeBuiltByNonProfit?: EnumRadioFieldValue;
  nonProfitEIN?: TextFieldValue;
  letterFromNonProfit?: WildfireBoxContentExplorerValue;
  isThisProjectReleatedToFloridaExistingHomesProject?: EnumRadioFieldValue;
  floridaExistingHomeProjectEligibilityDocuments?: WildfireBoxContentExplorerValue;
  otherDocuments?: WildfireBoxContentExplorerValue;
}

export enum DistanceToSaltWaterShorelineTypes {
  ZeroToThreeHundredFeet = 1,
  ThreeHundredToThreeThousandFeet = 2,
  GreaterThanThreeThousandFeet = 3,
}
