import {
  FieldFormV2,
  FieldSchemaFormBuilderV2,
  RelativityTooltip,
} from "@ucl/library";
import { Button, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { useNavigate } from "react-router";
import { fortifiedHomeSCSHRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { Evaluation_HomeSCSHEvaluationFormModel } from "../../../../types/HomeSCSHEvaluationFormModel";
import { SCSHEvaluationForm_FormParts } from "../../../../types/HomeSCSHEvaluation_EvaluationFormModel";
import useHomeSCSHEvaluationForm from "./useHomeSCSHEvaluationForm";

export interface HomeSCSHEvaluationFormProps {
  evaluationId: string;
  isIterationEnginePage?: boolean;
  formPart?: SCSHEvaluationForm_FormParts;
  onFormSave?: (fieldKey: string, value: any) => Promise<void>;
  setFormModel?: (formModel: Evaluation_HomeSCSHEvaluationFormModel) => void;
  disableForm?: boolean;
  onInitialized?: () => void;
  setHasUnsavedChanges: (value: boolean) => void;
  hideFormControls?: boolean;
}

export const HomeSCSHEvaluationForm = (props: HomeSCSHEvaluationFormProps) => {
  const {
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    applicationFormModel,
    isFirstPartOfForm,
    isLastPartOfForm,
    getApplicationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    noBuilders,
    containerRef,
    setShouldSubmit,
  } = useHomeSCSHEvaluationForm(props);

  const navigate = useNavigate();

  return (
    <div ref={containerRef} className="wildfire-form-container">
      {noBuilders || isLoading ? (
        <Loading />
      ) : !applicationFormModel ? (
        <NonIdealState />
      ) : (
        <>
          <FieldFormV2<Evaluation_HomeSCSHEvaluationFormModel>
            isDisabled={isSubmitting || props.disableForm}
            value={applicationFormModel}
            fieldSchemaFormBuilder={
              getApplicationFormPartBuilder() as FieldSchemaFormBuilderV2<Evaluation_HomeSCSHEvaluationFormModel>
            }
            onFormSubmit={async (form) => {
              await handleFormSubmit(form);
            }}
            ref={formRef}
            onFieldChanged={async (form, value, fieldProps) => {
              await onFormFieldChange(form, value, fieldProps);
            }}
          />
          {!props.hideFormControls && (
            <div className="wildfire-form-footer">
              {!isFirstPartOfForm && (
                <Button
                  className={
                    "wildfire-form-back-button " +
                    "wildfire-form-navigation-button"
                  }
                  text="Back"
                  icon={IconNames.ARROW_LEFT}
                  onClick={() => {
                    // Navigate to the previous part of the form
                    const previousFormPart = Object.values(
                      SCSHEvaluationForm_FormParts
                    )[
                      Object.values(SCSHEvaluationForm_FormParts).indexOf(
                        props.formPart!
                      ) - 1
                    ].valueOf();
                    navigate(
                      `${fortifiedHomeSCSHRoutePrefix}/${props.evaluationId}/evaluation-form/${previousFormPart}`
                    );
                  }}
                />
              )}
              {!isLastPartOfForm && (
                <RelativityTooltip shouldShowTooltip={false} content={""}>
                  <Button
                    text={"Continue"}
                    rightIcon={IconNames.ARROW_RIGHT}
                    loading={isSubmitting}
                    onClick={() => {
                      if (formRef.current) {
                        props.setHasUnsavedChanges(false);
                        setShouldSubmit(true);
                        setIsSubmitting(true);
                        formRef.current.submit();
                      }
                    }}
                  />
                </RelativityTooltip>
              )}
              {isLastPartOfForm && (
                <RelativityTooltip shouldShowTooltip={false} content={""}>
                  <Button
                    className="wildfire-form-submit-button"
                    text={"Submit Information"}
                    rightIcon={IconNames.ARROW_RIGHT}
                    loading={isSubmitting}
                    onClick={() => {
                      if (formRef.current) {
                        props.setHasUnsavedChanges(false);
                        setShouldSubmit(true);
                        setIsSubmitting(true);
                        formRef.current.submit();
                      }
                    }}
                  />
                </RelativityTooltip>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default HomeSCSHEvaluationForm;
