import {
  DateFieldValue,
  EnumSingleSelectFieldValue,
  NumericFieldValue,
  SingleCheckboxFieldValue,
  TextFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";

export enum BaseHomeEvaluationInstallerComplianceGablesFormParts {
  InstallerComplianceFormGeneralInformation = "installer-compliance-form-general-information",
  InstallerComplianceFormEngineeredGables = "installer-compliance-form-engineered-gables",
  InstallerComplianceFormPrescriptiveGables = "installer-compliance-form-prescriptive-gables",
}

export interface BaseHomeEvaluationInstallerComplianceGablesFormModel
  extends IInstallerComplianceFormGeneralInformation,
    IInstallerComplianceFormEngineeredGables,
    IInstallerComplianceFormPrescriptiveGables {}

export interface IInstallerComplianceFormGeneralInformation {
  permitNumber?: TextFieldValue;
  dateInstallationWasCompleted?: DateFieldValue;
}

export interface IInstallerComplianceFormEngineeredGables {
  attestation281?: SingleCheckboxFieldValue;
  attestation282?: SingleCheckboxFieldValue;
  attestation283?: SingleCheckboxFieldValue;
  attestation284?: SingleCheckboxFieldValue;
  gableConnectedBraceTopType?: EnumSingleSelectFieldValue;
  gableConnectedBraceTopTypeOther?: TextFieldValue;
  attestation285?: SingleCheckboxFieldValue;
  gableConnectedBraceBottomType?: EnumSingleSelectFieldValue;
  gableConnectedBraceBottomTypeOther?: TextFieldValue;
  attestation286?: SingleCheckboxFieldValue;
  strapManufacturerAndModel?: TextFieldValue;
  maxAllowableUpliftLoadPerStrapPerManufacturer?: NumericFieldValue;
}

export interface IInstallerComplianceFormPrescriptiveGables {
  attestation287?: SingleCheckboxFieldValue;
  attestation288?: SingleCheckboxFieldValue;
  prescriptiveCodeForGableBracingInstallation?: EnumSingleSelectFieldValue;
  prescriptiveCodeForGableBracingInstallationDescription?: TextFieldValue;
  attestation291?: SingleCheckboxFieldValue;
}

export enum PrescriptiveCodeForGableBracinngInstallationTypes {
  InternationalExistingBuildingCode = 1,

  FloridaBuildingCode = 2,

  Other = 3,
}

export enum GableConnectedBracedTopTypes {
  ConnectedToRoof = 1,
  AttachedToOutlookerFrame = 2,
  AttachedToHorizontalBracing = 3,
  Other = 4,
}

export enum GableConnectedBracedBottomTypes {
  ConnectedToCeiling = 1,

  AttachedToTopOfWall = 2,

  AttachedToHorizontalBracing = 3,

  Other = 4,
}

export enum GableStudsBracedTypes {
  ConnectedToRoof = 1,
  AttachedToOutlookerFrame = 2,
  Other = 4,
}
