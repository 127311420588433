import {
  EnumRadioFieldValue,
  EnumSingleSelectFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorerValue } from "../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

export enum BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts {
  SiteDesignInformation = "site-design-information",
  WindowsAndSkylights = "windows-and-skylights",
  EntryDoors = "entry-doors",
  Garage = "garage",
  GableEnds = "gable-ends",
  Soffits = "soffits",
  Chimneys = "chimneys",
  AttachedStructures = "attached-structures",
  DesignPressureRatedOpenings = "design-pressure-rated-openings",
  WallSheathing = "wall-sheathing",
  ContinuousLoadPath = "continuous-load-path",
}

export interface BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormModel
  extends ISilverGoldComplianceGeneralInformation {}

export interface ISilverGoldComplianceGeneralInformation {
  // Silver Compliance Information
  didEngineerSpecifyWindPressuresForZone4And5?: EnumRadioFieldValue;
  zone4PositiveRequiredWallWindPressure?: number;
  zone4NegativeRequiredWallWindPressure?: number;
  zone5PositiveRequiredWallWindPressure?: number;
  zone5NegativeRequiredWallWindPressure?: number;
  wallWindPressuresDocumentation?: WildfireBoxContentExplorerValue;
  areSkylightsOrLightTunnelsPresent?: EnumRadioFieldValue;
  areSkylightsImpactRated?: EnumRadioFieldValue;
  skylightDebrisImpactRatingPhotos?: WildfireBoxContentExplorerValue;
  skylightDebrisImpactRatingDocumentation?: WildfireBoxContentExplorerValue;
  areWindowsImpactRatedOrProtected?: EnumRadioFieldValue;
  windowLabelsAndImpactRatedCoverPhotos?: WildfireBoxContentExplorerValue;
  windowRatingAndImpactRatedCoverDocumentation?: WildfireBoxContentExplorerValue;

  // Show this section when HAZARD_TYPE = HURRICANE
  areEntryDoorsImpactRatedOrProtected?: EnumRadioFieldValue;
  entryDoorLabelAndImpactRatedCoverPhotos?: WildfireBoxContentExplorerValue;
  entryDoorRatingAndImpactRatedCoverDocumentation?: WildfireBoxContentExplorerValue;

  // General Section 2
  doesHomeHaveGarageDoors?: EnumRadioFieldValue;
  doesGarageDoorsHaveGlazing?: EnumRadioFieldValue;
  areGarageDoorsImpactRatedOrProtected?: EnumRadioFieldValue;
  isGarageDoorDesignPressureRated?: EnumRadioFieldValue;
  designPressureRatingOfGarageDoorsPositive?: number;
  designPressureRatingOfGarageDoorsNegative?: number;
  doesGarageDoorPressureRatingMeetOrExceed?: EnumRadioFieldValue;
  garageDoorLabelAndImpactRatedCoverPhotos?: WildfireBoxContentExplorerValue;
  garageDoorRatingAndImpactRatedCoverDocumentation?: WildfireBoxContentExplorerValue;

  // Show this section when BUILDING_ELEMENTS_INCLUDED_ON_HOME = Gables Over 3ft OR Gable Overhangs
  gableEndSheathing?: WildfireBoxContentExplorerValue;
  gableEndBracing?: WildfireBoxContentExplorerValue;
  gableEndOverhangs?: WildfireBoxContentExplorerValue;

  // Show this section when HAZARD_TYPE = HURRICANE
  soffitCoverType?: EnumSingleSelectFieldValue;
  flexibleSoffitCoverPhoto?: WildfireBoxContentExplorerValue;
  rigidSoffitCoverPhoto?: WildfireBoxContentExplorerValue;
  doesSoffitOverhangExtendMoreThan12Inches?: EnumRadioFieldValue;
  soffitBracingPhoto?: WildfireBoxContentExplorerValue;

  // Show this section when BUILDING_ELEMENTS_INCLUDED_ON_HOME = Chimneys (Silver)
  chimneyDetails?: WildfireBoxContentExplorerValue;
  chimneyLocation?: WildfireBoxContentExplorerValue;
  chimneyFramingAndAnchorage?: WildfireBoxContentExplorerValue;

  // Show this section when BUILDING_ELEMENTS_INCLUDED_ON_HOME = Single Level or Multi Level Attached Structures (Silver)
  attachedStructureRoofToBeam?: WildfireBoxContentExplorerValue;
  singleLevelAttachedStructureBeamToColumn?: WildfireBoxContentExplorerValue;
  singleLevelAttachedStructureColumnToFoundation?: WildfireBoxContentExplorerValue;
  multiLevelAttachedStructureRoofBeamToUpperLevelColumns?: WildfireBoxContentExplorerValue;
  multiLevelAttachedStructureUpperLevelColumnToLowerLevelColumnOrMiddleFloorBeams?: WildfireBoxContentExplorerValue;
  multiLevelAttachedStructureMiddleFloorBeamToLowerLevelColumn?: WildfireBoxContentExplorerValue;
  multiLevelAttachedStructureLowerLevelColumnToFoundation?: WildfireBoxContentExplorerValue;

  // Gold Compliance Information
  doWindowPressureRatingsMeetOrExceed?: EnumRadioFieldValue;
  doEntryDoorPressureRatingsMeetOrExceed?: EnumRadioFieldValue;
  additionalDesignPressureRatingDocumentationForOpenings?: WildfireBoxContentExplorerValue;
  doSkylightsDesignPressuresMeetOrExceed?: EnumRadioFieldValue;
  wallSheathingTypeAndThickness?: WildfireBoxContentExplorerValue;
  roofToWallConnection?: WildfireBoxContentExplorerValue;
  wallAboveToWallBelowConnection?: WildfireBoxContentExplorerValue;
  lowerWallToFoundationConnection?: WildfireBoxContentExplorerValue;
  holdDownsTensionTiesAtBuildingCorners?: WildfireBoxContentExplorerValue;
}
