import React, { ReactNode, createContext, useState } from "react";
import { WildfireEvaluation } from "../../types/WildfireApplication/WildfireApplication";
import { EvaluationFormModel } from "../../forms/EvaluationFormModel";
import WildfireAppHeader from "../Header/WildfireAppHeader";
import { Evaluation_HomeStandardEvaluation } from "../../../fortifiedV2/Home/standard/types/HomeEvaluation";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../fortifiedV2/Home/standard/types/HomeEvaluationFormModel";
import { ProductTypes } from "../../../common/types/ProductTypes";
import { Evaluation_HomeNCIUAEvaluation } from "../../../fortifiedV2/Home/nciua/evaluation/types/HomeNCIUAEvaluation";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../../fortifiedV2/Home/nciua/evaluation/types/HomeNCIUAEvaluationFormModel";
import { Evaluation_HomeSCSHEvaluation } from "../../../fortifiedV2/Home/scsh/types/HomeSCSHEvaluation";
import { Evaluation_HomeSCSHEvaluationFormModel } from "../../../fortifiedV2/Home/scsh/types/HomeSCSHEvaluationFormModel";
import { Evaluation_HomeStandardRedesignationFormModel } from "../../../fortifiedV2/Home/standardRedesignation/types/HomeStandardRedesignationFormModel_Model";
import { Evaluation_HomeStandardRedesignation } from "../../../fortifiedV2/Home/standardRedesignation/types/HomeStandardRedesignation";
import { Evaluation_HomeNCIUARedesignation } from "../../../fortifiedV2/Home/nciuaRedesignation/evaluation/types/HomeNCIUARedesignation";
import { Evaluation_HomeNCIUARedesignationFormModel } from "../../../fortifiedV2/Home/nciuaRedesignation/evaluation/types/HomeNCIUARedesignationFormModel_Model";
import FortifiedHomeAppHeader from "../../../fortifiedV2/Home/common/components/Header/FortifiedHomeAppHeader";

// Create the context
interface AppHeaderContextProps {
  appHeader: ReactNode;
  setWildfireEvaluationHeader: (
    wildfireEvaluation: WildfireEvaluation | EvaluationFormModel
  ) => void;
  setFotifiedHomeEvaluationHeader: (
    homeEvaluation:
      | Evaluation_HomeStandardEvaluation
      | Evaluation_HomeStandardEvaluationFormModel
      | Evaluation_HomeStandardRedesignationFormModel
      | Evaluation_HomeStandardRedesignation
      | Evaluation_HomeNCIUAEvaluation
      | Evaluation_HomeNCIUAEvaluationFormModel
      | Evaluation_HomeNCIUARedesignation
      | Evaluation_HomeNCIUARedesignationFormModel
      | Evaluation_HomeSCSHEvaluation
      | Evaluation_HomeSCSHEvaluationFormModel
  ) => void;
  setDefaultHeader: () => void;
}

const wildfireHeader = "Wildfire Prepared";
const fortifiedHomeHeader = "Fortified Home";

const defaultValue: AppHeaderContextProps = {
  appHeader: "",
  setWildfireEvaluationHeader: (
    wildfireEvaluation: WildfireEvaluation | EvaluationFormModel
  ): void => {
    throw new Error(`Function not implemented.${wildfireEvaluation}`);
  },
  setFotifiedHomeEvaluationHeader: (
    homeEvaluation:
      | Evaluation_HomeStandardEvaluation
      | Evaluation_HomeStandardEvaluationFormModel
      | Evaluation_HomeStandardRedesignationFormModel
      | Evaluation_HomeStandardRedesignation
      | Evaluation_HomeNCIUAEvaluation
      | Evaluation_HomeNCIUAEvaluationFormModel
      | Evaluation_HomeNCIUARedesignation
      | Evaluation_HomeNCIUARedesignationFormModel
      | Evaluation_HomeSCSHEvaluation
      | Evaluation_HomeSCSHEvaluationFormModel
  ): void => {
    throw new Error(`Function not implemented.${homeEvaluation}`);
  },
  setDefaultHeader: (): void => {
    throw new Error(`Function not implemented.`);
  },
};

export const AppHeaderContext =
  createContext<AppHeaderContextProps>(defaultValue);

// Create a provider
export const AppHeaderProvider: React.FC<{
  children: React.ReactNode;
  productType: ProductTypes;
}> = ({ children, productType }) => {
  const headerToUse =
    productType === ProductTypes.FORTIFIEDHome
      ? fortifiedHomeHeader
      : wildfireHeader;

  const [appHeader, setAppHeader] = useState<ReactNode>(headerToUse);

  const setDefaultHeader = () => {
    setAppHeader(headerToUse);
  };

  const setWildfireEvaluationHeader = (
    wildfireEvaluation: WildfireEvaluation | EvaluationFormModel
  ) => {
    setAppHeader(<WildfireAppHeader wildfireEvaluation={wildfireEvaluation} />);
  };

  const setFotifiedHomeEvaluationHeader = (
    homeEvaluation:
      | Evaluation_HomeStandardEvaluation
      | Evaluation_HomeStandardEvaluationFormModel
      | Evaluation_HomeStandardRedesignationFormModel
      | Evaluation_HomeStandardRedesignation
      | Evaluation_HomeNCIUAEvaluation
      | Evaluation_HomeNCIUAEvaluationFormModel
      | Evaluation_HomeNCIUARedesignation
      | Evaluation_HomeNCIUARedesignationFormModel
      | Evaluation_HomeSCSHEvaluation
      | Evaluation_HomeSCSHEvaluationFormModel
  ) => {
    setAppHeader(<FortifiedHomeAppHeader homeEvaluation={homeEvaluation} />);
  };

  return (
    <AppHeaderContext.Provider
      value={{
        appHeader,
        setWildfireEvaluationHeader,
        setFotifiedHomeEvaluationHeader,
        setDefaultHeader,
      }}
    >
      {children}
    </AppHeaderContext.Provider>
  );
};
