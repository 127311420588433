export enum NCIUAEvaluationForm_FormParts {
  Information = "information",
}

export interface HomeNCIUAEvaluation_EvaluationFormModel
  extends HomeNCIUAEvaluation_EvaluationFormBase {}

export interface HomeNCIUAEvaluation_EvaluationFormBase {
  id: string;
  homeNCIUAEvaluationId: string;
  evaluationId: string;

  informationRequiredFieldCount: number;
  informationLastSubmittedAt: string;
  informationHasBeenSubmitted: boolean;
  isInformationComplete: boolean;
  areAllRequiredFieldsComplete: boolean;
}
