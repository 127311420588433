import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { get } from "lodash";
import { Evaluation_HomeNCIUARedesignationFormModel } from "../../../evaluation/types/HomeNCIUARedesignationFormModel_Model";
import { permissionStore } from "../../../../../../foritfied/stores/PermissionStore";

const modelName = "Evaluation";
const subModelName = "HomeNCIUARedesignation";
const formName = "HomeownerAuthorizationForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

const homeownerAuthorizationFields = (
  form: Evaluation_HomeNCIUARedesignationFormModel,
  errors?: { [key: string]: string[] }
) => [
  buildFieldKey("Attestation"),
  {
    fieldKey: buildFieldKey("HomeownerAuthorizationForm"),
    defaultToUpload: false,
    errorMessages: get(errors, "homeownerAuthorizationForm.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
  } as WildfireBoxContentExplorerProps,
];

const homeownerAuthorizationRequiredFields = [
  buildFieldKey("Attestation"),
  buildFieldKey("HomeownerAuthorizationForm"),
];

// Full form builder with all parts included
export const homeAuthorizationFormBuilderNCIUARedesignation = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUARedesignationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUARedesignationFormModel>(
        modelName,
        homeownerAuthorizationFields(form, errors),
        onValueChange,
        form,
        errors,
        homeownerAuthorizationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUARedesignationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeAuthorizationFormIterationEngineBuilderNCUIARedesignation = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUARedesignationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUARedesignationFormModel>(
        modelName,
        homeownerAuthorizationFields(form, errors),
        onValueChange,
        form,
        errors,
        homeownerAuthorizationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUARedesignationFormModel>;
};
