import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorer } from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { YesOrNoSelection } from "../../../../../../wildfire/types/FormFields";
import { InstalledFoundationTypes } from "../../../../common/types/BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormModel";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "InstallerComplianceContinuousLoadPathForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

// Part 1: Installer Compliance Form General Information
const installerComplianceFormGeneralInformationFields = () => [
  buildFieldKey("PermitNumber"),
  buildFieldKey("DateInstallationWasCompleted"),
];

const installerComplianceFormGeneralInformationRequiredFields = [
  buildFieldKey("PermitNumber"),
  buildFieldKey("DateInstallationWasCompleted"),
];

export const installerComplianceFormContinuousLoadPathGeneralInformationBuilder =
  (
    factory: SchemaFactoryV2
  ): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      const fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
          modelName,
          installerComplianceFormGeneralInformationFields(),
          onValueChange,
          form,
          errors,
          installerComplianceFormGeneralInformationRequiredFields
        ),
      ];
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
  };

// Part 2: Installer Compliance Form Continuous Load Path For New Construction
const installerComplianceFormContinuousLoadPathForNewConstructionFields = (
  form: Evaluation_HomeStandardEvaluationFormModel
) => [
  buildFieldKey("Attestation321"),
  buildFieldKey("Attestation322"),
  buildFieldKey("Attestation323"),
  buildFieldKey("Attestation324"),
  buildFieldKey("DoesHomeHaveInstalledConcreteCMUWalls"),
  {
    fieldKey: buildFieldKey("Attestation326"),
    hidden: !(
      form.homeStandardEvaluation.installerComplianceContinuousLoadPathForm
        .doesHomeHaveInstalledConcreteCMUWalls === YesOrNoSelection.Yes
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation327"),
    hidden: !(
      form.homeStandardEvaluation.installerComplianceContinuousLoadPathForm
        .doesHomeHaveInstalledConcreteCMUWalls === YesOrNoSelection.Yes
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation328"),
    hidden: !(
      form.homeStandardEvaluation.installerComplianceContinuousLoadPathForm
        .doesHomeHaveInstalledConcreteCMUWalls === YesOrNoSelection.Yes
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation329"),
    hidden: !(
      form.homeStandardEvaluation.installerComplianceContinuousLoadPathForm
        .doesHomeHaveInstalledConcreteCMUWalls === YesOrNoSelection.Yes
    ),
  },
  buildFieldKey("InstalledFoundationType"),
  {
    fieldKey: buildFieldKey("Attestation331"),
    hidden: !(
      form.homeStandardEvaluation.installerComplianceContinuousLoadPathForm
        .installedFoundationType ===
      InstalledFoundationTypes.SlabOnGradeOrStemWall
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation332"),
    hidden: !(
      form.homeStandardEvaluation.installerComplianceContinuousLoadPathForm
        .installedFoundationType ===
      InstalledFoundationTypes.SlabOnGradeOrStemWall
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation333"),
    hidden: !(
      form.homeStandardEvaluation.installerComplianceContinuousLoadPathForm
        .installedFoundationType ===
      InstalledFoundationTypes.ElevatedFoundationOnPiersPilings
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation334"),
    hidden: !(
      form.homeStandardEvaluation.installerComplianceContinuousLoadPathForm
        .installedFoundationType ===
      InstalledFoundationTypes.ElevatedFoundationOnPiersPilings
    ),
  },
  buildFieldKey("Attestation335"),
  buildFieldKey("InstalledRoofToWallConnectionsDescriptionType"),
  buildFieldKey("Attestation337"),
  buildFieldKey("IsHomeMoreThan1Story"),
  {
    fieldKey: buildFieldKey(
      "InstalledWallAboveToWallBelowConnectionsDescriptionType"
    ),
    hidden: !(
      form.homeStandardEvaluation.installerComplianceContinuousLoadPathForm
        .isHomeMoreThan1Story === YesOrNoSelection.Yes
    ),
  },
  buildFieldKey("Attestation340"),
  buildFieldKey("Attestation341"),
  buildFieldKey("Attestation342"),
];

const installerComplianceFormContinuousLoadPathForNewConstructionRequiredFields =
  [
    buildFieldKey("Attestation321"),
    buildFieldKey("Attestation322"),
    buildFieldKey("Attestation323"),
    buildFieldKey("Attestation324"),
    buildFieldKey("DoesHomeHaveInstalledConcreteCMUWalls"),
    buildFieldKey("Attestation326"),
    buildFieldKey("Attestation327"),
    buildFieldKey("Attestation328"),
    buildFieldKey("Attestation329"),
    buildFieldKey("InstalledFoundationType"),
    buildFieldKey("Attestation331"),
    buildFieldKey("Attestation332"),
    buildFieldKey("Attestation333"),
    buildFieldKey("Attestation334"),
    buildFieldKey("Attestation335"),
    buildFieldKey("InstalledRoofToWallConnectionsDescriptionType"),
    buildFieldKey("Attestation337"),
    buildFieldKey("IsHomeMoreThan1Story"),
    buildFieldKey("InstalledWallAboveToWallBelowConnectionsDescriptionType"),
    buildFieldKey("Attestation340"),
    buildFieldKey("Attestation341"),
    buildFieldKey("Attestation342"),
  ];

export const installerComplianceFormContinuousLoadPathForNewConstructionBuilder =
  (
    factory: SchemaFactoryV2
  ): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      const fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
          modelName,
          installerComplianceFormContinuousLoadPathForNewConstructionFields(
            form
          ),
          onValueChange,
          form,
          errors,
          installerComplianceFormContinuousLoadPathForNewConstructionRequiredFields
        ),
      ];
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
  };

// Part 3: Installer Compliance Form Continuous Load Path For Retrofit Of Existing Home
const installerComplianceFormContinuousLoadPathForRetrofitOfExistingHomeFields =
  () => [buildFieldKey("Attestation343")];

const installerComplianceFormContinuousLoadPathForRetrofitOfExistingHomeRequiredFields =
  [buildFieldKey("Attestation343")];

export const installerComplianceFormContinuousLoadPathForRetrofitOfExistingHomeBuilder =
  (
    factory: SchemaFactoryV2
  ): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      const fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
          modelName,
          installerComplianceFormContinuousLoadPathForRetrofitOfExistingHomeFields(),
          onValueChange,
          form,
          errors,
          installerComplianceFormContinuousLoadPathForRetrofitOfExistingHomeRequiredFields
        ),
      ];
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
  };

// Part 4: Installer Compliance Form Continuous Load Path For Wood Framing
const installerComplianceFormContinuousLoadPathForWoodFramingFields = () => [
  buildFieldKey("Attestation3441"),
  buildFieldKey("Attestation3442"),
];

const installerComplianceFormContinuousLoadPathForWoodFramingRequiredFields = [
  buildFieldKey("Attestation3441"),
  buildFieldKey("Attestation3442"),
];

export const installerComplianceFormContinuousLoadPathForWoodFramingBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        installerComplianceFormContinuousLoadPathForWoodFramingFields(),
        onValueChange,
        form,
        errors,
        installerComplianceFormContinuousLoadPathForWoodFramingRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included
export const homeStandardInstallerComplianceContinuousLoadPathBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...installerComplianceFormGeneralInformationFields(),
          ...installerComplianceFormContinuousLoadPathForNewConstructionFields(
            form
          ),
          ...installerComplianceFormContinuousLoadPathForRetrofitOfExistingHomeFields(),
          ...installerComplianceFormContinuousLoadPathForWoodFramingFields(),
        ],
        onValueChange,
        form,
        errors,
        [
          ...installerComplianceFormGeneralInformationRequiredFields,
          ...installerComplianceFormContinuousLoadPathForNewConstructionRequiredFields,
          ...installerComplianceFormContinuousLoadPathForRetrofitOfExistingHomeRequiredFields,
          ...installerComplianceFormContinuousLoadPathForWoodFramingRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeStandardIterationEngineInstallerComplianceContinuousLoadPathFormBuilder =
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      let fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
          modelName,
          [
            ...installerComplianceFormGeneralInformationFields(),
            ...installerComplianceFormContinuousLoadPathForNewConstructionFields(
              form
            ),
            ...installerComplianceFormContinuousLoadPathForRetrofitOfExistingHomeFields(),
            ...installerComplianceFormContinuousLoadPathForWoodFramingFields(),
          ],
          onValueChange,
          form,
          errors,
          [
            ...installerComplianceFormGeneralInformationRequiredFields,
            ...installerComplianceFormContinuousLoadPathForNewConstructionRequiredFields,
            ...installerComplianceFormContinuousLoadPathForRetrofitOfExistingHomeRequiredFields,
            ...installerComplianceFormContinuousLoadPathForWoodFramingRequiredFields,
          ],
          {
            FileUploader: WildfireBoxContentExplorer,
          }
        ),
      ];
      if (beforeBuild) {
        fields = beforeBuild(fields);
      }
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
  };
