import "./applicant-styles.scss";
import { ChangeRequestV2 } from "../types/ChangeRequestV2";
import { ChangeRequestStatus } from "../../../../foritfied/types/evaluation/ChangeRequest";
import { SecondaryButton } from "@ucl/library";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faArrowDownLong,
  faArrowUpLong,
} from "@fortawesome/free-solid-svg-icons";
import { dialogStore } from "@ucl/library";
import { DialogProps } from "@ucl/library/lib/components/Dialogs/Dialog/Dialog";
import { WildfireSchemaFactoryV2 } from "../../../customHooks/useFieldSchemaFactory";
import { WildfireFieldComplianceDTO } from "../../../types/WildfireFieldComplianceDTO";
import { useState } from "react";
import WildfireApplicationApplicantIterationEngineChangeRequestDetails from "./WildfireApplicationApplicantIterationEngineChangeRequestDetails";

interface WildfireApplicationApplicantIterationEngineChangeRequestsProps {
  evaluationId: string;
  changeRequests: ChangeRequestV2[];
  fieldEvaluationCompliants: WildfireFieldComplianceDTO[];
  wildfireSchemaFactory: WildfireSchemaFactoryV2;
  onChangeRequestCompleted: () => void;
}

const WildfireApplicationApplicantIterationEngineChangeRequests: React.FC<
  WildfireApplicationApplicantIterationEngineChangeRequestsProps
> = (props) => {
  const unresolvedChangeRequests = props.changeRequests.filter(
    (cr) => cr.status !== ChangeRequestStatus.Resolved
  );

  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  return (
    <div className="wildfire-applicant-iteration-engine-change-requests">
      {props.changeRequests.length === 0 && (
        <section className="app-wie-change-requests app-wie-change-requests-no-requests">
          <div>
            <span>No Change Requests</span>
          </div>
        </section>
      )}
      {unresolvedChangeRequests.length > 0 && (
        <section className="app-wie-change-requests app-wie-change-requests-unresolved">
          <ul>
            {unresolvedChangeRequests.map((changeRequest) => (
              <li
                key={changeRequest.id}
                className="app-wie-change-request-details"
              >
                <div>{changeRequest.evaluationFieldLabel}</div>
                <div className="app-wie-change-request-details-action">
                  <SecondaryButton
                    className={`app-wie-change-request-details-status app-wie-change-request-details-status-${changeRequest.status}`}
                    onClick={() => {
                      dialogStore.openDialog({
                        id: `dialog-${changeRequest.id}`,
                        className: "app-wie-change-request-details-dialog",
                        header: `Change Request: ${changeRequest.evaluationFieldLabel}`,
                        allowDragging: false,
                        content: () => (
                          <WildfireApplicationApplicantIterationEngineChangeRequestDetails
                            evaluationId={props.evaluationId}
                            changeRequestId={changeRequest.id}
                            schemaFactory={props.wildfireSchemaFactory}
                            onChangeRequestCompleted={() => {
                              dialogStore.closeDialog(
                                `dialog-${changeRequest.id}`
                              );
                              props.onChangeRequestCompleted();
                            }}
                          />
                        ),
                      } as DialogProps);
                    }}
                  >
                    <span className="app-wie-change-request-details-status-header">
                      {changeRequest.status === ChangeRequestStatus.Addressed
                        ? "Addressed"
                        : "Click here to View Changes Required"}
                    </span>
                  </SecondaryButton>
                </div>
              </li>
            ))}
          </ul>
        </section>
      )}
      {props.fieldEvaluationCompliants.length > 0 && (
        <section className="app-wie-change-requests app-wie-change-requests-resolved">
          <div className="app-wie-change-requests-resolved-banner">
            <div className="app-wie-change-requests-resolved-banner__text">
              <FontAwesomeIcon icon={faCircleCheck} />
              <span>Great job on meeting the requirements below!</span>
            </div>
            <span className="app-wie-change-requests-resolved-banner__buttons">
              {isCollapsed && (
                <SecondaryButton
                  onClick={() => {
                    setIsCollapsed(!isCollapsed);
                  }}
                >
                  {"View Completed Requirements"}
                  <FontAwesomeIcon icon={faArrowDownLong} />
                </SecondaryButton>
              )}
              {!isCollapsed && (
                <SecondaryButton
                  onClick={() => {
                    setIsCollapsed(!isCollapsed);
                  }}
                >
                  {"Hide Completed Requirements"}
                  <FontAwesomeIcon icon={faArrowUpLong} />
                </SecondaryButton>
              )}
            </span>
          </div>
          {!isCollapsed && (
            <ul className="app-wie-change-requests-resolved-compliance">
              {props.fieldEvaluationCompliants.map((fieldCompliant) => (
                <li
                  key={fieldCompliant.complianceDetails}
                  className="app-wie-change-request-details"
                >
                  <div>{fieldCompliant.complianceDetails}</div>
                  <div className="app-wie-change-request-details-action">
                    <FontAwesomeIcon size="xl" icon={faCircleCheck} />
                  </div>
                </li>
              ))}
            </ul>
          )}
        </section>
      )}
    </div>
  );
};

export default WildfireApplicationApplicantIterationEngineChangeRequests;
