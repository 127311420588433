import GridClickableIconColumn from "../../components/FortifiedGrid/GridClickableIconColumn";
import FortifiedGridColumn from "../../types/fortifiedGrid/FortifiedGridColumn";
import GridCommentsColumn from "../../components/FortifiedGrid/GridCommentsColumn";
import { UserRoleType } from "../../../common/Components/User/userTypes";
import FortifiedCertificateGridClickableIconColumn from "../../components/FortifiedGrid/FortifiedCertificateGridClickableIconColumn";
import { CommentType } from "../../../common/apiClients/comments/types";

//Fortified Board Grid Column Configuration
const boardColumnsConfiguration: FortifiedGridColumn[] = [
  {
    field: "id",
    headerText: "GUID",
    type: "string",
    visible: true,
  } as FortifiedGridColumn,
  {
    field: "fortifiedId",
    headerText: "FORTIFIED ID",
    type: "string",
    visible: true,
  } as FortifiedGridColumn,
  {
    field: "productType_AsString",
    headerText: "Product",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "isWithdrawn",
    headerText: "Withdrawn",
    width: 130,
    type: "boolean",
  } as FortifiedGridColumn,
  {
    field: "status_AsString",
    headerText: "Status",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "daysSinceStatusUpdated",
    headerText: "Days Since Status Updated",
    type: "number",
  } as FortifiedGridColumn,
  {
    field: "totalAmountPaid",
    headerText: "Total Paid",
    type: "number",
    format: { format: "C2", currency: "en-US" },
  } as FortifiedGridColumn,
  {
    field: "totalCreditApplied",
    headerText: "Credits Applied",
    type: "number",
    format: { format: "C2", currency: "en-US" },
  } as FortifiedGridColumn,
  {
    field: "IsComplexCommercialEvaluationOrSingleBuilding",
    headerText: "Is Parent",
    type: "boolean",
  } as FortifiedGridColumn,
  {
    field: "isComplexCommercialEvaluationBuilding",
    headerText: "Is Child",
    type: "boolean",
  } as FortifiedGridColumn,
  new GridClickableIconColumn({
    field: "evaluatorNote",
    headerText: "Evaluator Notes",
    type: "string",
    columnName: "evaluatorNote",
    icon: "edit",
    width: 160,
    dialogType: "evaluatorNote",
  }),
  new GridCommentsColumn({
    field: "auditorNotes", // TODO: Change to auditorComments
    headerText: "Auditor Comments",
    type: "string",
    width: 110,
    unreadCommentCount: "unreadCommentsCount",
    commentType: CommentType.Auditor,
    allowFiltering: false,
    allowSearching: false,
    allowSorting: false,
  }),
  {
    field: "evaluationCompany",
    headerText: "Evaluation Company",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "applicantFirstName",
    headerText: "Applicant First Name",
    width: 130,
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "applicantLastName",
    headerText: "Applicant Last Name",
    width: 130,
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "businessName",
    headerText: "Business Name",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "businessAddress",
    headerText: "Business Address",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "businessCity",
    headerText: "Business City",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "businessState_AsString",
    headerText: "Business State",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "businessZip",
    headerText: "Business Zip Code",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "longitude",
    headerText: "Longitude",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "latitude",
    headerText: "Latitude",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "businessPhoneNumber",
    headerText: "Business Phone Number",
    width: 160,
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "businessEmail",
    headerText: "Business Email",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "isApplicantBuildingOwner",
    headerText: "Building Owner",
    width: 120,
    type: "boolean",
  } as FortifiedGridColumn,
  {
    field: "companyName",
    headerText: "Company Name",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "companyPhone",
    headerText: "Company Phone",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "companyEmail",
    headerText: "Company Email",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "isNewConstruction",
    headerText: "New construction",
    width: 150,
    type: "boolean",
  } as FortifiedGridColumn,
  {
    field: "sizeSqfts",
    headerText: "Size (sqft)",
    type: "number",
  } as FortifiedGridColumn,
  {
    field: "heightFts",
    headerText: "Height (ft)",
    type: "number",
  } as FortifiedGridColumn,
  {
    field: "approxSizeSqft",
    headerText: "Approx. Size (sqft)",
    type: "number",
  } as FortifiedGridColumn,
  {
    field: "approxHeightFt",
    headerText: "Approx. Height (Ft)",
    type: "number",
  } as FortifiedGridColumn,
  {
    field: "grossRoofAreaSqft",
    headerText: "Gross Roof Area (sqft)",
    type: "number",
  } as FortifiedGridColumn,
  {
    field: "totalFloorAreaSqft",
    headerText: "Total Floor Area (sqft)",
    type: "number",
  } as FortifiedGridColumn,
  {
    field: "buildingName",
    headerText: "Building Name",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "buildingAddress",
    headerText: "Building Address",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "buildingAddress2",
    headerText: "Building Address 2",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "buildingCity",
    headerText: "Building City",
    width: 120,
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "buildingState_AsString",
    headerText: "Building State",
    width: 120,
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "buildingZip",
    headerText: "Building Zip Code",
    width: 120,
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "buildingCounty",
    headerText: "Building County",
    width: 120,
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "hazard_AsString",
    headerText: "Hazard Type",
    width: 120,
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "occupancy_AsString",
    headerText: "Occupancy",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "designationLevel_AsString",
    headerText: "Designation",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "evaluatorEmail",
    headerText: "Evaluator Email",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "hasHailSupplement",
    headerText: "Hail Supplement",
    type: "boolean",
    width: 136,
  } as FortifiedGridColumn,
  //FH Columns
  {
    field: "homeOwnerFirstName",
    headerText: "Home Owner First Name",
    width: 150,
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "homeOwnerLastName",
    headerText: "Home Owner Last Name",
    width: 150,
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "homeOwnerEmail",
    headerText: "Home Owner Email",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "auditor_AsFullName",
    headerText: "Auditor",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "auditorTrainee_AsFullName",
    headerText: "Auditor Trainee",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "traineeAuditStatus_AsString",
    headerText: "Trainee Audit Status",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "isNonProfitProject",
    headerText: "Non-Profit",
    type: "boolean",
  } as FortifiedGridColumn,
  {
    field: "specialProjectName_AsString",
    headerText: "Special Project Name",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "policyNumber",
    headerText: "Policy Number",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "claimNumber",
    headerText: "Claim Number",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "auditStartDate",
    headerText: "Audit Start Date",
    type: "datetime",
    format: { format: "MM/dd/yyyy", type: "datetime" },
  } as FortifiedGridColumn,
  {
    field: "auditEndDate",
    headerText: "Audit End Date",
    type: "datetime",
    format: { format: "MM/dd/yyyy", type: "datetime" },
  } as FortifiedGridColumn,
  {
    field: "isFloridaExisitingHomesProject",
    headerText: "Is Florida Exisiting Homes Project?",
    type: "boolean",
  } as FortifiedGridColumn,
  {
    field: "isNewRoof",
    headerText: "New Roof",
    width: 100,
    type: "boolean",
  } as FortifiedGridColumn,
  {
    field: "distanceToSaltWaterShoreline_AsString",
    headerText: "Distance To SaltWater Shoreline",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "pathwayForContinuousLoadPath_AsString",
    headerText: "Pathway For Continuous Load Path",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "rooferEmail",
    headerText: "Roofer Email",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "engineerEmail",
    headerText: "Engineer Email",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "contractorEmail",
    headerText: "Contractor Email",
    type: "string",
  } as FortifiedGridColumn,
  // End FH Columns
  {
    field: "approvedAt_AsDateOnly",
    headerText: "Approved Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
  } as FortifiedGridColumn,
  {
    field: "approvedAt",
    headerText: "Approved Date/Time",
    type: "datetime",
    format: { format: "MM/dd/yyyy hh:mm:ss a", type: "datetime" },
  } as FortifiedGridColumn,
  {
    field: "expiresAt_AsDateOnly",
    headerText: "Expires Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
  } as FortifiedGridColumn,
  {
    field: "expiresAt",
    headerText: "Expires Date/Time",
    type: "datetime",
    format: { format: "MM/dd/yyyy hh:mm:ss a", type: "datetime" },
  } as FortifiedGridColumn,
  {
    field: "evaluationExpirationDateType_AsString",
    headerText: "Expiration Type",
    type: "string",
  } as FortifiedGridColumn,
  {
    field: "createdAt_AsDateOnly",
    headerText: "Created Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
  } as FortifiedGridColumn,
  {
    field: "createdAt",
    headerText: "Created Date/Time",
    type: "datetime",
    format: { format: "MM/dd/yyyy hh:mm:ss a", type: "datetime" },
  } as FortifiedGridColumn,
  {
    field: "modifiedAt_AsDateOnly",
    headerText: "Updated Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
  } as FortifiedGridColumn,
  {
    field: "modifiedAt",
    headerText: "Updated Date/Time",
    type: "datetime",
    format: { format: "MM/dd/yyyy hh:mm:ss a", type: "datetime" },
  } as FortifiedGridColumn,
  new FortifiedGridColumn({
    field: "createdBy_AsName",
    headerText: "Created By",
    type: "string",
  }),
  new FortifiedGridColumn({
    field: "modifiedBy_AsName",
    headerText: "Updated By",
    type: "string",
  }),
  new FortifiedGridColumn({
    field: "roofCoverTypes_AsString",
    headerText: "Roof Cover Types",
    type: "string",
    width: 180,
  }),
  new FortifiedGridColumn({
    field: "evaluator_AsFullName",
    headerText: "Evaluator",
    type: "string",
  }),
  new GridCommentsColumn({
    field: "unreadCommentsCount",
    headerText: "Comments",
    type: "string",
    width: 110,
    unreadCommentCount: "unreadCommentsCount",
    commentType: CommentType.General,
    allowFiltering: false,
    allowSearching: false,
    allowSorting: false,
  }),
  new FortifiedGridColumn({
    field: "isRedesignation",
    headerText: "Redesignation",
    width: 151,
    type: "boolean",
  }),
  new FortifiedGridColumn({
    field: "numberOfUnits",
    headerText: "Number of Units",
    type: "number",
  }),
  {
    field: "submittedDate",
    headerText: "Submitted Date/Time",
    type: "datetime",
    format: { format: "MM/dd/yyyy hh:mm:ss a", type: "datetime" },
  } as FortifiedGridColumn,
  new FortifiedGridColumn({
    field: "applicantTitle",
    headerText: "Applicant Title",
    type: "string",
  }),
  new FortifiedGridColumn({
    field: "projectPhase",
    headerText: "Project Phase",
    type: "string",
  }),
  new FortifiedGridColumn({
    field: "ibcVersion",
    headerText: "IBC Version",
    type: "string",
  }),
  new FortifiedGridColumn({
    field: "asceCode",
    headerText: "ASCE Code",
    type: "string",
  }),
  new FortifiedGridColumn({
    field: "importanceFactor",
    headerText: "Importance Factor",
    type: "string",
  }),
  new FortifiedGridColumn({
    field: "exposureCategory",
    headerText: "Exposure Category",
    type: "string",
  }),
  new FortifiedGridColumn({
    field: "occupiedYears",
    headerText: "Occupied Years",
    type: "string",
  }),
  new FortifiedGridColumn({
    field: "yearBuilt",
    headerText: "Year Built",
    type: "string",
  }),
  new FortifiedGridColumn({
    field: "numberOfRoofCoverSystems",
    headerText: "Number Of Roof Cover Systems",
    headerTextAlign: "Left",
    width: 170,
    type: "number",
  }),
  new FortifiedGridColumn({
    field: "multifamilyPrimaryUse",
    headerText: "Multifamily Primary Use",
    width: 140,
    type: "string",
  }),
  new FortifiedGridColumn({
    field: "multiUnitAppId",
    headerText: "Multi Unit App Id",
    width: 140,
    type: "string",
  }),
  new FortifiedGridColumn({
    field: "rejectionReasons_AsCSV",
    headerText: "Rejection Reasons",
    width: 140,
    type: "string",
  }),
  new FortifiedCertificateGridClickableIconColumn({
    field: "downloadCertificate",
    headerText: "Certificate",
    type: "string",
    icon: "download",
    width: 100,
    allowFiltering: false,
    allowSearching: false,
    allowSorting: false,
  }),
];

const defaultFields = [
  "unreadCommentsCount",
  "isRedesignation",
  "productType_AsString",
  "id",
  "fortifiedId",
  "status_AsString",
  "IsComplexCommercialEvaluationOrSingleBuilding",
  "isComplexCommercialEvaluationBuilding",
  "buildingName",
  "buildingAddress",
  "buildingAddress2",
  "buildingCity",
  "buildingState_AsString",
  "buildingZip",
  "buildingCounty",
  "longitude",
  "latitude",
  "hazard_AsString",
  "hasHailSupplement",
  "isWithdrawn",
  "rejectionReasons_AsCSV",
  "designationLevel_AsString",
  "evaluationCompany",
  "evaluatorEmail",
  "createdAt_AsDateOnly",
  "createdAt",
  "modifiedAt_AsDateOnly",
  "modifiedAt",
  "approvedAt_AsDateOnly",
  "approvedAt",
  "expiresAt_AsDateOnly",
  "expiresAt",
  "isNonProfitProject",
  "evaluationExpirationDateType_AsString",
  "downloadCertificate",
];

const commercialApplicantFields = [
  "unreadCommentsCount",
  "fortifiedId",
  "isRedesignation",
  "productType_AsString",
  "hazard_AsString",
  "hasHailSupplement",
  "designationLevel_AsString",
  "status_AsString",
  "IsComplexCommercialEvaluationOrSingleBuilding",
  "isComplexCommercialEvaluationBuilding",
  "evaluatorEmail",
  "buildingName",
  "buildingAddress",
  "buildingAddress2",
  "buildingCity",
  "buildingState_AsString",
  "buildingZip",
  "buildingCounty",
  "longitude",
  "latitude",
  "applicantTitle",
  "applicantFirstName",
  "applicantLastName",
  "businessName",
  "businessAddress",
  "businessPhoneNumber",
  "businessEmail",
  "isApplicantBuildingOwner",
  "companyName",
  "companyPhone",
  "companyEmail",
  "isNewConstruction",
  "projectPhase",
  "ibcVersion",
  "asceCode",
  "importanceFactor",
  "exposureCategory",
  "occupiedYears",
  "yearBuilt",
  "sizeSqfts",
  "heightFts",
  "approxSizeSqft",
  "approxHeightFt",
  "grossRoofAreaSqft",
  "totalFloorAreaSqft",
  "numberOfUnits",
  "multifamilyPrimaryUse",
  "numberOfRoofCoverSystems",
  "expiresAt_AsDateOnly",
  "expiresAt",
  "isNonProfitProject",
  "evaluationExpirationDateType_AsString",
  "createdAt_AsDateOnly",
  "createdAt",
  "approvedAt_AsDateOnly",
  "approvedAt",
  "rejectionReasons_AsCSV",
  "downloadCertificate",
];

const commercialEvaluatorFields = [
  "unreadCommentsCount",
  "fortifiedId",
  "isRedesignation",
  "productType_AsString",
  "hazard_AsString",
  "hasHailSupplement",
  "designationLevel_AsString",
  "status_AsString",
  "IsComplexCommercialEvaluationOrSingleBuilding",
  "isComplexCommercialEvaluationBuilding",
  "evaluatorNote",
  "evaluatorEmail",
  "isNewConstruction",
  "buildingName",
  "buildingAddress",
  "buildingAddress2",
  "buildingCity",
  "buildingState_AsString",
  "buildingZip",
  "buildingCounty",
  "longitude",
  "latitude",
  "applicantFirstName",
  "applicantLastName",
  "applicantTitle",
  "businessName",
  "businessAddress",
  "businessPhoneNumber",
  "businessEmail",
  "isApplicantBuildingOwner",
  "companyName",
  "companyPhone",
  "companyEmail",
  "projectPhase",
  "ibcVersion",
  "asceCode",
  "importanceFactor",
  "exposureCategory",
  "occupiedYears",
  "yearBuilt",
  "sizeSqfts",
  "heightFts",
  "approxSizeSqft",
  "approxHeightFt",
  "grossRoofAreaSqft",
  "totalFloorAreaSqft",
  "numberOfUnits",
  "multifamilyPrimaryUse",
  "numberOfRoofCoverSystems",
  "expiresAt_AsDateOnly",
  "expiresAt",
  "isNonProfitProject",
  "evaluationExpirationDateType_AsString",
  "createdAt_AsDateOnly",
  "createdAt",
  "approvedAt_AsDateOnly",
  "approvedAt",
  "rejectionReasons_AsCSV",
  "downloadCertificate",
];

const commercialAuditorAndAdminFields = [
  "unreadCommentsCount",
  "fortifiedId",
  "isRedesignation",
  "productType_AsString",
  "hazard_AsString",
  "hasHailSupplement",
  "designationLevel_AsString",
  "status_AsString",
  "IsComplexCommercialEvaluationOrSingleBuilding",
  "isComplexCommercialEvaluationBuilding",
  "auditorNotes",
  "evaluationCompany",
  "evaluatorEmail",
  "isNewConstruction",
  "buildingName",
  "buildingAddress",
  "buildingAddress2",
  "buildingCity",
  "buildingState_AsString",
  "buildingZip",
  "buildingCounty",
  "longitude",
  "latitude",
  "applicantFirstName",
  "applicantLastName",
  "applicantTitle",
  "businessName",
  "businessAddress",
  "businessPhoneNumber",
  "businessEmail",
  "isApplicantBuildingOwner",
  "companyName",
  "companyPhone",
  "companyEmail",
  "projectPhase",
  "ibcVersion",
  "asceCode",
  "importanceFactor",
  "exposureCategory",
  "occupiedYears",
  "yearBuilt",
  "sizeSqfts",
  "heightFts",
  "approxSizeSqft",
  "approxHeightFt",
  "grossRoofAreaSqft",
  "totalFloorAreaSqft",
  "numberOfUnits",
  "multifamilyPrimaryUse",
  "numberOfRoofCoverSystems",
  "expiresAt_AsDateOnly",
  "expiresAt",
  "isNonProfitProject",
  "evaluationExpirationDateType_AsString",
  "createdAt_AsDateOnly",
  "createdAt",
  "createdBy_AsName",
  "modifiedAt_AsDateOnly",
  "modifiedAt",
  "modifiedBy_AsName",
  "approvedAt_AsDateOnly",
  "approvedAt",
  "rejectionReasons_AsCSV",
  "downloadCertificate",
  "daysSinceStatusUpdated",
  "totalAmountPaid",
  "totalCreditApplied",
];

const homeAuditorFields = [
  "unreadCommentsCount",
  "fortifiedId",
  "isRedesignation",
  "hazard_AsString",
  "hasHailSupplement",
  "designationLevel_AsString",
  "status_AsString",
  "isWithdrawn",
  "auditorNotes",
  "evaluator_AsFullName",
  "auditor_AsFullName",
  "auditorTrainee_AsFullName",
  "traineeAuditStatus_AsString",
  "homeOwnerFirstName",
  "homeOwnerLastName",
  "buildingAddress",
  "buildingAddress2",
  "buildingCity",
  "buildingState_AsString",
  "buildingZip",
  "buildingCounty",
  "longitude",
  "latitude",
  "auditStartDate",
  "auditEndDate",
  "expiresAt_AsDateOnly",
  "expiresAt",
  "evaluationExpirationDateType_AsString",
  "isFloridaExisitingHomesProject",
  "isNonProfitProject",
  "specialProjectName_AsString",
  "isNewRoof",
  "rooferEmail",
  "modifiedBy_AsName",
  "createdAt_AsDateOnly",
  "createdAt",
  "createdBy_AsName",
  "modifiedAt_AsDateOnly",
  "modifiedAt",
  "modifiedBy_AsName",
  "id",
  "approvedAt_AsDateOnly",
  "approvedAt",
  "submittedDate",
  "multiUnitAppId",
  "rejectionReasons_AsCSV",
  "roofCoverTypes_AsString",
  "downloadCertificate",
  "daysSinceStatusUpdated",
  "totalAmountPaid",
  "totalCreditApplied",
];

const homeEvaluatorFields = [
  "unreadCommentsCount",
  "fortifiedId",
  "isRedesignation",
  "hazard_AsString",
  "designationLevel_AsString",
  "status_AsString",
  "isWithdrawn",
  "evaluatorNote",
  "homeOwnerFirstName",
  "homeOwnerLastName",
  "buildingAddress",
  "buildingAddress2",
  "buildingCity",
  "buildingState_AsString",
  "buildingZip",
  "buildingCounty",
  "longitude",
  "latitude",
  "expiresAt_AsDateOnly",
  "expiresAt",
  "evaluationExpirationDateType_AsString",
  "isNonProfitProject",
  "specialProjectName_AsString",
  "isNewRoof",
  "createdAt_AsDateOnly",
  "createdAt",
  "modifiedAt_AsDateOnly",
  "modifiedAt",
  "modifiedBy_AsName",
  "evaluator_AsFullName",
  "approvedAt_AsDateOnly",
  "approvedAt",
  "multiUnitAppId",
  "rejectionReasons_AsCSV",
  "roofCoverTypes_AsString",
  "downloadCertificate",
];

const homeNCIUAFields = [
  "unreadCommentsCount",
  "fortifiedId",
  "status_AsString",
  "hazard_AsString",
  "isNewRoof",
  "buildingAddress",
  "buildingAddress2",
  "buildingCity",
  "buildingState_AsString",
  "buildingZip",
  "buildingCounty",
  "evaluator_AsFullName",
  "createdAt_AsDateOnly",
  "createdAt",
  "submittedDate",
  "auditor_AsFullName",
  "auditStartDate",
  "approvedAt_AsDateOnly",
  "approvedAt",
  "expiresAt_AsDateOnly",
  "expiresAt",
  "evaluationExpirationDateType_AsString",
  "modifiedAt_AsDateOnly",
  "modifiedAt",
  "modifiedBy_AsName",
  "policyNumber",
  "claimNumber",
  "isNonProfitProject",
  "specialProjectName_AsString",
  "multiUnitAppId",
  "rejectionReasons_AsCSV",
  "roofCoverTypes_AsString",
  "downloadCertificate",
];

const homeFields = [
  "unreadCommentsCount",
  "fortifiedId",
  "isRedesignation",
  "hazard_AsString",
  "designationLevel_AsString",
  "status_AsString",
  "homeOwnerFirstName",
  "homeOwnerLastName",
  "buildingAddress",
  "buildingAddress2",
  "buildingCity",
  "buildingState_AsString",
  "buildingZip",
  "buildingCounty",
  "longitude",
  "latitude",
  "expiresAt_AsDateOnly",
  "expiresAt",
  "evaluationExpirationDateType_AsString",
  "isNonProfitProject",
  "specialProjectName_AsString",
  "createdAt_AsDateOnly",
  "createdAt",
  "approvedAt_AsDateOnly",
  "approvedAt",
  "multiUnitAppId",
  "rejectionReasons_AsCSV",
  "roofCoverTypes_AsString",
  "downloadCertificate",
];

const ibhsAdminFields = [
  "unreadCommentsCount",
  "fortifiedId",
  "isRedesignation",
  "productType_AsString",
  "hazard_AsString",
  "hasHailSupplement",
  "designationLevel_AsString",
  "status_AsString",
  "IsComplexCommercialEvaluationOrSingleBuilding",
  "isComplexCommercialEvaluationBuilding",
  "isWithdrawn",
  "auditorNotes",
  "evaluatorNote",
  "evaluator_AsFullName",
  "auditor_AsFullName",
  "auditorTrainee_AsFullName",
  "traineeAuditStatus_AsString",
  "homeOwnerFirstName",
  "homeOwnerLastName",
  "applicantFirstName",
  "applicantLastName",
  "buildingAddress",
  "buildingAddress2",
  "buildingCity",
  "buildingState_AsString",
  "buildingZip",
  "buildingCounty",
  "longitude",
  "latitude",
  "isNonProfitProject",
  "specialProjectName_AsString",
  "multiUnitAppId",
  "policyNumber",
  "claimNumber",
  "expiresAt_AsDateOnly",
  "expiresAt",
  "evaluationExpirationDateType_AsString",
  "isNewRoof",
  "rooferEmail",
  "modifiedAt_AsDateOnly",
  "modifiedAt",
  "modifiedBy_AsName",
  "createdAt_AsDateOnly",
  "createdAt",
  "createdBy_AsName",
  "approvedAt_AsDateOnly",
  "approvedAt",
  "submittedDate",
  "rejectionReasons_AsCSV",
  "roofCoverTypes_AsString",
  "grossRoofAreaSqft",
  "totalFloorAreaSqft",
  "downloadCertificate",
  "daysSinceStatusUpdated",
  "totalAmountPaid",
  "totalCreditApplied",
];

const filterAndSortBoardColumns = (columns: string[]) => [
  ...boardColumnsConfiguration
    .filter((x) => columns.includes(x.field))
    .sort((a, b) => columns.indexOf(a.field) - columns.indexOf(b.field)),
];

export const boardRoleColumnsConfiguration: Record<
  UserRoleType | "Default",
  FortifiedGridColumn[]
> = {
  "FC/FMF Applicant": filterAndSortBoardColumns(commercialApplicantFields),
  "FC/FMF Evaluator": filterAndSortBoardColumns(commercialEvaluatorFields),
  "FC/FMF Auditor": filterAndSortBoardColumns(commercialAuditorAndAdminFields),
  "FH Applicant": [],
  "FH Evaluator": filterAndSortBoardColumns(homeEvaluatorFields),
  "FH Auditor": filterAndSortBoardColumns(homeAuditorFields),
  "FH Auditor Trainee": filterAndSortBoardColumns(homeAuditorFields),
  "FH Roofer": filterAndSortBoardColumns(homeFields),
  "FH Direct Submit Roofer": filterAndSortBoardColumns(homeFields),
  "FH Engineer": filterAndSortBoardColumns(homeFields),
  "FH Contractor": filterAndSortBoardColumns(homeFields),
  "IBHS Admin": filterAndSortBoardColumns(ibhsAdminFields),
  "IBHS Partner": filterAndSortBoardColumns(ibhsAdminFields),
  "FH Evaluator Admin": filterAndSortBoardColumns(homeEvaluatorFields),
  "Relativity Admin": filterAndSortBoardColumns(ibhsAdminFields),
  "NCIUA Partner": filterAndSortBoardColumns(homeNCIUAFields),
  "FC/FMF Admin": filterAndSortBoardColumns(commercialAuditorAndAdminFields),
  "WFP Applicant": [],
  "WFP Admin": [],
  "WFP Evaluator": [],
  "WFP Auditor": [],
  Default: filterAndSortBoardColumns(defaultFields),
};

export const getGridColumnsConfigurationByRole = (role?: UserRoleType) => {
  if (!role) {
    return boardRoleColumnsConfiguration.Default as FortifiedGridColumn[];
  }

  const boardColumnsConfigurationForRole = boardRoleColumnsConfiguration[role];

  return boardColumnsConfigurationForRole
    ? boardColumnsConfigurationForRole
    : (boardRoleColumnsConfiguration.Default as FortifiedGridColumn[]);
};
