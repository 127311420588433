import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { BaseHomeEvaluationRedesignationFormParts } from "../../../common/types/BaseHomeEvaluationRedesignationFormModel";
import { HomeStandardRedesignationFormModel } from "../../types/HomeStandardRedesignationFormModel";
import { Evaluation_HomeStandardRedesignationFormModel } from "../../types/HomeStandardRedesignationFormModel_Model";

class HomeStandardRedesignationFormAPIClient {
  updateHomeStandardRedesignationForm = async (
    evaluationId: string,
    redesignationForm: HomeStandardRedesignationFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeStandardRedesignationFormModel> => {
    return baseApiClient.put<Evaluation_HomeStandardRedesignationFormModel>(
      `/home-standard/redesignation/redesignation-form/${evaluationId}`,
      {
        redesignationForm,
        fieldKey,
      }
    );
  };

  submitHomeStandardRedesignationForm = async (
    evaluationId: string,
    redesignationForm: HomeStandardRedesignationFormModel,
    formPart?: BaseHomeEvaluationRedesignationFormParts
  ): Promise<Evaluation_HomeStandardRedesignationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationRedesignationFormParts
      ).pop() as BaseHomeEvaluationRedesignationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeStandardRedesignationFormModel>(
      `/home-standard/redesignation/redesignation-form/${evaluationId}/${formPartValue}`,
      redesignationForm
    );
  };
}

export const homeStandardRedesignationFormAPIClient =
  new HomeStandardRedesignationFormAPIClient();
