import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC, useEffect, useState } from "react";
import { reactPlugin } from "../../AppInsights";
import { WildfireEvaluationProvider } from "../components/Provider/WildfireEvaluationProvider";
import { WildfireEvaluationCommentProvider } from "../components/Provider/WildfireEvaluationCommentsProvider";
import { EvaluationChangeRequestProvider } from "../components/Provider/EvaluationChangeRequestProvider";
import { WildfireSchemaFactoryV2 } from "../customHooks/useFieldSchemaFactory";
import wildfireBaseApiClient from "../lib/wildfireBaseApiClient";
import { Schema } from "@ucl/library";
import { wildfireUserStore } from "../stores/WildfireUserStore";
import { UnSavedChangesBlockerProvider } from "../components/Provider/UnsavedChangesBlockerContext";
import { WildfireLandscapeReviewApplicantIterationEngine } from "../components/IterationEngine/WildfireLandscapeReviewApplicantIterationEngine";
import WildfireLandscapeReviewAdminIterationEngine from "../components/IterationEngine/WildfireLandscapeReviewAdminIterationEngine";

export interface WildfireLandscapeReviewIterationEnginePageProps {
  evaluationId?: string;
}

const WildfireLandscapeReviewIterationEngineComponent: FC<
  WildfireLandscapeReviewIterationEnginePageProps
> = (props) => {
  const [wildfireSchemaFactory, setWildfireSchemaFactory] =
    useState<WildfireSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await wildfireBaseApiClient.get<Schema>(`/wildfire/schema`);
    const newSchemaFactory = new WildfireSchemaFactoryV2(schema);
    setWildfireSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!wildfireSchemaFactory) {
      getSchema();
    }
  }, []);

  return (
    <div className="wildfire-page">
      {props.evaluationId && wildfireSchemaFactory && (
        <WildfireEvaluationProvider evaluationId={props.evaluationId}>
          <WildfireEvaluationCommentProvider>
            <EvaluationChangeRequestProvider
              evaluationId={props.evaluationId}
              wildfireSchema={wildfireSchemaFactory.schema}
            >
              <UnSavedChangesBlockerProvider>
                <>
                  {wildfireUserStore.isWildfireApplicant && (
                    <WildfireLandscapeReviewApplicantIterationEngine
                      evaluationId={props.evaluationId}
                      wildfireSchemaFactory={wildfireSchemaFactory}
                    />
                  )}
                  {!wildfireUserStore.isWildfireApplicant && (
                    <WildfireLandscapeReviewAdminIterationEngine
                      evaluationId={props.evaluationId}
                      wildfireSchemaFactory={wildfireSchemaFactory}
                    />
                  )}
                </>
              </UnSavedChangesBlockerProvider>
            </EvaluationChangeRequestProvider>
          </WildfireEvaluationCommentProvider>
        </WildfireEvaluationProvider>
      )}
    </div>
  );
};

export const WildfireLandscapeReviewIterationEnginePage = withAITracking(
  reactPlugin,
  WildfireLandscapeReviewIterationEngineComponent,
  "WildfireIterationEngine",
  "tracking-container"
);
