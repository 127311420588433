import {
  FieldLabel,
  PermissionsContext,
  PermissionsContextProps,
  fieldTypes,
} from "@ucl/library";
import { useContext, useEffect, useState } from "react";
import { FieldLabeledProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { boxTokenStore } from "../../../stores/BoxTokenStore";
import { BoxContentExplorer } from "../../../../common/Components/Fields/BoxContentExplorer";
import variables from "../../../../common/config/variables";
import "./styles.scss";
import { FormGroup, Intent } from "@blueprintjs/core";
import classnames from "classnames";
import { BoxFile } from "box-ui-elements";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { get, set } from "lodash";

export interface WildfireBoxContentExplorerValue {
  rootFolderId?: string;
  fileCount?: number;
  hasModifiedFiles?: boolean;
}

export interface WildfireBoxContentExplorerProps
  extends fieldTypes.BaseFieldProps<WildfireBoxContentExplorerValue> {
  labelProps?: FieldLabeledProps;
  examplePhoto?: string;
  examplePhoto2?: string;
  defaultToUpload?: boolean;
  legacyEvaluationShouldMakeOptional?: boolean;
  canDelete?: boolean;
  hasLimitedFileTypes?: boolean;
}

export const WildfireBoxContentExplorer: React.FC<
  WildfireBoxContentExplorerProps
> = (props) => {
  const [ready, setReady] = useState<boolean>(false);
  const [token, setToken] = useState<string>();

  const [localValue, setLocalValue] = useState<WildfireBoxContentExplorerValue>(
    {
      ...props.value,
      fileCount: props.value?.fileCount || 0,
      hasModifiedFiles: props.value?.hasModifiedFiles || false,
    }
  );

  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const [timestamp, updateTimestamps] = useState<string>(
    `${Date.now()}-${props.value?.rootFolderId}`
  );

  //TODO: Find a Better Place for Passing Permissions Per Field
  const { hasPermission } = useContext(
    PermissionsContext
  ) as PermissionsContextProps<PermissionsKey>;

  const setBoxTokenStore = async () => {
    if (!boxTokenStore.isTokenValid) {
      await boxTokenStore.refreshBoxToken();
    } else {
      await boxTokenStore.init();
    }

    setToken(boxTokenStore.boxToken);
  };

  useEffect(() => {
    const refreshInterval = parseInt(variables.boxFileUploaderRefreshInterval); //30 minutes

    setBoxTokenStore();

    // Set up an interval to periodically check and refresh the token
    const tokenRefreshInterval = setInterval(setBoxTokenStore, refreshInterval);

    setReady(true);

    return () => {
      clearInterval(tokenRefreshInterval);
    };
  }, []);

  useEffect(() => {
    updateTimestamps(`${Date.now()}-${props.value?.rootFolderId}`);
  }, [props.value?.fileCount]);

  useEffect(() => {
    if (props.value && props.value !== localValue) {
      setLocalValue({
        ...props.value, // Copy all properties from props.value
        fileCount: props.value.fileCount || 0,
        hasModifiedFiles: props.value.hasModifiedFiles || false,
        rootFolderId: props.value.rootFolderId,
      });

      updateTimestamps(`${Date.now()}-${props.value?.rootFolderId}`);
    }
  }, [props.value]);

  useEffect(() => {
    if (!get(window, "ibhs")) {
      set(window, "ibhs", { boxAcceptExt: "*" });
    }
    if (props.hasLimitedFileTypes) {
      set(window, "ibhs", { boxAcceptExt: allowableFileExtensions.join(",") });
    }
  }, [props.hasLimitedFileTypes]);

  useEffect(() => {
    if (hasChanges) {
      syncFiles();
      setHasChanges(false);
    }
  }, [hasChanges]);

  const syncFiles = () => {
    if (props.onSubmit) {
      props.onSubmit(localValue);
    }
  };

  const onUpload = (files: BoxFile[]): void => {
    setLocalValue((prevValue) => ({
      ...prevValue,
      fileCount: Math.max(0, (prevValue.fileCount || 0) + files.length),
      hasModifiedFiles: true,
    }));

    setHasChanges(true);
  };

  const onDelete = (files: BoxFile[]): void => {
    setLocalValue((prevValue) => ({
      ...prevValue,
      fileCount: Math.max(0, (prevValue.fileCount || 0) - files.length),
      hasModifiedFiles: true,
    }));

    setHasChanges(true);
  };

  const hasFiles = (localValue.fileCount || 0) > 0;

  const allowableFileExtensions = [
    ".jpg",
    ".jpeg",
    ".jpe",
    ".jif",
    ".jfif",
    ".jfi",
    ".png",
    ".gif",
    ".webp",
    ".tiff",
    ".tif",
    ".heif",
    ".heic",
  ];

  return (
    <div className="wildfire-box-content-explorer-field">
      <FormGroup
        className={
          props.isRequired &&
          !hasFiles &&
          !props.legacyEvaluationShouldMakeOptional
            ? "has-required-background"
            : ""
        }
      >
        {props.label && (
          <FieldLabel
            labelName={props.label}
            tooltipDescription={props.description}
            fieldProps={{ ...props }}
            {...props.labelProps}
            disabled={props.disabled}
          />
        )}
        <div
          className={classnames({
            "wildfire-box-content-explorer-has-example-photo":
              !!props.examplePhoto,
            "wildfire-box-content-explorer-no-example-photo":
              !props.examplePhoto,
          })}
        >
          <div className="wildfire-box-content-explorer-container">
            {ready && token && (
              <BoxContentExplorer
                key={timestamp}
                token={token}
                rootFolderId={localValue.rootFolderId}
                language="en-US"
                canCreateNewFolder={false}
                canShare={false}
                canRename={false}
                canDelete={
                  !props.disabled &&
                  (hasPermission("CanDeleteFilesInUploaders") ||
                    !!props.canDelete)
                }
                onUpload={(files: BoxFile[]) => {
                  onUpload(files);
                }}
                onDelete={(files: BoxFile[]) => {
                  onDelete(files);
                }}
                canUpload={!props.disabled}
                defaultToUpload={
                  false
                  //props.defaultToUpload && !hasFiles && !props.disabled
                }
                hasAutoUpload={true}
                hasModifiedUploadButton={true}
                requestInterceptor={(request) => {
                  if (props.hasLimitedFileTypes) {
                    const { data } = request;

                    if (data?.name) {
                      const fileExtension = data.name
                        .split(".")
                        .pop()
                        ?.toLowerCase();
                      if (
                        !allowableFileExtensions.includes(`.${fileExtension}`)
                      ) {
                        request.data.parent.id = null; // Invalidate the folder ID to block upload

                        AppToaster.show({
                          message: (
                            <div>
                              <h4>
                                {request.data.name} is an unsupported file type.
                              </h4>
                              <p>
                                Please upload a file with a supported extension.
                              </p>
                              <div>Allowable file extensions:</div>
                              <ul>
                                {allowableFileExtensions.map((value) => (
                                  <li key={value}>{value}</li>
                                ))}
                              </ul>
                            </div>
                          ),
                          intent: Intent.DANGER,
                          timeout: 5000,
                        });
                      }
                    }
                  }
                  return request;
                }}
              />
            )}
            {props.examplePhoto && (
              <div className="wildfire-box-content-explorer-example-photo-container">
                <p className="wildfire-box-content-explorer-example-photo-label">
                  <strong>Example: </strong>
                </p>
                <img
                  className="wildfire-box-content-explorer-example-photo"
                  src={props.examplePhoto}
                  alt="Example Photo 1"
                />
                <img
                  className="wildfire-box-content-explorer-example-photo"
                  src={props.examplePhoto2}
                  alt="Example Photo 2"
                />
              </div>
            )}
          </div>
        </div>
      </FormGroup>
    </div>
  );
};
