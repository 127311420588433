import {
  FieldSchemaFormBuilderV2,
  SchemaFactoryV2,
  SmartAddressFieldProps,
} from "@ucl/library";
import { addressApiClient } from "../../../../../../foritfied/lib/apiClients/address/addressApiClient";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorer } from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { Evaluation_HomeSCSHEvaluationFormModel } from "../../../types/HomeSCSHEvaluationFormModel";

const modelName = "Evaluation";
const subModelName = "HomeSCSHEvaluation";
const formName = "BasicInformationForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

//Part 1: Homeowner Information
const homeownerInformationFields = () => [
  buildFieldKey("HomeownerFirstName"),
  buildFieldKey("HomeownerLastName"),
  buildFieldKey("HomeownerEmail"),
  {
    fieldKey: buildFieldKey("Address"),
    getAddressesByQuery: addressApiClient.getAddressesByQuery,
    getAddressByQuery: addressApiClient.getAddressByQuery,
    isSmartOnly: false,
    shouldShowAutoSuggest: true,
    shouldSetCountyOnSelect: true,
  } as SmartAddressFieldProps,
  buildFieldKey("DuplicateAddressConfirmation"),
];

const homeownerInformationRequiredFields = [
  buildFieldKey("HomeownerFirstName"),
  buildFieldKey("HomeownerLastName"),
  buildFieldKey("HomeownerEmail"),
  buildFieldKey("Address"),
];

export const homeSCSHHomeownerInformationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeSCSHEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeSCSHEvaluationFormModel>(
        modelName,
        homeownerInformationFields(),
        onValueChange,
        form,
        errors,
        homeownerInformationRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeSCSHEvaluationFormModel>;
};

// Full form builder with all parts included
export const homeSCSHBasicInformationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeSCSHEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeSCSHEvaluationFormModel>(
        modelName,
        homeownerInformationFields(),
        onValueChange,
        form,
        errors,
        homeownerInformationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeSCSHEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeSCSHIterationEngineBasicInformationFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_HomeSCSHEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeSCSHEvaluationFormModel>(
        modelName,
        homeownerInformationFields(),
        onValueChange,
        form,
        errors,
        homeownerInformationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeSCSHEvaluationFormModel>;
};
