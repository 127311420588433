import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { evaluationStore } from "../../../../foritfied/stores/EvaluationStore";
import HomeStandardRedesignationChecklist from "../components/Checklist/HomeStandardRedesignationChecklist";

export interface HomeStandardRedesignationChecklistPageProps {
  evaluationId?: string;
}

const HomeStandarddRedesignationChecklistPageComponent: FC<
  HomeStandardRedesignationChecklistPageProps
> = (props) => {
  //In the future, we want to remove all dependencies on the global store
  evaluationStore.init(props.evaluationId || "");
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeStandardRedesignationChecklist
            evaluationId={props.evaluationId}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeStandardRedesignationChecklistPage = withAITracking(
  reactPlugin,
  HomeStandarddRedesignationChecklistPageComponent,
  "HomeStandardRedesignationChecklistPage",
  "tracking-container"
);
