import { Schema } from "@ucl/library";
import { useContext, useEffect, useState } from "react";
import wildfireBaseApiClient from "../../../../../../../wildfire/lib/wildfireBaseApiClient";
import { UnsavedChangesBlockerContext } from "../../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../../../../../common/types/Forms/FormChecklist";
import { HomeSchemaFactoryV2 } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { BaseHomeEvaluationHomeownerAuthorizationFormParts } from "../../../../../common/types/BaseHomeEvaluationHomeownerAuthorizationFormModel";
import { Evaluation_HomeNCIUARedesignationFormModel } from "../../../../evaluation/types/HomeNCIUARedesignationFormModel_Model";
import { HomeNCIUARedesignationHomeownerAuthorizationFormContainerProps } from "./HomeNCUIARedesignationHomeownerAuthorizationFormContainer";
import { AppHeaderContext } from "../../../../../../../wildfire/components/Provider/AppHeaderContext";

function useHomeNCIUARedesignationHomeownerAuthorizationFormContainer(
  props: HomeNCIUARedesignationHomeownerAuthorizationFormContainerProps
) {
  const [commercialSchemaFactory, setCommercialSchemaFactory] =
    useState<HomeSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await wildfireBaseApiClient.get<Schema>(`/wildfire/schema`);
    const newSchemaFactory = new HomeSchemaFactoryV2(schema);
    setCommercialSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!commercialSchemaFactory) {
      getSchema();
    }
    return () => {
      setDefaultHeader();
    };
  }, []);

  useEffect(() => {
    setFormPart(getBestFormPart());
  }, [props.homeownerAuthorizationFormPart]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const [formPart, setFormPart] = useState<
    BaseHomeEvaluationHomeownerAuthorizationFormParts | undefined
  >(props.homeownerAuthorizationFormPart);

  const [evaluationFormModel, setEvaluationFormModel] =
    useState<Evaluation_HomeNCIUARedesignationFormModel>();

  const homeownerAuthorizationForm =
    evaluationFormModel?.homeNCIUARedesignation.homeownerAuthorizationForm;

  const { setFotifiedHomeEvaluationHeader, setDefaultHeader } =
    useContext(AppHeaderContext);

  useEffect(() => {
    if (evaluationFormModel) {
      setFotifiedHomeEvaluationHeader(evaluationFormModel);
    }
  }, [evaluationFormModel]);

  const getNextUnsubmittedFormPart = () => {
    if (!homeownerAuthorizationForm) {
      return null;
    }

    if (!homeownerAuthorizationForm.isHomeownerAuthorizationComplete) {
      return BaseHomeEvaluationHomeownerAuthorizationFormParts.HomeownerAuthorization;
    }

    // Form is complete
    return null;
  };

  const getBestFormPart =
    (): BaseHomeEvaluationHomeownerAuthorizationFormParts => {
      // Default to the first part of the form if we can't find a better option
      const defaultFormPart = Object.values(
        BaseHomeEvaluationHomeownerAuthorizationFormParts
      )[0];

      // If the application form part is provided (by URL), use it
      if (!!props.homeownerAuthorizationFormPart) {
        return props.homeownerAuthorizationFormPart;
      }

      // Otherwise, try to find the first unsubmitted part of the form
      // TODO: This doesn't work yet, needs to wait for applicationFormModel to load
      return getNextUnsubmittedFormPart() ?? defaultFormPart;
    };

  const getApplicationPartChecklistStatus = (
    formPart: BaseHomeEvaluationHomeownerAuthorizationFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case BaseHomeEvaluationHomeownerAuthorizationFormParts.HomeownerAuthorization:
        return homeownerAuthorizationForm?.isHomeownerAuthorizationComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
    }
  };

  const checklistParts: FormChecklistPart<BaseHomeEvaluationHomeownerAuthorizationFormParts>[] =
    [
      {
        formPart:
          BaseHomeEvaluationHomeownerAuthorizationFormParts.HomeownerAuthorization,
        displayName: "Homeowner Authorization",
        requiredFieldCount:
          homeownerAuthorizationForm?.homeownerAuthorizationRequiredCount,
      },
    ];

  return {
    applicationChecklistParts: checklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    applicationFormPart: formPart,
    getBestFormPart,
    commercialSchemaFactory,
  };
}

export default useHomeNCIUARedesignationHomeownerAuthorizationFormContainer;
