import React from "react";
import { useNavigate } from "react-router";
import { SecondaryButton } from "@ucl/library";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.scss";
import { faListAlt } from "@fortawesome/free-solid-svg-icons";

interface GoToChecklistButtonButtonProps {
  evaluationId: string;
  productTypeRoute: string;
}

const GoToChecklistButton: React.FC<GoToChecklistButtonButtonProps> = (
  props
) => {
  const navigate = useNavigate();
  return (
    <SecondaryButton
      className="go-to-checklist-button"
      onClick={() => {
        navigate(`${props.productTypeRoute}/checklist/${props.evaluationId}`);
      }}
    >
      <FontAwesomeIcon icon={faListAlt} />
      {"View Checklist"}
    </SecondaryButton>
  );
};

export default GoToChecklistButton;
