import { useNavigate } from "react-router";
import {
  ApplicantApplicationStatuses,
  ApplicantLandscapeReviewStatuses,
  WildfireApplicationOverviewCardProps,
} from "./WildfireApplicationOverviewCard";
import { ReactNode, useState } from "react";
import { Button, Callout, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { wildfireEvaluationApiClient } from "../../../lib/apiClients/evaluation/wildfireEvaluationApiClient";
import { browserDownloadFileResponse } from "../../../../common/utils/download";
import { parseDate } from "../../../../common/utils/date";
import { wildfireRoutePrefix } from "../../../pages/index/wildfireRouteConfig";
import { CancelMadronusIntegrationButton } from "../../Button/CancelMadronusIntegrationButton/CancelMadronusIntegrationButton";
import {
  faCircleCheck,
  faCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { EvaluationStatus } from "../../../types/WildfireApplication/WildfireApplication";
import ResubmitEvalautionButton from "../../Button/ResubmitEvaluationButton/ResubmitEvalautionButton";
import { WildfireEvaluationSteps } from "../../../../foritfied/types/evaluation/Evaluation";
import { formatSecondaryAddressInfo } from "../../../lib/application/applicationUtil";
import { wildfireLandscapeReviewApiClient } from "../../../lib/apiClients/evaluation/wildfireLandscapeReviewApiClient";
import { useFeatureToggle } from "../../../customHooks/useFeatureToggle";

const useWildfireApplicationOverviewCard = ({
  wildfireEvaluation,
  fetchWildfireApplications,
}: WildfireApplicationOverviewCardProps) => {
  const { areWFPLandscapeReviewsEnabled } = useFeatureToggle();

  const navigate = useNavigate();

  const [isCertificateLoading, setIsCertificateLoading] = useState(false);
  const [isCreateLandscapeReviewLoading, setIsCreateLandscapeReviewLoading] =
    useState(false);

  const getFormattedSecondaryAddressInfo = (): string => {
    const address = wildfireEvaluation.streetAddress;
    const hasAddress =
      !!address &&
      !!address.street &&
      !!address.city &&
      !!address.state_AsString &&
      !!address.zip;

    // WFP ID and Address (if it exists)
    return hasAddress
      ? `, ${formatSecondaryAddressInfo(
          address.street2,
          address.city,
          address.state_AsString,
          address.zip
        )}`
      : "";
  };

  const getPrimaryHeaderInfo = (): string => {
    const wfpId = wildfireEvaluation.wildfirePreparedApplicationId;
    const address = wildfireEvaluation.streetAddress;
    const hasAddress =
      !!address &&
      !!address.street &&
      !!address.city &&
      !!address.state_AsString &&
      !!address.zip;

    // WFP ID and Address (if it exists)
    return hasAddress ? `${wfpId} - ${address.street}` : wfpId;
  };

  const isOriginApplicationInIBHSEligibilityReview =
    wildfireEvaluation.originApplication_Step ===
    WildfireEvaluationSteps.WildfireApplicationIBHSEligibilityReview;

  const originApplicationCurrentApplicantStep =
    wildfireEvaluation.originApplication_ApplicantStatus;
  const firstLandscapeReviewCurrentApplicantStep =
    wildfireEvaluation.firstLandscapeReview_ApplicantStatus;
  const secondLandscapeReviewCurrentApplicantStep =
    wildfireEvaluation.secondLandscapeReview_ApplicantStatus;

  const originApplicationStatus =
    wildfireEvaluation.originApplication_Status_AsString;
  const firstLandscapeReviewStatus =
    wildfireEvaluation.firstLandscapeReview_Status_AsString;
  const secondLandscapeReviewStatus =
    wildfireEvaluation.secondLandscapeReview_Status_AsString;

  const continueLandscapeReviewEvaluationId =
    wildfireEvaluation.continueLandscapeReviewEvaluationId;
  const continueLandscapeReviewStatus =
    wildfireEvaluation.continueLandscapeReviewStatus_AsString;
  const continueLandscapeReviewFormPart =
    wildfireEvaluation.continueLandscapeReviewFormPart;

  const getApplicantProgressValue = (step?: number, status?: string) => {
    if (!step || !status) {
      return 0;
    }

    if (status === "Designated") {
      return step;
    }

    return step - 1;
  };

  const getStatusBadgeClass = (status?: EvaluationStatus): string => {
    switch (status) {
      case "Application":
        return "application";
      case "Application Payment":
        return "application-payment";
      case "Eligibility Photos":
        return "eligibility-photos";
      case "IBHS Eligibility Review":
        return "ibhs-eligibity-review";
      case "Changes Required":
        return "changes-required";
      case "Ready For Re-Review":
        return "ready-for-re-review";
      case "Evaluator Assignment":
      case "Field Evaluations":
        return "field-evaluations";
      case "IBHS Review":
        return "ibhs-review";
      case "Designated":
        return "approved";
      case "Not Designated":
        return "not-approved";
      case "Closed":
        return "closed";
      default:
        return "default";
    }
  };

  const getOriginApplicationStatusSummary = (): string[] => {
    if (!originApplicationStatus) {
      return ["Missing current status value. Please contact support."];
    }

    switch (originApplicationStatus) {
      case "Application":
        return ["Your application has been started, but not submitted."];
      case "Application Payment":
        return ["Your application is pending payment."];
      case "Eligibility Photos":
        return ["Your application is pending eligibility photos."];
      case "IBHS Eligibility Review":
        return ["We are reviewing your eligibility photos."];
      case "Changes Required":
        if (isOriginApplicationInIBHSEligibilityReview) {
          return [
            "After reviewing your eligibility photos, your home needs additional required prep work. We're here to help.",
          ];
        } else {
          return [
            "After reviewing your documentation, there are outstanding requirements for the Wildfire Prepared Home designation.",
          ];
        }
      case "Ready For Re-Review":
        if (isOriginApplicationInIBHSEligibilityReview) {
          return ["We are reviewing your submitted changes."];
        } else {
          return ["We are reviewing your submitted changes."];
        }
      case "Evaluator Assignment":
      case "Field Evaluations":
        return [
          "Congratulations! Your eligibility photos were approved. Your application has been sent to a 3rd party evaluation company.",
        ];
      case "IBHS Review":
        return [
          "Your Wildfire Prepared Home application is making progress! IBHS has received your home's evaluation photos and data from the evaluator.",
        ];
      case "Designated":
        return [
          "Congratulations! Your home has achieved the Wildfire Prepared Home designation that meets the IBHS standard.",
        ];
      case "Not Designated":
        return [
          "We regret to inform you that your home did not achieve a Wildfire Prepared Home designation.",
        ];
      case "Closed":
        return ["Your home's application has been closed."];
      default:
        return ["Unexpected current status value. Please contact support."];
    }
  };

  const getOriginApplicationNextStatusSummary = (): ReactNode[] => {
    if (!originApplicationStatus) {
      return ["Missing current status value. Please contact support."];
    }

    switch (originApplicationStatus) {
      case "Application":
        return [
          <>
            <b>1. Preparation</b>: Complete your home and yard prep work as
            outlined in the{" "}
            <a href="https://wildfireprepared.org/wp-content/uploads/WPH-How-To-Prepare-My-Home-Checklist.pdf">
              &quot;How-To Prepare My Home Checklist&quot;
            </a>{" "}
            Take wide-angle photos (&quot;eligibility photo&quot;) of every side
            of your home. At least two photos per side works well.
          </>,
          <>
            <b>2. Complete Application and Payment</b>: Fill out all required
            fields. Once complete, you&apos;ll be directed to the payment page.
            Please note, the application fee is nonrefundable, so ensure you are
            ready to continue.
          </>,
          <>
            <b>3. Photo Upload and Submission</b>: Upload your eligibility
            photos for review. Note that you must upload the photos within 60
            days to prevent your application from being closed due to
            inactivity.
          </>,
        ];
      case "Application Payment":
        return [
          <>
            <b>1. Pay Application Fee</b>: The application fee is nonrefundable,
            so ensure you are ready to continue. You will be required to upload
            eligibility photos next.
            <ul>
              <li>Click &quot;Continue Application&quot;</li>
              <li>Click &quot;Proceed to Payment.&quot;</li>
              <li>Enter payment information and click &quot;Pay.&quot;</li>
            </ul>
          </>,
          <>
            <b>2. Photo Upload and Submission</b>: Upload your eligibility
            photos for review. Note that you must upload the photos within 60
            days to prevent your application from being closed due to
            inactivity.
          </>,
        ];
      case "Eligibility Photos":
        return [
          <>
            <b>1. Prep and Photos</b>: Take wide-angle photos (&quot;eligibility
            photo&quot;) of every side of your home showing you&apos;ve
            completed the wildfire mitigation work as outlined in the{" "}
            <a href="https://wildfireprepared.org/wp-content/uploads/WPH-How-To-Prepare-My-Home-Checklist.pdf">
              &quot;How-To Prepare My Home Checklist&quot;
            </a>
          </>,
          <>
            <b>2. Photo Upload</b>: Upload your eligibility photos for review.
            Be sure to upload the photos within 60 days to prevent your
            application from being closed due to inactivity.
          </>,
          <>
            <b>3. Submit</b>: Click &quot;Submit for Eligibility Review.&quot;
          </>,
        ];
      case "IBHS Eligibility Review":
        return [
          <>
            <b>1. Check for Updates</b>: Watch your email or log in here for
            updates.
          </>,
          <>
            <b>2. Approval and Evaluation</b>: Once approved, your home will be
            queued for an in-person evaluation. Note: Not every home will be
            eligible.
          </>,
          <>
            <b>3. Additional Work (If Needed)</b>: If more work is required,
            we&apos;ll guide you by providing link to log into the portal with
            feedback. You&apos;ll have 60 days to complete it and submit new
            photos.
          </>,
          <>
            <b>4. Feedback and Re-Review (If Needed)</b>: Complete the requested
            changes, capture new photos, and click &quot;Send for Review.&quot;
          </>,
        ];
      case "Changes Required":
        if (isOriginApplicationInIBHSEligibilityReview) {
          return [
            <>
              <b>1. View Required Changes</b>: Click &quot;See Change
              Requests&quot; to view comments for necessary remediation.
            </>,
            <>
              <b>2. Complete Work</b>: Finish the required changes within 60
              days, then take and upload photos of the completed work.
            </>,
            <>
              <b>3. Submit for Re-Review</b>: Click &quot;Send for Review&quot;
              to complete the step. After 60 days of inactivity, your
              application will be closed.
            </>,
          ];
        } else {
          return [
            <>
              <b>1. View Required Changes</b>: Click &quot;See Change
              Requests&quot; to view comments for necessary remediation.
              We&apos;re here to help.
            </>,
            <>
              <b>2. Complete Work</b>: Finish the required changes within 60
              days, then take and upload photos of the completed work.
            </>,
            <>
              <b>3. Submit for Re-Review</b>: Click &quot;Send for Review&quot;
              to complete the step. After 60 days of inactivity, your
              application will be closed
            </>,
          ];
        }

      case "Ready For Re-Review":
        if (isOriginApplicationInIBHSEligibilityReview) {
          return [
            <>
              <b>1. Check for Updates</b>: Watch your email or log in here for
              updates or additional required changes.
            </>,
            <>
              <b>2. Next step</b>: Once approved, your application will proceed
              to an in-person exterior home evaluation.
            </>,
          ];
        } else {
          return [
            <>
              <b>1. Check for Updates</b>: Watch your email or log in here for
              updates or additional required changes.
            </>,
            <>
              <b>2. Next step</b>: Once approved, your designation and
              certificate will be emailed and accessible here.
            </>,
          ];
        }

      case "Evaluator Assignment":
      case "Field Evaluations":
        return [
          <>
            <b>1. Check for Updates</b>: An evaluator has been assigned and will
            call to schedule within a week of the visit. Evaluations depend on
            weather, location, and workload.
          </>,
          <>
            <b>2. Exterior Access</b>: You don&apos;t need to be home but ensure
            exterior access by leaving gates open/unlocked.
          </>,
          <>
            <b>3. In-Person Evaluation</b>: The evaluator will visit to take
            photos and document your completed work. They do not provide
            wildfire mitigation feedback and are only there to collect photos
            and data to verify the work.
          </>,
          <>
            <b>4. Review and Designation</b>: After the evaluation, the Wildfire
            Prepared Home Team will review the photos and data to determine your
            designation level.
          </>,
          <>
            <b>5. Additional Work (If Needed)</b>: If more work is required,
            we&apos;ll guide you by providing link to log into the portal with
            feedback. You&apos;ll have 60 days to complete it and submit new
            photos.
          </>,
        ];
      case "IBHS Review":
        return [
          <>
            <b>1. Check for Updates</b>: Watch your email or log in here for
            updates.
          </>,
          <>
            <b>2. Approval and Designation</b>: Once your home qualifies, a
            certificate will be available for download.
          </>,
          <>
            <b>3. Additional Work (If Needed)</b>: If more work is required,
            we&apos;ll guide you by providing link via email to log into this
            portal with feedback. You&apos;ll have 60 days to complete it and
            submit new photos.
          </>,
        ];
      case "Designated":
        return [
          <>
            <b>1. Download Certificate</b>: See your home&apos;s designation
            level and download your certificate.
          </>,
          <>
            <b>2. Insurance</b>: Contact your insurance provider to explore
            potential price considerations or incentives.
          </>,
          <>
            <b>3. Certificate Expiration</b>: This certificate is valid for 3
            years. An annual maintenance review is required to maintain
            compliance:
            <ul>
              <li>
                Ensure no vegetation enters the 5-foot noncombustible buffer.
              </li>
              <li>
                Maintain vegetation within 5-30 feet. We&apos;ll notify you via
                email or text when your annual review is due.
              </li>
            </ul>
          </>,
          <>
            <b>4. Recertification</b>: After 3 years, recertify at{" "}
            <a href="https://wildfireprepared.org/">wildfireprepared.org</a> to
            keep your designation active.
          </>,
        ];
      case "Not Designated":
        return [
          <>
            <b>1. Check for Updates</b>: Check your email for details on why
            your application was not approved.
          </>,
          <>
            <b>2. Reapply</b>: If you wish to reapply, visit{" "}
            <a href="https://wildfireprepared.org/">wildfireprepared.org</a> to
            ensure your home will qualify and click &quot;Reapply.&quot;
          </>,
        ];
      case "Closed":
        return [
          <>
            <b>1. Check for Updates</b>: Check your email for details on why
            your application was closed.
          </>,
          <>
            <b>2. Reapply</b>: If you wish to reapply, visit{" "}
            <a href="https://wildfireprepared.org/">wildfireprepared.org</a> to
            ensure your home will qualify and click &quot;Reapply.&quot;
          </>,
        ];
      default:
        return ["Unexpected current status value. Please contact support."];
    }
  };

  const getOriginApplicationActionText = (): string => {
    if (!originApplicationStatus) {
      return "Missing current status value. Please contact support.";
    }

    switch (originApplicationStatus) {
      case "Application":
      case "Application Payment":
      case "Eligibility Photos":
      case "Changes Required":
        return "Action Needed:";
      case "IBHS Eligibility Review":
        return "No action is needed at this time. Next steps:";
      case "Ready For Re-Review":
        if (isOriginApplicationInIBHSEligibilityReview) {
          return "No action is needed at this time. Next steps:";
        } else {
          return "No action is needed at this time.";
        }
      case "Evaluator Assignment":
      case "Field Evaluations":
        return "No action is needed at this time. Next steps:";
      case "IBHS Review":
        return "No action is needed at this time.";
      case "Designated":
      case "Not Designated":
      case "Closed":
        return "";
      default:
        return "Unexpected current status value. Please contact support.";
    }
  };

  const getLandscapeReviewStatusSummary = (
    status?: EvaluationStatus
  ): string[] => {
    if (!status) {
      return ["Missing current status value. Please contact support."];
    }

    // TODO: Add text for Landscape Review status summaries when received from IBHS
    switch (status) {
      case "Application":
        return ["Your application has been started, but not submitted."];
      case "Application Payment":
        return ["Your application is pending payment."];
      case "Eligibility Photos":
        return ["Your application is pending maintenance photos."];
      case "IBHS Review":
        return ["We are reviewing your eligibility photos."];
      case "Changes Required":
        return [
          "After reviewing your eligibility photos, your home needs additional required prep work. We're here to help.",
        ];
      case "Ready For Re-Review":
        return ["We are reviewing your submitted changes."];
      case "Designated":
        return ["TBD Designated Status Summary"];
      case "Not Designated":
        return ["TBD Not Designated Status Summary"];
      case "Closed":
        return ["TBD Closed Status Summary"];
      default:
        return ["Unexpected current status value. Please contact support."];
    }
  };

  const getLandscapeReviewNextStatusSummary = (
    status?: EvaluationStatus
  ): ReactNode[] => {
    if (!status) {
      return ["Missing current status value. Please contact support."];
    }

    // TODO: Add text for Landscape Review next status summaries when received from IBHS
    switch (status) {
      case "Application":
        return [
          <>
            <b>1. Preparation</b>: Complete your annual maintenance prep work as
            outlined in the{" "}
            {/* TODO: Replace with Annual Maintenance Guide link */}
            <a href="https://wildfireprepared.org/wp-content/uploads/WPH-How-To-Prepare-My-Home-Checklist.pdf">
              &quot;Annual Maintenance Guide&quot;
            </a>{" "}
            . Take wide-angle photos (&quot;eligibility photo&quot;) of every
            side of your home. At least two photos per side works well.
          </>,
          <>
            <b>2. Complete Application and Payment</b>: Fill out all required
            fields. Once complete, you&apos;ll be directed to the payment page.
            Please note, the application fee is nonrefundable, so ensure you are
            ready to continue.
          </>,
          <>
            <b>3. Photo Upload and Submission</b>: Upload your eligibility
            photos for review. Note that you must upload the photos within 60
            days to prevent your application from being closed due to
            inactivity.
          </>,
        ];
      case "Application Payment":
        return [
          <>
            <b>1. Pay Application Fee</b>: The application fee is nonrefundable,
            so ensure you are ready to continue. You will be required to upload
            eligibility photos next.
            <ul>
              <li>Click &quot;Proceed to Payment.&quot;</li>
              <li>Enter payment information and click &quot;Pay.&quot;</li>
            </ul>
          </>,
          <>
            <b>2. Photo Upload and Submission</b>: Upload your eligibility
            photos for review. Note that you must upload the photos within 60
            days to prevent your application from being closed due to
            inactivity.
          </>,
        ];
      case "Eligibility Photos":
        return [
          <>
            <b>1. Prep and Photos</b>: Take wide-angle photos (&quot;maintenance
            photos&quot;) of every side of your home showing you&apos;ve
            completed the wildfire mitigation work as outlined in the{" "}
            <a href="https://wildfireprepared.org/wp-content/uploads/WPH-How-To-Prepare-My-Home-Checklist.pdf">
              &quot;How-To Prepare My Home Checklist&quot;
            </a>
          </>,
          <>
            <b>2. Photo Upload</b>: Upload your eligibility photos for review.
            Be sure to upload the photos within 60 days to prevent your
            application from being closed due to inactivity.
          </>,
          <>
            <b>3. Submit</b>: Click &quot;Submit for Review.&quot;
          </>,
        ];
      case "IBHS Review":
        return [
          <>
            <b>1. Check for Updates</b>: Watch your email or log in here for
            updates.
          </>,
          <>
            <b>2. Approval and Evaluation</b>: Once approved, your home will be
            queued for an in-person evaluation. Note: Not every home will be
            eligible.
          </>,
          <>
            <b>3. Additional Work (If Needed)</b>: If more work is required,
            we&apos;ll guide you by providing link to log into the portal with
            feedback. You&apos;ll have 60 days to complete it and submit new
            photos.
          </>,
          <>
            <b>4. Feedback and Re-Review (If Needed)</b>: Complete the requested
            changes, capture new photos, and click &quot;Send for Review.&quot;
          </>,
        ];
      case "Changes Required":
        return [
          <>
            <b>1. View Required Changes</b>: Click &quot;See Change
            Requests&quot; to view comments for necessary remediation.
          </>,
          <>
            <b>2. Complete Work</b>: Finish the required changes within 60 days,
            then take and upload photos of the completed work.
          </>,
          <>
            <b>3. Submit for Re-Review</b>: Click &quot;Send for Review&quot; to
            complete the step. After 60 days of inactivity, your application
            will be closed.
          </>,
        ];
      case "Ready For Re-Review":
        return [
          <>
            <b>1. Check for Updates</b>: Watch your email or log in here for
            updates or additional required changes.
          </>,
          <>
            <b>2. Next step</b>: Once approved, your application will proceed to
            an in-person exterior home evaluation.
          </>,
        ];
      case "Designated":
        return [<>TBD Designated Next Status Summary</>];
      case "Not Designated":
        return [<>TBD Not Designated Next Status Summary</>];
      case "Closed":
        return [<>TBD Not Designated Next Status Summary</>];
      default:
        return ["Unexpected current status value. Please contact support."];
    }
  };

  const getLandscapeReviewActionText = (status?: EvaluationStatus): string => {
    if (!status) {
      return "Missing current status value. Please contact support.";
    }

    switch (status) {
      case "Application":
      case "Application Payment":
      case "Eligibility Photos":
      case "Changes Required":
        return "Action Needed:";
      case "IBHS Review":
      case "Ready For Re-Review":
        return "No action is needed at this time. Next steps:";
      case "Designated":
      case "Not Designated":
      case "Closed":
        return "";
      default:
        return "Unexpected current status value. Please contact support.";
    }
  };

  const getDesignationStatusCallout = (): ReactNode => {
    switch (originApplicationStatus) {
      case "Designated":
        return (
          <Callout
            className="wildfire-application-overview-card-body-callout"
            intent={Intent.SUCCESS}
            icon={null}
          >
            <ul>
              <li>
                <strong>Approval Date:</strong>{" "}
                {parseDate(wildfireEvaluation.approvedAt)}
              </li>
              {areWFPLandscapeReviewsEnabled && (
                <>
                  <li>
                    <strong>First Annual Review Due Date Date:</strong>{" "}
                    {parseDate(wildfireEvaluation.firstLandscapeReviewDueAt)}
                  </li>
                  <li>
                    <strong>Second Annual Review Due Date:</strong>{" "}
                    {parseDate(wildfireEvaluation.secondLandscapeReviewDueAt)}
                  </li>
                </>
              )}
              <li>
                <strong>Expiration Date:</strong>{" "}
                {parseDate(wildfireEvaluation.expiresAt)}
              </li>
              <li>
                <strong>Designation Level:</strong>{" "}
                {wildfireEvaluation.designationLevel}
              </li>
              {areWFPLandscapeReviewsEnabled && (
                <li>
                  <strong>Designation Status:</strong>{" "}
                  {wildfireEvaluation.isDesginationActive
                    ? "Active"
                    : "Inactive - Annual Review Past Due"}
                </li>
              )}
            </ul>
          </Callout>
        );
      case "Field Evaluations":
        const evaluationCompany = wildfireEvaluation.evaluationCompany_AsString;
        return (
          evaluationCompany === "Madronus" && (
            <Callout
              className="wildfire-application-overview-card-body-callout"
              intent={Intent.WARNING}
              icon={null}
            >
              <span>
                Your application&apos;s Field Evaluations are being processed by{" "}
                <strong>{evaluationCompany}</strong>, an IBHS cerftified
                Evaluation Company.
              </span>
              <CancelMadronusIntegrationButton
                evaluationId={wildfireEvaluation.id}
                currentApplicationFormPart={
                  wildfireEvaluation.currentApplicationFormPart
                }
              />
            </Callout>
          )
        );
    }
  };

  const getActionButtons = (): ReactNode => {
    if (
      wildfireEvaluation.isAutoRejected ||
      originApplicationStatus === "Not Designated"
    ) {
      return (
        <ResubmitEvalautionButton
          evaluationId={wildfireEvaluation.id}
          refresh={fetchWildfireApplications}
        />
      );
    }

    switch (originApplicationStatus) {
      case "Application":
      case "Application Payment":
      case "Eligibility Photos":
        return (
          <Button
            className="wildfire-application-overview-card-footer-navigation-button"
            intent={Intent.PRIMARY}
            rightIcon={IconNames.ARROW_RIGHT}
            text="Continue Application"
            onClick={() => {
              navigate(
                `${wildfireRoutePrefix}/${wildfireEvaluation.id}/application-form/${wildfireEvaluation.currentApplicationFormPart}`
              );
            }}
          />
        );

      case "Changes Required":
        return (
          <Button
            className="wildfire-application-overview-card-footer-navigation-button"
            intent={Intent.PRIMARY}
            rightIcon={IconNames.ARROW_RIGHT}
            text={"See Change Requests"}
            onClick={() => {
              navigate(
                `${wildfireRoutePrefix}/iteration-engine/application/${wildfireEvaluation.id}`
              );
            }}
          />
        );

      // TODO: Add back when ready to support DIY Field Evaluations
      // case "Field Evaluations":
      //   return (
      //     <Button
      //       className="wildfire-application-overview-card-footer-navigation-button"
      //       intent={Intent.PRIMARY}
      //       rightIcon={IconNames.ARROW_RIGHT}
      //       text="Continue Field Evaluations"
      //       onClick={() => {
      //         navigate(
      //           `${wildfireRoutePrefix}/${wildfireEvaluation.id}/${wildfireEvaluation.wildfireApplication.currentFieldEvaluationFormPartURL}`
      //         );
      //       }}
      //     />
      //   );

      case "Designated":
        return (
          <>
            {areWFPLandscapeReviewsEnabled &&
              wildfireEvaluation.canCreateNewLandscapeReview && (
                <Button
                  className="wildfire-application-overview-card-footer-navigation-button"
                  intent={Intent.PRIMARY}
                  rightIcon={IconNames.ARROW_RIGHT}
                  text="Start Annual Review"
                  loading={isCreateLandscapeReviewLoading}
                  onClick={async () => {
                    setIsCreateLandscapeReviewLoading(true);
                    await wildfireLandscapeReviewApiClient
                      .createWildfireLandscapeReview(wildfireEvaluation.id)
                      .then((evaluation) => {
                        navigate(
                          `${wildfireRoutePrefix}/${evaluation.id}/landscape-review-form`
                        );
                      })
                      .finally(() => {
                        setIsCreateLandscapeReviewLoading(false);
                      });
                  }}
                />
              )}
            {areWFPLandscapeReviewsEnabled &&
              continueLandscapeReviewEvaluationId &&
              getLandscapeReviewActionButtons()}
            <Button
              className="wildfire-application-overview-card-footer-certificate-button"
              intent={Intent.SUCCESS}
              icon={IconNames.IMPORT}
              text="Download Certificate"
              loading={isCertificateLoading}
              onClick={async () => {
                setIsCertificateLoading(true);
                await wildfireEvaluationApiClient
                  .downloadCertificate(wildfireEvaluation.id)
                  .then((response) =>
                    browserDownloadFileResponse(response, "Certificate.pdf")
                  )
                  .finally(() => setIsCertificateLoading(false));
              }}
            />
          </>
        );
    }
  };

  const getLandscapeReviewActionButtons = (): ReactNode => {
    switch (continueLandscapeReviewStatus) {
      case "Application":
      case "Application Payment":
      case "Eligibility Photos":
        return (
          <Button
            className="wildfire-application-overview-card-footer-navigation-button"
            intent={Intent.PRIMARY}
            rightIcon={IconNames.ARROW_RIGHT}
            text="Continue Annual Review"
            onClick={() => {
              navigate(
                `${wildfireRoutePrefix}/${continueLandscapeReviewEvaluationId}/landscape-review-form/${continueLandscapeReviewFormPart}`
              );
            }}
          />
        );
      case "Changes Required":
        return (
          <Button
            className="wildfire-application-overview-card-footer-navigation-button"
            intent={Intent.PRIMARY}
            rightIcon={IconNames.ARROW_RIGHT}
            text={"See Change Requests"}
            onClick={() => {
              navigate(
                `${wildfireRoutePrefix}/iteration-engine/landscape-review/${continueLandscapeReviewEvaluationId}`
              );
            }}
          />
        );
    }
  };

  const isFinalDeterminationStatus = (status?: string): boolean =>
    status === "Designated" || status === "Not Designated";

  const originApplicationHasFinalDetermination = isFinalDeterminationStatus(
    originApplicationStatus
  );
  const firstLandscapeReviewHasFinalDetermination = isFinalDeterminationStatus(
    firstLandscapeReviewStatus
  );
  const secondLandscapeReviewHasFinalDetermination = isFinalDeterminationStatus(
    secondLandscapeReviewStatus
  );

  const applicationDisplayStatuses = Object.values(
    ApplicantApplicationStatuses
  ).filter((value) => typeof value === "number") as number[];

  const landscapeReviewDisplayStatuses = Object.values(
    ApplicantLandscapeReviewStatuses
  ).filter((value) => typeof value === "number") as number[];

  const getStatusClass = (
    applicantDisplayStatus: number,
    currentApplicantStep: number,
    evaluationStatus: EvaluationStatus
  ) => {
    const statusBaseClass = "wildfire-application-overview-status-bar__item";

    let statusClass = "";

    if (
      evaluationStatus === "Closed" ||
      evaluationStatus === "Not Designated"
    ) {
      if (applicantDisplayStatus < currentApplicantStep) {
        statusClass = `${statusBaseClass}__green`;
      } else if (applicantDisplayStatus === currentApplicantStep) {
        statusClass = `${statusBaseClass}__red`;
      } else {
        statusClass = `${statusBaseClass}__default`;
      }
    } else {
      if (applicantDisplayStatus < currentApplicantStep) {
        statusClass = `${statusBaseClass}__green`;
      } else if (applicantDisplayStatus === currentApplicantStep) {
        if (evaluationStatus === "Designated") {
          statusClass = `${statusBaseClass}__green`;
        } else {
          statusClass = `${statusBaseClass}__yellow`;
        }
      } else {
        statusClass = `${statusBaseClass}__default`;
      }
    }

    if (applicantDisplayStatus === currentApplicantStep) {
      statusClass += " __current";
    }

    return statusClass;
  };

  const getStatusIcon = (
    applicantDisplayStatus: number,
    currentApplicantStep: number,
    evaluationStatus: EvaluationStatus
  ) => {
    if (
      evaluationStatus === "Closed" ||
      evaluationStatus === "Not Designated"
    ) {
      if (applicantDisplayStatus < currentApplicantStep) {
        return faCircleCheck;
      } else if (applicantDisplayStatus === currentApplicantStep) {
        return faTimesCircle;
      } else {
        return faCircle;
      }
    } else {
      if (applicantDisplayStatus < currentApplicantStep) {
        return faCircleCheck;
      } else if (applicantDisplayStatus === currentApplicantStep) {
        if (evaluationStatus === "Designated") {
          return faCircleCheck;
        } else {
          return faCircle;
        }
      } else {
        return faCircle;
      }
    }
  };

  return {
    primaryHeaderInfo: getPrimaryHeaderInfo(),
    secondaryHeaderInfo: getFormattedSecondaryAddressInfo(),

    originApplicationStatus,
    originApplicationStatusBadgeClass: getStatusBadgeClass(
      originApplicationStatus
    ),
    originApplicationStatusSummary: getOriginApplicationStatusSummary(),
    originApplicationNextStatusSummary: getOriginApplicationNextStatusSummary(),
    originApplicationActionText: getOriginApplicationActionText(),
    originApplicationCurrentApplicantStep,
    originApplicationApplicantProgressValue: getApplicantProgressValue(
      originApplicationCurrentApplicantStep,
      originApplicationStatus
    ),
    originApplicationHasFinalDetermination,
    designationStatusCallout: getDesignationStatusCallout(),

    firstLandscapeReviewStatus,
    firstLandscapeReviewStatusBadgeClass: getStatusBadgeClass(
      firstLandscapeReviewStatus
    ),
    firstLandscapeReviewStatusSummary: getLandscapeReviewStatusSummary(
      firstLandscapeReviewStatus
    ),
    firstLandscapeReviewNextStatusSummary: getLandscapeReviewNextStatusSummary(
      firstLandscapeReviewStatus
    ),
    firstLandscapeReviewActionText: getLandscapeReviewActionText(
      firstLandscapeReviewStatus
    ),
    firstLandscapeReviewCurrentApplicantStep,
    firstLandscapeReviewApplicantProgressValue: getApplicantProgressValue(
      firstLandscapeReviewCurrentApplicantStep,
      firstLandscapeReviewStatus
    ),
    firstLandscapeReviewHasFinalDetermination,

    secondLandscapeReviewStatus,
    secondLandscapeReviewStatusBadgeClass: getStatusBadgeClass(
      secondLandscapeReviewStatus
    ),
    secondLandscapeReviewStatusSummary: getLandscapeReviewStatusSummary(
      secondLandscapeReviewStatus
    ),
    secondLandscapeReviewNextStatusSummary: getLandscapeReviewNextStatusSummary(
      secondLandscapeReviewStatus
    ),
    secondLandscapeReviewActionText: getLandscapeReviewActionText(
      secondLandscapeReviewStatus
    ),
    secondLandscapeReviewCurrentApplicantStep,
    secondLandscapeReviewApplicantProgressValue: getApplicantProgressValue(
      secondLandscapeReviewCurrentApplicantStep,
      secondLandscapeReviewStatus
    ),
    secondLandscapeReviewHasFinalDetermination,

    applicationDisplayStatuses,
    landscapeReviewDisplayStatuses,
    getStatusClass,
    getStatusIcon,

    actionButtons: getActionButtons(),
  };
};

export default useWildfireApplicationOverviewCard;
