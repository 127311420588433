import { useEffect, useState } from "react";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../common/Components/Error/ErrorStore";
import { Evaluation_HomeStandardRedesignation } from "../types/HomeStandardRedesignation";
import { homeStandardRedesignationAPIClient } from "../lib/apiClients/homeStandardRedesignationAPIClient";

export const useHomeStandardRedesignation = (evaluationId: string) => {
  const [homeStandardRedesignation, setHomeStandardRedesignation] =
    useState<Evaluation_HomeStandardRedesignation | null>();
  const [isApplicationLoading, setIsApplicationLoading] = useState(false);

  useEffect(() => {
    getHomeStandardRedesignation(evaluationId);
  }, [evaluationId]);

  const getHomeStandardRedesignation = async (evaluationId: string) => {
    await homeStandardRedesignationAPIClient
      .getHomeStandardRedesignation(evaluationId)
      .then((response) => {
        setHomeStandardRedesignation(response);
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      });
  };

  const refreshHomeStandardEvaluation = async () => {
    setIsApplicationLoading(true);
    if (homeStandardRedesignation?.id) {
      await homeStandardRedesignationAPIClient
        .getHomeStandardRedesignation(homeStandardRedesignation?.id)
        .then((response) => {
          setHomeStandardRedesignation(response);
          //setWildfireEvaluationHeader(response);
        })
        .catch(() => {
          errorStore.setErrorMessage(genericErrorMessage);
        })
        .finally(() => {
          setIsApplicationLoading(false);
        });
    }
  };

  return {
    homeStandardRedesignation,
    refreshHomeStandardEvaluation,
    isApplicationLoading,
    setHomeStandardRedesignation,
  };
};
