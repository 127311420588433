import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import HomeNCIUAEligibilityFormContainer from "../components/Forms/EligibilityForm/Container/HomeNCIUAEligibilityFormContainer";
import { HomeEligibilityFormParts } from "../../common/types/BaseHomeEvaluationEligibilityFormModel";

export interface HomeNCIUAEligibilityPageProps {
  evaluationId?: string;
  formPart?: HomeEligibilityFormParts;
}

const HomeNCIUAEligibilityPageComponent: FC<HomeNCIUAEligibilityPageProps> = (
  props
) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeNCIUAEligibilityFormContainer
            evaluationId={props.evaluationId}
            formPart={props.formPart}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeNCIUAEligibilityPage = withAITracking(
  reactPlugin,
  HomeNCIUAEligibilityPageComponent,
  "HomeNCIUAEligibilityPage",
  "tracking-container"
);
