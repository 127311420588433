import { ExistingResource } from "../../../foritfied/types/evaluation/ExistingResource";

export enum CommentType {
  General = 1,
  Auditor = 2,
}

export interface CommentReadDTO {
  evaluationId?: string;
  message?: string;
  isRead?: boolean;
  canModify?: boolean;
  viewedBy?: string;
  viewedBy_AsName?: string;
  creatorRole: number;
  creatorRole_AsString?: string;
  type: CommentType;
}

export interface Comment {
  evaluationId?: string;
  message?: string;
  type: CommentType;
}

export type ExistingComment = ExistingResource<CommentReadDTO>;
