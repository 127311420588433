import { FormChecklistPart } from "../../../../../../common/types/Forms/FormChecklist";

export const findVisibleFormPart = <TFormPart extends string | number>(
  direction: "next" | "previous",
  currentFormPart: TFormPart,
  formChecklistParts: FormChecklistPart<TFormPart>[]
): TFormPart | null => {
  const currentIndex = formChecklistParts.findIndex(
    (part) => part.formPart === currentFormPart
  );

  if (currentIndex === -1) return null;

  const increment = direction === "next" ? 1 : -1;
  const endIndex = direction === "next" ? formChecklistParts.length : -1;

  for (let i = currentIndex + increment; i !== endIndex; i += increment) {
    const checklistPart = formChecklistParts[i];
    if (!checklistPart.hidden) {
      return checklistPart.formPart;
    }
  }

  return null;
};
