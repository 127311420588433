import { PrimaryCard, SecondaryCard } from "@ucl/library";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { Navigate } from "react-router-dom";
import FormChecklistProgress from "../../../../../../Common/components/Forms/FormChecklistProgress/FormChecklistProgress";
import { fortifiedHomeStandardRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { FieldSchemaFactoryProvider } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { BaseHomeEvaluationInstallingContractorConfirmationFormParts } from "../../../../../common/types/BaseHomeEvaluationInstallingContractorConfirmationFormModel";
import useHomeStandardInstallingContractorConfirmationFormContainer from "./useHomeStandardInstallingContractorConfirmationFormContainer";
import HomeStandardEvaluationInstallingContractorConfirmationForm from "../Form/HomeStandardEvaluationInstallingContractorConfirmationForm";
import GoToChecklistButton from "../../../../../common/components/Buttons/GoToChecklist/GoToIterationEngineButton";
import DownloadInstallerConfirmationFormContainer from "../../../../../common/components/Buttons/DownloadInstallerConfirmationForm/DownloadInstallerConfirmationFormContainer";

export interface HomeStandardInstallingContractorConfirmationFormContainerProps {
  evaluationId: string;
  installingContractorConfirmationFormPart?: BaseHomeEvaluationInstallingContractorConfirmationFormParts;
}

export const HomeStandardInstallingContractorConfirmationFormContainer = (
  props: HomeStandardInstallingContractorConfirmationFormContainerProps
) => {
  const {
    applicationChecklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    applicationFormPart,
    getBestFormPart,
    commercialSchemaFactory,
    evaluationFormModel,
  } = useHomeStandardInstallingContractorConfirmationFormContainer(props);

  return (
    <>
      {!applicationFormPart ||
      !Object.values(
        BaseHomeEvaluationInstallingContractorConfirmationFormParts
      ).includes(applicationFormPart) ? (
        // TODO: Show error page if the application form part is not valid
        <Navigate
          to={`${fortifiedHomeStandardRoutePrefix}/${
            props.evaluationId
          }/installing-contractor-confirmation/${getBestFormPart().valueOf()}`}
        />
      ) : !commercialSchemaFactory ? (
        <Loading />
      ) : (
        <div className="wildfire-form-page">
          <div className="wildfire-form-progress">
            <div className="wildfire-form-progress__steps">
              <FormChecklistProgress
                checklistParts={applicationChecklistParts}
                evaluationId={props.evaluationId}
                formURL={`${fortifiedHomeStandardRoutePrefix}/${props.evaluationId}/installing-contractor-confirmation`}
                title={"Installing Contractor Confirmation"}
                activeFormPart={applicationFormPart}
                getChecklistStatus={getApplicationPartChecklistStatus}
              />
            </div>
          </div>
          <section className="wildfire-form-content">
            <div className="wildfire-form-content__header"></div>
            <PrimaryCard
              className="wildfire-form-primary-card"
              headerContent={
                <div className="wildfire-form-primary-card__header">
                  Home Standard Installing Contractor Confirmation:{" "}
                  {
                    applicationChecklistParts.find(
                      (value) => value.formPart === applicationFormPart
                    )?.displayName
                  }
                  <div className="wildfire-form-primary-card__header-buttons">
                    <GoToChecklistButton
                      evaluationId={props.evaluationId}
                      productTypeRoute={fortifiedHomeStandardRoutePrefix}
                    />
                  </div>
                </div>
              }
            >
              <SecondaryCard
                className="wildfire-form-secondary-card"
                hideBody={false}
                isCollapsible={false}
              >
                <FieldSchemaFactoryProvider
                  schemaFactory={commercialSchemaFactory}
                >
                  <DownloadInstallerConfirmationFormContainer
                    evaluationId={props.evaluationId}
                    fortifiedId={evaluationFormModel?.fortifiedId || ""}
                    asBannerLink={true}
                  />
                  <HomeStandardEvaluationInstallingContractorConfirmationForm
                    key={applicationFormPart}
                    evaluationId={props.evaluationId}
                    isIterationEnginePage={false}
                    formPart={applicationFormPart}
                    disableForm={false}
                    setFormModel={setEvaluationFormModel}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                  />
                </FieldSchemaFactoryProvider>
              </SecondaryCard>
            </PrimaryCard>
          </section>
        </div>
      )}
    </>
  );
};
export default HomeStandardInstallingContractorConfirmationFormContainer;
