import {
  TextAreaFieldProps,
  FieldFormV2,
  FieldFormV2Module,
  FieldSchemaFormBuilderV2,
  Footer,
  EnumCheckboxFieldProps,
} from "@ucl/library";
import { FC, useEffect, useRef, useState } from "react";
import { Intent } from "@blueprintjs/core";
import { AppToaster } from "../../../../foritfied/components/Toast/Toast";
import { closeWildfireRejectionDialog } from "./WildfireRejectionDialog";
import { Option } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { ComplianceTypes } from "../../../types/ComplianceTypes";
import WildfireSecondaryButton from "../../Button/DialogFooterButtons/SecondaryButton/SecondaryButton";
import WildfireSubmitButton from "../../Button/DialogFooterButtons/SubmitButton/SubmitButton";
import { wildfireApplicationApiClient } from "../../../lib/apiClients/evaluation/wildfireApplicationApiClient";

export interface WildfireRejectionFormProps {
  evaluationId: string;
  wildfireApplicationId: string;
  reject: (form: WildfireRejectionFormModel) => Promise<void>;
}

export interface WildfireRejectionFormModel {
  rejectionReasons: string[];
  additionalNotes: string;
  rejectionReasonOptions: Option<string>[];
}

const WildfireRejectionFormBuilder: FieldSchemaFormBuilderV2<
  WildfireRejectionFormModel
> = (onValueChange, form, _) => {
  return [
    {
      //TODO: This needs to be converted to MultiSelectField with new Syncfusion implementation
      type: "EnumCheckbox",
      fieldName: "rejectionReasonSelections",
      fieldKey: "rejectionReasonSelections",
      label: "",
      value: form.rejectionReasons,
      optionValues: form.rejectionReasonOptions,
      onSubmit: function (value) {
        onValueChange(
          { ...form, rejectionReasons: value as string[] },
          value,
          this
        );
      },
    } as EnumCheckboxFieldProps,
    {
      type: "TextArea",
      fieldName: "additionalNotes",
      fieldKey: "additionalNotes",
      label: "Additional Notes",
      value: form.additionalNotes,
      onSubmit: function (value) {
        onValueChange(
          { ...form, additionalNotes: value as string },
          value,
          this
        );
      },
    } as TextAreaFieldProps,
  ];
};

export const WildfireRejectionForm: FC<WildfireRejectionFormProps> = ({
  evaluationId,
  wildfireApplicationId,
  reject,
}: WildfireRejectionFormProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const WildfireRejectionFormRef =
    useRef<FieldFormV2Module<WildfireRejectionFormModel>>(null);

  const [wildfireRejectionFormDefaults, setWildfireRejectionForm] =
    useState<WildfireRejectionFormModel>({
      rejectionReasons: [],
      additionalNotes: "",
      rejectionReasonOptions: [],
    });

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    setIsLoading(true);
    wildfireApplicationApiClient
      .getFormFieldDecisioning(evaluationId)
      .then((res) => {
        const nonCompliantFields = Object.keys(res)
          .filter(
            (field) =>
              res[field].complianceType == ComplianceTypes.NotCompliant ||
              res[field].complianceType == ComplianceTypes.Indeterminate
          )
          .map((nonCompliantFieldKey) => ({
            label: res[nonCompliantFieldKey].fieldLabel,
            value: nonCompliantFieldKey,
          }));

        setWildfireRejectionForm({
          ...wildfireRejectionFormDefaults,
          rejectionReasonOptions: nonCompliantFields,
        });

        setIsLoading(false);
      });
  };

  return (
    <>
      <FieldFormV2<WildfireRejectionFormModel>
        ref={WildfireRejectionFormRef}
        value={wildfireRejectionFormDefaults}
        fieldSchemaFormBuilder={WildfireRejectionFormBuilder}
        onFormSubmit={async (form) => {
          await reject(form)
            .then(async () => {
              AppToaster.show({
                message: "Evaluation not designated.",
                intent: Intent.SUCCESS,
              });
            })
            .catch((error) => {
              AppToaster.show({
                message: "Unexpected error occured while rejecting evaluation.",
                intent: Intent.DANGER,
              });
              console.error(error);
            });
        }}
      />
      <Footer>
        <WildfireSecondaryButton
          buttonText="Cancel"
          onClick={() => {
            closeWildfireRejectionDialog(wildfireApplicationId);
          }}
        />
        <WildfireSubmitButton
          buttonText="Reject"
          isLoading={isLoading}
          onClick={() => {
            closeWildfireRejectionDialog(wildfireApplicationId);
            WildfireRejectionFormRef.current?.submit();
          }}
        />
      </Footer>
    </>
  );
};
