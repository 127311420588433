import { GridColumn } from "@ucl/library";
import { UserRoleType } from "../../../../../common/Components/User/userTypes";

const wildfireCommentTemplateGridColumnsConfiguration: GridColumn[] = [
  new GridColumn({
    field: "productType",
    headerText: "Product Type",
    type: "string",
  }),
  new GridColumn({
    field: "formType",
    headerText: "Form",
    type: "string",
  }),
  new GridColumn({
    field: "templateName",
    headerText: "Template Name",
    type: "string",
  }),
  new GridColumn({
    field: "templateContent",
    headerText: "Content",
    type: "string",
    clipMode: "EllipsisWithTooltip",
    width: 300,
  }),
  new GridColumn({
    field: "modifiedAt_AsDateOnly",
    headerText: "Updated Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
  }),
  new GridColumn({
    field: "modifiedBy_AsName",
    headerText: "Updated By",
    type: "string",
  }),
  new GridColumn({
    field: "createdAt_AsDateOnly",
    headerText: "Created Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
  }),
  new GridColumn({
    field: "createdBy_AsName",
    headerText: "Created By",
    type: "string",
  }),
];

const wildfireCommentTemplateGridRoleColumnsConfigurationDefaultFields = [
  "productType",
  "formType",
  "templateName",
  "templateContent",
  "modifiedAt_AsDateOnly",
  "modifiedBy_AsName",
  "createdAt_AsDateOnly",
  "createdBy_AsName",
];

export const wildfireCommentTemplateGridRoleColumnsConfiguration: Record<
  "Default",
  GridColumn[] | undefined
> = {
  Default: [
    ...wildfireCommentTemplateGridColumnsConfiguration
      .filter((x) =>
        wildfireCommentTemplateGridRoleColumnsConfigurationDefaultFields.includes(
          x.field
        )
      )
      .sort(function (a, b) {
        return (
          wildfireCommentTemplateGridRoleColumnsConfigurationDefaultFields.indexOf(
            a.field
          ) -
          wildfireCommentTemplateGridRoleColumnsConfigurationDefaultFields.indexOf(
            b.field
          )
        );
      }),
  ],
};

export const getWildfireCommentTemplateGridColumnsConfigurationByRole = (
  _role?: UserRoleType
) => {
  return wildfireCommentTemplateGridRoleColumnsConfiguration.Default as GridColumn[];
};
