import React from "react";
import { Button, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { permissionStore } from "../../../../../../foritfied/stores/PermissionStore";
import { dialogsViewerStore } from "../../../../../../foritfied/stores/DialogsViewerStore";

interface AdminPanelButtonProps {
  evaluationId: string;
}

const AdminPanelButton: React.FC<AdminPanelButtonProps> = (props) => {
  return (
    <>
      {permissionStore.canViewAdminButton && (
        <Button
          minimal
          large
          onClick={() => {
            dialogsViewerStore.setIsAdminDialogOpen(true, {
              evaluationId: props.evaluationId,
            });
          }}
        >
          <Icon icon={IconNames.COG} size={16} />
        </Button>
      )}
    </>
  );
};

export default AdminPanelButton;
