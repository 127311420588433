import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { get } from "lodash";
import { Evaluation_HomeNCIUARedesignationFormModel } from "../../../evaluation/types/HomeNCIUARedesignationFormModel_Model";
import { YesOrNoSelection } from "../../../../../../wildfire/types/FormFields";
import { RedesignationUpdatedSystemTypes } from "../../../../common/types/BaseHomeEvaluationRedesignationFormModel";
import { permissionStore } from "../../../../../../foritfied/stores/PermissionStore";

const modelName = "Evaluation";
const subModelName = "HomeNCIUARedesignation";
const formName = "RedesignationForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

const redesignationFormFields = (
  form: Evaluation_HomeNCIUARedesignationFormModel,
  errors?: { [key: string]: string[] }
) => [
  buildFieldKey("DesignationLevel"),
  buildFieldKey("HasTheRoofCoverBeenReplacedInLast5Years"),
  {
    fieldKey: buildFieldKey("HasAnyPortionOfRoofBeenRepairedInLast5Years"),
    hidden:
      form.homeNCIUARedesignation.redesignationForm
        .hasTheRoofCoverBeenReplacedInLast5Years !== YesOrNoSelection.Yes,
  },
  {
    fieldKey: buildFieldKey("WereAnyStructuralMembersRepaired"),
    hidden:
      form.homeNCIUARedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years !== YesOrNoSelection.Yes,
  },
  {
    fieldKey: buildFieldKey("WereAnyRoofSheathingPanelsReplaced"),
    hidden:
      form.homeNCIUARedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years !== YesOrNoSelection.Yes,
  },
  {
    fieldKey: buildFieldKey("WasAnyPortionOfSealedRoofDeckRepaired"),
    hidden:
      form.homeNCIUARedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years !== YesOrNoSelection.Yes,
  },
  {
    fieldKey: buildFieldKey("WasAnyPortionOfDripEdgeReplaced"),
    hidden:
      form.homeNCIUARedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years !== YesOrNoSelection.Yes,
  },
  {
    fieldKey: buildFieldKey("WasAnyPortionOfRoofCoverReplaced"),
    hidden:
      form.homeNCIUARedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years !== YesOrNoSelection.Yes,
  },
  {
    fieldKey: buildFieldKey("DoesNewRoofCoverMeetHailImpactRequirements"),
    hidden:
      form.homeNCIUARedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years !== YesOrNoSelection.Yes,
  },
  buildFieldKey("HaveThereBeenAnyStructuralAdditionsToHomeInLast5Years"),
  {
    fieldKey: buildFieldKey("DoAnyNewAdditionsQualifyAsAttachedStructure"),
    hidden:
      form.homeNCIUARedesignation.redesignationForm
        .haveThereBeenAnyStructuralAdditionsToHomeInLast5Years !==
      YesOrNoSelection.Yes,
  },
  buildFieldKey("RedesignationUpdatedSystemTypes"),
  {
    fieldKey: buildFieldKey("IsCorrosionRustVisible"),
    hidden:
      !form.homeNCIUARedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.NoneOfTheAbove
      ),
  },
  {
    fieldKey: buildFieldKey("OverallRedesignationElevationPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "overallRedesignationElevationPhotos.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("RoofStructuralMemberRepairPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "roofStructuralMemberRepairPhoto.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      form.homeNCIUARedesignation.redesignationForm
        .wereAnyStructuralMembersRepaired !== YesOrNoSelection.Yes,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "SignedAndSealedRepairDesignFromProfessionalEngineer"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "signedAndSealedRepairDesignFromProfessionalEngineer.FileCount"
    ),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      form.homeNCIUARedesignation.redesignationForm
        .wereAnyStructuralMembersRepaired !== YesOrNoSelection.Yes,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ReplacedRoofSheathingTypeThicknessPhoto"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "replacedRoofSheathingTypeThicknessPhoto.FileCount"
    ),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      form.homeNCIUARedesignation.redesignationForm
        .wereAnyRoofSheathingPanelsReplaced !== YesOrNoSelection.Yes,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ReplacedRoofSheathingNailsPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "replacedRoofSheathingNailsPhoto.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      form.homeNCIUARedesignation.redesignationForm
        .wereAnyRoofSheathingPanelsReplaced !== YesOrNoSelection.Yes,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ReplacedRoofSheathingNailSpacingPhotos"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "replacedRoofSheathingNailSpacingPhotos.FileCount"
    ),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      form.homeNCIUARedesignation.redesignationForm
        .wereAnyRoofSheathingPanelsReplaced !== YesOrNoSelection.Yes,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("RepairedSealedRoofDeckPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "repairedSealedRoofDeckPhotos.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      form.homeNCIUARedesignation.redesignationForm
        .wasAnyPortionOfSealedRoofDeckRepaired !== YesOrNoSelection.Yes,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ReplacedUnderlaymentLabelPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "replacedUnderlaymentLabelPhoto.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      form.homeNCIUARedesignation.redesignationForm
        .wasAnyPortionOfSealedRoofDeckRepaired !== YesOrNoSelection.Yes,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("CertifiedDesignPressureReportForMetalPanelSystem"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "certifiedDesignPressureReportForMetalPanelSystem.FileCount"
    ),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      form.homeNCIUARedesignation.redesignationForm
        .wasAnyPortionOfSealedRoofDeckRepaired !== YesOrNoSelection.Yes,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ReplacedDripEdgePhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "replacedDripEdgePhoto.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      form.homeNCIUARedesignation.redesignationForm
        .wasAnyPortionOfDripEdgeReplaced !== YesOrNoSelection.Yes,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ReplacedRoofCoverOverallSectionsPhoto"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "replacedRoofCoverOverallSectionsPhoto.FileCount"
    ),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      form.homeNCIUARedesignation.redesignationForm
        .wasAnyPortionOfRoofCoverReplaced !== YesOrNoSelection.Yes,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ReplacedRoofCoverInstallationPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "replacedRoofCoverInstallationPhotos.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      form.homeNCIUARedesignation.redesignationForm
        .wasAnyPortionOfRoofCoverReplaced !== YesOrNoSelection.Yes,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ReplacedRoofCoverProductLabelPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "replacedRoofCoverProductLabelPhoto.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      form.homeNCIUARedesignation.redesignationForm
        .wasAnyPortionOfRoofCoverReplaced !== YesOrNoSelection.Yes,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ReplacedRoofCoverHailImpactRatingPhoto"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "replacedRoofCoverHailImpactRatingPhoto.FileCount"
    ),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      form.homeNCIUARedesignation.redesignationForm
        .doesNewRoofCoverMeetHailImpactRequirements !== YesOrNoSelection.Yes,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("StructuralAdditionsRenovationsPhotos"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "structuralAdditionsRenovationsPhotos.FileCount"
    ),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      form.homeNCIUARedesignation.redesignationForm
        .haveThereBeenAnyStructuralAdditionsToHomeInLast5Years !==
      YesOrNoSelection.Yes,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("StructuralAdditionsRenovationsDocumentation"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "structuralAdditionsRenovationsDocumentation.FileCount"
    ),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      form.homeNCIUARedesignation.redesignationForm
        .haveThereBeenAnyStructuralAdditionsToHomeInLast5Years !==
      YesOrNoSelection.Yes,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedRoofMountedVentsOrSoffitsPhoto"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "modifiedRoofMountedVentsOrSoffitsPhoto.FileCount"
    ),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeNCIUARedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.RoofMountedVentsOrSoffits
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedRoofMountedVentsOrSoffitsDocumentation"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "modifiedRoofMountedVentsOrSoffitsDocumentation.FileCount"
    ),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeNCIUARedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.RoofMountedVentsOrSoffits
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedRoofMountedPhotovoltaicPVSystemPhoto"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "modifiedRoofMountedPhotovoltaicPVSystemPhoto.FileCount"
    ),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "ModifiedRoofMountedPhotovoltaicPVSystemDocumentation"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "modifiedRoofMountedPhotovoltaicPVSystemDocumentation.FileCount"
    ),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeNCIUARedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.RoofMountedPhotovoltaicSystem
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedSkylightsPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "modifiedSkylightsPhotos.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeNCIUARedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.Skylights
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedSkylightsDocumentation"),
    defaultToUpload: false,
    errorMessages: get(errors, "modifiedSkylightsDocumentation.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeNCIUARedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.Skylights
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedEntryDoorsPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "modifiedEntryDoorsPhotos.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeNCIUARedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.EntryDoors
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedEntryDoorsDocumentation"),
    defaultToUpload: false,
    errorMessages: get(errors, "modifiedEntryDoorsDocumentation.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeNCIUARedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.EntryDoors
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedWindowsPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "modifiedWindowsPhotos.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeNCIUARedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.Windows
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedWindowsDocumentation"),
    defaultToUpload: false,
    errorMessages: get(errors, "modifiedWindowsDocumentation.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeNCIUARedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.Windows
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedGarageDoorsPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "modifiedGarageDoorsPhotos.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeNCIUARedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.GarageDoors
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedGarageDoorsDocumentation"),
    defaultToUpload: false,
    errorMessages: get(errors, "modifiedGarageDoorsDocumentation.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeNCIUARedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.GarageDoors
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedWindowAndDoorShutterSystemsPhotos"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "modifiedWindowAndDoorShutterSystemsPhotos.FileCount"
    ),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeNCIUARedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.WindowAndDoorShutterSystems
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedWindowAndDoorShutterSystemsDocumentation"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "modifiedWindowAndDoorShutterSystemsDocumentation.FileCount"
    ),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeNCIUARedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.WindowAndDoorShutterSystems
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("VisibleCorrosionPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "visibleCorrosionPhotos.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      form.homeNCIUARedesignation.redesignationForm.isCorrosionRustVisible !==
      YesOrNoSelection.Yes,
  } as WildfireBoxContentExplorerProps,
];

const redesignationFormRequiredFields = [
  buildFieldKey("DesignationLevel"),
  buildFieldKey("HasTheRoofCoverBeenReplacedInLast5Years"),
  buildFieldKey("HasAnyPortionOfRoofBeenRepairedInLast5Years"),
  buildFieldKey("WereAnyStructuralMembersRepaired"),
  buildFieldKey("WereAnyRoofSheathingPanelsReplaced"),
  buildFieldKey("WasAnyPortionOfSealedRoofDeckRepaired"),
  buildFieldKey("WasAnyPortionOfDripEdgeReplaced"),
  buildFieldKey("WasAnyPortionOfRoofCoverReplaced"),
  buildFieldKey("DoesNewRoofCoverMeetHailImpactRequirements"),
  buildFieldKey("HaveThereBeenAnyStructuralAdditionsToHomeInLast5Years"),
  buildFieldKey("DoAnyNewAdditionsQualifyAsAttachedStructure"),
  buildFieldKey("RedesignationUpdatedSystemTypes"),
  buildFieldKey("IsCorrosionRustVisible"),
];

export const redesignationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUARedesignationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUARedesignationFormModel>(
        modelName,
        redesignationFormFields(form, errors),
        onValueChange,
        form,
        errors,
        redesignationFormRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUARedesignationFormModel>;
};

// Full form builder with all parts included
export const homeNCIUARedesignationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUARedesignationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUARedesignationFormModel>(
        modelName,
        redesignationFormFields(form),
        onValueChange,
        form,
        errors,
        redesignationFormRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUARedesignationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeNCIUARedesignationFormIterationEngineBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUARedesignationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUARedesignationFormModel>(
        modelName,
        redesignationFormFields(form, errors),
        onValueChange,
        form,
        errors,
        redesignationFormRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUARedesignationFormModel>;
};
