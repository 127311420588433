import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts } from "../../common/types/BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormModel";
import HomeStandardInstallerComplianceContinuousLoadPathFormContainer from "../components/Forms/InstallerComplianceContinuousLoadPathForm/Container/HomeStandardInstallerComplianceContinuousLoadPathFormContainer";

export interface HomeStandardInstallerComplianceContinuousLoadPathPageProps {
  evaluationId?: string;
  installerComplianceContinuousLoadPathFormPart?: BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts;
}

const HomeStandardInstallerComplianceContinuousLoadPathPageComponent: FC<
  HomeStandardInstallerComplianceContinuousLoadPathPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeStandardInstallerComplianceContinuousLoadPathFormContainer
            evaluationId={props.evaluationId}
            formParts={props.installerComplianceContinuousLoadPathFormPart}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeStandardInstallerComplianceContinuousLoadPathPage =
  withAITracking(
    reactPlugin,
    HomeStandardInstallerComplianceContinuousLoadPathPageComponent,
    "HomeStandardInstallerComplianceContinuousLoadPathPage",
    "tracking-container"
  );
