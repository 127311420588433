import {
  AsyncAutoCompleteFieldProps,
  EnumRadioFieldProps,
  FieldFormV2,
  FieldFormV2Module,
  FieldSchemaFormBuilderV2,
  Footer,
} from "@ucl/library";
import { FC, useRef, useState } from "react";
import { EnumRadioFieldValue } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { useNavigate } from "react-router";
import "./styles.scss";
import WildfireSecondaryButton from "../../../../../../../../wildfire/components/Button/DialogFooterButtons/SecondaryButton/SecondaryButton";
import WildfireSubmitButton from "../../../../../../../../wildfire/components/Button/DialogFooterButtons/SubmitButton/SubmitButton";
import { closeCreateHomeEvaluationDialog } from "./CreateHomeEvaluationDialog";
import { homeStandardEvaluationAPIClient } from "../../../../../../standard/lib/apiClients/homeStandardEvaluationAPIClient";
import { homeSCSHEvaluationAPIClient } from "../../../../../../scsh/lib/apiClients/homeSCSHEvaluationAPIClient";
import { homeNCIUAEvaluationAPIClient } from "../../../../../../nciua/evaluation/lib/apiClients/homeNCIUAEvaluationAPIClient";
import { getAuthRequestHeader } from "../../../../../../../../common/lib/msal/fortifiedHomeMsal";
import variables from "../../../../../../../../common/config/variables";
import { Predicate, Query } from "@syncfusion/ej2-data";
import { HomeEvaluationOptions } from "../../../../../../../../foritfied/types/schema";
import { homeStandardRedesignationAPIClient } from "../../../../../../standardRedesignation/lib/apiClients/homeStandardRedesignationAPIClient";
import { fortifiedUserStore } from "../../../../../../../../foritfied/stores/FortifiedUserStore";
import HomeEvaluationFieldInfoContainer from "../../../../../../../../foritfied/components/Dialogs/CreateEvaluation/Home/HomeEvaluationFieldInfoContainer";
import EvaluationHistory from "../../../../../../../../foritfied/components/Evaluation/History/EvaluationHistory";
import { NCIUAProjectTypes } from "../../../../../types/SpecialProjects/SpecialProjectTypes";
import { homeNCIUARedesignationAPIClient } from "../../../../../../nciuaRedesignation/evaluation/lib/apiClients/homeNCIUARedesignationAPIClient";

export interface CreateHomeEvaluationFormModel {
  selectedEvaluationType?: EnumRadioFieldValue;
  redesignationOption?: HomeEvaluationOptions;
  redesignationId?: string;
}

export const CreateHomeEvaluationForm: FC = () => {
  const createFCFMFEvaluationFormRef =
    useRef<FieldFormV2Module<CreateHomeEvaluationFormModel>>(null);

  const [homeEvaluationFormModel, setHomeEvaluationFormModel] =
    useState<CreateHomeEvaluationFormModel>();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const createHomeEvaluation = async (form: CreateHomeEvaluationFormModel) => {
    switch (form.selectedEvaluationType) {
      case 1:
        setIsLoading(true);
        await homeStandardEvaluationAPIClient
          .createHomeStandardEvaluation()
          .then((homeStandardEvaluation) => {
            closeCreateHomeEvaluationDialog();
            navigate(
              `/fh/standard/${homeStandardEvaluation.id}/basic-information/homeowner-information`
            );
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });

        break;
      case 2:
        setIsLoading(true);

        if (
          form.redesignationOption?.specialProjectName &&
          NCIUAProjectTypes.includes(
            form.redesignationOption.specialProjectName
          )
        ) {
          await homeNCIUARedesignationAPIClient
            .createHomeNCIUARedesignation(form.redesignationId || "")
            .then((homeStandardRedesignation) => {
              closeCreateHomeEvaluationDialog();
              navigate(
                `/fh/nciua-redesignation/checklist/${homeStandardRedesignation.id}`
              );
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          await homeStandardRedesignationAPIClient
            .createHomeStandardRedesignation(form.redesignationId || "")
            .then((homeStandardRedesignation) => {
              closeCreateHomeEvaluationDialog();
              navigate(
                `/fh/standard-redesignation/checklist/${homeStandardRedesignation.id}`
              );
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }

        break;
      case 3:
        setIsLoading(true);
        await homeSCSHEvaluationAPIClient
          .createHomeSCSHEvaluation()
          .then((homeSCSHEvaluation) => {
            closeCreateHomeEvaluationDialog();
            navigate(
              `/fh/scsh/${homeSCSHEvaluation.id}/basic-information/homeowner-information`
            );
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });

        break;
      case 4:
        setIsLoading(true);
        await homeNCIUAEvaluationAPIClient
          .createHomeNCIUAEvaluation()
          .then((homeNCIUAEvaluation) => {
            closeCreateHomeEvaluationDialog();
            navigate(
              `/fh/nciua/${homeNCIUAEvaluation.id}/basic-information/homeowner-information`
            );
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });

        break;
      default:
        break;
    }
  };

  const optionValues = [
    {
      label: "New Evaluation",
      value: 1,
    },
    {
      label: "Redesignation",
      value: 2,
    },
    //TODO: Add These for Relativity Admins to test Special Workflows
    // {
    //   label: "SCSH",
    //   value: 3,
    // },
    // {
    //   label: "NCIUA",
    //   value: 4,
    // },
  ];

  const isRelativityAdmin = fortifiedUserStore.IsRelativityAdmin;

  const filteredOptionValues = optionValues.filter(
    (option) => isRelativityAdmin || (option.value !== 3 && option.value !== 4)
  );

  const createFcFMFFormBuilder: FieldSchemaFormBuilderV2<
    CreateHomeEvaluationFormModel
  > = (onValueChange, form, _) => {
    return [
      {
        type: "EnumRadio",
        fieldName: "updateHomeowner",
        fieldKey: "updateHomeowner",
        label: "Choose an Evaluation Type",
        value: form.selectedEvaluationType,
        optionValues: filteredOptionValues,
        onSubmit: function (value) {
          onValueChange(
            {
              ...form,
              selectedEvaluationType: value as number,
              redesignationId: undefined,
              redesignationOption: undefined,
            },
            value,
            this
          );
        },
      } as EnumRadioFieldProps,
      //TODO: This needs to be refactored potentially
      {
        type: "AsyncAutoComplete",
        fieldName: "redesignationId",
        fieldKey: "redesignationId",
        hidden: form.selectedEvaluationType != 2,
        label: "Lookup Evaluation by FID",
        placeholder: "Type to search Evaluations...",
        noResultsLabel: "No Evaluations found",
        apiBaseUrl: variables.apiBaseUrl,
        odataUrl: `${variables.apiBaseUrl}odata/evaluationodata/options`,
        getAuthRequestHeader,
        fieldNames: [
          "id",
          "fortifiedId",
          "isApproved",
          "isRedesignation",
          "isActive",
        ],
        autoCompleteSettings: {
          fields: { text: "fortifiedId", value: "id" },
          itemTemplate: "<table><tr><td>${fortifiedId}</td></tr><table>",
          query: new Query().select(["id", "fortifiedId"]).take(20),
        },
        sortBy: "fortifiedId",
        searchBy: (searchText: string) =>
          new Predicate(
            // Build our own fullName since it's a computed property and can't be queried directly
            "fortifiedId",
            "contains",
            searchText,
            true
          ),

        value: form.redesignationId,
        onSubmit: function (value) {
          const evaluation = value as unknown as HomeEvaluationOptions;
          onValueChange(
            {
              ...form,
              redesignationId: evaluation?.id,
              redesignationOption: evaluation,
            },
            value,
            this
          );
        },
      } as AsyncAutoCompleteFieldProps,
    ];
  };

  return (
    <div className="create-home-evalaution-dialog">
      <FieldFormV2<CreateHomeEvaluationFormModel>
        ref={createFCFMFEvaluationFormRef}
        value={homeEvaluationFormModel}
        isDisabled={isLoading}
        fieldSchemaFormBuilder={createFcFMFFormBuilder}
        onFormSubmit={async (form) => {
          await createHomeEvaluation(form);
        }}
        onFieldChanged={(form) => {
          setHomeEvaluationFormModel(form);
        }}
      />
      <div>You will be starting a new FORTIFIED Application, continue?</div>
      <br />

      {homeEvaluationFormModel?.redesignationOption && (
        <>
          <div className="create-home-evaluation-info-container">
            <HomeEvaluationFieldInfoContainer
              selectedOption={homeEvaluationFormModel?.redesignationOption}
              selectedChildOption={undefined}
            />
          </div>
          <EvaluationHistory
            evaluationId={homeEvaluationFormModel?.redesignationOption.id}
          />
        </>
      )}

      <Footer>
        <WildfireSecondaryButton
          //className="wildfire-admin-update-dialog-cancel-button"
          buttonText="Cancel"
          onClick={() => closeCreateHomeEvaluationDialog()}
          disabled={isLoading}
        />
        <WildfireSubmitButton
          //className="wildfire-admin-update-dialog-submit-button"
          buttonText="Submit"
          onClick={() => createFCFMFEvaluationFormRef.current?.submit()}
          isLoading={isLoading}
        />
      </Footer>
    </div>
  );
};
