import {
  AddressFieldValue,
  EnumRadioFieldValue,
  TextFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorerValue } from "../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

export enum BaseHomeEvaluationRooferConfirmationFormParts {
  RoofingConfirmation = "roofing-confirmation",
}

export interface BaseHomeEvaluationRooferConfirmationFormModel
  extends IRooferConfirmation {}

export interface IRooferConfirmation {
  isCertifiedRoofingContractorAndComplianceAccurate?: EnumRadioFieldValue;
  showWarning_IsCertifiedRoofingContractorAndComplianceAccurate?: boolean;
  understandsFraudulentInformationPenalties?: EnumRadioFieldValue;
  showWarning_UnderstandsFraudulentInformationPenalties?: boolean;
  printedName?: TextFieldValue;
  companyName?: TextFieldValue;
  phoneNumber?: TextFieldValue;
  address?: AddressFieldValue;
  licenseOrRegistrationNumber?: TextFieldValue;
  fortifiedWiseRoofingConactorID?: TextFieldValue;
  signedRoofingComplianceForms?: WildfireBoxContentExplorerValue;
}
