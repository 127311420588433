import { useEffect, useState } from "react";
import { WildfireEvaluationOverview } from "../types/WildfireApplication/WildfireApplication";
import { wildfireEvaluationApiClient } from "../lib/apiClients/evaluation/wildfireEvaluationApiClient";
import {
  errorStore,
  genericErrorMessage,
} from "../../common/Components/Error/ErrorStore";

export const useWildfireApplications = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [wildfireApplications, setWildfireApplications] =
    useState<WildfireEvaluationOverview[]>();

  const fetchWildfireApplications = async () => {
    await wildfireEvaluationApiClient
      .GetWildfireEvaluationsForApplicantCards()
      .then((response) => {
        setWildfireApplications(response);
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchWildfireApplications();
  }, []);

  return {
    isLoading,
    wildfireApplications,
    fetchWildfireApplications,
  };
};
