import { useNavigate } from "react-router";
import { EvaluationFormModel } from "../../../forms/EvaluationFormModel";
import { LandscapeReviewFormParts } from "../../../forms/LandscapeReviewForm/LandscapeReviewFormModel";
import useLandscapeReviewForm from "./useLandscapeReviewForm";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { Button, NonIdealState } from "@blueprintjs/core";
import {
  FieldFormV2,
  FieldSchemaFormBuilderV2,
  RelativityTooltip,
} from "@ucl/library";
import { IconNames } from "@blueprintjs/icons";
import "../styles.scss";
import { wildfireRoutePrefix } from "../../../pages/index/wildfireRouteConfig";
import classnames from "classnames";
import { FormChecklistPart } from "../../../../common/types/Forms/FormChecklist";

export interface LandscapeReviewFormProps {
  wildfireEvaluationId: string;
  isWiePage?: boolean;
  formPart?: LandscapeReviewFormParts;
  hideFormControls?: boolean;
  onFormSave?: (fieldKey: string, value: any) => Promise<void>;
  setFormModel?: (formModel: EvaluationFormModel) => void;
  disableForm?: boolean;
  onInitialized?: () => void;
  setHasUnsavedChanges: (value: boolean) => void;
  checklistParts?: FormChecklistPart<LandscapeReviewFormParts>[];
}

const LandscapeReviewForm = (props: LandscapeReviewFormProps) => {
  const navigate = useNavigate();

  const {
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    landscapeReviewFormModel,
    setShouldSubmit,
    isFirstPartOfForm,
    isLastPartOfForm,
    getLandscapeReviewFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    noBuilders,
    isSubmitDisabled,
    toolTipText,
    containerRef,
    buttonText,
  } = useLandscapeReviewForm(props);

  return (
    <div ref={containerRef} className="wildfire-form-container">
      {noBuilders || isLoading ? (
        <Loading />
      ) : !landscapeReviewFormModel ? (
        <NonIdealState />
      ) : (
        <>
          <FieldFormV2<EvaluationFormModel>
            isDisabled={isSubmitting || props.disableForm}
            value={landscapeReviewFormModel}
            fieldSchemaFormBuilder={
              getLandscapeReviewFormPartBuilder() as FieldSchemaFormBuilderV2<EvaluationFormModel>
            }
            onFormSubmit={async (form) => {
              await handleFormSubmit(form);
            }}
            ref={formRef}
            onFieldChanged={async (form, value, fieldProps) => {
              await onFormFieldChange(form, value, fieldProps);
            }}
          />

          {!props.hideFormControls && (
            <div className="wildfire-form-footer">
              {!isFirstPartOfForm && (
                <Button
                  className={
                    "wildfire-form-back-button " +
                    "wildfire-form-navigation-button"
                  }
                  text="Back"
                  icon={IconNames.ARROW_LEFT}
                  onClick={() => {
                    // Navigate to the previous visible form part
                    const currentFormPartIndex = Object.values(
                      LandscapeReviewFormParts
                    ).indexOf(props.formPart!);

                    // Find the previous visible form part
                    let previousFormPart = null;
                    for (let i = currentFormPartIndex - 1; i >= 0; i--) {
                      const formPart = Object.values(LandscapeReviewFormParts)[
                        i
                      ];

                      // Check if formChecklistParts exists and if the form part is hidden
                      const checklistPart = props.checklistParts?.find(
                        (part) => part.formPart === formPart
                      );

                      // If formChecklistParts doesn't exist, assume the part is not hidden/disabled
                      if (
                        !checklistPart ||
                        (!checklistPart.hidden && !checklistPart.disabled)
                      ) {
                        previousFormPart = formPart;
                        break;
                      }
                    }

                    if (previousFormPart) {
                      navigate(
                        `${wildfireRoutePrefix}/${props.wildfireEvaluationId}/landscape-review-form/${previousFormPart}`
                      );
                    }
                  }}
                />
              )}
              <RelativityTooltip
                shouldShowTooltip={isSubmitDisabled}
                content={toolTipText}
              >
                <Button
                  className={classnames({
                    "wildfire-form-submit-button":
                      isLastPartOfForm && !isSubmitDisabled,
                    "wildfire-form-wide-button": isLastPartOfForm,
                    "wildfire-form-continue-button": !isLastPartOfForm,
                    "wildfire-form-navigation-button":
                      !isLastPartOfForm && !isSubmitDisabled,
                  })}
                  text={buttonText}
                  rightIcon={
                    isLastPartOfForm ? IconNames.TICK : IconNames.ARROW_RIGHT
                  }
                  disabled={isSubmitDisabled}
                  loading={isSubmitting}
                  onClick={() => {
                    if (formRef.current) {
                      props.setHasUnsavedChanges(false);
                      setShouldSubmit(true);
                      setIsSubmitting(true);
                      formRef.current.submit();
                    }
                  }}
                />
              </RelativityTooltip>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LandscapeReviewForm;
