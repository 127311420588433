import { dialogStore } from "@ucl/library";
import { DialogProps } from "@ucl/library/lib/components/Dialogs/Dialog/Dialog";
import { PaymentCreditForm } from "./PaymentCreditForm";
import { PaymentCreditResponseReadDTO } from "../../../types/PaymentCredit/PaymentCreditResponseReadDTO";
import { EvaluationStep } from "../../../../../foritfied/types/evaluation/Evaluation";
import { ProductSubtypes } from "../../../types/Evaluation/BaseEvaluation";

const dialogIdBase = "payment-credit-dialog-v2";

const getDialogId = (): string => `${dialogIdBase}`;

const buildPaymentCreditDialogV2 = (
  paymentCredit: PaymentCreditResponseReadDTO,
  fortifiedId: string,
  productSubtype: ProductSubtypes,
  evaluationStep: EvaluationStep,
  setEvaluation: (evaluation: any) => void,
  evaluationId: string
): DialogProps => {
  return {
    id: getDialogId(),
    className: dialogIdBase,
    header: "Select Payment Method",
    allowDragging: false,
    content: () => (
      <div>
        <PaymentCreditForm
          paymentCredit={paymentCredit}
          fortifiedId={fortifiedId}
          productSubtype={productSubtype}
          step={evaluationStep}
          setEvaluation={setEvaluation}
          evaluationId={evaluationId}
        />
      </div>
    ),
  };
};
export const openPaymentCreditDialogV2 = (
  paymentCredit: PaymentCreditResponseReadDTO,
  fortifiedId: string,
  productSubtype: ProductSubtypes,
  evaluationStep: EvaluationStep,
  setEvaluation: (evaluation: any) => void,
  evaluationId: string
) =>
  dialogStore.openDialog(
    buildPaymentCreditDialogV2(
      paymentCredit,
      fortifiedId,
      productSubtype,
      evaluationStep,
      setEvaluation,
      evaluationId
    )
  );

export const closePaymentCreditDialogV2 = () =>
  dialogStore.closeDialog(getDialogId());
