import "./applicant-styles.scss";
import { ChangeRequestV2 } from "../types/ChangeRequestV2";
import { ChangeRequestStatus } from "../../../../foritfied/types/evaluation/ChangeRequest";
import { SecondaryButton } from "@ucl/library";
import { dialogStore } from "@ucl/library";
import { DialogProps } from "@ucl/library/lib/components/Dialogs/Dialog/Dialog";
import WildfireLandscapeReviewApplicantIterationEngineChangeRequestDetails from "./WildfireLandscapeReviewApplicantIterationEngineChangeRequestDetails";
import { WildfireSchemaFactoryV2 } from "../../../customHooks/useFieldSchemaFactory";

interface WildfireLandscapeReviewApplicantIterationEngineChangeRequestsProps {
  evaluationId: string;
  changeRequests: ChangeRequestV2[];
  wildfireSchemaFactory: WildfireSchemaFactoryV2;
  onChangeRequestCompleted: () => void;
}

const WildfireLandscapeReviewApplicantIterationEngineChangeRequests: React.FC<
  WildfireLandscapeReviewApplicantIterationEngineChangeRequestsProps
> = (props) => {
  const unresolvedChangeRequests = props.changeRequests.filter(
    (cr) => cr.status !== ChangeRequestStatus.Resolved
  );

  return (
    <div className="wildfire-applicant-iteration-engine-change-requests">
      {props.changeRequests.length === 0 && (
        <section className="app-wie-change-requests app-wie-change-requests-no-requests">
          <div>
            <span>No Change Requests</span>
          </div>
        </section>
      )}
      {unresolvedChangeRequests.length > 0 && (
        <section className="app-wie-change-requests app-wie-change-requests-unresolved">
          <ul>
            {unresolvedChangeRequests.map((changeRequest) => (
              <li
                key={changeRequest.id}
                className="app-wie-change-request-details"
              >
                <div>{changeRequest.evaluationFieldLabel}</div>
                <div className="app-wie-change-request-details-action">
                  <SecondaryButton
                    className={`app-wie-change-request-details-status app-wie-change-request-details-status-${changeRequest.status}`}
                    onClick={() => {
                      dialogStore.openDialog({
                        id: `dialog-${changeRequest.id}`,
                        className: "app-wie-change-request-details-dialog",
                        header: `Change Request: ${changeRequest.evaluationFieldLabel}`,
                        allowDragging: false,
                        content: () => (
                          <WildfireLandscapeReviewApplicantIterationEngineChangeRequestDetails
                            evaluationId={props.evaluationId}
                            changeRequestId={changeRequest.id}
                            schemaFactory={props.wildfireSchemaFactory}
                            onChangeRequestCompleted={() => {
                              dialogStore.closeDialog(
                                `dialog-${changeRequest.id}`
                              );
                              props.onChangeRequestCompleted();
                            }}
                          />
                        ),
                      } as DialogProps);
                    }}
                  >
                    <span className="app-wie-change-request-details-status-header">
                      {changeRequest.status === ChangeRequestStatus.Addressed
                        ? "Addressed"
                        : "Click here to View Changes Required"}
                    </span>
                  </SecondaryButton>
                </div>
              </li>
            ))}
          </ul>
        </section>
      )}
    </div>
  );
};

export default WildfireLandscapeReviewApplicantIterationEngineChangeRequests;
