import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { PaymentCreditResponseReadDTO } from "../../../../Common/types/PaymentCredit/PaymentCreditResponseReadDTO";
import { Evaluation_HomeStandardEvaluation } from "../../types/HomeEvaluation";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";

const baseHomeStandardURL = "home-standard/evaluation";

class HomeStandardEvaluationAPIClient {
  createHomeStandardEvaluation = async () => {
    return baseApiClient.post<Evaluation_HomeStandardEvaluation>(
      `${baseHomeStandardURL}`
    );
  };

  getHomeStandardEvaluation = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_HomeStandardEvaluation>(
      `${baseHomeStandardURL}/${evaluationId}`
    );
  };

  getHomeStandardEvaluationFormModel = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_HomeStandardEvaluationFormModel>(
      `${baseHomeStandardURL}/${evaluationId}`
    );
  };

  getApplicationFeePaymentCreditDetails = async (evaluationId: string) => {
    return await baseApiClient.get<PaymentCreditResponseReadDTO>(
      `${baseHomeStandardURL}/${evaluationId}/payment/credit-details`
    );
  };

  applyApplicationFeePaymentCredit = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_HomeStandardEvaluation>(
      `${baseHomeStandardURL}/${evaluationId}/payment/credit`
    );
  };

  getApplicationFeeStripeCheckoutLink = async (evaluationId: string) => {
    return await baseApiClient.get<string>(
      `${baseHomeStandardURL}/${evaluationId}/payment/stripe-checkout`
    );
  };

  sendRequestedChangesEmail = async (evaluationId: string) => {
    return await baseApiClient.post<void>(
      `${baseHomeStandardURL}/${evaluationId}/requested-changes-email`
    );
  };

  hasEvaluationsWithSameAddress = async (evaluationId: string) => {
    return await baseApiClient.get<boolean>(
      `${baseHomeStandardURL}/${evaluationId}/evaluations-with-same-address`
    );
  };
  getRooferConfirmationForm = async (evaluationId: string) => {
    return baseApiClient.downloadGet(
      `${baseHomeStandardURL}/${evaluationId}/generate-roofing-confirmation-form`
    );
  };
  getInstallerConfirmationForm = async (evaluationId: string) => {
    return baseApiClient.downloadGet(
      `${baseHomeStandardURL}/${evaluationId}/generate-installing-contractor-confirmation-form`
    );
  };
  getEngineeringConfirmationForm = async (evaluationId: string) => {
    return baseApiClient.downloadGet(
      `${baseHomeStandardURL}/${evaluationId}/generate-engineering-form`
    );
  };
}

export const homeStandardEvaluationAPIClient =
  new HomeStandardEvaluationAPIClient();
