import {
  AddressFieldValue,
  EnumRadioFieldValue,
  EnumSingleSelectFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";

export enum HomeBasicInformationFormParts {
  HomeownerInformation = "homeowner-information",
}

export interface BaseHomeEvaluationBasicInformationFormModel {
  address: AddressFieldValue;
  specialProjectType: EnumSingleSelectFieldValue;
  contractorType: EnumSingleSelectFieldValue;
  isFHLBDFortifiedFundProject: EnumRadioFieldValue;
  isHomeConnectedToFoundation: EnumRadioFieldValue;
}
