import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import useFeatureToggleAPI from "../../common/Components/FeatureToggle/hook/useFeatureToggleAPI";
import { FeatureToggleSet } from "../../common/Components/FeatureToggle/FeatureToggles";
import wildfireBaseAPIClient from "../lib/wildfireBaseApiClient";

interface FeatureToggleContextProps {
  featureToggles: FeatureToggleSet | null;
  isWildfireEnabled: boolean;
  areInternalCommentsEnabled: boolean;
  areNewGridMetricsEnabled: boolean;
  isWFPAuditorRoleEnabled: boolean;
  areWFPLandscapeReviewsEnabled: boolean;
}

interface FeatureToggleProviderProps {
  children: ReactNode;
}

const FeatureToggleContext = createContext<FeatureToggleContextProps>({
  featureToggles: null,
  isWildfireEnabled: false,
  areInternalCommentsEnabled: false,
  areNewGridMetricsEnabled: false,
  isWFPAuditorRoleEnabled: false,
  areWFPLandscapeReviewsEnabled: false,
});

export const FeatureToggleProvider: React.FC<FeatureToggleProviderProps> = (
  props
) => {
  const [featureToggles, setFeatureToggles] = useState<FeatureToggleSet | null>(
    null
  );
  const { getFeatureToggleSet } = useFeatureToggleAPI(wildfireBaseAPIClient);

  useEffect(() => {
    const init = async () => {
      if (!featureToggles) {
        const toggles = await getFeatureToggleSet();
        setFeatureToggles(toggles);
      }
    };

    init();
  }, [getFeatureToggleSet, featureToggles]);

  const isWildfireEnabled = featureToggles?.Wildfire ?? false;
  const areInternalCommentsEnabled =
    featureToggles?.WFPInternalComments ?? false;
  const areNewGridMetricsEnabled = featureToggles?.NewGridMetrics ?? false;
  const isWFPAuditorRoleEnabled = featureToggles?.WFPAuditorRole ?? false;
  const areWFPLandscapeReviewsEnabled =
    featureToggles?.WFPLandscapeReviews ?? false;

  return (
    <FeatureToggleContext.Provider
      value={{
        featureToggles,
        isWildfireEnabled,
        areInternalCommentsEnabled,
        areNewGridMetricsEnabled,
        isWFPAuditorRoleEnabled,
        areWFPLandscapeReviewsEnabled,
      }}
    >
      {props.children}
    </FeatureToggleContext.Provider>
  );
};

export const useFeatureToggle = () => useContext(FeatureToggleContext);
