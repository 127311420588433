import "./applicant-styles.scss";
import {
  FieldSchemaFactoryProvider,
  WildfireSchemaFactoryV2,
} from "../../customHooks/useFieldSchemaFactory";
import WildfireLandscapeReviewApplicantIterationEngineChangeRequests from "./Forms/WildfireLandscapeReviewApplicantIterationEngineChangeRequests";
import IterationEngineHeader from "./Header/IterationEngineHeader";
import useWildfireIterationEngine from "./useWildfireIterationEngine";
import { TabComponent } from "@syncfusion/ej2-react-navigations";
import { Tag } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ChangeRequestStatus } from "../../../foritfied/types/evaluation/ChangeRequest";
import { WildfireEvaluationSteps } from "../../../foritfied/types/evaluation/Evaluation";
import { wildfireLandscapeReviewApiClient } from "../../lib/apiClients/evaluation/wildfireLandscapeReviewApiClient";

export interface WildfireLandscapeReviewApplicantIterationEngineProps {
  evaluationId: string;
  wildfireSchemaFactory: WildfireSchemaFactoryV2;
}
export const WildfireLandscapeReviewApplicantIterationEngine: React.FC<
  WildfireLandscapeReviewApplicantIterationEngineProps
> = (props) => {
  const {
    wildfireEvaluation,
    refreshWildfireEvaluation,
    sendForward,
    isSendForwardLoading,
    approve,
    isApproveLoading,
    reject,
    isRejectLoading,
    close,
    cancel,
    reopen,
    requestChanges,
    regenerateCertificate,
    downloadCertificate,
    isRequestChangesLoading,
    allChangeRequests,
    resolvedAllChangeRequests,
    getEvaluationFieldKeysForAllUnresolvedChangeRequests,
    hasNoUnaddressedChangeRequests,
    hasOneNewChangeRequest,
    canEditForm,
    getChangeRequests,
    longestOutstandingChangeRequest,
  } = useWildfireIterationEngine(props.evaluationId);

  const tabId = "LandscapeReview";
  const formTabs = [{ id: tabId, label: "Landscape Review" }];

  const unresolvedChangeRequests = allChangeRequests.filter(
    (cr) => cr.status === ChangeRequestStatus.New
  );

  return (
    <section className="wildfire-applicant-iteration-engine">
      {wildfireEvaluation && (
        <FieldSchemaFactoryProvider
          schemaFactory={props.wildfireSchemaFactory}
          formOverrides={{
            iterationEngineProps: {
              fieldKeysWithChangeRequests:
                getEvaluationFieldKeysForAllUnresolvedChangeRequests,
              shouldEnableForm: canEditForm,
              changeRequests: allChangeRequests,
            },
          }}
        >
          <div className="wildfire-iteration-engine">
            <div className="wildfire-iteration-engine__header">
              <IterationEngineHeader
                evaluationId={wildfireEvaluation.id}
                auditorId={wildfireEvaluation.auditorId}
                wildfireApplicationId={
                  wildfireEvaluation.wildfireLandscapeReview
                    .wildfirePreparedApplicationId
                }
                unreadCommentsCount={wildfireEvaluation.unreadCommentsCount}
                wildfireApplicationOwnerId={
                  wildfireEvaluation?.wildfireLandscapeReview.ownerId
                }
                step={wildfireEvaluation.wildfireLandscapeReview.step}
                status={wildfireEvaluation.status_AsString}
                resolvedAllChangeRequest={resolvedAllChangeRequests}
                hasOneNewChangeRequest={hasOneNewChangeRequest}
                hasNoUnaddressedChangeRequests={hasNoUnaddressedChangeRequests}
                submitAdminUpdate={(wildfireEvaluationId, form) =>
                  wildfireLandscapeReviewApiClient
                    .submitLandscapeReviewAdminUpdate(
                      wildfireEvaluationId,
                      form
                    )
                    .then()
                }
                refreshWildfireApplication={refreshWildfireEvaluation}
                sendForward={sendForward}
                isSendForwardLoading={isSendForwardLoading}
                approve={approve}
                isApproveLoading={isApproveLoading}
                reject={reject}
                close={close}
                cancel={cancel}
                reopen={reopen}
                isRejectLoading={isRejectLoading}
                requestChanges={requestChanges}
                isRequestChangesLoading={isRequestChangesLoading}
                downloadCertificate={downloadCertificate}
                regenerateCertificate={regenerateCertificate}
                externalFolderId={wildfireEvaluation.externalFolderId}
                legacyEvaluationSource={
                  wildfireEvaluation.legacyEvaluationSource
                }
                auditorNotes={wildfireEvaluation.auditorNotes}
                isWithdrawn={wildfireEvaluation.isWithdrawn}
                shouldShowAutoRejectBanner={
                  wildfireEvaluation.wildfireLandscapeReview
                    .shouldShowAutoRejectBanner
                }
                longestOutstandingChangeRequest={
                  longestOutstandingChangeRequest
                }
                homeOwnerId={wildfireEvaluation.wildfireLandscapeReview.ownerId}
                moveableStepOptionsByAdmin={
                  wildfireEvaluation.wildfireLandscapeReview
                    .moveableStepOptionsByAdmin
                }
              />
            </div>
            <div className="wildfire-iteration-engine__content">
              <TabComponent
                headerPlacement="Top"
                overflowMode="MultiRow"
                animation={{
                  previous: { effect: "None" },
                  next: { effect: "None" },
                }}
              >
                <div className="e-tab-header wildfire-iteration-engine__top-panel">
                  {formTabs.map((tab) => {
                    let tabLabel = tab.label;
                    let tabTitle = "Wildfire Prepared Home";

                    if (
                      tabLabel === "Base" &&
                      wildfireEvaluation.step ===
                        WildfireEvaluationSteps.WildfireApplicationIBHSEligibilityReview
                    ) {
                      tabLabel = "Eligibility Review";
                      tabTitle = "";
                    }

                    return (
                      <div
                        key={tab.id}
                        className="wildfire-iteration-engine__tab"
                      >
                        <span className="wildfire-iteration-engine__tab-title">
                          <span className="wildfire-iteration-engine__tab-title-prefix">
                            {tabTitle}{" "}
                          </span>
                          <span className="wildfire-iteration-engine__tab-title-header">
                            {tabLabel}
                          </span>
                        </span>
                        {unresolvedChangeRequests?.length > 0 && (
                          <span className="wildfire-iteration-engine__tab-change-requests-count">
                            <div className="change-requests-count">
                              <Tag
                                icon={IconNames.WarningSign}
                                large={true}
                                round={true}
                              >
                                <span className="change-requests-count-counter">
                                  {unresolvedChangeRequests.length}
                                </span>
                                <span className="change-requests-count-suffix">
                                  {` Change${
                                    unresolvedChangeRequests.length > 1
                                      ? "s"
                                      : ""
                                  } Required`}
                                </span>
                              </Tag>
                            </div>
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="e-content wildfire-iteration-engine__bottom-panel">
                  {formTabs.map((tab) => {
                    return (
                      <div key={tab.id}>
                        <div
                          key={tab.id}
                          className="control_wrapper"
                          id="control_wrapper"
                        >
                          <WildfireLandscapeReviewApplicantIterationEngineChangeRequests
                            key={unresolvedChangeRequests.length}
                            evaluationId={wildfireEvaluation.id}
                            changeRequests={allChangeRequests}
                            wildfireSchemaFactory={props.wildfireSchemaFactory}
                            onChangeRequestCompleted={async () =>
                              await getChangeRequests?.()
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </TabComponent>
            </div>
          </div>
        </FieldSchemaFactoryProvider>
      )}
    </section>
  );
};
