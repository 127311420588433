import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts } from "../../../common/types/BaseHomeEvaluationInstallerComplianceAttachedStructureFormModel";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";
import { HomeStandardEvaluationInstallerComplianceAttachedStructureFormModel } from "../../types/HomeStandardEvaluationInstallerComplianceAttachedStructureFormModel";

class HomeStandardEvaluationInstallerComplianceAttachedStructureAPIClient {
  updateHomeStandardInstallerComplianceAttachedStructureForm = async (
    evaluationId: string,
    installerComplianceAttachedStructureForm: HomeStandardEvaluationInstallerComplianceAttachedStructureFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/installer-compliance-attached-structure-form/${evaluationId}`,
      {
        installerComplianceAttachedStructureForm,
        fieldKey,
      }
    );
  };

  submitHomeStandardInstallerComplianceAttachedStructureForm = async (
    evaluationId: string,
    installerComplianceAttachedStructureForm: HomeStandardEvaluationInstallerComplianceAttachedStructureFormModel,
    formPart?: BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts
      ).pop() as BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/installer-compliance-attached-structure-form/${evaluationId}/${formPartValue}`,
      installerComplianceAttachedStructureForm
    );
  };
}

export const homeStandardEvaluationInstallerComplianceAttachedStructureAPIClient =
  new HomeStandardEvaluationInstallerComplianceAttachedStructureAPIClient();
