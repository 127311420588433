import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import HomeStandardRedesignationFormContainer from "../components/Forms/RedesignationForm/Container/HomeStandardRedesignationFormContainer";
import { BaseHomeEvaluationRedesignationFormParts } from "../../common/types/BaseHomeEvaluationRedesignationFormModel";

export interface HomeStandardRedesignationFormPageProps {
  evaluationId?: string;
  redesignationFormPart?: BaseHomeEvaluationRedesignationFormParts;
}

const HomeStandardRedesignationFormPageComponent: FC<
  HomeStandardRedesignationFormPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeStandardRedesignationFormContainer
            evaluationId={props.evaluationId}
            redesignationFormPart={props.redesignationFormPart}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeStandardRedesignationFormPage = withAITracking(
  reactPlugin,
  HomeStandardRedesignationFormPageComponent,
  "HomeStandardRedesignationFormPage",
  "tracking-container"
);
