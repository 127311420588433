import { NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { FC, useEffect, useState } from "react";

export interface PaymentRedirectPageProps {
  getCheckoutUrl: () => Promise<string>;
}

export const PaymentRedirectPage: FC<PaymentRedirectPageProps> = ({
  getCheckoutUrl,
}) => {
  const [errors, setErrors] = useState<string[]>();

  useEffect(() => {
    getCheckoutUrl()
      .then((checkoutUrl) => {
        window.location.href = checkoutUrl;
      })
      .catch((error) => {
        const response = error?.response;
        if (response?.status === 400) {
          const errorFields = response.data?.additionalInfo;
          setErrors(
            errorFields ? Object.values<string>(errorFields).flat() : []
          );
        } else {
          setErrors([]);
        }
      });
  }, []);

  return errors ? (
    <NonIdealState
      icon={IconNames.ERROR}
      description={
        errors.length === 0 ? (
          <>An unexpected error occurred while loading the payment page.</>
        ) : (
          <>
            <h3>One or more validation errors occurred:</h3>
            {errors.map((error, i) => (
              <p key={`payment_redirect_error_${i}`}>{error}</p>
            ))}
          </>
        )
      }
    />
  ) : (
    <Loading />
  );
};
