import {
  EnumMultiSelectFieldValue,
  SingleCheckboxFieldValue,
  TextAreaFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";

export enum BaseHomeEvaluationEngineeringComplianceGablesFormParts {
  EngineeringComplianceGables = "engineering-compliance-gables",
}

export enum GableEndWallFramedTypes {
  BalloonFramed = 1,
  PlatformFramed = 2,
}

export interface BaseHomeEvaluationEngineeringComplianceGablesFormModel
  extends IEngineeringComplianceGables {}

interface IEngineeringComplianceGables {
  howAreGableEndWallsFramed?: EnumMultiSelectFieldValue;
  attestation207?: SingleCheckboxFieldValue;
  attestation208?: SingleCheckboxFieldValue;
  attestation209?: SingleCheckboxFieldValue;
  attestation210?: SingleCheckboxFieldValue;
  gableConnectedBraceTopType?: EnumMultiSelectFieldValue;
  gableConnectedBraceTopTypeOther?: TextAreaFieldValue;
  attestation212?: SingleCheckboxFieldValue;
  attestation213?: SingleCheckboxFieldValue;
  attestation214?: SingleCheckboxFieldValue;
  gableConnectedBraceBottomType?: EnumMultiSelectFieldValue;
  gableConnectedBraceBottomTypeOther?: TextAreaFieldValue;
  attestation216?: SingleCheckboxFieldValue;
  gableStudsBracedType?: EnumMultiSelectFieldValue;
  gableStudsBracedTypeOther?: TextAreaFieldValue;
  attestation218?: SingleCheckboxFieldValue;
}
