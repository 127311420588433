import {
  DateFieldProps,
  EnumRadioFieldProps,
  EnumSingleSelectFieldV2Props,
  FieldSchemaFormBuilderV2,
  NumericFieldProps,
  SchemaFactoryV2,
  TextFieldProps,
} from "@ucl/library";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { get } from "lodash";
import { YesOrNoSelection } from "../../../../../../wildfire/types/FormFields";
import {
  AtticVentTypes,
  RoofSheathingTypes,
  SkyLightImpactRatingTypes,
  StandardDetailEdgeInstallationTypes,
  StandardDetailSealedRoofDeckCoverTypes,
  StandardDetailSheathingAttachmentTypes,
} from "../../../../common/types/BaseHomeEvaluationRoofingComplianceGeneralInformationFormModel";
import { HazardTypes } from "../../../../../Common/types/Evaluation/BaseEvaluation";
import {
  ReadonlyWarningBannerField,
  ReadonlyWarningBannerFieldProps,
} from "../../../../../Common/components/Fields/ReadonlyWarningBanner/ReadonlyWarningBannerField";
import {
  NewConstructionOrExistingHomeTypes,
  NewOrExistingRoofTypes,
} from "../../../../common/types/BaseHomeEvaluationEligibilityFormModel";
import { permissionStore } from "../../../../../../foritfied/stores/PermissionStore";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "RoofingComplianceGeneralInformationForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

// Part 1: Basic Information
const basicInformationFields = (
  form: Evaluation_HomeStandardEvaluationFormModel
) => [
  {
    fieldKey: buildFieldKey("RoofInstallationDate"),
    hidden:
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof !==
      YesOrNoSelection.Yes,
    minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 100)),
    maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 20)),
  } as DateFieldProps,
  {
    fieldKey: buildFieldKey("DistanceToSaltWaterShoreline"),
    hidden: form.hazard !== HazardTypes.Hurricane,
    value:
      form.homeStandardEvaluation.eligibilityForm
        .distanceToSaltWaterShorelineType,
    disabled: true,
  } as EnumSingleSelectFieldV2Props,
  {
    fieldKey: buildFieldKey("NumberOfDifferentRoofCoverTypes"),
    hidden:
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof !==
      YesOrNoSelection.Yes,
    min: 1,
    max: 5,
    format: "0",
    decimals: 0,
    showIncrementButtons: false,
  } as NumericFieldProps,
  buildFieldKey("WasPermitIssued"),
];

const basicInformationRequiredFields = [
  buildFieldKey("RoofInstallationDate"),
  buildFieldKey("NumberOfDifferentRoofCoverTypes"),
  buildFieldKey("WasPermitIssued"),
];

export const homeStandardBasicInformationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        basicInformationFields(form),
        onValueChange,
        form,
        errors,
        basicInformationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 2: Permit Information
const permitInformationFields = (
  form: Evaluation_HomeStandardEvaluationFormModel
) => [
  {
    fieldKey: buildFieldKey("PermitNumber"),
    hidden: !(
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .wasPermitIssued === YesOrNoSelection.Yes
    ),
  },
  {
    fieldKey: buildFieldKey("PermitDate"),
    hidden: !(
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .wasPermitIssued === YesOrNoSelection.Yes
    ),
    minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 20)),
    maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 20)),
  },
  {
    fieldKey: buildFieldKey("PermitIssuedBy"),
    hidden: !(
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .wasPermitIssued === YesOrNoSelection.Yes
    ),
  },
  {
    fieldKey: buildFieldKey("DateOfFinalBldgDeptInspection"),
    hidden: !(
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .wasPermitIssued === YesOrNoSelection.Yes
    ),
    minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 20)),
    maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 20)),
  },
];

const permitInformationRequiredFields = [
  buildFieldKey("PermitNumber"),
  buildFieldKey("PermitDate"),
  buildFieldKey("PermitIssuedBy"),
];

export const homeStandardPermitInformationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        permitInformationFields(form),
        onValueChange,
        form,
        errors,
        permitInformationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 3: Site Conditions
const siteConditionsFields = (
  form: Evaluation_HomeStandardEvaluationFormModel
) => [
  {
    fieldKey: buildFieldKey("BuildingCodeAndEdition"),
    hidden: !(
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof ===
      YesOrNoSelection.Yes
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("Asce7Edition"),
    hidden: !(
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof ===
      YesOrNoSelection.Yes
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("UltimateSiteDesignWindSpeed"),
    min: 1,
    max: 300,
    hidden: !(
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof ===
      YesOrNoSelection.Yes
    ),
    showIncrementButtons: false,
    format: "0",
    decimals: 0,
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey(
      "UltimateSiteDesignWindSpeedExceeds200MPHWarningBanner"
    ),
    hidden:
      (form.homeStandardEvaluation?.roofingComplianceGeneralInformationForm
        ?.ultimateSiteDesignWindSpeed ?? 0) <= 200,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey(
      "UltimateSiteDesignWindSpeedBelow115MPHWarningBanner"
    ),
    hidden:
      (form.homeStandardEvaluation?.roofingComplianceGeneralInformationForm
        ?.ultimateSiteDesignWindSpeed ?? 0) >= 115 ||
      (form.homeStandardEvaluation?.roofingComplianceGeneralInformationForm
        ?.ultimateSiteDesignWindSpeed ?? 0) <= 0,
  } as ReadonlyWarningBannerFieldProps,
  buildFieldKey("SiteExposureCategory"),
  {
    fieldKey: buildFieldKey("MeanRoofHeight"),
    min: 15,
    max: 99,
    hidden: !(
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof ===
      YesOrNoSelection.Yes
    ),
    format: "0",
    decimals: 0,
    showIncrementButtons: false,
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("MeanRoofHeightGreaterThan3WarningBanner"),
    hidden:
      (form.homeStandardEvaluation?.roofingComplianceGeneralInformationForm
        ?.meanRoofHeight ?? 0) < 40,
  } as ReadonlyWarningBannerFieldProps,
];

const siteConditionsRequiredFields = [
  buildFieldKey("BuildingCodeAndEdition"),
  buildFieldKey("Asce7Edition"),
  buildFieldKey("UltimateSiteDesignWindSpeed"),
  buildFieldKey("SiteExposureCategory"),
  buildFieldKey("MeanRoofHeight"),
];

export const homeStandardSiteConditionsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        siteConditionsFields(form),
        onValueChange,
        form,
        errors,
        siteConditionsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 4: Roof Structure
const roofStructureFields = (
  form: Evaluation_HomeStandardEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("MaxRoofStructuralMemberSpacing"),
    min: 0,
    max: 999,
    format: "0",
    decimals: 0,
    showIncrementButtons: false,
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey(
      "MaxRoofStructuralMemberSpacingExceeds24WarningBanner"
    ),
    hidden:
      (form.homeStandardEvaluation?.roofingComplianceGeneralInformationForm
        ?.maxRoofStructuralMemberSpacing ?? 0) <= 24,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("MaximumRoofStructuralMemberSpacingPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "maximumRoofStructuralMemberSpacingPhoto"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
  } as WildfireBoxContentExplorerProps,
];

const roofStructureRequiredFields = [
  buildFieldKey("MaxRoofStructuralMemberSpacing"),
  buildFieldKey("MaximumRoofStructuralMemberSpacingPhoto"),
];

export const homeStandardRoofStructureBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        roofStructureFields(form, errors),
        onValueChange,
        form,
        errors,
        roofStructureRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 5: Roof Sheathing
const roofSheathingFields = (
  form: Evaluation_HomeStandardEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  buildFieldKey("RoofSheathingTypes"),
  {
    fieldKey: buildFieldKey("RoofSheathingTypeWhenOther"),
    hidden:
      !form.homeStandardEvaluation.roofingComplianceGeneralInformationForm.roofSheathingTypes?.includes(
        RoofSheathingTypes.Other
      ),
  },
  buildFieldKey("MinimumRoofSheathingThickness"),
  {
    fieldKey: buildFieldKey("MinimumRoofSheathingThicknessWarningBanner"),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("MinimumRoofSheathingThicknessPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "minimumRoofSheathingThicknessPhotos"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
  } as WildfireBoxContentExplorerProps,
  buildFieldKey(
    "SelectedStandardDetailsThatMatchesInstalledSheathingAttachments"
  ),
  {
    fieldKey: buildFieldKey(
      "SelectedStandardDetailsThatMatchesInstalledSheathingAttachmentsInvalidOptions1WarningBanner"
    ),
    hidden: !(
      form.hazard === HazardTypes.Hurricane &&
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof ===
        NewOrExistingRoofTypes.NewRoof &&
      form.homeStandardEvaluation.eligibilityForm
        .newConstructionOrExistingHome ==
        NewConstructionOrExistingHomeTypes.NewConstruction
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey(
      "SelectedStandardDetailsThatMatchesInstalledSheathingAttachmentsInvalidOptions2WarningBanner"
    ),
    hidden: !(
      form.hazard === HazardTypes.Hurricane &&
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof ===
        NewOrExistingRoofTypes.ExistingRoof &&
      form.homeStandardEvaluation.eligibilityForm
        .newConstructionOrExistingHome ===
        NewConstructionOrExistingHomeTypes.NewConstruction
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey(
      "SelectedStandardDetailsThatMatchesInstalledSheathingAttachmentsInvalidOptions3WarningBanner"
    ),
    hidden: !(
      form.hazard === HazardTypes.Hurricane &&
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof ===
        NewOrExistingRoofTypes.NewRoof &&
      form.homeStandardEvaluation.eligibilityForm
        .newConstructionOrExistingHome ===
        NewConstructionOrExistingHomeTypes.ExistingHome
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey(
      "SelectedStandardDetailsThatMatchesInstalledSheathingAttachmentsInvalidOptions4WarningBanner"
    ),
    hidden: !(
      form.hazard === HazardTypes.HighWind &&
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof ===
        NewOrExistingRoofTypes.NewRoof &&
      form.homeStandardEvaluation.eligibilityForm
        .newConstructionOrExistingHome ===
        NewConstructionOrExistingHomeTypes.NewConstruction
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey(
      "SelectedStandardDetailsThatMatchesInstalledSheathingAttachmentsInvalidOptions5WarningBanner"
    ),
    hidden: !(
      form.hazard === HazardTypes.HighWind &&
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof ===
        NewOrExistingRoofTypes.ExistingRoof &&
      form.homeStandardEvaluation.eligibilityForm
        .newConstructionOrExistingHome ===
        NewConstructionOrExistingHomeTypes.NewConstruction
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey(
      "SelectedStandardDetailsThatMatchesInstalledSheathingAttachmentsInvalidOptions6WarningBanner"
    ),
    hidden: !(
      form.hazard === HazardTypes.HighWind &&
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof ===
        NewOrExistingRoofTypes.NewRoof &&
      form.homeStandardEvaluation.eligibilityForm
        .newConstructionOrExistingHome ===
        NewConstructionOrExistingHomeTypes.ExistingHome
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey(
      "SelectedStandardDetailsThatMatchesInstalledSheathingAttachmentsInvalidOptions7WarningBanner"
    ),
    hidden: !(
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof ===
        NewOrExistingRoofTypes.ExistingRoof &&
      form.homeStandardEvaluation.eligibilityForm
        .newConstructionOrExistingHome ===
        NewConstructionOrExistingHomeTypes.ExistingHome
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("RoofSheathingFastenerTypePhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "roofSheathingFastenerTypePhoto"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm.selectedStandardDetailsThatMatchesInstalledSheathingAttachments?.includes(
        StandardDetailSheathingAttachmentTypes.FSRD1
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("RoofSheathingAttachmentPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "roofSheathingAttachmentPhoto"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm.selectedStandardDetailsThatMatchesInstalledSheathingAttachments?.includes(
        StandardDetailSheathingAttachmentTypes.FSRD1
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "DoesRoofDeckAttachmentNotMatchFortifiedPrescribedRequirements"
    ),
    hidden:
      !form.homeStandardEvaluation.roofingComplianceGeneralInformationForm.selectedStandardDetailsThatMatchesInstalledSheathingAttachments?.includes(
        StandardDetailSheathingAttachmentTypes.NoneOfTheAbove
      ),
  },
  {
    fieldKey: buildFieldKey(
      "SelectedStandardDetailsThatMatchesInstalledSheathingAttachmentsInvalidOptions8WarningBanner"
    ),
    hidden:
      !form.homeStandardEvaluation.roofingComplianceGeneralInformationForm.selectedStandardDetailsThatMatchesInstalledSheathingAttachments?.includes(
        StandardDetailSheathingAttachmentTypes.NoneOfTheAbove
      ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey(
      "RoofDeckAttachmentEngineeringAnalysisRequiredDocumentation"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "roofDeckAttachmentEngineeringAnalysisRequiredDocumentation"
    ),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .doesRoofDeckAttachmentNotMatchFortifiedPrescribedRequirements === true
    ),
  } as WildfireBoxContentExplorerProps,
  buildFieldKey("IsEntireRoofLowSloped"),
];

const roofSheathingRequiredFields = [
  buildFieldKey("RoofSheathingTypes"),
  buildFieldKey("RoofSheathingTypeWhenOther"),
  buildFieldKey("MinimumRoofSheathingThickness"),
  buildFieldKey("MinimumRoofSheathingThicknessPhotos"),
  buildFieldKey("RoofSheathingFastenerTypePhoto"),
  buildFieldKey("RoofSheathingAttachmentPhoto"),
  buildFieldKey("RoofDeckAttachmentEngineeringAnalysisRequiredDocumentation"),
  buildFieldKey(
    "SelectedStandardDetailsThatMatchesInstalledSheathingAttachments"
  ),
  buildFieldKey(
    "DoesRoofDeckAttachmentNotMatchFortifiedPrescribedRequirements"
  ),
  buildFieldKey("IsEntireRoofLowSloped"),
];

export const homeStandardRoofSheathingBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        roofSheathingFields(form, errors),
        onValueChange,
        form,
        errors,
        roofSheathingRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 6: Sealed Roof Deck
const sealedRoofDeckFields = (
  form: Evaluation_HomeStandardEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  buildFieldKey("SelectedStandardDetailsThatMatchesSealedRoofDeck"),
  {
    fieldKey: buildFieldKey(
      "DoesSealedRoofDeckNotMatchFortifiedPrescribedRequirements"
    ),
    hidden:
      !form.homeStandardEvaluation.roofingComplianceGeneralInformationForm.selectedStandardDetailsThatMatchesSealedRoofDeck?.includes(
        StandardDetailSealedRoofDeckCoverTypes.NoneOfTheAbove
      ),
  },
  {
    fieldKey: buildFieldKey(
      "SelectedStandardDetailsThatMatchesSealedRoofDeckInvalidOptions1WarningBanner"
    ),
    hidden:
      !form.homeStandardEvaluation.roofingComplianceGeneralInformationForm.selectedStandardDetailsThatMatchesSealedRoofDeck?.includes(
        StandardDetailSealedRoofDeckCoverTypes.NoneOfTheAbove
      ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey(
      "SealedRoofDeckTapedSeamsAndUnderlaymentInstallationPhotos"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "SealedRoofDeckTapedSeamsAndUnderlaymentInstallationPhotos.FileCount"
    ),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardEvaluation.roofingComplianceGeneralInformationForm.selectedStandardDetailsThatMatchesSealedRoofDeck?.includes(
        StandardDetailSealedRoofDeckCoverTypes.FSRD2
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SealedRoofDeck2PlyInstallationPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "sealedRoofDeck2PlyInstallationPhotos"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: ![
      StandardDetailSealedRoofDeckCoverTypes.FSRD3,
      StandardDetailSealedRoofDeckCoverTypes.FSRD7,
      StandardDetailSealedRoofDeckCoverTypes.FSRD8,
    ].some((type) =>
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm.selectedStandardDetailsThatMatchesSealedRoofDeck?.includes(
        type
      )
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "SealedRoofDeckSelfAdheredMembraneInstallationPhotos"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "sealedRoofDeckSelfAdheredMembraneInstallationPhotos"
    ),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardEvaluation.roofingComplianceGeneralInformationForm.selectedStandardDetailsThatMatchesSealedRoofDeck?.includes(
        StandardDetailSealedRoofDeckCoverTypes.FSRD4
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SealedRoofDeckSprayFoamInstallationPhotos"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "SealedRoofDeckSprayFoamInstallationPhotos.FileCount"
    ),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    // NOTE: formstack v1 is using StandardDetailSheathingAttachmentTypes and not StandardDetailSealedRoofDeckCoverTypes.
    hidden:
      !form.homeStandardEvaluation.roofingComplianceGeneralInformationForm.selectedStandardDetailsThatMatchesInstalledSheathingAttachments?.includes(
        StandardDetailSheathingAttachmentTypes.FSRD1
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "SealedRoofDeckTapedSeamsWithTileInstallationPhotos"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "sealedRoofDeckTapedSeamsWithTileInstallationPhotos"
    ),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardEvaluation.roofingComplianceGeneralInformationForm.selectedStandardDetailsThatMatchesSealedRoofDeck?.includes(
        StandardDetailSealedRoofDeckCoverTypes.FSRD5
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "SealedRoofDeckSelfAdheredMembraneWithTileInstallationPhotos"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "sealedRoofDeckSelfAdheredMembraneWithTileInstallationPhotos"
    ),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardEvaluation.roofingComplianceGeneralInformationForm.selectedStandardDetailsThatMatchesSealedRoofDeck?.includes(
        StandardDetailSealedRoofDeckCoverTypes.FSRD6
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SealedRoofDeckUnderlaymentLabelPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "sealedRoofDeckUnderlaymentLabelPhotos"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: ![
      StandardDetailSealedRoofDeckCoverTypes.FSRD1,
      StandardDetailSealedRoofDeckCoverTypes.FSRD2,
      StandardDetailSealedRoofDeckCoverTypes.FSRD3,
      StandardDetailSealedRoofDeckCoverTypes.FSRD4,
      StandardDetailSealedRoofDeckCoverTypes.FSRD5,
      StandardDetailSealedRoofDeckCoverTypes.FSRD6,
      StandardDetailSealedRoofDeckCoverTypes.FSRD7,
      StandardDetailSealedRoofDeckCoverTypes.FSRD8,
    ].some((type) =>
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm.selectedStandardDetailsThatMatchesSealedRoofDeck?.includes(
        type
      )
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SealedRoofDeckNoneOfTheAbovePhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "sealedRoofDeckNoneOfTheAbovePhotos"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .doesSealedRoofDeckNotMatchFortifiedPrescribedRequirements === true &&
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm.selectedStandardDetailsThatMatchesSealedRoofDeck?.includes(
        StandardDetailSealedRoofDeckCoverTypes.NoneOfTheAbove
      )
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SealedRoofDeckClosedCellFoamDocumentation"),
    defaultToUpload: false,
    errorMessages: get(errors, "sealedRoofDeckClosedCellFoamDocumentation"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    // NOTE: formstack v1 is using StandardDetailSheathingAttachmentTypes and not StandardDetailSealedRoofDeckCoverTypes.
    hidden:
      !form.homeStandardEvaluation.roofingComplianceGeneralInformationForm.selectedStandardDetailsThatMatchesInstalledSheathingAttachments?.includes(
        StandardDetailSheathingAttachmentTypes.FSRD1
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("FullyInstalledRoofCoverPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "fullyInstalledRoofCoverPhotos"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
  } as WildfireBoxContentExplorerProps,
];

const sealedRoofDeckRequiredFields = [
  buildFieldKey("SelectedStandardDetailsThatMatchesSealedRoofDeck"),
  buildFieldKey("DoesSealedRoofDeckNotMatchFortifiedPrescribedRequirements"),
  buildFieldKey("SealedRoofDeckTapedSeamsAndUnderlaymentInstallationPhotos"),
  buildFieldKey("SealedRoofDeck2PlyInstallationPhotos"),
  buildFieldKey("SealedRoofDeckSelfAdheredMembraneInstallationPhotos"),
  buildFieldKey("SealedRoofDeckSprayFoamInstallationPhotos"),
  buildFieldKey("SealedRoofDeckTapedSeamsWithTileInstallationPhotos"),
  buildFieldKey("SealedRoofDeckSelfAdheredMembraneWithTileInstallationPhotos"),
  buildFieldKey("SealedRoofDeckUnderlaymentLabelPhotos"),
  buildFieldKey("SealedRoofDeckNoneOfTheAbovePhotos"),
  buildFieldKey("SealedRoofDeckClosedCellFoamDocumentation"),
  buildFieldKey("FullyInstalledRoofCoverPhotos"),
];

export const homeStandardSealedRoofDeckBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        sealedRoofDeckFields(form),
        onValueChange,
        form,
        errors,
        sealedRoofDeckRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 7: Drip Edge
const dripEdgeFields = (
  form: Evaluation_HomeStandardEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("AreInstalledDripEdgeNewAndCodeCompliantMaterials"),
    hidden: !(
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof ===
      YesOrNoSelection.Yes
    ),
  },
  {
    fieldKey: buildFieldKey(
      "AreInstalledDripEdgeNewAndCodeCompliantMaterialsWarningBanner"
    ),
    hidden:
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .areInstalledDripEdgeNewAndCodeCompliantMaterials !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("StandardDetailThatMatchesDripEdgeInstallation"),
    hidden: !(
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof ===
      YesOrNoSelection.Yes
    ),
  },
  {
    fieldKey: buildFieldKey(
      "StandardDetailThatMatchesDripEdgeInstallationHighWindWarningBanner"
    ),
    hidden: !(
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .standardDetailThatMatchesDripEdgeInstallation ===
        StandardDetailEdgeInstallationTypes.FDE1 &&
      form.hazard === HazardTypes.HighWind
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey(
      "StandardDetailThatMatchesDripEdgeInstallationHurricaneWarningBanner"
    ),
    hidden: !(
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .standardDetailThatMatchesDripEdgeInstallation ===
        StandardDetailEdgeInstallationTypes.FDE3 &&
      form.hazard === HazardTypes.Hurricane
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("NewDripEdgeInstallationPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "newDripEdgeInstallationPhotos"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof ===
      YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "WasNewDripEdgeAndRoofFlashingInstalledPerManufacturerSpecifications"
    ),
    hidden: !(
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof ===
        YesOrNoSelection.Yes &&
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .standardDetailThatMatchesDripEdgeInstallation ===
        StandardDetailEdgeInstallationTypes.NoneOfTheAbove
    ),
  },
  {
    fieldKey: buildFieldKey(
      "WasNewDripEdgeAndRoofFlashingInstalledPerManufacturerSpecificationsWarningBanner"
    ),
    hidden:
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .wasNewDripEdgeAndRoofFlashingInstalledPerManufacturerSpecifications !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("IsDripEdgeInstalled"),
    hidden: !(
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof ===
      YesOrNoSelection.No
    ),
  },
  {
    fieldKey: buildFieldKey("ExistingDripEdgeInstallationPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "existingDripEdgeInstallationPhotos"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof ===
      YesOrNoSelection.No
    ),
  } as WildfireBoxContentExplorerProps,
];

const dripEdgeRequiredFields = [
  buildFieldKey("AreInstalledDripEdgeNewAndCodeCompliantMaterials"),
  buildFieldKey("StandardDetailThatMatchesDripEdgeInstallation"),
  buildFieldKey("NewDripEdgeInstallationPhotos"),
  buildFieldKey(
    "WasNewDripEdgeAndRoofFlashingInstalledPerManufacturerSpecifications"
  ),
  buildFieldKey("IsDripEdgeInstalled"),
  buildFieldKey("ExistingDripEdgeInstallationPhotos"),
];

export const homeStandardDripEdgeBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        dripEdgeFields(form, errors),
        onValueChange,
        form,
        errors,
        dripEdgeRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 8: Attic Vents
const atticVentsFields = (
  form: Evaluation_HomeStandardEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  buildFieldKey("AreRoofMountedVentsOrGableEndsPresent"),
  {
    fieldKey: buildFieldKey("RoofVentilation"),
    hidden:
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .areRoofMountedVentsOrGableEndsPresent !== YesOrNoSelection.Yes,
  },
  {
    fieldKey: buildFieldKey("DoAllRoofMountedVentsMeetTAS100A"),
    hidden:
      !form.homeStandardEvaluation.roofingComplianceGeneralInformationForm.roofVentilation?.includes(
        AtticVentTypes.RoofMountedVents
      ),
  },
  {
    fieldKey: buildFieldKey("DoAllRoofMountedVentsMeetTAS100AWarningBanner"),
    hidden:
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .doAllRoofMountedVentsMeetTAS100A !== YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("RoofVentilationPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "roofVentilationPhoto"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardEvaluation.roofingComplianceGeneralInformationForm.roofVentilation?.includes(
        AtticVentTypes.RoofMountedVents
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "AreAllRoofMountedVentsInstalledPerManufacturerRequirements"
    ),
    hidden:
      !form.homeStandardEvaluation.roofingComplianceGeneralInformationForm.roofVentilation?.includes(
        AtticVentTypes.RoofMountedVents
      ),
  },
  {
    fieldKey: buildFieldKey(
      "AreAllRoofMountedVentsInstalledPerManufacturerRequirementsWarningBanner"
    ),
    hidden:
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .areAllRoofMountedVentsInstalledPerManufacturerRequirements !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey(
      "DoAllGableEndVentsHaveARemovablePerFortified2020HomeStandard"
    ),
    hidden:
      !form.homeStandardEvaluation.roofingComplianceGeneralInformationForm.roofVentilation?.includes(
        AtticVentTypes.GableEndsVents
      ),
  },
  {
    fieldKey: buildFieldKey(
      "DoAllGableEndVentsHaveARemovablePerFortified2020HomeStandardWarningBanner"
    ),
    hidden:
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .doAllGableEndVentsHaveARemovablePerFortified2020HomeStandard !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("GableEndsVentsPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "gableEndsVentsPhoto"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardEvaluation.roofingComplianceGeneralInformationForm.roofVentilation?.includes(
        AtticVentTypes.GableEndsVents
      ),
  } as WildfireBoxContentExplorerProps,
];

const atticVentsRequiredFields = [
  buildFieldKey("AreRoofMountedVentsOrGableEndsPresent"),
  buildFieldKey("RoofVentilation"),
  buildFieldKey("DoAllRoofMountedVentsMeetTAS100A"),
  buildFieldKey("RoofVentilationPhoto"),
  buildFieldKey("AreAllRoofMountedVentsInstalledPerManufacturerRequirements"),
  buildFieldKey("DoAllGableEndVentsHaveARemovablePerFortified2020HomeStandard"),
  buildFieldKey("GableEndsVentsPhoto"),
];

export const homeStandardAtticVentsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        atticVentsFields(form, errors),
        onValueChange,
        form,
        errors,
        atticVentsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 9: Photo Voltaic Panels
const photoVoltaicPanelsFields = (
  form: Evaluation_HomeStandardEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  buildFieldKey("AreRoofMountedPhotoVoltaicPanelsPresent"),
  {
    fieldKey: buildFieldKey(
      "WerePVPanelsInstalledAndAnchoredPerAnchorageDetails"
    ),
    hidden: !(
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .areRoofMountedPhotoVoltaicPanelsPresent === YesOrNoSelection.Yes
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "WerePVPanelsInstalledAndAnchoredPerAnchorageDetailsWarningBanner"
    ),
    hidden:
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .werePVPanelsInstalledAndAnchoredPerAnchorageDetails !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("PvAnchorageDocumentation"),
    defaultToUpload: false,
    errorMessages: get(errors, "pvAnchorageDocumentation"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .areRoofMountedPhotoVoltaicPanelsPresent === YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("PvPanelImpactRating"),
    hidden: !(
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .areRoofMountedPhotoVoltaicPanelsPresent === YesOrNoSelection.Yes &&
      form.homeStandardEvaluation.eligibilityForm.hasHailSupplement ===
        YesOrNoSelection.Yes
    ),
  },
  {
    fieldKey: buildFieldKey("PvHailRatingDocumentation"),
    defaultToUpload: false,
    errorMessages: get(errors, "pvHailRatingDocumentation"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .areRoofMountedPhotoVoltaicPanelsPresent === YesOrNoSelection.Yes &&
      form.homeStandardEvaluation.eligibilityForm.hasHailSupplement ===
        YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
];

const photoVoltaicPanelsRequiredFields = [
  buildFieldKey("AreRoofMountedPhotoVoltaicPanelsPresent"),
  buildFieldKey("WerePVPanelsInstalledAndAnchoredPerAnchorageDetails"),
  buildFieldKey("PvAnchorageDocumentation"),
  buildFieldKey("PvPanelImpactRating"),
  buildFieldKey("PvHailRatingDocumentation"),
];

export const homeStandardPhotoVoltaicPanelsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        photoVoltaicPanelsFields(form, errors),
        onValueChange,
        form,
        errors,
        photoVoltaicPanelsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 10: Skylights
const skyLightsFields = (
  form: Evaluation_HomeStandardEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  buildFieldKey("AreSkylightsPresent"),
  {
    fieldKey: buildFieldKey(
      "SkylightExistsAndRatingsAndInstallationAreUnknown"
    ),
    hidden: !(
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .areSkylightsPresent === YesOrNoSelection.Yes &&
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof ===
        NewOrExistingRoofTypes.ExistingRoof &&
      form.homeStandardEvaluation.eligibilityForm
        .newConstructionOrExistingHome ===
        NewConstructionOrExistingHomeTypes.ExistingHome
    ),
  },
  {
    fieldKey: buildFieldKey(
      "WereSkylightsInstalledPerManufacturerInstructions"
    ),
    hidden: !(
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .areSkylightsPresent === YesOrNoSelection.Yes &&
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .skylightExistsAndRatingsAndInstallationAreUnknown !== true
    ),
  },
  {
    fieldKey: buildFieldKey(
      "WereSkylightsInstalledPerManufacturerInstructionsWarningBanner"
    ),
    hidden:
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .wereSkylightsInstalledPerManufacturerInstructions !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("SkylightImpactRating"),
    hidden: !(
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .areSkylightsPresent === YesOrNoSelection.Yes &&
      form.homeStandardEvaluation.eligibilityForm.hasHailSupplement ===
        YesOrNoSelection.Yes
    ),
  },
  {
    fieldKey: buildFieldKey(
      "WereSkylightsInstalledPerManufacturerInstructionsWarningBanner"
    ),
    hidden:
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .skylightImpactRating !== SkyLightImpactRatingTypes.NoneOfTheAbove,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("SkylightHailRatingPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "skylightHailRatingPhotos"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .areSkylightsPresent === YesOrNoSelection.Yes &&
      form.homeStandardEvaluation.eligibilityForm.hasHailSupplement ===
        YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SkylightHailRatingDocumentation"),
    defaultToUpload: false,
    errorMessages: get(errors, "skylightHailRatingDocumentation"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardEvaluation.roofingComplianceGeneralInformationForm
        .areSkylightsPresent === YesOrNoSelection.Yes &&
      form.homeStandardEvaluation.eligibilityForm.hasHailSupplement ===
        YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
];

const skyLightsRequiredFields = [
  buildFieldKey("AreSkylightsPresent"),
  buildFieldKey("WereSkylightsInstalledPerManufacturerInstructions"),
  buildFieldKey("SkylightImpactRating"),
  buildFieldKey("SkylightHailRatingPhotos"),
  buildFieldKey("SkylightHailRatingDocumentation"),
];

export const homeStandardSkyLightsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        skyLightsFields(form, errors),
        onValueChange,
        form,
        errors,
        skyLightsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included
export const homeStandardRoofingComplianceGeneralInformationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...basicInformationFields(form),
          ...permitInformationFields(form),
          ...roofStructureFields(form, errors),
          ...roofSheathingFields(form),
          ...dripEdgeFields(form, errors),
          ...skyLightsFields(form, errors),
          ...photoVoltaicPanelsFields(form, errors),
          ...atticVentsFields(form, errors),
          ...siteConditionsFields(form),
          ...sealedRoofDeckFields(form),
        ],
        onValueChange,
        form,
        errors,
        [
          ...basicInformationRequiredFields,
          ...permitInformationRequiredFields,
          ...roofStructureRequiredFields,
          ...roofSheathingRequiredFields,
          ...dripEdgeRequiredFields,
          ...skyLightsRequiredFields,
          ...photoVoltaicPanelsRequiredFields,
          ...atticVentsRequiredFields,
          ...siteConditionsRequiredFields,
          ...sealedRoofDeckRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeStandardIterationEngineRoofingComplianceGeneralInformationFormBuilder =
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      let fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
          modelName,
          [
            ...basicInformationFields(form),
            ...permitInformationFields(form),
            ...siteConditionsFields(form),
            ...roofStructureFields(form, errors),
            ...roofSheathingFields(form),
            ...sealedRoofDeckFields(form),
            ...dripEdgeFields(form, errors),
            ...atticVentsFields(form, errors),
            ...photoVoltaicPanelsFields(form, errors),
            ...skyLightsFields(form, errors),
          ],
          onValueChange,
          form,
          errors,
          [
            ...basicInformationRequiredFields,
            ...permitInformationRequiredFields,
            ...siteConditionsRequiredFields,
            ...roofStructureRequiredFields,
            ...roofSheathingRequiredFields,
            ...sealedRoofDeckRequiredFields,
            ...dripEdgeRequiredFields,
            ...atticVentsRequiredFields,
            ...photoVoltaicPanelsRequiredFields,
            ...skyLightsRequiredFields,
          ],
          {
            FileUploader: WildfireBoxContentExplorer,
            WarningBanner: ReadonlyWarningBannerField,
          }
        ),
      ];
      if (beforeBuild) {
        fields = beforeBuild(fields);
      }
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
  };
