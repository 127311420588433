import { useContext, useEffect, useState } from "react";
import { WildfireEvaluationSteps } from "../../../../foritfied/types/evaluation/Evaluation";
import { ChangeRequestV2 } from "../types/ChangeRequestV2";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { PrimaryCard } from "@ucl/library";
import LandscapeReviewForm from "../../Forms/LandscapeReviewForm/LandscapeReviewForm";
import classNames from "classnames";
import { UnsavedChangesBlockerContext } from "../../Provider/UnsavedChangesBlockerContext";

interface WildfireLandscapeReviewAdminIterationEngineFormsProps {
  selectedTabId: string;
  changeRequests: ChangeRequestV2[];
  evaluationId: string;
  evaluationStep: WildfireEvaluationSteps;
  getChangeRequests?: () => Promise<void>;
  onEvaluationFormInitialized?: () => void;
  fieldsContainerClassName: string;
}

const WildfireLandscapeReviewAdminIterationEngineForms: React.FC<
  WildfireLandscapeReviewAdminIterationEngineFormsProps
> = (props) => {
  const onFormSave = async (fieldKey: string) => {
    const fieldKeyHasChangeRequest = props.changeRequests.some(
      (cr) => cr.evaluationFieldKey === fieldKey
    );
    if (fieldKeyHasChangeRequest) {
      props.getChangeRequests && (await props.getChangeRequests());
    }
  };

  const [ready, setReady] = useState(false);

  const onAllFormsInitialized = () => {
    props.onEvaluationFormInitialized?.();
    setTimeout(() => {
      setReady(true);
    }, 800);
  };

  const loadEvaluationForms =
    props.evaluationStep ===
    WildfireEvaluationSteps.WildfireApplicationIBHSReview;

  const [landscapeReviewFormInitiaized, setLandscapeReviewFormInitiaized] =
    useState(!loadEvaluationForms);

  const [allFormsInitialized, setAllFormsInitialized] = useState(false);

  useEffect(() => {
    const formsInitialized = landscapeReviewFormInitiaized;

    if (formsInitialized) {
      setTimeout(() => {
        setAllFormsInitialized(true);
        onAllFormsInitialized();
      }, 600);
    } else {
      setAllFormsInitialized(false);
    }
  }, [landscapeReviewFormInitiaized]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  return (
    <>
      {!ready && <Loading />}

      <div style={{ visibility: ready ? "visible" : "hidden" }}>
        {!allFormsInitialized && <Loading />}
        <div
          className={classNames(props.fieldsContainerClassName, {
            "evaluation-forms-container-initialized": allFormsInitialized,
          })}
        >
          {loadEvaluationForms && (
            <>
              <PrimaryCard headerContent="Landscape Review">
                <LandscapeReviewForm
                  wildfireEvaluationId={props.evaluationId}
                  isWiePage={true}
                  hideFormControls={true}
                  onInitialized={() => {
                    setLandscapeReviewFormInitiaized(true);
                  }}
                  onFormSave={onFormSave}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                />
              </PrimaryCard>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default WildfireLandscapeReviewAdminIterationEngineForms;
