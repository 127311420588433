import {
  SingleCheckboxFieldValue,
  TextFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";

export enum BaseHomeEvaluationEngineeringComplianceChimneyFormParts {
  EngineeringComplianceChimney = "engineering-compliance-chimney",
}

export interface BaseHomeEvaluationEngineeringComplianceChimneyFormModel
  extends IEngineeringComplianceChimney {}

export interface IEngineeringComplianceChimney {
  attestation219?: SingleCheckboxFieldValue;
  attestation220?: SingleCheckboxFieldValue;
  attestation221?: SingleCheckboxFieldValue;
  chimneyAnchoredToStructureDescription?: TextFieldValue;
}
