import { FieldFormV2Module } from "@ucl/library";
import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../../../../common/Components/Error/ErrorStore";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { useNavigate } from "react-router";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../../types/HomeEvaluationFormModel";
import { homeStandardEvaluationAPIClient } from "../../../../lib/apiClients/homeStandardEvaluationAPIClient";
import { fortifiedHomeStandardRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { useHomeFieldSchemaFactory } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { HomeStandardEvaluationSilverGoldComplianceGeneralInformationFormProps } from "./HomeStandardEvaluationSilverGoldComplianceGeneralInformationForm";
import { BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts } from "../../../../../common/types/BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormModel";
import { homeStandardEvaluationSilverGoldComplianceGeneralInformationAPIClient } from "../../../../lib/apiClients/homeStandardEvaluationSilverGoldComplianceGeneralInformationAPIClient";

function useHomeStandardEvaluationSilverGoldComplianceGeneralInformationForm(
  props: HomeStandardEvaluationSilverGoldComplianceGeneralInformationFormProps
) {
  const { builders, wieBuilders } = useHomeFieldSchemaFactory();
  const navigate = useNavigate();

  const formRef =
    useRef<FieldFormV2Module<Evaluation_HomeStandardEvaluationFormModel>>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [applicationFormModel, setApplicationFormModel] =
    useState<Evaluation_HomeStandardEvaluationFormModel>();

  const [isInitialized, setIsInitialized] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
      props.onInitialized?.();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isInitialized) {
      props.onInitialized?.();
    }
  }, [isInitialized]);

  useEffect(() => {
    //Used to React to External Changes Pushed on the Form (Iteration Engine)
    if (applicationFormModel) {
      setApplicationFormModel({ ...applicationFormModel });
    }
  }, [wieBuilders]);

  useEffect(() => {
    fetchApplicationForm();
  }, []);

  useEffect(() => {
    if (!!applicationFormModel) {
      setApplicationFormModel(applicationFormModel);

      if (props.setFormModel) {
        props.setFormModel(applicationFormModel);
      }
    }
  }, [applicationFormModel]);

  // Differentiates between save and submit
  let shouldSubmit = false;
  const setShouldSubmit = (value: boolean) => {
    shouldSubmit = value;
  };

  const isFirstPartOfForm =
    props.formPart ===
    Object.values(
      BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts
    )[0];

  const isLastPartOfForm =
    props.formPart ===
      Object.values(
        BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts
      ).pop() ||
    applicationFormModel?.homeStandardEvaluation
      .silverGoldComplianceGeneralInformationForm.areAllRequiredFieldsComplete;

  const noBuilders = !builders && !wieBuilders;

  const fetchApplicationForm = async () => {
    setIsLoading(true);
    await homeStandardEvaluationAPIClient
      .getHomeStandardEvaluationFormModel(props.evaluationId)
      .then((response) => {
        setApplicationFormModel((prevModel) => ({
          ...prevModel,
          ...response,
        }));
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Debounce setup
  const handleFormSubmitDebounced = useCallback(
    debounce(
      async (
        form: Evaluation_HomeStandardEvaluationFormModel,
        _value: any,
        fieldProps: BaseFieldProps<any>
      ) => {
        if (fieldProps.fieldKey) {
          await handleFormSubmit(form, fieldProps.fieldKey).then(async () => {
            await props.onFormSave?.(fieldProps.fieldKey || "", _value);
          });
        }
      },
      2000
    ),
    []
  );

  useEffect(() => {
    // Cleanup the debounce function on component unmount
    return () => handleFormSubmitDebounced.cancel();
  }, [handleFormSubmitDebounced]);

  const onFormFieldChange = async (
    form: Evaluation_HomeStandardEvaluationFormModel,
    _value: any,
    fieldProps: BaseFieldProps<any>
  ) => {
    // Call the debounced submit function
    if (!!_value) {
      handleFormSubmitDebounced(form, _value, fieldProps);
      props.setHasUnsavedChanges(true);
    }
  };

  const getApplicationFormPartBuilder = () => {
    if (props.isIterationEnginePage) {
      return wieBuilders?.HomeStandardIterationEngineSilverGoldComplianceGeneralInformationForm;
    }
    switch (props.formPart) {
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.SiteDesignInformation:
        return builders?.HomeStandardSilverGoldSiteDesignInformation;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.WindowsAndSkylights:
        return builders?.HomeStandardSilverGoldWindowsAndSkylights;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.EntryDoors:
        return builders?.HomeStandardSilverGoldEntryDoors;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.Garage:
        return builders?.HomeStandardSilverGoldGarage;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.GableEnds:
        return builders?.HomeStandardSilverGoldGableEnds;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.Soffits:
        return builders?.HomeStandardSilverGoldSoffits;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.Chimneys:
        return builders?.HomeStandardSilverGoldChimneys;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.AttachedStructures:
        return builders?.HomeStandardSilverGoldAttachedStructures;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.DesignPressureRatedOpenings:
        return builders?.HomeStandardSilverGoldPressureRatedOpenings;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.WallSheathing:
        return builders?.HomeStandardSilverGoldWallSheathing;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.ContinuousLoadPath:
        return builders?.HomeStandardSilverContinuousLoadPath;
      default:
        return builders?.HomeStandardSilverGoldComplianceGeneralInformationForm;
    }
  };

  const areAllRequiredFieldsOnFormSectionComplete = () => {
    if (!props.formPart || !applicationFormModel) {
      return false;
    }

    switch (props.formPart) {
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.SiteDesignInformation:
        return applicationFormModel.homeStandardEvaluation
          .silverGoldComplianceGeneralInformationForm
          .isSiteDesignInformationComplete;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.WindowsAndSkylights:
        return applicationFormModel.homeStandardEvaluation
          .silverGoldComplianceGeneralInformationForm
          .isWindowsAndSkylightsComplete;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.EntryDoors:
        return applicationFormModel.homeStandardEvaluation
          .silverGoldComplianceGeneralInformationForm.isEntryDoorsComplete;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.Garage:
        return applicationFormModel.homeStandardEvaluation
          .silverGoldComplianceGeneralInformationForm.isGarageComplete;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.GableEnds:
        return applicationFormModel.homeStandardEvaluation
          .silverGoldComplianceGeneralInformationForm.isGableEndsComplete;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.Soffits:
        return applicationFormModel.homeStandardEvaluation
          .silverGoldComplianceGeneralInformationForm.isSoffitsComplete;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.Chimneys:
        return applicationFormModel.homeStandardEvaluation
          .silverGoldComplianceGeneralInformationForm.isChimneysComplete;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.AttachedStructures:
        return applicationFormModel.homeStandardEvaluation
          .silverGoldComplianceGeneralInformationForm
          .isAttachedStructuresComplete;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.DesignPressureRatedOpenings:
        return applicationFormModel.homeStandardEvaluation
          .silverGoldComplianceGeneralInformationForm
          .isDesignPressureRatedOpeningsComplete;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.WallSheathing:
        return applicationFormModel.homeStandardEvaluation
          .silverGoldComplianceGeneralInformationForm.isWallSheathingComplete;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.ContinuousLoadPath:
        return applicationFormModel.homeStandardEvaluation
          .silverGoldComplianceGeneralInformationForm
          .isContinuousLoadPathComplete;
      default:
        return false;
    }
  };

  const areAllRequiredFieldsComplete =
    areAllRequiredFieldsOnFormSectionComplete();

  const handleFormSubmit = async (
    form: Evaluation_HomeStandardEvaluationFormModel,
    fieldKey?: string
  ) => {
    setApplicationFormModel(form);

    await (shouldSubmit
      ? homeStandardEvaluationSilverGoldComplianceGeneralInformationAPIClient.submitHomeStandardSilverGoldComplianceGeneralInformationForm(
          form.id,
          form.homeStandardEvaluation
            .silverGoldComplianceGeneralInformationForm,
          props.formPart
        )
      : homeStandardEvaluationSilverGoldComplianceGeneralInformationAPIClient.updateHomeStandardSilverGoldComplianceGeneralInformationForm(
          form.id,
          form.homeStandardEvaluation
            .silverGoldComplianceGeneralInformationForm,
          fieldKey
        )
    )
      .then(async (response) => {
        handleFormResponse(response);
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while saving the form",
            intent: Intent.DANGER,
          });
        }
        throw error;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleFormResponse = async (
    response: Evaluation_HomeStandardEvaluationFormModel
  ) => {
    props.setHasUnsavedChanges(false);

    if (shouldSubmit) {
      setApplicationFormModel(response);

      if (isLastPartOfForm) {
        navigate(
          `${fortifiedHomeStandardRoutePrefix}/checklist/${props.evaluationId}`
        );
      } else {
        // Navigate to the next visible form part
        const currentFormPartIndex = Object.values(
          BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts
        ).indexOf(props.formPart!);

        // Find the next visible form part
        let nextFormPart = null;
        for (
          let i = currentFormPartIndex + 1;
          i <
          Object.values(
            BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts
          ).length;
          i++
        ) {
          const formPart = Object.values(
            BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts
          )[i];

          // Check if formChecklistParts exists and if the form part is hidden
          const checklistPart = props.applicationChecklistParts?.find(
            (part) => part.formPart === formPart
          );

          // If formChecklistParts doesn't exist, assume the part is not hidden
          if (!checklistPart || !checklistPart.hidden) {
            nextFormPart = formPart;
            break;
          }
        }

        if (nextFormPart) {
          navigate(
            `${fortifiedHomeStandardRoutePrefix}/${props.evaluationId}/silver-gold-compliance-general-information/${nextFormPart}`
          );
        }
      }

      // }
    } else {
      handlePartialResponseFormUpdate(response);
    }
  };

  const handlePartialResponseFormUpdate = (
    response: Evaluation_HomeStandardEvaluationFormModel
  ) => {
    // This is used to combat save on blur issues. This only updates fields that are essential to the form.
    // These are set in the API response. Field Counts and sub section completion status
    setApplicationFormModel((prevState) => {
      if (!prevState) return prevState;

      const {
        areAllRequiredFieldsComplete,
        siteDesignInformationRequiredCount,
        isSiteDesignInformationComplete: isSiteDesignInformtionComplete,
        windowsAndSkylightsRequiredCount,
        isWindowsAndSkylightsComplete: areWindowsAndSkylightsComplete,
        entryDoorsRequiredCount,
        isEntryDoorsComplete: areEntryDoorsComplete,
        garageRequiredCount,
        isGarageComplete,
        gableEndsRequiredCount,
        isGableEndsComplete: areGableEndsComplete,
        soffitsRequiredCount,
        isSoffitsComplete: areSoffitsComplete,
        chimneysRequiredCount,
        isChimneysComplete: areChimneysComplete,
        attachedStructuresRequiredCount,
        isAttachedStructuresComplete: areAttchedStructuresComplete,
        designPressureRatedOpeningsRequiredCount,
        isDesignPressureRatedOpeningsComplete:
          areDesignPressureRatedOpeningsComplete,
        wallSheathingRequiredCount,
        isWallSheathingComplete,
        continuousLoadPathRequiredCount,
        isContinuousLoadPathComplete,
      } = response.homeStandardEvaluation
        .silverGoldComplianceGeneralInformationForm;

      const { canProceedToApplicationFee } = response.homeStandardEvaluation;

      return {
        ...prevState,
        homeStandardEvaluation: {
          ...prevState.homeStandardEvaluation,
          canProceedToApplicationFee,
          silverGoldComplianceGeneralInformationForm: {
            ...prevState.homeStandardEvaluation
              .silverGoldComplianceGeneralInformationForm,
            areAllRequiredFieldsComplete,
            siteDesignInformationRequiredCount,
            isSiteDesignInformationComplete: isSiteDesignInformtionComplete,
            windowsAndSkylightsRequiredCount,
            isWindowsAndSkylightsComplete: areWindowsAndSkylightsComplete,
            entryDoorsRequiredCount,
            isEntryDoorsComplete: areEntryDoorsComplete,
            garageRequiredCount,
            isGarageComplete,
            gableEndsRequiredCount,
            isGableEndsComplete: areGableEndsComplete,
            soffitsRequiredCount,
            isSoffitsComplete: areSoffitsComplete,
            chimneysRequiredCount,
            isChimneysComplete: areChimneysComplete,
            attachedStructuresRequiredCount,
            isAttachedStructuresComplete: areAttchedStructuresComplete,
            designPressureRatedOpeningsRequiredCount,
            isDesignPressureRatedOpeningsComplete:
              areDesignPressureRatedOpeningsComplete,
            wallSheathingRequiredCount,
            isWallSheathingComplete,
            continuousLoadPathRequiredCount,
            isContinuousLoadPathComplete,
          },
        },
      };
    });
  };

  return {
    builders,
    wieBuilders,
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    applicationFormModel,
    isFirstPartOfForm,
    isLastPartOfForm,
    getApplicationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    noBuilders,
    containerRef,
    setShouldSubmit,
    areAllRequiredFieldsComplete,
  };
}

export default useHomeStandardEvaluationSilverGoldComplianceGeneralInformationForm;
