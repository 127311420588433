import { makeObservable, observable, runInAction } from "mobx";
import {
  ConfirmDialogOptions,
  PostDesignationFilesDialogOptions,
} from "../components/Dialogs/types";
import { CommentType } from "../../common/apiClients/comments/types";

class DialogsViewerStore {
  public isComplianceLetterSendForwardDialogOpen = false;
  public isIBHSReviewApprovalDialogOpen = false;
  public ibhsReviewApprovalDialogOptions?: {
    afterApproval?: () => void | undefined;
  };

  public isAuditorApprovalDialogOpen = false;
  public auditorApprovalDialogOptions?: {
    afterApproval?: () => void | undefined;
  };
  public isAuditorRejectionDialogOpen = false;
  public isAuditorRequestChangeDialogOpen = false;
  public isEvaluatorReviewSubmissionDialogOpen = false;
  public isEvaluationCreationDialogOpen = false;
  public isViewCertificateDialogOpen = false;
  public isWithdrawEvaluationDialogOpen = false;
  public isCommentsDialogOpen = false;
  public commentDialogOpenOptions?: {
    evaluationId: string;
    fortifiedId: string;
    productType_AsString: string;
    commentType?: CommentType;
  };

  // User
  public isUserUpdateDialogOpen = false;
  public userUpdateDialogOpenOptions?: { userId: string };

  // Payment Credits
  public isApplyPaymentCreditDialogOpen = false;
  public isPaymentCreditDialogOpen = false;
  public paymentCreditDialogOpenOptions?: {
    paymentCreditId?: string;
    isCreate: boolean;
  };

  //Auditor Comments
  public isAuditorNotesDialogOpen = false;
  public auditorNotesDialogOpenOptions?: {
    auditorNotes: string;
    evaluationId: string;
  };

  //Evaluator Comments
  public isEvaluatorNotesDialogOpen = false;
  public evaluatorNotesDialogOpenOptions?: {
    evaluatorNotes: string;
    evaluationId: string;
  };

  //Rejection Dialog
  public isRejectionReasonDialogOpen = false;
  public auditorRejectionDialogOptions?: {
    afterRejection?: () => void | undefined;
  };

  // Admin Dialog
  public isAdminDialogOpen = false;
  public adminDialogOpenOptions?: { evaluationId: string };

  // Legacy Evaluation Files Dialog
  public isLegacyEvaluationFilesDialogOpen = false;

  // Legacy Evaluation Data Dialog
  public isLegacyEvaluationDataDialogOpen = false;

  // Non Profit Proceed to IBHS Review
  public isProceedToIBHSReviewDialogOpen = false;

  // Confirm
  public isConfirmDialogOpen = false;
  public confirmDialogOptions?: ConfirmDialogOptions;

  //Post Designation Files
  public isPostDesignationFilesDialogOpen = false;
  public postDesignationFilesDialogOptions?: PostDesignationFilesDialogOptions;

  constructor() {
    makeObservable(this, {
      isComplianceLetterSendForwardDialogOpen: observable,
      isIBHSReviewApprovalDialogOpen: observable,
      isAuditorApprovalDialogOpen: observable,
      isAuditorRequestChangeDialogOpen: observable,
      isAuditorRejectionDialogOpen: observable,
      isEvaluatorReviewSubmissionDialogOpen: observable,
      isEvaluationCreationDialogOpen: observable,
      isViewCertificateDialogOpen: observable,

      // User
      isUserUpdateDialogOpen: observable,
      userUpdateDialogOpenOptions: observable,

      // Payment Credits
      isApplyPaymentCreditDialogOpen: observable,
      isPaymentCreditDialogOpen: observable,
      paymentCreditDialogOpenOptions: observable,

      //Withdraw
      isWithdrawEvaluationDialogOpen: observable,

      //Comments
      isCommentsDialogOpen: observable,
      commentDialogOpenOptions: observable,

      //Auditor Comments
      isAuditorNotesDialogOpen: observable,
      auditorNotesDialogOpenOptions: observable,

      //Evaluator Comments
      isEvaluatorNotesDialogOpen: observable,
      evaluatorNotesDialogOpenOptions: observable,

      //Rejection Dialog
      isRejectionReasonDialogOpen: observable,

      // Admin Dialog
      isAdminDialogOpen: observable,

      // Legacy Evaluation Files Dialog
      isLegacyEvaluationFilesDialogOpen: observable,

      // Legacy Evaluation Data Dialog
      isLegacyEvaluationDataDialogOpen: observable,

      // Non Profit Proceed to IBHS Review
      isProceedToIBHSReviewDialogOpen: observable,

      // Confirm
      isConfirmDialogOpen: observable,
      confirmDialogOptions: observable,

      //Post Designation Files
      isPostDesignationFilesDialogOpen: observable,
    });
  }

  setIsComplianceLetterSendForwardDialogOpen = (
    isComplianceLetterSendForwardDialogOpen: boolean
  ) => {
    runInAction(() => {
      this.isComplianceLetterSendForwardDialogOpen =
        isComplianceLetterSendForwardDialogOpen;
    });
  };

  setIsIBHSReviewApprovalDialogOpen = (
    isIBHSReviewApprovalDialogOpen: boolean,
    approvalDialogOpenOptions?: {
      afterApproval?: () => void;
    }
  ) => {
    runInAction(() => {
      this.isIBHSReviewApprovalDialogOpen = isIBHSReviewApprovalDialogOpen;
      this.ibhsReviewApprovalDialogOptions = approvalDialogOpenOptions;
    });
  };

  setIsAuditorApprovalDialogOpen = (
    isAuditorApprovalDialogOpen: boolean,
    approvalDialogOpenOptions?: {
      afterApproval?: () => void;
    }
  ) => {
    runInAction(() => {
      this.isAuditorApprovalDialogOpen = isAuditorApprovalDialogOpen;
      this.auditorApprovalDialogOptions = approvalDialogOpenOptions;
    });
  };

  setIsAuditorRejectionDialogOpen = (
    isAuditorRejectionDialogOpen: boolean,
    rejectionReasonDialogOpenOptions?: {
      afterRejection?: () => void;
    }
  ) => {
    runInAction(() => {
      this.isAuditorRejectionDialogOpen = isAuditorRejectionDialogOpen;
      this.auditorRejectionDialogOptions = rejectionReasonDialogOpenOptions;
    });
  };

  setIsAuditorRequestChangeDialogOpen = (
    isAuditorRequestChangeDialogOpen: boolean
  ) => {
    runInAction(() => {
      this.isAuditorRequestChangeDialogOpen = isAuditorRequestChangeDialogOpen;
    });
  };

  setIsEvaluatorReviewSubmissionDialogOpen = (
    isEvaluatorReviewSubmissionDialogOpen: boolean
  ) => {
    runInAction(() => {
      this.isEvaluatorReviewSubmissionDialogOpen =
        isEvaluatorReviewSubmissionDialogOpen;
    });
  };

  setIsEvaluationCreationDialog = (isEvaluationCreationDialogOpen: boolean) => {
    runInAction(() => {
      this.isEvaluationCreationDialogOpen = isEvaluationCreationDialogOpen;
    });
  };

  setIsViewCertificateDialogOpen = (isViewCertificateDialogOpen: boolean) => {
    runInAction(() => {
      this.isViewCertificateDialogOpen = isViewCertificateDialogOpen;
    });
  };

  setIsUserUpdateDialogOpen = (
    isUserUpdateDialogOpen: boolean,
    userUpdateDialogOpenOptions?: { userId: string }
  ) => {
    runInAction(() => {
      this.isUserUpdateDialogOpen = isUserUpdateDialogOpen;
      this.userUpdateDialogOpenOptions = userUpdateDialogOpenOptions;
    });
  };

  setIsApplyPaymentCreditDialogOpen = (
    isApplyPaymentCreditDialogOpen: boolean
  ) => {
    runInAction(() => {
      this.isApplyPaymentCreditDialogOpen = isApplyPaymentCreditDialogOpen;
    });
  };

  setIsPaymentCreditDialogOpen = (
    isPaymentCreditDialogOpen: boolean,
    paymentCreditDialogOpenOptions?: {
      paymentCreditId?: string;
      isCreate: boolean;
    }
  ) => {
    runInAction(() => {
      this.isPaymentCreditDialogOpen = isPaymentCreditDialogOpen;
      this.paymentCreditDialogOpenOptions = paymentCreditDialogOpenOptions;
    });
  };

  setIsWithdrawEvaluationDialogOpen = (
    isWithdrawEvaluationDialogOpen: boolean
  ) => {
    runInAction(() => {
      this.isWithdrawEvaluationDialogOpen = isWithdrawEvaluationDialogOpen;
    });
  };

  setIsCommentsDialogOpen = (
    isCommentsDialogOpen: boolean,
    commentDialogOpenOptions?: {
      evaluationId: string;
      fortifiedId: string;
      productType_AsString: string;
      commentType?: CommentType;
    }
  ) => {
    runInAction(() => {
      this.isCommentsDialogOpen = isCommentsDialogOpen;
      this.commentDialogOpenOptions = commentDialogOpenOptions;
    });
  };

  setIsAuditorNotesDialogOpen = (
    isAuditorNotesDialogOpen: boolean,
    auditorNotesDialogOpenOptions?: {
      auditorNotes: string;
      evaluationId: string;
    }
  ) => {
    runInAction(() => {
      this.isAuditorNotesDialogOpen = isAuditorNotesDialogOpen;
      this.auditorNotesDialogOpenOptions = auditorNotesDialogOpenOptions;
    });
  };
  setIsEvaluatorNotesDialogOpen = (
    isEvaluatorNotesDialogOpen: boolean,
    evaluatorNotesDialogOpenOptions?: {
      evaluatorNotes: string;
      evaluationId: string;
    }
  ) => {
    runInAction(() => {
      this.isEvaluatorNotesDialogOpen = isEvaluatorNotesDialogOpen;
      this.evaluatorNotesDialogOpenOptions = evaluatorNotesDialogOpenOptions;
    });
  };
  setIsRejectionReasonDialogOpen = (isRejectionReasonDialogOpen: boolean) => {
    runInAction(() => {
      this.isRejectionReasonDialogOpen = isRejectionReasonDialogOpen;
    });
  };

  setIsAdminDialogOpen = (
    isAdminDialogOpen: boolean,
    adminDialogOpenOptions?: { evaluationId: string }
  ) => {
    runInAction(() => {
      this.isAdminDialogOpen = isAdminDialogOpen;
      this.adminDialogOpenOptions = adminDialogOpenOptions;
    });
  };

  setIsLegacyEvaluationFilesDialogOpen = (
    isLegacyEvaluationFilesDialogOpen: boolean
  ) => {
    runInAction(() => {
      this.isLegacyEvaluationFilesDialogOpen =
        isLegacyEvaluationFilesDialogOpen;
    });
  };

  setIsLegacyEvaluationDataDialogOpen = (
    isLegacyEvaluationDataDialogOpen: boolean
  ) => {
    runInAction(() => {
      this.isLegacyEvaluationDataDialogOpen = isLegacyEvaluationDataDialogOpen;
    });
  };
  setIsProceedToIBHSReviewDialogOpen = (
    isProceedToIBHSReviewDialogOpen: boolean
  ) => {
    runInAction(() => {
      this.isProceedToIBHSReviewDialogOpen = isProceedToIBHSReviewDialogOpen;
    });
  };
  setIsConfirmDialogOpen = (
    isOpen: boolean,
    confirmDialogOptions?: ConfirmDialogOptions
  ) => {
    runInAction(() => {
      this.isConfirmDialogOpen = isOpen;
      this.confirmDialogOptions = confirmDialogOptions;
    });
  };
  confirm = (confirmDialogOptions: ConfirmDialogOptions) => {
    this.setIsConfirmDialogOpen(true, confirmDialogOptions);
  };

  setIsPostDesignationFilesDialogOpen = (
    isPostDesignationFilesDialogOpen: boolean,
    postDesignationFilesDialogOptions?: PostDesignationFilesDialogOptions
  ) => {
    runInAction(() => {
      this.isPostDesignationFilesDialogOpen = isPostDesignationFilesDialogOpen;
      this.postDesignationFilesDialogOptions =
        postDesignationFilesDialogOptions;
    });
  };
}

export const dialogsViewerStore = new DialogsViewerStore();
