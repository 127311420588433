import { dialogStore } from "@ucl/library";
import { DialogProps } from "@ucl/library/lib/components/Dialogs/Dialog/Dialog";
import { CreateHomeEvaluationForm } from "./CreateHomeEvaluationForm";

const dialogIdBase = "home-create-evaluation-dialog";

const getDialogId = (): string => `${dialogIdBase}`;

const buildCreateHomeEvaluationDialog = (): DialogProps => {
  return {
    id: getDialogId(),
    className: dialogIdBase,
    header: "Create Home Evaluation",
    allowDragging: false,
    content: () => (
      <div>
        <CreateHomeEvaluationForm />
      </div>
    ),
  };
};

export const openCreateHomeEvaluationDialog = () =>
  dialogStore.openDialog(buildCreateHomeEvaluationDialog());

export const closeCreateHomeEvaluationDialog = () =>
  dialogStore.closeDialog(getDialogId());
